import { flatten } from 'flat';

let english = {
  blueshieldca: {
    footer:
      '© Blue Shield of California 1999-2021. All rights reserved. Blue Shield of California is an independent member of the Blue Shield Association. Health insurance products are offered by Blue Shield of California Life & Health Insurance Company. Health plans are offered by Blue Shield of California.',
  },
  bryanPSecureVerification: {
    pageTitle: 'BryanP - Secure Verification',
  },
  complianceButton: {
    label: 'Send Complaince Message',
    verifyText: 'This message may be stored. Learn more at: ',
    error: 'Something went wrong...',
  },
  defaultSecureVerification: {
    pageTitle: 'Secure Verification',
    title: 'Account Verification',
    email: 'Email address',
    description: 'Please provide your personal information.',
    loading: 'Validating social identity, please wait...',
    firstName: 'First Name',
    lastName: 'Last Name',
    required: 'Required fields',
    submit: 'Submit',
    alertError: 'Something went wrong. Please contact your agent.',
    button: {
      label: 'Generate Link',
      verifyText:
        'We need to verify you are a customer here: {link} Verification will be submitted via your web browser.',
      error: 'Short url generation failed, long url used instead',
    },
  },
  defaultSocialSecureTransfer: {
    error: {
      pageTitle: 'SST - Error',
      title: 'Error!',
      description: 'Something went wrong.',
      nextStep: 'Next step: send us a Direct Message.',
      tellUsMore: "Tell us more about what's going on so we can get things resolved.",
      directMessage: 'Direct Message',
    },
    success: {
      pageTitle: 'SST - Success',
      title: 'Success!',
      description: 'Thanks for logging into your fitbit account.',
      nextStep: 'Next step: send us a Direct Message.',
      tellUsMore: "Tell us more about what's going on so we can get started.",
      directMessage: 'Direct Message',
    },
    pageTitle: 'Social Secure Transfer',
    title: 'Account Verification',
    email: 'Email address',
    description: 'Please provide your personal information.',
    loading: 'Validating social identity, please wait...',
    firstName: 'First Name',
    lastName: 'Last Name',
    required: 'Required fields',
    submit: 'Submit',
  },
  error: {
    pageTitle: 'Videotron - Error',
    title: 'Error!',
    description: 'Something went wrong.',
    nextStep: 'Next step: send us a Direct Message.',
    tellUsMore: "Tell us more about what's going on so we can get things resolved.",
    directMessage: 'Direct Message',
  },
  fitbit: {
    success: {
      pageTitle: 'fitbit - Success',
      title: 'Success!',
      description: 'Thanks for logging into your fitbit account.',
      nextStep: 'Next step: send us a Direct Message.',
      tellUsMore: "Tell us more about what's going on so we can get started.",
      directMessage: 'Direct Message',
    },
    error: {
      pageTitle: 'fitbit - Error',
      title: 'Error!',
      description: 'Something went wrong.',
      nextStep: 'Next step: send us a Direct Message.',
      tellUsMore: "Tell us more about what's going on so we can get things resolved.",
      directMessage: 'Direct Message',
    },
  },
  fidelity: {
    landing: {
      pageTitle: 'Secure Verification',
      firstText: 'You will be redirected to a Fidelity login page shortly.',
      secondText:
        'After you authenticate, you will be connected to a secure chat with a Fidelity Customer Care Associate.',
      thirdText: 'Having problems?',
    },
    success: {
      pageTitle: 'Fidelity - Secure Chat',
    },
    notFound: {
      pageTitle: 'Error',
      description: 'Page not found',
      nextStep: 'Please return to the social channel and contact your agent to request a new link.',
    },
    expired: {
      pageTitle: 'Expired Link',
      description: 'This verification link is no longer valid',
      nextStep: 'Please return to the social channel and contact your agent to request a new link.',
    },
    error: {
      pageTitle: 'Account Verification Error',
      description: "We're sorry, but we were unable to verify your account",
      nextStep: 'Please return to the social channel and contact your agent to try again.',
    },
  },
  quickbooks: {
    landing: {
      pageTitle: 'Intuit Quickbooks - Social Care Secure Verification',
      firstText: 'You will be redirected to social login page shortly.',
      secondText:
        'After you authenticate, you will be connected to a secure chat with a Intuit Quickbooks Customer Care Associate.',
      thirdText: 'Having problems?',
    },
    success: {
      pageTitle: 'Intuit Quickbooks - Social Care Secure Chat Session',
    },
    notFound: {
      pageTitle: 'Intuit Quickbooks - Error',
      title: 'Error',
      description: 'Page not found.',
      nextStep:
        'Please return to the original social channel and contact our care team to request a new link.',
    },
    expired: {
      pageTitle: 'Intuit Quickbooks - Expired Link',
      title: 'Expired Link',
      description: 'Sorry, this verification link is no longer valid.',
      nextStep:
        'Please return to the original social channel and contact our care team to request a new link.',
    },
    error: {
      pageTitle: 'Intuit Quickbooks - Account Verification Error',
      title: 'Account Verification Error',
      description: 'Sorry, but we were unable to verify your account.',
      nextStep:
        'Please return to the original social channel and contact our care team to request a new link.',
    },
    socialVerificationForm: {
      loading: 'Validating link, please wait...',
      description:
        'Please provide your personal information, then you will be taken to a secured chat.',
      verified:
        'Your data has already been verified, please re-type the email previously entered to get access to your secured chat.',
      submittedAndVerified: 'You have already been verified.',
      linkSubmittedAndVerified: 'Click here to continue',
      phone: 'Phone number',
      name: 'Name',
      companyName: 'Company name',
      companyId: 'Company id',
    },
  },
  manualc01: {
    landing: {
      pageTitle: 'Fidelity - Secure Verification',
    },
    success: {
      pageTitle: 'Fidelity - Success',
    },
    error: {
      pageTitle: 'Fidelity - Error',
    },
  },
  koodoButton: {
    label: 'Koodo Verification',
  },
  provide: {
    heading: 'Please provide your personal information.',
    headingTwitter: '{name}, Please provide your personal information.',
    aditionalInfo: 'Additional info. Please fill at least 2 of these fields.',
  },
  rocksnropes: {
    button: {
      label: 'Generate Link',
      verifyText:
        'We need to verify you are a customer here: {link} Verification will be submitted via your web browser.',
      error: 'Short url generation failed, long url used instead',
    },
    success: {
      pageTitle: "Rocks N' Ropes - Success",
      title: 'Success!',
      description: 'Thanks for providing your account details.',
      tellUsMore:
        'Thanks for providing your account details. Your agent will get right back to you',
    },
  },
  salesforceButton: {
    label: 'Create Salesforce Ticket',
  },
  socialVerificationButton: {
    label: 'Send to Social Verification',
    verifyText:
      'We need to verify you are a customer here: {link} Verification will be submitted via your web browser.',
    error: 'Short url generation failed, long url used instead',
  },
  socialVerificationForm: {
    pageTitle: 'Videotron - Secure Verification',
    title: 'Account Verification',
    email: 'Email address',
    description: 'Please provide your personal information.',
    loading: 'Validating social identity, please wait...',
    firstName: 'First Name',
    lastName: 'Last Name',
    address: 'Address',
    zipCode: 'Postal Code',
    phone: 'Phone number linked to the Videotron account',
    birthday: 'Date of birth',
    birthdayPlaceholder: 'Enter Date of Birth',
    mothermname: "Mother's maiden name",
    accountPassword: 'Secret code (if applicable)',
    required: 'Required fields',
    submit: 'Submit',
    alertError: 'Something went wrong. Please contact your agent.',
  },
  success: {
    pageTitle: 'Videotron - Success',
    title: 'Success!',
    description: 'Thanks for providing your account details.',
    nextStep: 'Next step: send us a Direct Message.',
    tellUsMore: "Tell us more about what's going on so we can get started.",
    directMessage: 'Direct Message',
  },
  talkTalkButton: {
    label: 'Secure Verification',
  },
  telusSecureVerification: {
    pageTitle: 'TELUS - Secure Verification',
    title: 'Account Verification',
    email: 'Email address',
    description: 'Please provide your personal information.',
    landing: {
      firstText: 'Validating your identity, please wait...',
      secondText:
        "After your social identity is validated, you will be asked to sign into your Telus account to verify you're a customer.",
    },
    error: {
      pageTitle: 'TELUS - Account Verification Error',
      description: "We're sorry, but we were unable to verify your account",
      nextStep: 'Please return to the social channel and contact your agent to try again.',
    },
    button: {
      label: 'Telus Verification',
      verifyText:
        'We need to verify you are a customer here: {link} Verification will be submitted via your web browser.',
      error: 'Short url generation failed, long url used instead',
    },
  },
  koodoSecureVerification: {
    pageTitle: 'KOODO - Secure Verification',
    title: 'Account Verification',
    email: 'Email address',
    description: 'Please provide your personal information.',
    landing: {
      firstText: 'Validating your identity, please wait...',
      secondText:
        "After your social identity is validated, you will be asked to sign into your Koodo account to verify you're a customer.",
    },
    error: {
      pageTitle: 'KOODO - Account Verification Error',
      description: "We're sorry, but we were unable to verify your account",
      nextStep: 'Please return to the social channel and contact your agent to try again.',
    },
    button: {
      label: 'Koodo Verification',
      verifyText:
        'We need to verify you are a customer here: {link} Verification will be submitted via your web browser.',
      error: 'Short url generation failed, long url used instead',
    },
  },
  validations: {
    required: 'This field is required.',
    email: 'It is not a valid email.',
    phone: 'Please include a valid phone number.',
    aditionalInfo: 'Please fill at least 2 fields.',
    atLeastOneFormOfContact: 'Please provide at least one additional form of information.',
  },
  generic: {
    socialVerificationButton: {
      label: 'Verify Customer',
      verifyText:
        'We need to verify you are a customer here: {link} Verification will be submitted via your web browser.',
      error: 'Short url generation failed, long url used instead',
    },
    verifyUserButton: {
      label: 'Set to Verify',
    },
    unverifyUserButton: {
      label: 'Unverify User',
    },
  },
  synchrony: {
    landing: {
      converstationNote: 'Customer submitted authentication form',
    },
    svButton: {
      primaryButton: {
        label: 'Generate Authentication Link',
        verifyText:
          'We need to verify you are a customer here: {link} Verification will be submitted via your web browser.',
        error: 'Short url generation failed, long url used instead',
      },
      verifyButton: {
        label: 'Customer Authentication Completed',
        error: 'There was an error authenticating customer',
        conversationNote: 'Customer authentication completed by associate.',
      },
      unverifyButton: {
        label: 'Remove Customer Authentication',
        error: 'There was an error removing customer authentication',
        conversationNote: 'Authentication removed/expired. Customer authentication required.',
      },
    },
  },
  dominion: {
    landing: {
      pageTitle: 'Dominion Energy | Secure Chat',
    },
    success: {
      pageTitle: 'Dominion Energy | Secure Chat',
    },
    expired: {
      pageTitle: 'Dominion Energy | Secure Chat',
      description: 'This verification link is no longer valid',
      nextStep: 'Please return to the social channel and contact your agent to request a new link.',
    },
    error: {
      pageTitle: 'Dominion Energy | Secure Chat',
      firstText:
        "We're sorry, but we were unable to verify your account. Please return to the social channel you started on and attempt to contact us again for support.",
    },
    businessHours: {
      pageTitle: 'Dominion Energy | Secure Chat',
      firstText:
        'Thanks for reaching out. We’re sorry we missed you. Our hours are typically Monday-Friday, 8am-5pm. We will get back to you soon.',
    },
  },
};

export default flatten(english);
