import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';

import { makeStyles } from '@material-ui/styles';
import { Grid, CircularProgress } from '@material-ui/core';
import $ from 'jquery';
import {isMobile, isMobileOnly, isIOS, withOrientationChange, isTablet} from 'react-device-detect';

import { API_LOCAL, API_LAMBDA, IS_LOCAL_DEV, MANUALC01_GUID } from '../../constants';
import './fonts/style.css';

const ErrorPath = `/${MANUALC01_GUID}/error`;

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    flexGrow: 1,
    padding: '0 20',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  requiredLabel: {
    color: 'rgba(0, 0, 0, 0.54)',
    margin: '0 0 0 8px',
  },
  modernChatDesktop: {
    left: '20% !important',
    width: '60% !important',
    bottom: '10% !important',
    position: 'relative !important',
    maxWidth: '100% !important',
    minHeight: '600px !important',
    maxHeight:  '100% !important'
  },
  modernChatMobile: {
    maxHeight: '95% !important' ,
    position: 'relative !important',
    width: '86% !important',
    left: '7% !important',
    minHeight: '550px !important'
  },
  modernChatMobileLandscape: {
    width: '70% !important',
    minHeight: '500px !important',
    left: '15% !important',
    maxHeight: '100% !important' ,
    position: 'relative !important',
  },
  modernChatTablet: {
    left: '10% !important',
    position: 'relative !important',
    maxHeight: '100% !important' ,
    minHeight: '600px !important',
    width: '80% !important',
  },
  modernChatTabletLandscape: {
    minWidth: '70% !important',
    left: '15% !important',
    maxHeight: '100% !important' ,
    minHeight: '600px !important',
    position: 'relative !important',
  },
  modernChatContent: {
    width: '100% !important',
  },
  title: {
    flexGrow: 1,
    fontSize: '2.33em',
    lineHeight: 1.25,
    fontWeight: 'bold',
  },
  description: {
    lineHeight: 1.55,
    margin: '0.5em 0',
  },
  button: {
    textAlign: 'center',
    fontWeight: 'bold',
    lineHeight: 1,
    borderRadius: '4px',
    letterSpacing: '0.04em',
    padding: '15px 20px',
    //fontFamily: "'Montserrat', sans-serif",
    '&:hover': {
      opacity: 0.7,
    },
  },
  customForm: {
    paddingTop: 20,
    //maxWidth: '500px',
    //border: '1px solid #e2e2e2',
    margin: '0 auto',
  },
  progressWrapper: {
    paddingBottom: '2rem',
  },
  progress: {
    margin: '20px auto',
    color: '#118741'
  }

}));

let Success = (props) => {
  const classes = useStyles();
  const { isLandscape, isPortrait, apiManager, history } = props;

  const intl = useIntl();

  const [init, setInit] = useState(false);
  const [loading, setLoading] = useState(true);
  const [customer, setCustomer] = useState('');
  const [chatData, setChatData] = useState(null);

  useEffect(() => {
    const pageTitle = intl.formatMessage({ id: 'manualc01.success.pageTitle' });
    if (document.title !== pageTitle) {
      document.title = pageTitle;
    }
  }, []);

  const getModernChatData = async () => {
    //is there another way to get this id?
    const customer = sessionStorage.getItem('client');
    const responseData = await apiManager.getChatJson({ id: customer });

    if (responseData) {
      setChatData(responseData);
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    console.log(`customer: ${customer}`)
    setLoading(true);
    getModernChatData();
    //onSuccess('token');

    setInit(true);
  }, []);

  const injectScript = () => {
    window.addEventListener('khorosInit', async (event) => {
      const { givenName, properties } = chatData;
      if (event.detail) {
        event.detail.sdk.updateUser({
          givenName,
          properties,
        });
      }

      setModernChatStyles();

      if (window.Brandmessenger) {
        window.Brandmessenger.open();
      }

      setLoading(false);
    });

    const { companyKey, appId, widgetId, jwt, userId } = chatData;

    window.KHOROS_CONFIG = {
      companyKey: companyKey,
      appId: appId,
      widgetId: widgetId,
      jwt,
      userId,
    };

    var scriptTag = document.createElement('script');
    scriptTag.setAttribute(
      'src',
      'https://brand-messenger.qa.aws.lcloud.com/bundle/loader.js?v=' + new Date().getTime(),
    );
    document.head.appendChild(scriptTag);
  };

  useEffect(() => {
    if (window.Brandmessenger) {
      window.Brandmessenger.destroy();
    }

    if (chatData) {
      injectScript();
    }
  }, [chatData]);

  useEffect(() => {
    setModernChatStyles();
    $('#khoros-firstMessageChatWrapper').remove();
  }, [isMobile, isPortrait, isLandscape, loading]);

  const onSuccess = async (code) => {
    //const payload = sessionStorage.getItem('payload');
    //const jwt = sessionStorage.getItem('token');
    //setLoading(true);

    //const response = await apiManager.update(payload, jwt, code, 'manualc01');

    //setLoading(false);
  };

  const setModernChatStyles = () => {
    const desktopStyles = classes.modernChatDesktop;
    const mobileStyles = classes.modernChatMobile;
    const mobileLandscapeStyles = classes.modernChatMobileLandscape;
    const tabletStyles = classes.modernChatTablet;
    const tabletLandscapeStyles = classes.modernChatTabletLandscape;
    let webMessengerSelector = $('#web-messenger-container');

    if (webMessengerSelector && window && window.innerWidth > 767) {
      if ((isMobileOnly || isIOS) && !isTablet) {
        webMessengerSelector.addClass(isLandscape ? mobileLandscapeStyles : mobileStyles);
        webMessengerSelector.removeClass(isLandscape ? mobileStyles : mobileLandscapeStyles);
        webMessengerSelector.removeClass(desktopStyles);
        webMessengerSelector.removeClass(tabletStyles);

      }else if(isTablet || isIOS) {
        webMessengerSelector.addClass(isLandscape ? tabletLandscapeStyles : tabletStyles);
        webMessengerSelector.removeClass(mobileStyles);
        webMessengerSelector.removeClass(mobileLandscapeStyles);
        webMessengerSelector.removeClass(desktopStyles);

      } else {
        webMessengerSelector.addClass(desktopStyles);
        webMessengerSelector.removeClass(mobileStyles);
        webMessengerSelector.removeClass(mobileLandscapeStyles);
        webMessengerSelector.removeClass(tabletStyles);
        webMessengerSelector.removeClass(tabletLandscapeStyles);
      }
      webMessengerSelector.contents().find('#wrapper').css({width: '100%', height: '80% !important'});
    }
    else {
        webMessengerSelector.removeClass(desktopStyles);
        webMessengerSelector.removeClass(mobileStyles);
        webMessengerSelector.removeClass(mobileLandscapeStyles);
        webMessengerSelector.removeClass(tabletStyles);
        webMessengerSelector.removeClass(tabletLandscapeStyles);
    }
    $('#web-messenger-container').contents().find('#khoros-firstMessageChatWrapper').css({display: 'none'})

  }


  $('#web-messenger-container').contents().find('#khoros-firstMessageChatWrapper').css({display: 'none'})

  return (
    <div className={classes.root}>
      <div
        id="overlay"
        style={{
          height: 0,
          background: '#fff',
          zIndex: 234234,
          position: 'absolute',
          width: '100%',
          left: '0px',
          top: '92px',
        }}
      >
        <br />
        <div style={{ margin: '0 auto', width: '50%' }}></div>
        <br />
      </div>
      <div className={classes.content}>
        <Grid container>{loading && <CircularProgress className={classes.progress} />}</Grid>
      </div>
    </div>
  );
};

Success = withOrientationChange(Success);

export default Success;
