import React from 'react'; 
import Card from '@material-ui/core/Card'; 
import CardHeader from '@material-ui/core/CardHeader';
import { makeStyles } from '@material-ui/core/styles';
//import './style.css';


const useStyles = makeStyles(theme => ({
  header: {
    color: 'white',
    marginRight: 'auto',
    backgroundColor: '#2C4047',
    marginRight: 'auto !important',
    marginLeft: 'auto',
    textAlign: 'center',
    alignItems: 'center',
    margin: '0 auto',
    padding:' 0 20px'
  } 
}));

export default function Header() {
  const classes = useStyles();
  return (
    <Card style={{   color: 'white',
    marginRight: 'auto',
    backgroundColor: '#2C4047',
    marginRight: 'auto !important',
    marginLeft: 'auto',
    textAlign: 'center',
    alignItems: 'center',
    margin: '0 auto',
    padding:' 0 20px'}} className={classes.header}>
      <header>
        <h1><a href="https://www.talktalk.co.uk/" target="_blank">Talk Talk For Everyone</a></h1>
    </header>
  </Card>
  );
  }