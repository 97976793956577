import React, { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

import { makeStyles } from '@material-ui/styles';
import { Grid, Select, MenuItem, FormControl, InputLabel, TextField, Button } from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from '@material-ui/pickers';

import zendeskHelper from '../../helpers/zendeskHelper';


const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(4)
    },
    content: {
        padding: 50,
        textAlign: 'left'
    },
    formControl: {
        margin: theme.spacing(1),
        width: '95%',
        maxWidth: '550px'
    }
}));



const DateInput = (props) => {
    const classes = useStyles();

    const { dataItem, dataAction, label, type, multiline, inputProps, placeholder, validations, handleErrors, fieldError, isNumericOnly, showFormError, isRequired, format } = props;
    const [error, setError] = useState('');
    const hasValidations = validations && validations.length > 0;

    const handleValidations = () => {
        let validationResponse = '';

        validations.find(validation => {
            switch (validation) {
                case 'isDate':
                    validationResponse = zendeskHelper.validDate(dataItem);
                    break;
            };

            return !!validationResponse;
        });

        setError(validationResponse);
        if (handleErrors) {
            handleErrors(type, validationResponse);
        }
    }

    const onChange = (date) => {
        let value = date;
        
        dataAction(value);

        /*
        if (hasValidations) {
            handleValidations(value);
        }
        */
    }

    const renderControl = () => {
        let errorProps = {}
        if (hasValidations && (fieldError || error) && showFormError) {
            errorProps = {
                helperText: hasValidations && (fieldError || error),
                error: hasValidations && (fieldError || error)
            };
        }

        return (<Grid
            item
            container
            lg={12}
            xs={12} >
            <FormControl className={classes.formControl}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                        autoOk
                        disableFuture
                        variant="inline"
                        inputVariant="outlined"
                        format={format || "yyyy-MM-dd"}
                        id={`${type}-input`}
                        placeholder={placeholder}
                        label={label}
                        required={!!isRequired}
                        value={dataItem}
                        onChange={onChange}
                        onError={(error, value) => {
                            let validDate = value && value.toString() !== 'Invalid Date' && !isNaN(value.getDate());
                        
                            if (handleErrors && 
                                ((error && error !== fieldError) 
                                || (!error && fieldError && (!showFormError || validDate)))) {
                                handleErrors(type, error);
                            }
                        }}
                        {...errorProps}
                        InputAdornmentProps={{
                            position: "start"
                        }}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                    />
                </MuiPickersUtilsProvider>
            </FormControl>
        </Grid>)
    }

    return (renderControl());
};

export default DateInput;