import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import { Typography, Link } from "@material-ui/core";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "inline-block",
    color: "#000",
    fontSize: "100%",
    fontWeight: "400",
    fontFamily: "rutledge-regular",
    lineHeight: 1.5,
    textAlign: "center",
    margin: 0,
  },
  links: {
    fontSize: ".875rem",
    borderRight: "1px solid",
    padding: "0 .925rem 0 0",
    margin: "0 .925rem .75rem 0",
    fontWeight: 400,
    textAlign: "left",
  },
  wrapper: {
    height: "800px",
  },
}));

const Footer = (props) => {
  const { className, ...rest } = props;

  const classes = useStyles();

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
      style={{ width: "100%" }}
    ></div>
  );
};

Footer.propTypes = {
  className: PropTypes.string,
};

export default Footer;
