import React from 'react';
import PropTypes from 'prop-types';
import videotronLogo from './videotronLogo.png'; 

const VideotronLogo = ({ className }) => (
  <img src={videotronLogo} alt={'Videotron Logo'} width={50} height={50}/>
);

VideotronLogo.propTypes = {
  className: PropTypes.string
};

export default VideotronLogo;
