import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { makeStyles } from '@material-ui/styles';
import { Grid, CircularProgress } from '@material-ui/core';
import { API_LOCAL, API_LAMBDA, IS_LOCAL_DEV, KHOROS_GUID, PHASE } from '../../../constants';

import MuiAlert from '@material-ui/lab/Alert';
import Wrapper from '../../../components/Wrapper'

const SuccessPath = `/${KHOROS_GUID}/success`;
const ErrorPath = `/${KHOROS_GUID}/error`;

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    flexGrow: 1
  },
  progressWrapper: {
    paddingBottom: '2rem'
  },
  progress: {
    margin: 'auto'
  }
}));

const Success = (props) => {
  const classes = useStyles();

  const { apiManager, history, screenConfig } = props;

  const [loading, setLoading] = useState(true);

  const [open, setOpen] = useState(false);

  const [network, setNetwork] = useState(sessionStorage.getItem('network'));
  const [user, setUser] = useState(false);

  const [severity, setSeverity] = useState('');
  const [init, setInit] = useState(false);

  const token = window.location.search.replace("?token=", "");

  const sendSuccess = async () => {
    //const response = await apiManager.success(userProfile);
     
    const validation = await apiManager.validate(token, 'khoros');
    
    if(!validation || validation.msg){
      sessionStorage.setItem('error', JSON.stringify(validation));
      history.push(ErrorPath)
    }
    else {
      debugger;
    }
    
    setLoading(false);
  }

  if (!init) {
    setInit(true)
    sendSuccess();
  }

  return (
    <div className={classes.root}>
      {loading && (
        <Grid
          className={classes.progressWrapper}
          container
          item
          lg={12}
          xs={12}
        >
          <CircularProgress className={classes.progress} />
        </Grid>
      )}
      { network }
      { user.displayName }
      {!loading && <Wrapper screenConfig={screenConfig}></Wrapper>}
    </div >
  );
};

export default Success;
