import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/styles';
import { Sidebar, Topbar, Footer, Rightbar } from './components';

import {
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  TextField,
  Button,
  Snackbar,
  CircularProgress,
  AppBar,
  Typography,
  Toolbar,
  IconButton,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    flexGrow: 1,
  },
  mainContent: {
    height: '100%',
  },
  wrapper: {
    textAlign: 'left',
    padding: '5em 0',
    marginTop: '2em',
  },
  content: {
    width: '90%',
    maxWidth: '1290px',
    margin: '0 auto',
  },
  links: {
    wordBreak: 'break-word',
  },
  appBar: {
    paddingTop: '1.25em',
    paddingBottom: '1em',
  },
  appBarContent: {
    margin: '0 auto',
    padding: '0.5em 0',
    maxWidth: '1290px',
    width: '94%',
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  bodyLayout: {
    paddingRight: '0px !important',
    paddingLeft: '0px !important',
  },
  primaryLogo: {
    marginRight: 'auto',
    marginLeft: 'auto',
  },
  secondLogo: {
    height: '40px',
    width: '40px',
  },
}));

const Main = (props) => {
  const { children, user, screenConfig } = props;

  const classes = useStyles();
  const theme = useTheme();

  useEffect(() => {
    document.body.className = classes.bodyLayout;
    if (screenConfig) {
      if (screenConfig.pageTitle && document.title !== screenConfig.pageTitle) {
        document.title = screenConfig.pageTitle;
      }

      if (screenConfig.favicon) {
        const link = document.querySelector("link[rel*='icon']") || document.createElement('link');
        link.type = 'image/x-icon';
        link.rel = 'shortcut icon';
        link.href = screenConfig.favicon;
        document.getElementsByTagName('head')[0].appendChild(link);
      }
    }
  }, []);

  return (
    <div
      className={clsx({
        [classes.root]: true,
      })}
    >
      <main className={classes.mainContent}>
        <AppBar
          className={classes.appBar}
          style={{
            backgroundColor: screenConfig && screenConfig.headerColor,
          }}
          position="sticky"
        >
          <Toolbar className={classes.appBarContent}>
            <a
              className={screenConfig.headerLogoCentered ? classes.primaryLogo : ' '}
              href={screenConfig.headerLogoUrl}
              target="_blank"
            >
              {screenConfig.headerLogo}
            </a>
            <div className={classes.secondLogo}>{screenConfig.secondHeaderLogo}</div>
          </Toolbar>
        </AppBar>
        {children}
      </main>
      {screenConfig.showFooter && <Footer screenConfig={screenConfig} />}
    </div>
  );
};

Main.propTypes = {
  children: PropTypes.node,
  user: PropTypes.object,
};

export default Main;
