import React, { useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import _ from 'lodash';
import { makeStyles } from '@material-ui/styles';
import {
  Grid,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  TextField,
  Button,
  Snackbar,
  CircularProgress,
} from '@material-ui/core';
import './style.css';

import MuiAlert from '@material-ui/lab/Alert';

import { API_LOCAL, API_LAMBDA, IS_LOCAL_DEV, DOMINION_GUID, PHASE } from '../../../constants';

import zendeskHelper from '../../../helpers/zendeskHelper';
import TextFieldInput from '../../../components/Inputs/TextFieldInput';
import SelectInput from '../../../components/Inputs/SelectInput';

const SuccessPath = `/${DOMINION_GUID}/success`;
const ErrorPath = `/${DOMINION_GUID}/error`;
const MAIN_COLOR = '#0072CE';
const SECONDARY_COLOR = '#ffffff';
const COMPANY_KEY = 'sv-dominion';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    flexGrow: 1,
    padding: 20,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  requiredLabel: {
    color: 'rgba(0, 0, 0, 0.54)',
    margin: '0 0 0 8px',
  },
  title: {
    flexGrow: 1,
    borderBottom: `1px solid white `,
    fontSize: '2.33em',
    lineHeight: 1.25,
    fontWeight: 'bold',
    textAlign: 'center',
    fontFamily: "'Montserrat', sans-serif !important",
    backgroundColor: MAIN_COLOR,
    borderRadius: '4px',
    color: 'white',
  },
  description: {
    lineHeight: 1.55,
    margin: '0.8em 0',
    color: MAIN_COLOR,
  },
  button: {
    marginTop: 18,
    textAlign: 'center',
    fontWeight: 'bold',
    lineHeight: 1,
    borderRadius: '4px',
    letterSpacing: '0.04em',
    padding: '15px 20px',
    fontFamily: "'Montserrat', sans-serif",
    '&:hover': {
      opacity: 0.7,
    },
  },
  customForm: {
    paddingTop: 0,
    maxWidth: '500px',
    border: '1px solid #e2e2e2',
    margin: '0 auto',
    boxShadow: '2px 2px 5px #cacaca',
    borderRadius: '25px',
  },
  content: {
    padding: 5,
    textAlign: 'center',
    fontWeight: 'bold',
  },
  progressWrapper: {
    paddingBottom: '2rem',
  },
  progress: {
    margin: 'auto',
    color: `${MAIN_COLOR}`,
  },
}));

const DataForm = (props) => {
  const classes = useStyles();

  const { apiManager, history, screenConfig } = props;
  const intl = useIntl();

  const [init, setInit] = useState(false);
  const [loading, setLoading] = useState(true);
  const [buttonLoading, setButtonLoading] = useState(false);

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [address, setAddress] = useState('');
  const [acctOrSSN, setacctOrSSN] = useState('');
  const [isAccountHolder, setIsAccountHolder] = useState('Yes');

  const [errors, setErrors] = useState({});
  const [queryToken, setQueryToken] = useState('');
  const [payload, setPayload] = useState('');
  const [iv, setIv] = useState('');

  const validations = {
    firstName: ['isRequired'],
    lastName: ['isRequired'],
    isAccountHolder: ['isRequired'],
    address: ['isRequired'],
  };

  const aditionalInfoLabel = intl.formatMessage({ id: 'validations.aditionalInfo' });

  const handleChange = (callback, event) => {
    if (event) {
      callback(event.target.value);
    }
  };

  const handleErrors = (type, errorMsg) => {
    let formErrors = _.cloneDeep(errors);
    if (type) {
      if (errorMsg) {
        formErrors[type] = errorMsg;
      } else {
        delete formErrors[type];
      }
      setErrors(formErrors);
    }
  };

  const validateForm = async (inputValues = {}) => {
    let formValidationsResponse = {};
    let atLeastTwoResponse = {};
    const requiredLabel = intl.formatMessage({ id: 'validations.required' });
    const aditionalInfoLabel = intl.formatMessage({ id: 'validations.aditionalInfo' });
    let atLeastTwoCount = 2;

    let inputFields = Object.keys(validations);

    inputFields.forEach((field) => {
      let value = inputValues[field];
      let validationResponse = '';

      validations[field].find((validation) => {
        switch (validation) {
          case 'isEmail':
            validationResponse = zendeskHelper.validEmail(value);
            validationResponse = !validationResponse
              ? value.length == 0
                ? requiredLabel
                : null
              : validationResponse;
            break;
          case 'isRequired':
            validationResponse = value.length === 0 ? requiredLabel : null;
            break;
          default:
            break;
        }

        return !!validationResponse;
      });

      if (validationResponse) {
        formValidationsResponse[field] = validationResponse;
      }
    });

    return {
      errors: formValidationsResponse,
    };
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    setButtonLoading(true);

    const formErrors = await validateForm({ firstName, lastName, isAccountHolder, address });

    if (Object.keys(formErrors.errors).length > 0) {
      setLoading(false);
      setErrors(formErrors.errors);
    } else {
      const dataFormPayload = {
        fields:{
          svFirstName: firstName,
          svLastName: lastName,
          svAddress: address,
          svIsAccountHolder: isAccountHolder,
          svAccountNo: acctOrSSN,

        }
      };

      const response = await apiManager.authorProfileUpdate(dataFormPayload, '', COMPANY_KEY, false);

      if (response) {
        history.push(`${SuccessPath}?token=${payload}`);
      } else {
        history.push(ErrorPath);
      }

      setButtonLoading(false);
    }
  };

  const getJwt = async () => {
    const params = history.location.search
      .slice(1)
      .split('&')
      .reduce((acc, param) => {
        const [key, value] = param.split('=');
        return Object.assign(acc, { [key]: value });
      }, {});
    const { payload, iv } = params;
    if (!params) {
      // error
      history.push(ErrorPath);
    }
    setPayload(payload);
    setIv(iv);
    setInit(true);
    setLoading(false);
  };

  if (!init) {
    const pageTitle = intl.formatMessage({ id: 'dominion.landing.pageTitle' });
    if (document.title !== pageTitle) {
      document.title = pageTitle;
    }
    getJwt();
  }

  let disabledSubmit = loading || (errors && Object.keys(errors).length > 0);

  return (
    <div className={classes.root} id="vodafoneau">
      <div className={classes.content}>
        <Grid container>{loading && <CircularProgress className={classes.progress} />}</Grid>
        {init && (
          <Grid container justify="center" className={classes.customForm}>
            <Grid container>
              <Grid container item lg={12} xs={12} justify="space-between" alignItems="flex-center">
                <Grid item lg={12} xs={12}>
                  <Typography variant="h4" className={classes.title}>
                    {<FormattedMessage id="socialVerificationForm.title" />}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Typography variant="h6" className={classes.description}>
              {`We need to gather some information about your account so we can assist you. Please enter your details below.`}
            </Typography>
            <Typography variant="h6" className={classes.description}>
              {`
               Once successfully submitted, you will be taken to the secure chat.`}
            </Typography>
            <Grid item container justify="center">
              <TextFieldInput
                label={<FormattedMessage id="socialVerificationForm.firstName" />}
                type="firstName"
                dataItem={firstName}
                dataAction={setFirstName}
                validations={validations.firstName}
                isRequired
                fieldError={errors.firstName}
                handleErrors={handleErrors}
              />

              <TextFieldInput
                label={<FormattedMessage id="socialVerificationForm.lastName" />}
                type="lastName"
                dataItem={lastName}
                dataAction={setLastName}
                validations={validations.lastName}
                isRequired
                fieldError={errors.lastName}
                handleErrors={handleErrors}
              />

              <SelectInput
                label="Are you the account holder?"
                type="vodafoneAccountHolder"
                key="isAccountHolder"
                selectedValue={isAccountHolder}
                setSelectedValue={setIsAccountHolder}
                validations={validations.isAccountHolder}
                isRequired
                fieldError={errors.isAccountHolder}
                handleErrors={handleErrors}
              />
              <TextFieldInput
                label={<FormattedMessage id="socialVerificationForm.address" />}
                type="address"
                dataItem={address}
                dataAction={setAddress}
                validations={validations.address}
                isRequired
                fieldError={errors.address}
                handleErrors={handleErrors}
              />
            </Grid>
            <TextFieldInput
              label="Account # or Last 4 digits of SSN"
              type="acctOrSSN"
              dataItem={acctOrSSN}
              dataAction={setacctOrSSN}
              validations={validations.acctOrSSN}
              isRequired
              isNumericOnly
              fieldError={errors.acctOrSSN}
              handleErrors={handleErrors}
            />
            <FormControl className={classes.formControl}>
              <Button
                variant="contained"
                className={classes.button}
                style={
                  disabledSubmit
                    ? {}
                    : {
                        color: '#fff',
                        backgroundColor: MAIN_COLOR,
                      }
                }
                onClick={onSubmit}
                disabled={disabledSubmit}
              >
                <FormattedMessage id="socialVerificationForm.submit" />
              </Button>
              {buttonLoading && <CircularProgress size={24} className={classes.progress} />}
            </FormControl>
          </Grid>
        )}
      </div>
    </div>
  );
};

export default DataForm;
