import React, { useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import _ from 'lodash';
import { makeStyles } from '@material-ui/styles';
import { Grid, Typography, Select, MenuItem, FormControl, InputLabel, TextField, Button, Snackbar, CircularProgress } from '@material-ui/core';

import { isMobile } from 'react-device-detect';

//const mainColor = '#FEF104'; //sprintPrePaid color
const mainColor = '#F7901E'; //boostMobile color

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: '100%',
    flexGrow: 1,
    padding: 20
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  requiredLabel: {
    color: 'rgba(0, 0, 0, 0.54)',
    margin: '0 0 0 8px'
  },
  title: {
    flexGrow: 1,
    borderBottom: '4px solid ' + mainColor,
    fontSize: '2.33em',
    lineHeight: 1.25,
    fontWeight: 'bold'
  },
  description: {
    lineHeight: 1.55,
    margin: '0.5em 0'
  },
  button: {
    textAlign: 'center',
    fontWeight: 'bold',
    lineHeight: 1,
    borderRadius: '4px',
    letterSpacing: '0.04em',
    padding: '15px 20px',
    fontFamily: "'Montserrat', sans-serif",
    '&:hover': {
      opacity: 0.7
    }
  },
  customForm: {
    paddingTop: 20,
    //maxWidth: '500px',
    //border: '1px solid #e2e2e2',
    margin: '0 auto'
  },
  progressWrapper: {
    paddingBottom: '2rem'
  },
  progress: {
    margin: 'auto'
  }
}));

const Error = (props) => {
  const classes = useStyles();

  const { apiManager, history, screenConfig } = props;
  const intl = useIntl();

  const [init, setInit] = useState(false);
  const [loading, setLoading] = useState(true);

  const [msg, setMsg] = useState('');


  if(window.Brandmessenger){
    window.Brandmessenger.reset();
    delete window.Brandmessenger;
  }

  useEffect(() => {
    const pageTitle = intl.formatMessage({ id: 'defaultSocialSecureTransfer.error.pageTitle' });
    if (document.title !== pageTitle) {
      document.title = pageTitle;
    }
  }, [])

  useEffect(() => {
    const validation = sessionStorage.getItem('validation');
    if(validation != null){
      const error = JSON.parse(validation);

      setMsg(error.msg)
    }

  }, [])

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <Grid
          container
        >
          <Grid
            container
            item
            lg={12}
            xs={12}
            justify="space-between"
            alignItems="flex-start"
          >
            <Grid
              item
            >
              <Typography variant="h4" className={classes.title}>
                {intl.formatMessage({ id: 'error.title' })}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            item
            lg={12}
            xs={12}
          >
            <Typography variant="h6" className={classes.description}>
              {intl.formatMessage({ id: 'error.description' })}
            </Typography>

  <Typography variant="h6" className={classes.description}>{msg}</Typography>
          </Grid>
        </Grid>
      </div>
    </div >
  );
};

export default Error;
