import React from 'react'; 
import Typography from '@material-ui/core/Typography';
import '../style.css';
 
class CommonText extends React.Component { // eslint-disable-line react/prefer-stateless-function
  render() {
    return (
        <span>
        <Typography variant="h5" component="h5">
        Secure Verification
      </Typography>
      <Typography variant="body2" color="textSecondary" component="p">
        To continue our conversation we need to verify your identity by answering the following questions. 
      </Typography>
      </span>
    );
  }
}

export default CommonText;