import React, { useState, useEffect } from 'react';
import axios from 'axios';
import _ from 'lodash';
import $ from 'jquery';
import { makeStyles } from '@material-ui/styles';
import { Grid, Select, MenuItem, FormControl, InputLabel, TextField, Button, Snackbar, CircularProgress } from '@material-ui/core';

import MuiAlert from '@material-ui/lab/Alert';

import { DELTA_GUID, PHASE } from '../../../constants';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(1),
    width: '100%',
    flexGrow: 1,
    background: '#f1f4f6'
  },
  content: {
    padding: 5,
    paddingTop: 0,
    textAlign: 'left',
    background: 'none'
  },
  links: {
    wordBreak: 'break-word'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  button: {
    marginTop: 18,
    textAlign: 'center',
    borderRadius: '10em',
    letterSpacing: '0.04em',
    padding: '0.6em 0.6em',
    width: '280px',
    fontFamily: "'Montserrat', sans-serif",
    '&:hover': {
      opacity: 0.7
    }
  },
  requiredLabel: {
    color: 'rgba(0, 0, 0, 0.54)',
    margin: '0 0 0 8px'
  }
}));

const MemberLookupButton = (props) => {
  const classes = useStyles();

  const { apiManager, history } = props;

  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [severity, setSeverity] = useState('');
  const [msg, setMsg] = useState('');

  const payload = window.location.search.replace("?payload=", "");
  console.log('payload', payload);

  const getJwt = async (payload) => {
    const queryToken = window.location.search.indexOf("token") > -1 ? window.location.search.replace("?token=", "") : false;
    const queryPayload = window.location.search.indexOf("payload") > -1 ? window.location.search.replace("?payload=", "") : false;
    if (!queryPayload && !queryToken) {
            // error
            console.log('no query payload and no query token');
    }
    else if (queryPayload && !queryToken) {
        const data = await apiManager.init(queryPayload, `sv-delta-${PHASE}`);
        console.log(`data: ${JSON.stringify(data)}`);

        if (!data) {
            console.log('data is not defined');
        }
        else {
            sessionStorage.setItem("token", data.jwt);
            sessionStorage.setItem("queryPayload", queryPayload);
        }
    }
  }
  const callDeltaLoyaltyLookup = async event => {
    const result = await apiManager.getDeltaLoyaltyMember({ payload });
  }

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <Grid
          container
          justify="left"
          spacing={2}
        >
          <Grid
            item
            lg={12}
            xs={12}
          >
            <FormControl className={classes.formControl}>
              <Button className={classes.button}  variant="contained" color="primary" onClick={callDeltaLoyaltyLookup} disabled={loading} >
                Loyalty Lookup
              </Button>
              {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
            </FormControl>
          </Grid>
          <Grid
            item
            lg={6}
            xs={12}
          >
            <Snackbar
              open={open}
              autoHideDuration={6000}
              anchorOrigin= {{ vertical: 'bottom', horizontal: 'center' }}
              onClose={e => setOpen(!open)}>
              <Alert onClose={e => setOpen(!open)} severity={severity}>
                {msg}
              </Alert>
            </Snackbar>
          </Grid>
        </Grid>
      </div>
    </div >
  );
};

export default MemberLookupButton;
