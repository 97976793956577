import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { makeStyles } from '@material-ui/styles';
import { Grid, Typography, Select, MenuItem, FormControl, InputLabel, TextField, Button, Snackbar, CircularProgress } from '@material-ui/core';

import MuiAlert from '@material-ui/lab/Alert';

import { API_LOCAL, API_LAMBDA, IS_LOCAL_DEV, VODAFONE_GUID as VodafoneGUID, PHASE } from '../../../constants';
import moment from 'moment'
import zendeskHelper from '../../../helpers/zendeskHelper';
import TextFieldInput from '../../../components/Inputs/TextFieldInput';
import SelectInput from '../../../components/Inputs/SelectInput';
import DateInput from '../../../components/Inputs/DateInput';
import PasswordInput from '../../../components/Inputs/PasswordInput';

import './style.css';

const SuccessPath = `/${VodafoneGUID}/success`;
const ErrorPath = `/${VodafoneGUID}/error`;

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    flexGrow: 1,
    padding: '0 20px'
  },
  content: {
    marginBottom: '3em'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  requiredLabel: {
    color: 'rgba(0, 0, 0, 0.54)',
    margin: '0 0 0 8px'
  },
  title: {
    flexGrow: 1,
    fontSize: '2em',
    lineHeight: 1.25,
    color: "#333333"
  },
  description: {
    lineHeight: 1.55,
    margin: '0.8em 0',
    color: "#333333"
  },
  button: {
    textAlign: 'center',
    letterSpacing: '0.04em',
    fontWeight: 'bold',
    color: '#f4f4f4 !important',
    backgroundColor: "#9c2aa0 !important",
    '&:hover': {
      backgroundColor: "#5c264e !important"
    },
    '&:disabled': {
      opacity: '0.5'
    }
  },
  customForm: {
    marginTop: "20px !important",
    marginBottom: "20px !important", 
    paddingTop: 20,
    border: '1px solid #e2e2e2',
    margin: '0 auto'
  },
  extraFieldsLink: {
    marginTop: '1em',
    cursor: 'pointer',
    color: '#333333',
    textDecoration: 'underline',
    '&:hover': {
      color: '#000000'
    }
  },
  progressWrapper: {
    paddingBottom: '2rem',
    color: '#333333'
  },
  progress: {
    margin: 'auto',
    color: '#333333'
  },
  buttonProgress: {
    color: '#333333'
  }
}));

const Landing = (props) => {
  const classes = useStyles();

  const { apiManager, history, screenConfig } = props;

  const [init, setInit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [initLoading, setInitLoading] = React.useState({});
  const [useExtraFields, setUseExtraFields] = useState(true);

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phone, setPhone] = useState('');
  const [screenName, setScreenName] = useState('');
  const [network, setNetwork] = useState('');
  const [isAccountHolder, setIsAccountHolder] = useState("Yes");

  //extra fields
  const [birthDate, setBirthDate] = useState(null);
  const [address, setAddress] = useState('');
  const [state, setState] = useState('');


  const [errors, setErrors] = useState({});

  const [open, setOpen] = useState(false);
  const [severity, setSeverity] = useState('');
  const [msg, setMsg] = useState('');

  let validations = {
    firstName: ['isRequired'],
    lastName: ['isRequired'],
    phone: ['isPhone', 'isRequired'],
    isAccountHolder: ['isRequired']
  };

  const nonExtraFieldsValidations = {
    
  }

  const extraFieldsValidations = {
    birthDate: ['isRequired']
  }

  _.assignIn(validations, useExtraFields ? extraFieldsValidations : nonExtraFieldsValidations);


  const handleChange = (callback, event) => {
    if (event) {
      callback(event.target.value);
    }
  };

  const handleAccountHolderChange = (event) => {
    if (event) {
      if (event.target.value == "No") {
        setUseExtraFields(false);
      }
      else {
        setUseExtraFields(true);
      }

      setErrors({});
    }
  };

  const openExtraFields = () => {
    setUseExtraFields(true);
    setErrors({});
  }

  const handleErrors = (type, errorMsg) => {
    let formErrors = _.cloneDeep(errors);
    if (type) {
      if (errorMsg) {
        formErrors[type] = errorMsg;
      }
      else {
        delete formErrors[type];
      }
      setErrors(formErrors);
    }
  };

  const validateForm = async (inputValues = {}) => {
    let validationResponse = '';
    let formValidationsResponse = {};

    let inputFields = Object.keys(validations);

    inputFields.forEach(field => {
      let value = inputValues[field];
      validations[field].find(validation => {
        switch (validation) {
          case 'isEmail':
            validationResponse = zendeskHelper.validEmail(value);
            validationResponse = !validationResponse ? (!value || value.length == 0 ? 'Required' : null) : validationResponse;
            break;
          case 'isPhone':
            validationResponse = zendeskHelper.validPhone(value);
            break;
          case 'isRequired':
            validationResponse = !value || value.length == 0 ? 'Required' : null;
            break;
        };

        return !!validationResponse;

      });

      if (validationResponse) {
        formValidationsResponse[field] = validationResponse;
      }
    });

    return formValidationsResponse;
  }

  const onSubmit = async event => {
    event.preventDefault();
    setLoading(true);

    const formErrors = await validateForm({ firstName, lastName, phone, birthDate, isAccountHolder });

    if (formErrors && Object.keys(formErrors).length > 0) {
      setLoading(false);
      setErrors(formErrors);
    }
    else {

      const nonExtraFieldsPayload = {
      };
      const extraFieldsPayload = {
        birthDate: moment(birthDate).format('DD/MM/YYYY')
      };

      let payload = {
        first: firstName,
        last: lastName,
        mobile: phone,
        network,
        isAccountHolder
      };

      _.assignIn(payload, useExtraFields ? extraFieldsPayload : nonExtraFieldsPayload);

      const response = await apiManager.vodafoneUpdate(payload);

      if (response) {
        history.push(SuccessPath)

      }
      else {
        history.push(ErrorPath)
      }

      setLoading(false);
    }
  }



  const getJwt = async (payload) => {

    const token = await apiManager.init(payload, `vodafoneau-${PHASE}`);
    if (!token) {
      history.push(ErrorPath)
    }
    else {
      if (token.sso && token.sso.network) {
        setNetwork(token.sso.network);
      }
      sessionStorage.setItem("network", token.sso.network);
      sessionStorage.setItem("client", `vodafoneau-${PHASE}`);
    }
    setInit(true);
  };

  let payload = ''; 
  if (window.location.search.indexOf("?payload=") > -1) {
    payload = window.location.search.replace("?payload=", "");
  }
  //payload from queryParam
  //
  //console.log('payload', payload);


  if (!init) {
    const pageTitle = 'Vodafone Australia - Secure Verification';
    if (document.title !== pageTitle) {
      document.title = pageTitle;
    }
    getJwt(payload);
  }

  let isEmpty = (!firstName || !lastName || !phone || !isAccountHolder);
  if (useExtraFields) {
    isEmpty = (!firstName || !lastName || !phone || !birthDate || !isAccountHolder);
  }
  let isDisabled = loading || (errors && Object.keys(errors).length > 0) || isEmpty;

  return ( 
    <div className={classes.root} id='vodafoneau'>
      <style>
      {`.pageBody { background: linear-gradient(#000055, black) }
         `}
      </style>
      <div className={classes.content}>
        <Grid
          container
        >
          {init && <Grid
            item
            lg={12}
            xs={12}
          >
            <Typography variant="h6" className={classes.description}>
              {"So that we can help you, we need to verify your identity. Please enter your details below and then return to messenger."}
            </Typography>
          </Grid>}
        </Grid>
        {!init && <Grid
          className={classes.progressWrapper}
          container
          item
          lg={12}
          xs={12}
        >
          <CircularProgress className={classes.progress} />
        </Grid>
        }
        {init && <Grid
          container
          justify="left"
          lg={8}
          xs={12}
          spacing={4}
          className={classes.customForm}
        >
          <Grid
            item
            container
            justify="left"
            spacing={4}
          >
            <TextFieldInput
              label='First Name' type='firstName'
              dataItem={firstName} dataAction={setFirstName}
              validations={validations.firstName} isRequired fieldError={errors.firstName} handleErrors={handleErrors}
            />

            <TextFieldInput
              label='Last Name' type='lastName'
              dataItem={lastName} dataAction={setLastName}
              validations={validations.lastName} isRequired fieldError={errors.lastName} handleErrors={handleErrors}
            />

            <TextFieldInput
              label='Mobile Number' type='phone'
              dataItem={phone} dataAction={setPhone}
              validations={validations.phone} isRequired fieldError={errors.phone} handleErrors={handleErrors}
              isNumericOnly={true}
              inputProps={{
                maxLength: 10
              }}
            />

            <SelectInput
              label='Are you the account holder?' type='vodafoneAccountHolder' key='isAccountHolder'
              selectedValue={isAccountHolder} setSelectedValue={setIsAccountHolder}
              validations={validations.isAccountHolder} isRequired fieldError={errors.isAccountHolder} handleErrors={handleErrors} handleChange={handleAccountHolderChange}
            />

            {useExtraFields && [
              <DateInput
                label={'Date of birth'}
                placeholder={'Enter Date of birth (DD/MM/YYYY)'}
                type='birthDate' key='birthDate'
                isRequired
                dataItem={birthDate} dataAction={setBirthDate}
                validations={validations.birthDate}
                fieldError={errors.birthDate}
                handleErrors={handleErrors}
                showFormError={errors.birthDate && errors.birthDate === 'Required'}
                format={'dd/MM/yyyy'}
              />
            ]}

          </Grid>
          <Grid
            item
            container
            justify="left"
            spacing={4}
          >
            <Grid
              item
              lg={12}
              xs={12}>
              <label className={classes.requiredLabel}><span> * </span> Required fields</label>
            </Grid>
            <Grid
              item
              lg={6}
              xs={12}
            >
              <FormControl className={classes.formControl}>
                <Button variant="contained" onClick={onSubmit}
                  style={isDisabled ? {} : {
                    backgroundColor: '#9C2AA0',
                    color: '#F4F4F4'
                  }}
                  className={classes.button}
                  disabled={isDisabled} >
                  Submit
             </Button>
                {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
              </FormControl>
            </Grid>
            <Grid
              item
              lg={6}
              xs={12}
            >
              <Snackbar open={open} autoHideDuration={6000} onClose={e => setOpen(!open)}>
                <Alert onClose={e => setOpen(!open)} severity={severity}>
                  {msg}
                </Alert>
              </Snackbar>
            </Grid>
          </Grid>
        </Grid>}
      </div>


    </div >
  );
};

export default Landing;