import { isLocalhost } from './helpers/utils';
import {API_LOCAL, API_LAMBDA, PHASE, API_LOCAL_V2, API_LAMBDA_V2} from './phaseConstants';

const IS_LOCAL_DEV = isLocalhost;

export {API_LOCAL, API_LAMBDA, IS_LOCAL_DEV, PHASE, API_LOCAL_V2, API_LAMBDA_V2};

// Timeouts
export const INITIAL_WARNING_DURATION = 1000 * 60 * 60; // 60 minutes
export const EXTRA_LOGOUT_DURATION = 1000 * 60 * 70; // on 70 minutes, force logout

//GUIDs
export const BLUESHIELD_GUID = '829e1114-b115-48bb-bc39-f206a559193f';
export const BRYANP_GUID = 'h30d4a7f-024d-41b4-9728-591203de7bdd';
export const CHARTER_GUID = '881f3b66-b29e-4899-b20c-36a5b41a7630';
export const DELTA_GUID = '5cb9b37d-ae5a-46bd-8817-09c67a4125c0';
export const FIDELITY_GUID = '6b84ca32-744d-42e8-8268-fb675fb8e462';
export const FITBIT_GUID = '3d784b70-22dd-4831-a633-86caf39dab84';
export const FLOWAI_GUID = '35c4ccb9-6ce5-47a8-9b2b-9b540eedbdc9';
export const HASBRO_GUID = 'bfb56c63-f185-4a36-81da-7483346e3904';
export const KHOROS_GUID = 'bf2d7dce-4db1-47f2-abd0-bb6e31968622';
export const ROCKSNROPES_GUID = 'ef7726ac-0a9d-4c6f-bd97-99cd6373e35f';
export const SPRINTPPD_GUID = '826f9d30-81dd-40ab-b404-4a6c8c7d23b3';
export const TALKTALK_GUID = '48f11280-4e94-4605-b286-dc7e2202fa27';
export const TELUS_GUID = '2431cc80-2e00-4ed9-9f82-6ecdd93ce2ba';
export const VIDEOTRON_GUID = 'b30d4a7f-024d-4cb4-9778-591207de7bdd';
export const VODAFONE_GUID = '6b84c5a2-744d-49e8-8168-fb675fb8e461';
export const QUICKBOOKS_GUID = '4579ac5d-0396-4c4d-971c-531fbfecc711';


//FOR ENGINEERING
export const MANUALC01_GUID = 'd9b01400-ac97-4ea5-a83a-94256b309e0b';
export const MANUALC02_GUID = 'dbc60d73-fa18-4c9b-a503-29a4949d0bf0';
export const RESPONSEC01_GUID = '3654eb12-2b27-4be6-b691-bddac5dd0d6c';

// Vanity urls with root path redirection. This is a temporary solution
export const VANITY_URLS = [
  {
    'domain': 'social.vodafone.com.au',
    'redirect_to': `${VODAFONE_GUID}/error`,
  },
  {
    'domain': 'securechat.fidelity.com',
    'redirect_to': `${FIDELITY_GUID}/not-found`,
  },
];
//export const KOODO_GUID = '';
export const KOODO_GUID = 'c0669741-f0e5-4c87-9a22-16e9505706d3';
export const DOMINION_GUID = 'd2c0db18-718a-4eff-84bd-7e1e74b21277';
