import React, { useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { makeStyles } from '@material-ui/styles';
import moment from 'moment';
import { Grid, Typography, CircularProgress } from '@material-ui/core';

import {
  DOMINION_GUID as GUID,
} from '../../../constants';

const COMPANY_KEY = 'sv-dominion';
const SuccessPath = `/${GUID}/success`;
const FormPath = `/${GUID}/form`;
const ErrorPath = `/${GUID}/error`;
const OutsideBusinessHoursPath = `/${GUID}/offline`;
const ExpiredPath = `/${GUID}/expired`;
const MAIN_COLOR = '#0072CE';
const SECONDARY_COLOR = '#ffffff';
const EXPIRATION = 1440;

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    flexGrow: 1,
    padding: 20,
    marginTop: '4%',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  requiredLabel: {
    color: 'rgba(0, 0, 0, 0.54)',
    margin: '0 0 0 8px',
  },
  title: {
    flexGrow: 1,
    borderBottom: `1px solid white `,
    fontSize: '2.33em',
    lineHeight: 1.25,
    fontWeight: 'bold',
    textAlign: 'center',
    color: `${SECONDARY_COLOR}`,
  },
  content: {
    padding: 5,
    paddingTop: 0,
    textAlign: 'left',
    color: `white`,
    fontWeight: 'bold',
  },
  description: {
    lineHeight: 1.55,
    margin: '0.8em 0',
    color: `${SECONDARY_COLOR}`,
    textAlign: 'center',
  },
  customForm: {
    paddingTop: 20,
    margin: '0 auto',
  },
  progressWrapper: {
    paddingBottom: '2rem',
  },
  progress: {
    margin: 'auto',
    color: `${MAIN_COLOR}`,
  },
  bodyDominion: {
    backgroundColor: '#E2E2E2',
    paddingRight: '0px',
    paddingLeft: '0px',
  },
}));

const Landing = (props) => {
  const classes = useStyles();

  const { apiManager, history } = props;
  const intl = useIntl();

  const [init] = useState(false);
  const [loading, setLoading] = useState(true);
  const [network, setNetwork] = useState('');

  const getJwt = async () => {
    const params = history.location.search
      .slice(1)
      .split('&')
      .reduce((acc, param) => {
        const [key, value] = param.split('=');
        return Object.assign(acc, { [key]: value });
      }, {});
    const { payload, iv } = params;


    if (!payload) {
      history.push(ErrorPath);
    } else {
      const data = await apiManager.authorInit(payload, COMPANY_KEY, iv);

      if(data?.invalidInvitation){
          history.push(ExpiredPath)
          return false;
      }else if (data?.isOffline) {
          history.push(OutsideBusinessHoursPath)
          return false;
      }
      else if(data?.status !== 200){
          history.push(ErrorPath)
          return false ;
      }else{
        history.push(`${FormPath}?payload=${payload}&iv=${iv}`);
        return true ;
      }
    }
  };

  if (window.location.search.indexOf('?payload=') > -1) {
    window.location.search.replace('?payload=', '');
  }

  if (!init) {
    const pageTitle = intl.formatMessage({ id: 'dominion.landing.pageTitle' });
    if (document.title !== pageTitle) {
      document.title = pageTitle;
    }
    getJwt();
  }

  useEffect(() => {
    document.body.className = classes.bodyDominion;
  }, [classes.bodyDominion]);

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <Grid container>{loading && <CircularProgress className={classes.progress} />}</Grid>
      </div>
    </div>
  );
};

export default Landing;
