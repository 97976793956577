import React, { useState, useEffect } from 'react';
import axios from 'axios';
import _ from 'lodash';
import $ from 'jquery';
import { makeStyles } from '@material-ui/styles';
import { Grid, Select, MenuItem, FormControl, InputLabel, TextField, Button, Snackbar, CircularProgress } from '@material-ui/core';
import moment from 'moment'
import MuiAlert from '@material-ui/lab/Alert';

import { VODAFONE_GUID as VodafoneGUID, PHASE } from '../../../constants';

const LandingPath = `${VodafoneGUID}/landing`;

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles(theme => ({
  bodyCare: {
    backgroundColor: '#f1f4f6'
  },
  root: {
    padding: theme.spacing(1),
    width: '100%',
    flexGrow: 1,
    background: '#f1f4f6'
  },
  content: {
    padding: 5,
    paddingTop: 0,
    textAlign: 'left',
    background: 'none'
  },
  links: {
    wordBreak: 'break-word'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  button: {
    marginTop: 18,
    textAlign: 'center',
    borderRadius: '10em',
    letterSpacing: '0.04em',
    padding: '0.6em 0.6em',
    width: '280px',
    fontFamily: "'Montserrat', sans-serif",
    '&:hover': {
      opacity: 0.7
    }
  },
  requiredLabel: {
    color: 'rgba(0, 0, 0, 0.54)',
    margin: '0 0 0 8px'
  }
}));

const SvButton = (props) => {
  const classes = useStyles();

  const { apiManager, history } = props;

  const [loading, setLoading] = useState(false);
  const [verificationLink, setVerificationLink] = useState('');

  const [open, setOpen] = useState(false);
  const [severity, setSeverity] = useState('');
  const [msg, setMsg] = useState('');
  const [init, setInit] = useState(false);

  let payload = "";
  if (window.location.search.indexOf("?payload=") > -1) {
    payload = window.location.search.replace("?payload=", "");
  }

  useEffect(() => {
    document.body.className = classes.bodyCare;
  }, []);

  const checkPurge = async () => {
    if(payload.length > 0){
      await apiManager.purge(payload, `vodafoneau-${PHASE}`)
    }
  }
  const getJwt = async payload => {
    const initData = await apiManager.init(payload, `vodafoneau-${PHASE}`);
    if(initData){
      const { jwt } = initData;
      sessionStorage.setItem("token", jwt);
    }
  }


  if(!init){
    setInit(true);

    const purgeAttempt = sessionStorage.getItem('purgeAttempt');
    const now = moment().valueOf();
    getJwt(payload);

    if(purgeAttempt){

      const purge = moment(parseInt(purgeAttempt)).add(1, 'minute').valueOf();
      if(purge < now){
        sessionStorage.setItem('purgeAttempt', now);
        checkPurge()
      }
    }
    else {
      sessionStorage.setItem('purgeAttempt', now);
      checkPurge()
    }
  }


  const copyToClipboard = (details) => {
    let text = document.createElement('textarea')
    text.value = details
    text.setAttribute('readonly', '')
    document.body.appendChild(text)
    text.select()
    text.focus()
    document.execCommand('copy')
    document.body.removeChild(text)
  }

  const populateTextArea = async text => {
    console.log(text);
    window.top.postMessage(JSON.stringify({ appendResponse: text }), "*");
  }
  


  const populateCRMTransfer = async event => {
    event.preventDefault();
    setLoading(true);
    setVerificationLink('');

    let longLink = `${LandingPath}?payload=${payload}`;
    const verificationResponse = await apiManager.firebaseShortUrl(longLink, `sv-vodafoneau`);

    if (verificationResponse && verificationResponse.shortLink) {
      const shortenedLink = verificationResponse.shortLink;
      console.log("Successfully shortened url - " + shortenedLink);
      //const urlText = "We need to verify you are a customer here: " + shortenedLink + " Verification will be submitted via your web browser."
      const urlText = shortenedLink;
      populateTextArea(urlText);
      setVerificationLink(shortenedLink);
    }
    else {
      //const urlText = "We need to verify you are a customer here: " + longLink + " Verification will be submitted via your web browser."
      const urlText = 'Short url generation failed, please try again.';
      //console.log(longLink)
      populateTextArea(urlText);
      setMsg('Short url generation failed, long url used instead');
      setOpen(true);
      setSeverity('error');
      setVerificationLink(longLink);
    }

    setLoading(false);
  }

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <Grid
          container
          justify="left"
          spacing={2}
        >
          <Grid
            item
            lg={12}
            xs={12}
          >
            <FormControl className={classes.formControl}>
              <Button className={classes.button}  variant="contained" color="primary" onClick={populateCRMTransfer} disabled={loading} >
                Send to Social Verification
              </Button>
              {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
            </FormControl>
          </Grid>
          <Grid
            item
            lg={6}
            xs={12}
          >
            <Snackbar
              open={open}
              autoHideDuration={6000}
              anchorOrigin= {{ vertical: 'bottom', horizontal: 'center' }}
              onClose={e => setOpen(!open)}>
              <Alert onClose={e => setOpen(!open)} severity={severity}>
                {msg}
              </Alert>
            </Snackbar>
          </Grid>
        </Grid>
      </div>
    </div >
  );
};

export default SvButton;
