import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { makeStyles } from '@material-ui/styles';
import { Grid, FormControl, TextField } from '@material-ui/core';

import zendeskHelper from '../../helpers/zendeskHelper';


const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(4)
    },
    content: {
        padding: 50,
        textAlign: 'left'
    },
    formControl: {
        margin: theme.spacing(1.25),
        width: '95%',
        maxWidth: '550px'
    }
}));



const TextFieldInput = (props) => {
    const classes = useStyles();

    const { dataItem, dataAction, count, label, type, multiline, disabled, inputProps, gridProps, inputStyles, validations, onInputChange, handleErrors, fieldError, isNumericOnly, isRequired, variant } = props;
    const [error, setError] = useState('');
    const hasValidations = validations && validations.length > 0;
    let style = inputStyles ? {...inputStyles} : {};

    if (disabled) {
        style.opacity = '0.6';
    }

    const handleValidations = value => {
        let validationResponse = '';
        let countValue = count;

        validations.find(validation => {
            switch (validation) {
                case 'isEmail':
                    validationResponse = zendeskHelper.validEmail(value);
                    break;
                case 'isPhone':
                    validationResponse = zendeskHelper.validPhone(value);
                    break;
                case 'isRequired':
                    validationResponse = !value || value.length == 0 ? <FormattedMessage id='validations.required' /> : null;
                    break;
                //case 'atLeastTwo':
                //    validationResponse = !value || value.length == 0 ? <FormattedMessage id='validations.required' /> : null;
                //    break;
                default:
                    break;
            };

            return !!validationResponse;
        });

        setError(validationResponse);
        if (handleErrors) {
            handleErrors(type, validationResponse);
        }
    }

    const onChange = (e) => {
        let value = e.target.value;
        if (isNumericOnly) {
            const re = /^[0-9\b]+$/;

            if (value === '' || re.test(value)) {
                dataAction(value);
            }
            else if (value.length == 1) {
                dataAction('');
            }
        }
        else {
            dataAction(value);
        }

        if (hasValidations) {
            handleValidations(value);
        }

        if (onInputChange) {
            onInputChange(type, value);
        }
    }

    const renderControl = () => {
        return (<Grid
            item
            lg={gridProps && gridProps.lg ? gridProps.lg : 12}
            sm={gridProps && gridProps.sm ? gridProps.sm : 12}
            xs={gridProps && gridProps.xs ? gridProps.xs : 12} >
            <FormControl style={style} className={classes.formControl}>
                <TextField
                    error={hasValidations && (fieldError || error)}
                    variant={variant || "outlined"}
                    id={`${type}-input`}
                    label={label}
                    value={dataItem}
                    disabled={disabled}
                    helperText={hasValidations && (fieldError || error)}
                    onChange={onChange}
                    multiline={multiline}
                    required={!!isRequired}
                    rows={multiline ? 4 : 1}
                    inputProps={inputProps || {}}
                />
            </FormControl>
        </Grid>)
    }

    return (renderControl());
};

export default TextFieldInput;