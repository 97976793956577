import React, { useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import _ from 'lodash';
import { makeStyles } from '@material-ui/styles';
import { Grid, Typography, Select, MenuItem, FormControl, InputLabel, TextField, Button, Snackbar, CircularProgress } from '@material-ui/core';

import MuiAlert from '@material-ui/lab/Alert';

import { API_LOCAL, API_LAMBDA, IS_LOCAL_DEV, FITBIT_GUID as GUID, PHASE } from '../../../constants';

import zendeskHelper from '../../../helpers/zendeskHelper';
import TextFieldInput from '../../../components/Inputs/TextFieldInput';
import { KhorosLogo as Logo } from '../../../components/logos/index';
import './fonts/style.css'

const COMPANY_KEY = 'fitbit';
const SuccessPath = `/${GUID}/success`;
const ErrorPath = `/${GUID}/error`;

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: '100%',
    flexGrow: 1,
    padding: 20
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  requiredLabel: {
    color: 'rgba(0, 0, 0, 0.54)',
    margin: '0 0 0 8px'
  },
  title: {
    flexGrow: 1,
    borderBottom: '4px solid #00b0b9',
    color: 'rgb(0, 42, 58)',
    fontSize: '36px',
    lineHeight: 1.25,
    fontFamily: 'Proxima Nova Bold, Helvetica, Arial, sans-serif'
  },
  description: {
    margin: '0.5em 0',
    lineHeight: '23px',
    //fontSize: '18px',
    fontFamily: 'Proxima Nova Regular, Helvetica, Arial, sans-serif'
  },
  button: {
    marginTop: 18,
    textAlign: 'center',
    fontWeight: 'bold',
    lineHeight: 1,
    borderRadius: '4px',
    letterSpacing: '0.04em',
    padding: '15px 20px',
    fontFamily: "'Montserrat', sans-serif",
    '&:hover': {
      opacity: 0.7
    }
  },
  customForm: {
    paddingTop: 20,
    //maxWidth: '500px',
    //border: '1px solid #e2e2e2',
    margin: '0 auto'
  },
  progressWrapper: {
    paddingBottom: '2rem'
  },
  progress: {
    margin: 'auto'
  }
}));

const Landing = (props) => {
  const classes = useStyles();

  const { apiManager, history, screenConfig } = props;
  const intl = useIntl();

  const [init, setInit] = useState(false);
  const [loading, setLoading] = useState(false);

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');

  const [errors, setErrors] = useState({});
  const [token, setToken] = useState('');
  const [sso, setSso] = useState({});
  const [queryToken, setQueryToken] = useState('');

  const validations = {
    firstName: ['isRequired'],
    lastName: ['isRequired'],
    email: ['isRequired']
  };


  const getJwt = async (payload) => {
    const API = IS_LOCAL_DEV ? API_LOCAL : API_LAMBDA;
    const token = sessionStorage.getItem('token');
    const queryToken = history.location.search.indexOf("token") > -1 ? history.location.search.replace("?token=", "") : false;
    const queryPayload = history.location.search.indexOf("payload") > -1 ? history.location.search.replace("?payload=", "") : false;

    if (!queryPayload && !queryToken) {
      // error
      history.push(ErrorPath);
    }
    else if (queryPayload && !queryToken) {

      const data = await apiManager.init(payload, `${COMPANY_KEY}-${PHASE}`);

      if (!data) {
        history.push(ErrorPath)
      }
      else {
        sessionStorage.setItem("token", data.jwt);
        sessionStorage.setItem("network", data.sso.network);
        sessionStorage.setItem("client", COMPANY_KEY);

        window.location = `${API}/fitbit/fitbit/login`

        if (data.sso && data.sso.network && data.sso.network != 'external') {

        }
      }
    }
    else {
      const validation = await apiManager.validate(queryToken, COMPANY_KEY);

      if (validation) {
        setToken(token);
        setQueryToken(queryToken);
        setInit(true);
        history.push(SuccessPath)
      }
      else {
        history.push(ErrorPath);
      }
    }
  };


  let payload = '';
  if (window.location.search.indexOf("?payload=") > -1) {
    payload = window.location.search.replace("?payload=", "");
  }



  if (!init) {
    const pageTitle = intl.formatMessage({ id: 'defaultSecureVerification.pageTitle' });
    if (document.title !== pageTitle) {
      document.title = pageTitle;
    }
    getJwt(payload);
  }


  let disabledSubmit = loading || (errors && Object.keys(errors).length > 0) ;

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <Grid
          container
        >
          <Grid
            container
            item
            lg={12}
            xs={12}
            justify="space-between"
            alignItems="flex-start"
          >
            <Grid
              item
            >
              <Typography variant="h4" className={classes.title}>
                {<FormattedMessage id='defaultSecureVerification.title' />}
              </Typography>
            </Grid>
          </Grid>
          {!init && (
            <Grid
              className={classes.progressWrapper}
              item
              lg={12}
              xs={12}
            >
              <Typography variant="h6" className={classes.description}>
                {<FormattedMessage id='defaultSecureVerification.loading' />}
              </Typography>
              <CircularProgress disableShrink className={classes.progress} />
            </Grid>
          )}
          {init && <Grid
            item
            lg={12}
            xs={12}
          >
            <Typography variant="h6" className={classes.description}>
              {<FormattedMessage id='defaultSecureVerification.description' />}
            </Typography>
          </Grid>}
        </Grid>
      </div>
    </div >
  );
};

export default Landing;
