import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { makeStyles } from '@material-ui/styles';
import { Grid, Typography, Select, MenuItem, FormControl, InputLabel, TextField, Button, Snackbar, CircularProgress } from '@material-ui/core';
import { isMobile } from 'react-device-detect';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: '100%',
    flexGrow: 1,
    padding: '0 20px'
  },
  preHeader: {
    backgroundColor: '#4a4d4e',
    height: '40px'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  requiredLabel: {
    color: 'rgba(0, 0, 0, 0.54)',
    margin: '0 0 0 8px'
  },
  title: {
    flexGrow: 1,
    fontSize: '2em',
    lineHeight: 1.25,
    fontWeight: 'bold'
  },
  description: {
    lineHeight: 1.25,
    margin: '0.6em 0'
  },
  button: {
    textAlign: 'center',
    letterSpacing: '0.04em',
    '&:hover': {
      opacity: 0.7
    }
  },
  customForm: {
    paddingTop: 10,
    margin: '0 auto'
  },
  progressWrapper: {
    paddingBottom: '2rem'
  },
  progress: {
    margin: 'auto'
  }
}));

const Error = (props) => {
  const classes = useStyles();

  const { apiManager, history, screenConfig } = props;

  const [init, setInit] = useState(false);

  const [network, setNetwork] = useState('');
  const [networkUrl, setNetworkUrl] = useState('');

  useEffect(() => {
    const pageTitle = 'Vodafone Australia - Error';
    if (document.title !== pageTitle) {
      document.title = pageTitle;
    }
    const networkSession = sessionStorage.getItem('network');
    if (!networkSession || networkSession.length > 0) {
      setNetwork(networkSession);
    }
  }, [])

  useEffect(() => {
    let url;

    switch (network) {
      case 'twitter':
        url = 'https://twitter.com/messages/18186920-1235209488824774657';
        break;
      case 'facebook':
        url = isMobile ? 'https://m.me/vodafoneau' : 'https://www.facebook.com/messages/t/vodafoneau';
        break;
      default:
        break;
    }

    setNetworkUrl(url);

  }, [network])

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <Grid
          container
        >
          <Grid
            container
            item
            lg={12}
            xs={12}
            justify="space-between"
            alignItems="flex-start"
          >
            <Grid
              item
            >
              <Typography variant="h4" className={classes.title}>
                {'Error!'}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            item
            lg={12}
            xs={12}
          >
            <Typography variant="h6" className={classes.description}>
              {"Something went wrong."}
            </Typography>
          </Grid>
          <Grid
            item
            lg={12}
            xs={12}
          >
            <Typography variant="h6" className={classes.description}>
              {"Next step: send us a Direct Message and let us know."}
            </Typography>
          </Grid>
          <Grid
            item
            lg={12}
            xs={12}
          >
            <Typography variant="h6" className={classes.description}>
              {"Tell us more about what's going on so we can get things resolved."}
            </Typography>
          </Grid>
        </Grid>
        {networkUrl && <Grid
          container
          justify="left"
          className={classes.customForm}
        >
          <Grid
            item
            lg={12}
            xs={12}
          >
            <FormControl className={classes.formControl}>
              <Button variant="contained"
                style={{
                  backgroundColor: '#9C2AA0',
                  color: '#F4F4F4'
                }}
                className={classes.button}
                target="_blank" href={networkUrl} >
                Direct Message
                </Button>
            </FormControl>
          </Grid>
        </Grid>}
      </div>

    </div >
  );
};

export default Error;
