import React, {useEffect, useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {makeStyles} from '@material-ui/styles';
import {Grid, Typography} from '@material-ui/core';
import './fonts/style.css'


const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    flexGrow: 1,
    padding: '0 20',
    margin: '0 auto',
    marginTop: '4rem',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  requiredLabel: {
    color: 'rgba(0, 0, 0, 0.54)',
    margin: '0 0 0 8px',
  },
  title: {
    flexGrow: 1,
    fontSize: '2.33em',
    fontFamily: 'Fidelity Sans Regular, Helvetica, Sans-serif !important',
    lineHeight: 1.25,
    fontWeight: 'bold',
  },
  description: {
    lineHeight: 1.55,
    fontFamily: 'Fidelity Sans Regular, Helvetica, Sans-serif !important',
    margin: '0.5em 0',
  },
  button: {
    textAlign: 'center',
    fontWeight: 'bold',
    lineHeight: 1,
    borderRadius: '4px',
    letterSpacing: '0.04em',
    padding: '15px 20px',
    //fontFamily: "'Montserrat', sans-serif",
    '&:hover': {
      opacity: 0.7,
    },
  },
  customForm: {
    paddingTop: 20,
    //maxWidth: '500px',
    //border: '1px solid #e2e2e2',
    margin: '0 auto',
  },
  progressWrapper: {
    paddingBottom: '2rem',
  },
  progress: {
    margin: 'auto',
  },
}));

const Error = (props) => {
  const {apiManager, history} = props;
  const classes = useStyles();
  const intl = useIntl();
  const [msg, setMsg] = useState('');


  if (window.Brandmessenger) {
    window.Brandmessenger.reset();
    delete window.Brandmessenger;
  }

  useEffect(() => {
    const pageTitle = intl.formatMessage({id: 'fidelity.error.pageTitle'});
    if (document.title !== pageTitle) {
      document.title = pageTitle;
    }
  }, [])

  useEffect(() => {
    const validation = sessionStorage.getItem('validation');
    if (validation != null) {
      const error = JSON.parse(validation);

      setMsg(error.msg)
    }

  }, [])

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <Grid
          item
          lg={12}
          xs={12}
        >
          <Typography variant="h4" className={classes.title}>
            {<FormattedMessage id="fidelity.error.pageTitle"/>}
          </Typography>

        </Grid>

        <Grid
          item
          lg={12}
          xs={12}
        >
          <Typography variant="h6" style={{marginTop: '6rem'}} className={classes.description}>
            {<FormattedMessage id="fidelity.error.description"/>}
          </Typography>
          <Typography variant="h6" className={classes.description}>
            {<FormattedMessage id="fidelity.error.nextStep"/>}
          </Typography>
          {history.location.state &&
          <Typography className={classes.requiredLabel}>

            Error Code: {history.location.state.error_code}
          </Typography>
          }
        </Grid>
      </div>
    </div>
  );
};

export default Error;
