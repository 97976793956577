import React from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import SvButton from './SvButton';
import SVButtonVerify from './SvButtonVerify';
import Landing from './Landing';
import Success from './Success';
import Error from './Error';

class Generic extends React.Component {
  render() {
    const { screenConfig } = this.props;

    return (
      <Switch>
        <Route path="/default/sv-button">
          <SvButton {...this.props} screenConfig={screenConfig} />
        </Route>
        <Route path="/default/sv-button-verify">
          <SVButtonVerify {...this.props} screenConfig={screenConfig} />
        </Route>
        <Route path="/default/landing">
          <Landing {...this.props} screenConfig={screenConfig} />
        </Route>
        <Route path="/default/success">
          <Success {...this.props} screenConfig={screenConfig} />
        </Route>
        <Route path="/default/error">
          <Error {...this.props} screenConfig={screenConfig} />
        </Route>
      </Switch>
    );
  }
}

export default Generic;
