import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Typography, Link } from '@material-ui/core';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'inline-block',
    color: '#000',
    fontSize: '100%',
    fontWeight: '400',
    fontFamily: 'rutledge-regular',
    lineHeight: 1.5,
    textAlign: 'center',
    margin: 0
  },
  customFooter: {
    fontSize: '14px',
    lineHeight: '1.42857143',
    fontFamily: 'Cereal,Helvetica Neue,Helvetica,Arial,sans-serif',
    boxSizing: 'border-box',
    color: '#394348',
    background: '#fff'
  },
  h1: {
    fontFamily: 'inherit',
    fontWeight: 500,
    color: 'inherit',
    fontSize: '36px',
    lineHeight: '1.1',
    margin: 0
  },
  nav: {
    display: 'block'
  },
  listItems: {
    display: 'inline-block',
    listStyle: 'none',
    paddingLeft: '40px'
  },
  links: {
    lineHeight: '1.42857143',
    color: '#394348',
    fontFamily: 'Omnes,sans-serif',
    fontSize: '20px',
    fontWeight: 200,
    boxSizing: 'border-box',
    margin: 0,
    padding: 0
  },
  wrapper: {
    height: '800px'
  }
}));

const Footer = props => {
  const { className, ...rest } = props;

  const classes = useStyles();

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
      style={{width: '100%'}}
    >
        <div className={classes.customFooter}
          style={{margin: '3rem auto'}}>
            <div>
                <ul className={classes.list}>
                    <li className={classes.listItems}><a className={classes.links} href="https://community.talktalk.co.uk/t5/TalkTalk-Accessibility/ct-p/talktalk-accessibility" target="_blank">Accessibility</a></li>
                    <li className={classes.listItems}><a className={classes.links} href="https://www.talktalk.co.uk/legal/code-of-practice/" target="_blank">Code of Practice</a></li>
                    <li className={classes.listItems}><a className={classes.links} href="https://community.talktalk.co.uk/t5/Articles/How-do-I-make-a-complaint/ta-p/2204670" target="_blank">Complaints Code</a></li>
                    <li className={classes.listItems}><a className={classes.links} href="https://www.talktalk.co.uk/legal/our-cookie-policy.html" target="_blank">Cookies</a></li>
                    <li className={classes.listItems}><a className={classes.links} href="https://www.talktalk.co.uk/legal/" target="_blank">Legal</a></li>
                    <li className={classes.listItems}><a className={classes.links} href="https://www.talktalk.co.uk/legal/privacy-policy/" target="_blank">Privacy Policy</a></li>
                    <li className={classes.listItems}><a className={classes.links} href="https://www.talktalk.co.uk/legal/terms/talktalk-product-terms.html" target="_blank">Terms &amp; Conditions</a></li>
                </ul>
                <small>© TalkTalk 2019</small>
            </div>
        </div>
    </div>
  );
};

/*
custom-footer
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    font-size: 14px;
    line-height: 1.42857143;
    font-family: Cereal,Helvetica Neue,Helvetica,Arial,sans-serif;
    text-align: left;
    box-sizing: border-box;
    color: #394348;
    background: #f5f5f5;

<footer>
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    line-height: 1.42857143;
    text-align: left;
    color: #394348;
    box-sizing: border-box;
    justify-content: space-between;
    border-top: 1px solid #666;
    display: block;
    font-family: "Open Sans",sans-serif;
    font-size: 14px;
    margin: 0 auto;
    max-width: 1000px;
    padding: 40px 20px;

::after
    .ext#ext-footer:after {
    background: #394348;
    content: "";
    display: block;
    height: 60px;
    width: 100%;
}

<ul>
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    line-height: 1.42857143;
    text-align: left;
    color: #394348;
    font-family: "Open Sans",sans-serif;
    font-size: 14px;
    box-sizing: border-box;
    list-style: none;
    margin: 0;
    padding: 0;
    margin-bottom: 10px;

<li>
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    color: #394348;
    font-family: "Open Sans",sans-serif;
    font-size: 14px;
    list-style: none;
    box-sizing: border-box;
    border-right: 1px solid #394348;
    display: inline-block;
    line-height: 14px;
    margin-right: 10px;
    padding-right: 15px;

<a>
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    font-family: "Open Sans",sans-serif;
    font-size: 14px;
    list-style: none;
    line-height: 14px;
    box-sizing: border-box;
    background-color: transparent;
    text-decoration: none;
    color: #394348;

*/

Footer.propTypes = {
  className: PropTypes.string
};

export default Footer;

