import React, { useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from "react-intl";
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/styles';
import { Grid, AppBar, Toolbar } from '@material-ui/core';
import useStyles from './styles'


const BlueShieldCa = props => {
  const { children, user, screenConfig } = props;

  const classes = useStyles();
  const theme = useTheme();
  const intl = useIntl();

  const pageFooter = intl.formatMessage({ id: "blueshieldca.footer" });

  useEffect(() => {
    if (screenConfig) {
      if (screenConfig.pageTitle && document.title !== screenConfig.pageTitle) {
        document.title = screenConfig.pageTitle;
      }

      if (screenConfig.favicon) {
        const link = document.querySelector("link[rel*='icon']") || document.createElement('link');
        link.type = 'image/x-icon';
        link.rel = 'shortcut icon';
        link.href = screenConfig.favicon;
        document.getElementsByTagName('head')[0].appendChild(link);
      }
    }
  }, [])


  return (
    <div
      className={clsx({
        [classes.root]: true
      })}
    >
      <main className={classes.mainContent}>
        <AppBar className={classes.appBar}
          style={{
            backgroundColor: '#fff'
          }}
          position="relative">
          <div className={classes.preHeader}></div>
          <Grid
            container
            item
            alignContent="space-around"
            alignItems="flex-start"
            alignContent="space-around"
            lg={7}
            xs={10}
            style={{
              margin: 'auto'
            }}
          >
            <Toolbar className={classes.appBarContent}>
              <a className={classes.logo} href={screenConfig.headerLogoUrl} target='_blank'>{screenConfig.headerLogo}</a>
            </Toolbar>
          </Grid>
        </AppBar>
        <Grid
          container
          direction="column"
          alignContent="space-around"
          alignItems="flex-start"
          justify="space-between"
          lg={7}
          xs={10}
          className={classes.wrapper}
          style={{
            margin: 'auto'
          }}
        >
          <Grid
            item
          >
            <div className={classes.content}>
              {children}
            </div>
          </Grid>
          <Grid
            item
          >
            <div className={classes.footer}>
              {pageFooter}
            </div>
          </Grid>
        </Grid>

      </main>
    </div>
  );
};

BlueShieldCa.propTypes = {
  children: PropTypes.node,
  user: PropTypes.object
};

export default BlueShieldCa;
