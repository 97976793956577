import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';
import { Grid, Typography } from '@material-ui/core';

import _ from 'lodash';

import { CharterLogo as Logo } from '../../../components/logos/index';

const useStyles = makeStyles((theme) => ({ 
  root: {
    height: 'auto',
    flexGrow: 1,
    padding: 20,
    color: '#000',
    fontSize: '1.75rem',
    fontWeight: '700',
    fontFamily: 'rutledge-bold',
    fontStyle: 'normal',
    fontStreth: 'normal',
    letterSpacing: '-.9px',
    lineHeight: 1.32,
    margin: 0,
    background: 'transparent'
  },
  title: {
    fontFamily: 'RUTLEDGE BOLD',
    fontSize: '1.75rem',
    margin: 0,
    maxWidth: '302px',
    width: '100%',
    height: '45px',
    fontWeight: 700,
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: '1.32',
    letterSpacing: '-.9px',
    textAlign: 'center',
    color: '#000',
  },
  description: {
    fontFamily: 'RUTLEDGE REGULAR',
    fontSize: '16px',
    fontWeight: 300,
    textAlign: 'center',
    lineHeight: '24px',
  },
}));

export default function Error ({history, screenConfig}) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
        <style>{'body { background: rgb(248, 248, 248); }'}</style>
      <div className={classes.content}>
        <Grid
          container
        >
          <Grid
            container
            item
            lg={12}
            xs={12}
            style={{ 
              justifyContent: 'center'
              }}
          >
            <Grid
              item
            >
              <Typography variant="h4" className={classes.title}>
                {screenConfig.pageTitle}
              </Typography>
            </Grid>

          </Grid>

          <Grid
            item
            lg={12}
            xs={12}
          >
            <Typography variant="h6" className={classes.description}>
              {screenConfig.error}
            </Typography>
          </Grid>
          <Grid
            item
            lg={12}
            xs={12}
          >
         
          </Grid>

        </Grid>
      </div>
    </div >
  );
}