import React, { Component } from 'react';
import { Router } from 'react-router-dom';
import { IntlProvider } from "react-intl";

import {language, messages} from "./locale/index";
import { createBrowserHistory } from 'history';
import { ThemeProvider } from '@material-ui/styles';

import theme from './theme';
//import './assets/css/index.css';
import Routes from './Routes';

const browserHistory = createBrowserHistory();

export default class App extends Component {

  state = {
    user: null
  }

  render() {
    return (
      <IntlProvider locale={language} messages={messages}>
        <ThemeProvider theme={theme}>
          <Router history={browserHistory}>
            <Routes />
          </Router>
        </ThemeProvider>
      </IntlProvider>
    );
  }
}
