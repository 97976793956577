import React, { useState, useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import _ from "lodash";
import { makeStyles } from "@material-ui/styles";
import {
  Grid,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  TextField,
  Button,
  Snackbar,
  CircularProgress,
} from "@material-ui/core";

import MuiAlert from "@material-ui/lab/Alert";

import {
  API_LOCAL,
  API_LAMBDA,
  IS_LOCAL_DEV,
  ROCKSNROPES_GUID as GUID,
  PHASE,
} from "../../../constants";

import zendeskHelper from "../../../helpers/zendeskHelper";
import TextFieldInput from "../../../components/Inputs/TextFieldInput";
import { KhorosLogo as Logo } from "../../../components/logos/index";

const COMPANY_KEY = "rocksnropes";
const SuccessPath = `/${GUID}/success`;
const ErrorPath = `/${GUID}/error`;

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
    flexGrow: 1,
    padding: 20,
    background: "white",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  requiredLabel: {
    color: "rgba(0, 0, 0, 0.54)",
    margin: "0 0 0 8px",
  },
  title: {
    flexGrow: 1,
    borderBottom: "4px solid #0a0745",
    fontSize: "2.33em",
    lineHeight: 1.25,
    fontWeight: "bold",
    fontFamily: "Avenir, Brandon, Arial",
    color: "#a1a5b3",
  },
  description: {
    lineHeight: 1.55,
    margin: "0.8em 0",
    fontFamily: "Avenir, Brandon, Arial",
    color: "#a1a5b3",
  },
  button: {
    marginTop: 18,
    textAlign: "center",
    fontWeight: "bold",
    lineHeight: 1,
    borderRadius: "4px",
    letterSpacing: "0.04em",
    padding: "15px 20px",
    fontFamily: "Avenir, Brandon, Arial",
    "&:hover": {
      opacity: 0.7,
    },
    color: "white",
    background: "#0a0745",
  },
  customForm: {
    paddingTop: 20,
    // maxWidth: '500px',
    // border: '1px solid #e2e2e2',
    margin: "0 auto",
  },
  progressWrapper: {
    paddingBottom: "2rem",
  },
  progress: {
    margin: "auto",
  },
}));

const Landing = (props) => {
  const classes = useStyles();

  const { apiManager, history, screenConfig } = props;
  const intl = useIntl();

  const [init, setInit] = useState(false);
  const [loading, setLoading] = useState(false);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");

  const [errors, setErrors] = useState({});
  const [token, setToken] = useState("");
  const [sso, setSso] = useState({});
  const [queryToken, setQueryToken] = useState("");

  const validations = {
    firstName: ["isRequired"],
    lastName: ["isRequired"],
    email: ["isRequired"],
  };

  const handleChange = (callback, event) => {
    if (event) {
      callback(event.target.value);
    }
  };

  const handleErrors = (type, errorMsg) => {
    const formErrors = _.cloneDeep(errors);
    if (type) {
      if (errorMsg) {
        formErrors[type] = errorMsg;
      } else {
        delete formErrors[type];
      }
      setErrors(formErrors);
    }
  };

  const validateForm = async (inputValues = {}) => {
    const formValidationsResponse = {};

    const requiredLabel = intl.formatMessage({ id: "validations.required" });

    const inputFields = Object.keys(validations);

    inputFields.forEach((field) => {
      const value = inputValues[field];
      let validationResponse = "";

      validations[field].find((validation) => {
        switch (validation) {
          case "isEmail":
            validationResponse = zendeskHelper.validEmail(value);
            validationResponse = !validationResponse
              ? value.length == 0
                ? requiredLabel
                : null
              : validationResponse;
            break;
          case "isRequired":
            validationResponse = value.length === 0 ? requiredLabel : null;
            break;
          default:
            break;
        }

        return !!validationResponse;
      });

      if (validationResponse) {
        formValidationsResponse[field] = validationResponse;
      }
    });

    return {
      errors: formValidationsResponse,
    };
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    const formErrors = await validateForm({ firstName, lastName, email });

    if (Object.keys(formErrors.errors).length > 0) {
      setLoading(false);
      setErrors(formErrors.errors);
    } else {
      const payload = {
        svFirstName: firstName,
        svLastName: lastName,
        svEmail: email,
      };

      const response = await apiManager.update(
        payload,
        token,
        queryToken,
        COMPANY_KEY
      );

      if (response) {
        history.push(SuccessPath);
      } else {
        history.push(ErrorPath);
      }

      setLoading(false);
    }
  };

  const getJwt = async (payload) => {
    const API = IS_LOCAL_DEV ? API_LOCAL : API_LAMBDA;
    const token = sessionStorage.getItem("token");
    const queryToken =
      history.location.search.indexOf("token") > -1
        ? history.location.search.replace("?token=", "")
        : false;
    const queryPayload =
      history.location.search.indexOf("payload") > -1
        ? history.location.search.replace("?payload=", "")
        : false;

    if (!queryPayload && !queryToken) {
      // error

      history.push(ErrorPath);
    } else if (queryPayload && !queryToken) {
      const data = await apiManager.init(payload, `${COMPANY_KEY}-${PHASE}`);

      if (!data) {
        history.push(ErrorPath);
      } else {
        sessionStorage.setItem("token", data.jwt);
        sessionStorage.setItem("network", data.sso.network);
        sessionStorage.setItem("client", COMPANY_KEY);

        if (data.sso && data.sso.network && data.sso.network != "external") {
          window.location = `${API}/${data.sso.network}/${COMPANY_KEY}/login`;
        } else if (
          data.sso.network === "external" &&
          data.decryptedPayload.providerInstance === "www.apple.com"
        ) {
          // ABC exception - capture only
          setToken(token);
          setQueryToken(queryToken);
          setInit(true);
        } else {
          alert("not a social network");
        }
      }
    } else {
      const validation = await apiManager.validate(queryToken, COMPANY_KEY);

      if (validation) {
        setToken(token);
        setQueryToken(queryToken);
        setInit(true);
      } else {
        history.push(ErrorPath);
      }
    }
  };

  let payload = "";
  if (window.location.search.indexOf("?payload=") > -1) {
    payload = window.location.search.replace("?payload=", "");
  }

  if (!init) {
    const pageTitle = intl.formatMessage({
      id: "defaultSecureVerification.pageTitle",
    });
    if (document.title !== pageTitle) {
      document.title = pageTitle;
    }
    getJwt(payload);
  }

  const disabledSubmit = loading || (errors && Object.keys(errors).length > 0);

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <Grid container>
          <Grid
            container
            item
            lg={12}
            xs={12}
            justify="space-between"
            alignItems="flex-start"
          >
            <Grid item>
              <Typography variant="h4" className={classes.title}>
                {<FormattedMessage id="defaultSecureVerification.title" />}
              </Typography>
            </Grid>
            <Grid item>
              <Logo />
            </Grid>
          </Grid>
          {!init && (
            <Grid className={classes.progressWrapper} item lg={12} xs={12}>
              <Typography variant="h6" className={classes.description}>
                {<FormattedMessage id="defaultSecureVerification.loading" />}
              </Typography>
              <CircularProgress disableShrink className={classes.progress} />
            </Grid>
          )}
          {init && (
            <Grid item lg={12} xs={12}>
              <Typography variant="h6" className={classes.description}>
                {
                  <FormattedMessage id="defaultSecureVerification.description" />
                }
              </Typography>
            </Grid>
          )}
        </Grid>
        {init && (
          <Grid container justify="left" className={classes.customForm}>
            <Grid item container justify="left">
              <TextFieldInput
                label={
                  <FormattedMessage id="defaultSecureVerification.firstName" />
                }
                type="firstName"
                dataItem={firstName}
                dataAction={setFirstName}
                validations={validations.firstName}
                isRequired
                fieldError={errors.firstName}
                handleErrors={handleErrors}
              />

              <TextFieldInput
                label={
                  <FormattedMessage id="defaultSecureVerification.lastName" />
                }
                type="lastName"
                dataItem={lastName}
                dataAction={setLastName}
                validations={validations.lastName}
                isRequired
                fieldError={errors.lastName}
                handleErrors={handleErrors}
              />

              <TextFieldInput
                label={
                  <FormattedMessage id="defaultSecureVerification.email" />
                }
                type="email"
                dataItem={email}
                dataAction={setEmail}
                validations={validations.email}
                isRequired
                fieldError={errors.email}
                handleErrors={handleErrors}
              />
            </Grid>
          </Grid>
        )}
        <FormControl className={classes.formControl}>
          <Button
            variant="contained"
            className={classes.button}
            style={
              disabledSubmit
                ? {}
                : {
                    color: "#000",
                    backgroundColor: "#FDD200",
                  }
            }
            onClick={onSubmit}
            disabled={disabledSubmit}
          >
            <FormattedMessage id="defaultSecureVerification.submit" />
          </Button>
          {loading && (
            <CircularProgress size={24} className={classes.buttonProgress} />
          )}
        </FormControl>
      </div>
    </div>
  );
};

export default Landing;
