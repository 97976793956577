import React, { useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import _ from 'lodash';
import { makeStyles } from '@material-ui/styles';
import { Grid, Typography, Select, MenuItem, FormControl, InputLabel, TextField, Button, Snackbar, CircularProgress } from '@material-ui/core';

import MuiAlert from '@material-ui/lab/Alert';
import { isMobile } from 'react-device-detect';

import { API_LOCAL, API_LAMBDA, IS_LOCAL_DEV, FITBIT_GUID as GUID, PHASE } from '../../../constants';

import { KhorosLogo as Logo } from '../../../components/logos/index';
import './fonts/style.css'

const COMPANY_KEY = 'fitbit';
const LandingPath = `/${GUID}/landing`;

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: '100%',
    flexGrow: 1,
    padding: 20
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  requiredLabel: {
    color: 'rgba(0, 0, 0, 0.54)',
    margin: '0 0 0 8px'
  },
  title: {
    flexGrow: 1,
    borderBottom: '4px solid #00b0b9',
    lineHeight: 1.25,
    color: 'rgb(0, 42, 58)',
    fontSize: '36px',
    fontFamily: 'Proxima Nova Bold, Helvetica, Arial, sans-serif'
  },
  description: {
    margin: '0.5em 0',
    lineHeight: '23px',
    //fontSize: '18px',
    fontFamily: 'Proxima Nova Regular, Helvetica, Arial, sans-serif'
  },
  button: {
    textAlign: 'center',
    fontWeight: 'bold',
    lineHeight: 1,
    borderRadius: '4px',
    letterSpacing: '0.04em',
    padding: '15px 20px',
    fontFamily: "'Montserrat', sans-serif",
    '&:hover': {
      opacity: 0.7
    }
  },
  customForm: {
    paddingTop: 20,
    //maxWidth: '500px',
    //border: '1px solid #e2e2e2',
    margin: '0 auto'
  },
  progressWrapper: {
    paddingBottom: '2rem'
  },
  progress: {
    margin: 'auto'
  }
}));

const Success = (props) => {
  const classes = useStyles();

  const { apiManager, history, screenConfig } = props;
  const intl = useIntl();

  const [init, setInit] = useState(false);
  const [loading, setLoading] = useState(true);

  const [network, setNetwork] = useState('');
  const [networkUrl, setNetworkUrl] = useState('');

  useEffect(() => {
    try {
      const pageTitle = intl.formatMessage({ id: 'fitbit.success.pageTitle' });
      if (document.title !== pageTitle) {
        document.title = pageTitle;
      }
    }
    catch(ex){
      console.log(ex)
    }
  }, [])

  useEffect(() => {
    const run = async() => {
      if (history.location.search.indexOf("?token=") > -1) {
        const queryToken = history.location.search.replace("?token=", "");

        history.push(`${LandingPath}?token=${queryToken}`);
      }
      const networkSession = sessionStorage.getItem('network');
      if (!networkSession || networkSession.length > 0) {
        setNetwork(networkSession);

        const queryToken = history.location.search.replace("?token=", "");
        try {
          const response = await apiManager.update({}, sessionStorage.getItem("token"), queryToken, COMPANY_KEY);
        }
        catch(ex){
          console.log(ex)
        }

      }
      setInit(true)
    }
    run()


  }, [])




  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <Grid
          container
        >
          <Grid
            container
            item
            lg={12}
            xs={12}
            justify="space-between"
            alignItems="flex-start"
          >
            <Grid
              item
            >
              <Typography variant="h4" className={classes.title}>
                <FormattedMessage id='fitbit.success.title' />
              </Typography>
            </Grid>
          </Grid>
          <Grid
            item
            lg={12}
            xs={12}
          >
            <Typography variant="h6" className={classes.description}>
              <FormattedMessage id='fitbit.success.description' />
            </Typography>
          </Grid>
          <Grid
            item
            lg={12}
            xs={12}
          >
            <Typography variant="h6" className={classes.description}>
              <FormattedMessage id='fitbit.success.nextStep' />
            </Typography>
          </Grid>
          <Grid
            item
            lg={12}
            xs={12}
          >
            <Typography variant="h6" className={classes.description}>
              <FormattedMessage id='fitbit.success.tellUsMore' />
            </Typography>
          </Grid>
        </Grid>

      </div>


    </div >
  );
};

export default Success;
