import React, { useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import axios from 'axios';
import _ from 'lodash';
import $ from 'jquery';
import { makeStyles } from '@material-ui/styles';
import { Grid, FormControl, Button, Snackbar, CircularProgress } from '@material-ui/core';

import MuiAlert from '@material-ui/lab/Alert';

import { FLOWAI_GUID, PHASE  } from '../../../constants';

const LandingPath = `/${FLOWAI_GUID}/landing`;

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(1),
    width: '100%',
    flexGrow: 1,
    background: '#f1f4f6'
  },
  content: {
    padding: 5,
    paddingTop: 0,
    textAlign: 'left',
    background: 'none'
  },
  links: {
    wordBreak: 'break-word'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  button: {
    marginTop: 18,
    textAlign: 'center',
    borderRadius: '10em',
    letterSpacing: '0.04em',
    padding: '0.6em 0.6em',
    width: '280px',
    fontFamily: "'Montserrat', sans-serif",
    '&:hover': {
      opacity: 0.7
    }
  },
  requiredLabel: {
    color: 'rgba(0, 0, 0, 0.54)',
    margin: '0 0 0 8px'
  }
}));

const SvButton = (props) => {
  const classes = useStyles();

  const { apiManager, history } = props;
  const intl = useIntl();

  const [loading, setLoading] = useState(false);
  const [verificationLink, setVerificationLink] = useState('');

  const [open, setOpen] = useState(false);
  const [severity, setSeverity] = useState('');
  const [msg, setMsg] = useState('');

  const copyToClipboard = (details) => {
    let text = document.createElement('textarea')
    text.value = details
    text.setAttribute('readonly', '')
    document.body.appendChild(text)
    text.select()
    text.focus()
    document.execCommand('copy')
    document.body.removeChild(text)
  }

  const populateTextArea = async text => {
    console.log(text);
    window.top.postMessage(JSON.stringify({ appendResponse: text }), "*");
  }

  let payload = ""; 
  if (window.location.search.indexOf("?payload=") > -1) {
    payload = window.location.search.replace("?payload=", "");
  } 
  const populateCRMTransfer = async event => {
    event.preventDefault();
    setLoading(true);
    setVerificationLink('');

    let longLink = `${window.location.origin}${LandingPath}?payload=${payload}`;
    let data = { url: longLink };
    const verificationResponse = await apiManager.sendSecureSocialVerification(data, `flowai-${PHASE}`);

    if (verificationResponse && verificationResponse.shortLink) {
      const shortenedLink = verificationResponse.shortLink;
      console.log("Successfully shortened url - " + shortenedLink);
      const urlText = shortenedLink; // intl.formatMessage({ id: 'socialVerificationButton.verifyText'}, { link: shortenedLink });
      populateTextArea(urlText);
      setVerificationLink(shortenedLink);
    }
    else {
      const urlText = intl.formatMessage({ id: 'socialVerificationButton.verifyText'}, { link: longLink });
      populateTextArea(urlText);
      setMsg(intl.formatMessage({ id: 'socialVerificationButton.error' }));
      setOpen(true);
      setSeverity('error');
      setVerificationLink(longLink);
    }

    setLoading(false);

  }

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <Grid
          container
          justify="left"
          spacing={2}
        >
          <Grid
            item
            lg={12}
            xs={12}
          >
            <FormControl className={classes.formControl}>
              <Button className={classes.button} variant="contained" color="primary" onClick={populateCRMTransfer} disabled={loading} >
                <FormattedMessage id='socialVerificationButton.label' />
              </Button>
              {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
            </FormControl>
          </Grid>
          <Grid
            item
            lg={6}
            xs={12}
          >
            <Snackbar
              open={open}
              autoHideDuration={6000}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
              onClose={e => setOpen(!open)}>
              <Alert onClose={e => setOpen(!open)} severity={severity}>
                {msg}
              </Alert>
            </Snackbar>
          </Grid>
        </Grid>
      </div>
    </div >
  );
};

export default SvButton;
