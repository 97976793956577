import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/styles';
import { useMediaQuery } from '@material-ui/core';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import { CharterBackground } from '../../components/Backgrounds';
import { Footer } from './components';
import AcUnitIcon from '@material-ui/icons/AcUnit';

import {
  Grid, Select, MenuItem, FormControl, InputLabel, TextField, Button, Snackbar, CircularProgress,
  AppBar, Typography, Toolbar, IconButton
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    height: 'auto',   
  },
  body: {
    backgroundColor: 'rgb(248, 248, 248)'
  },
  wrapper: {
    backgroundImage: `url(${CharterBackground})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'
  },
  header: {
    backgroundColor: '#f8f8f8'
  },
  appBar: {
    height: '113px',
    width: '100%',
    boxShadow: 'none'
  },
  title: {
    borderBottom: 'none',
  },
  mainContent: {
    height: '100%',
    width: '100%'
  },
  content: {
    maxWidth: '520px',
    width: '100%',
    margin: 'auto',
    [theme.breakpoints.down('sm')]: {
      padding: '32px 30px',
    },
    [theme.breakpoints.up('md')]: {
      padding: '32px 114px 64px 104px',
    },
    [theme.breakpoints.up('lg')]: {
      padding: '32px 114px 64px 104px',
    },
    backgroundColor: '#fff',
  },
  logo: {
    height: "36px",
    position: 'relative',
    left: '10px'
  },
  appBarContent: {
    width: '950px',
    margin: 'auto',
    padding: 0
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  footer: {
    background: 'transparent !important'
  }
}));

const Charter = props => {
  const { children, user, screenConfig } = props;

  const classes = useStyles();
  const theme = useTheme();

  useEffect(() => {
    if (screenConfig) {
      if (screenConfig.pageTitle && document.title !== screenConfig.pageTitle) {
        document.title = screenConfig.pageTitle;
      }
      
      if (screenConfig.favicon) {
        const link = document.querySelector("link[rel*='icon']") || document.createElement('link');
        link.type = 'image/x-icon';
        link.rel = 'shortcut icon';
        link.href = screenConfig.favicon;
        document.getElementsByTagName('head')[0].appendChild(link);
      }
    }
  }, [])

  return (
    <div
      className={clsx({
        [classes.root]: true
      })}
    >
      <main className={classes.mainContent}>
        <AppBar className={classes.appBar}
          style={{
            backgroundColor: '#f8f8f8'
          }}
          position="relative">
          <Grid
            container
            item
            alignContent="space-around"
            alignItems="center"
            alignContent="space-around"
            //lg={8}
            xs={12}
          >
            <Toolbar className={classes.appBarContent} style={{justifyContent: 'center'}}>
              <a className={classes.logo} href={screenConfig.headerLogoUrl} target='_blank'>{screenConfig.headerLogo}</a>
            </Toolbar>
          </Grid>
        </AppBar>
        <div className={classes.wrapper}>
          <Grid
            container
            item
            ali
            alignContent="space-around"
            alignItems="center"
            alignContent="space-around"
            //lg={8}
            xs={12}
          >
            <div className={classes.content}>
              {children}
            </div>
          </Grid>
          <Footer/>
        </div>
      </main>
    </div>
    
  );
};

Charter.propTypes = {
  children: PropTypes.node,
  user: PropTypes.object
};

export default Charter;
