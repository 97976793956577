import React from 'react';
import { withStyles } from '@material-ui/styles';
import PropTypes from 'prop-types'; 
import {
  Grid, 
  IconButton,
  Link 
} from '@material-ui/core'; 
  

 
import { faFont, faCamera, faPlay, faChartPie, faLink, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"; 

const styles = (theme => ({
    root: {
      padding: theme.spacing(4)
    }, 
    img: {
        maxWidth: '600px'
    }
  }));

const Dashboard =  (props) => {
  const { classes } = props; 
 

  const [spacing, setSpacing] = React.useState(2);  

  

  const clickEdit = () => {
       
  }
 
  return ( 
    <div className={classes.root}> 
    <Grid
      container
      spacing={4}
    >
         
    
            <Grid
          item
          lg={12}
          sm={12}
          xl={12}
          xs={12}
        >
                   
 

<IconButton aria-label="close" className={classes.icon} onClick={clickEdit}>
<FontAwesomeIcon icon={faPlay} /> 
</IconButton>

        </Grid>
        <Grid
          item
          lg={12}
          sm={12}
          xl={12}
          xs={12}
        >
        <Grid
         container justify="center" spacing={spacing}
        >
         
 
            </Grid> </Grid>
   
      </Grid> 
    </div>
  );
};

Dashboard.propTypes = {
  history: PropTypes.object,
  updateUser: PropTypes.func
};

export default withStyles(styles)(Dashboard);
