import React, { useState, useEffect, useDebugValue } from 'react';
import { FormattedMessage, useIntl, injectIntl } from 'react-intl';
import _ from 'lodash';

import { makeStyles } from '@material-ui/styles';
import { withStyles } from '@material-ui/styles';
import {
  Grid,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  TextField,
  Button,
  Snackbar,
  CircularProgress,
} from '@material-ui/core';

import TextFieldInput from '../../components/Inputs/TextFieldInput';

import MuiAlert from '@material-ui/lab/Alert';

import { API_LOCAL, API_LAMBDA, IS_LOCAL_DEV, PHASE } from '../../constants';

const SuccessPath = '/default/success';
const ErrorPath = '/default/error';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles(theme => ({
  root: {
    height: 'auto',
    flexGrow: 1,
    padding: 0,
    color: '#000',
    fontSize: '1.75rem',
    fontWeight: '700',
    fontFamily: 'RUTLEDGE REGULAR',
    fontStyle: 'normal',
    fontStreth: 'normal',
    letterSpacing: '-.9px',
    lineHeight: 1.32,
    margin: 0,
    textAlign: 'center',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  requiredLabel: {
    color: 'rgba(0, 0, 0, 0.54)',
    margin: '0 0 0 8px',
  },
  description: {
    lineHeight: 1.55,
    margin: '0.8em 0',
  },
  button: {
    minWidth: '250px',
    marginTop: 18,
    textAlign: 'center',
    fontWeight: 'bold',
    textTransform: 'capitalize',
    lineHeight: 1,
    borderRadius: '4px',
    letterSpacing: '0.04em',
    padding: '15px 20px',
    color: '#fff',
    backgroundColor: '#00373d1',
    '&:hover': {
      opacity: 0.7,
    },
  },
  customForm: {
    paddingTop: 20,
    margin: '0 auto',
  },
  progressWrapper: {
    paddingBottom: '2rem',
  },
  progress: {
    margin: 'auto',
  },
  title: {
    fontFamily: 'RUTLEDGE BOLD',
    fontSize: '1.75rem',
    margin: 0,
    maxWidth: '302px',
    width: '100%',
    height: '45px',
    fontWeight: 700,
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: '1.32',
    letterSpacing: '-.9px',
    textAlign: 'center',
    color: '#000',
  },
  description: {
    fontFamily: 'RUTLEDGE REGULAR',
    fontSize: '16px',
    fontWeight: 300,
    textAlign: 'center',
    lineHeight: '24px',
  },
  error: {
    color: '#f04848',
    border: '1px solid #f04848',
    padding: '20px',
    margin: '20px 0px',
  },
  inputError: {
    color: '#f04848',
    border: '1px solid #f04848',
    borderRadius: '4px',
  },
  nameError: {
    margin: 8,
    width: '100%',
    color: 'red',
    fontWeight: 'normal',
    textAlign: 'left',
  },
}));


const GenericLanding = (props) => {
  const intl = useIntl();
  const classes = useStyles();

  const { apiManager, history } = props;

  const [init, setInit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [customer, setCustomer] = useState('');
  const [payload, setPayload] = useState('');

  //this one will be dynamically populated from paramstore
  const [formFields, setFormFields] = useState([]);
  const [returnToFields, setReturnToFields] = useState({});
  const [formValues, setFormValues] = useState({});
  const [validations, setValidations] = useState({});

  //const [conversationNote, setConversationNote] = useState('');
  const [errors, setErrors] = useState({});
  const [token, setToken] = useState('');
  const [sso, setSso] = useState({});
  const [queryToken, setQueryToken] = useState('');

  useEffect(() => {
    let currentValues = _.cloneDeep(formValues);
    let currentValidations = _.cloneDeep(validations);

    formFields.map(field => {

      let defaultValue = ''

      switch (field.type) {
        case 'date':
          defaultValue = null;
          break;
        case 'text':
        default:
          defaultValue = '';
          break;
      }

      currentValues[field.name] = field.default || defaultValue;
      if (field.validations) {
        currentValidations[field.name] = field.validations;
      }
    })

    setFormValues(currentValues);
    setValidations(currentValidations);

  }, [formFields]);

  useEffect(() => {

    const urlParams = new URLSearchParams(window.location.search);

    let payloadValue = urlParams.get('payload');
    let customerValue = urlParams.get('customer');

    //TODO: Remove after testing
    if (IS_LOCAL_DEV) {
      payloadValue = "r0oneebgzqrxJXIE0scFZ0-jEN1cPYAXSeAEZJDCPTPrDTzG_G4Gpsa8lACsMzTDjsXBOc_xe6fJ7dbEvWMT75_Urt4HdM5QCtGFZCgallQLM36_vnmD4Yi4MCvo1GGC_Q5DmG5QIHxuHbeB7iVYpLRcYXrmOLdNuhIANmi-NZbtKKlBe7XpPPZGQuQ2NxFv284aRErMDVRvs4dSVZTYBBKs1C6Hw35wwY-bE_PY3kNVf8JkS-lWnu7OYWBY2nS9-tqhZG1ugo0ZkJ9Vn0F0e63S8AI3iKnnXQw7GTTTp_dh6yE6-nvv2ufyZnsVg3hEk1usCUDXJm8pRL1x_NJrZrIwxC2nNEz9ZfCmFNG0xKLylUJAxGBvkeZeRzrBHK3WIiUwaN94MABplkFSj-O7B_LGuFtE1M2V_hv5P4hOc0zy7UCHDh6Tm1yGnbNwiWt8anYsgLOlFzQkqXizeywLtVUXwugM_FWEJ2X7h1fPCXbtEEw_N259bh2OOwiREtapFf4W-XA2T-IfiiC6vQ4F3UjwG6cO8EXqHaymVxm6g6eDRh2liNG5ir6sNc9BBpc5gBR40qR_eH3fFkzG0wOgj4c6IsctIUIoForTyEsUqOuDQZCJ_Pa8asXxnQ8tpKFG_ZtUIMDJ27_dz38PDlnur5fXHggrPICFnjh5-vdOqmX-DSlJYc-OVRCZYZQcyQJOCIOeEqxlVEzaOtwwe_YMNGiFNLJw5Ten24VBv9-FAKkjFxJhk8bYUyaxc-gdt2ikeXSAWEWSsOe43Slru_z9nZIScSqHZtWKr928brKt6A-HCiPCebdE9jamX_fS5XbeLRWij72r50vzAH_X-dG10wL9YKy4ezp3I41SnG8pYRT3Dhy7MQSdyLc1h8dH8FgOeyQksd7rrnuExZ4eckPZzEnRKhCypnisTMhpA7oBBKJGN8dL3liGHLaOjK18PWHOHmjrT7SEUwEpRXfksqM_6EwLV-aDSoVguLrZZ44KQGZy_wIPhcnCkqUYrHiuU4ScpA9ard6BnynQpWhiEhU=";
      customerValue = "synchrony";
    }

    if (payloadValue) {
      setPayload(payloadValue);
      setCustomer(customerValue);

      if (!init) {
        getJwt(payloadValue, customerValue);
      }
    }
    else {
      history.push(ErrorPath)
    }
  }, []);

  const getJwt = async (payload, customer) => {
    const API = IS_LOCAL_DEV ? API_LOCAL : API_LAMBDA;
    const token = sessionStorage.getItem('token');
    const queryToken =
      history.location.search.indexOf('token') > -1
        ? new URLSearchParams(history.location.search).get('token')
        : false;
    const queryPayload = payload || false;

    if (!queryPayload && !queryToken) {
      // error
      console.log('no query payload and no query token');
      history.push(ErrorPath);
    } else if (queryPayload && !queryToken) {
      const data = await apiManager.init(queryPayload, `${customer}-${PHASE}`);

      if (!data) {
        console.log('data is not defined');
      } else {
        sessionStorage.setItem('token', data.jwt);
        sessionStorage.setItem('queryPayload', queryPayload);
        sessionStorage.setItem('client', customer);
        sessionStorage.setItem('returnTo', JSON.stringify(data.returnToChannel) || []);
        //setConversationNote('Customer submitted authentication form'); //data.locale.landing.conversationNote
        setReturnToFields(data.returnToChannel || {});
        setFormFields(data.formFields || []);
        setToken(token);
        setInit(true);
      }
    }
  };

  const handleOnChange = (value, type) => {
    let currentValues = _.cloneDeep(formValues);
    if (type) {
      currentValues[type] = value;
      setFormValues(currentValues);
    }
  };

  const handleErrors = (type, errorMsg) => {
    let formErrors = _.cloneDeep(errors);
    if (type) {
      if (errorMsg) {
        formErrors[type] = errorMsg;
      }
      else {
        delete formErrors[type];
      }
      setErrors(formErrors);
    }
  };

  const validateForm = async (inputValues = {}) => {
    let validationResponse = '';
    let formValidationsResponse = {};

    let inputFields = Object.keys(validations);

    inputFields.forEach(field => {
      let value = inputValues[field];
      validations[field].find(validation => {
        switch (validation) {
          case 'isEmail':
            //validationResponse = zendeskHelper.validEmail(value);
            validationResponse = !validationResponse ? (!value || value.length == 0 ? 'Required' : null) : validationResponse;
            break;
          case 'isPhone':
            //validationResponse = zendeskHelper.validPhone(value);
            break;
          case 'isRequired':
            validationResponse = !value || value.length == 0 ? 'Required' : null;
            break;
        };

        return !!validationResponse;

      });

      if (validationResponse) {
        formValidationsResponse[field] = validationResponse;
      }
    });

    return formValidationsResponse;
  }

  const onSubmit = async event => {
    event.preventDefault();
    setLoading(true);

    const formErrors = await validateForm(formValues);

    if (formErrors && Object.keys(formErrors).length > 0) {
      setLoading(false);
      setErrors(formErrors);
    }
    else {

      let authorUpdatePayload = {};

      formFields.map(field => {
        if (formValues[field.name]) {
          authorUpdatePayload[field.authorProfileName] = formValues[field.name];
        }
      })

      //const note = `${conversationNote}: ${new Date().toDateString()}`;
      const response = await apiManager.genericUpdate({ authorUpdatePayload, token, queryToken: '', customer, note: 'Customer submitted authentication form.', reactivate: true});

      if (response) {
        history.push(SuccessPath)
      }
      else {
        history.push(ErrorPath)
      }

      setLoading(false);
    }
  }

  const screenConfig = props.screenConfig.landing;

  let isDisabled = loading || (errors && Object.keys(errors).length > 0);

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <Grid container>
          <Grid
            container
            item
            lg={12}
            xs={12}
            style={{
              justifyContent: 'center',
            }}
          >
            <Grid item>
              <Typography variant="h4" className={classes.title}>
                {screenConfig.pageTitle}
              </Typography>
            </Grid>
            <Grid item></Grid>
          </Grid>
          {!init && (
            <Grid className={classes.progressWrapper} item lg={12} xs={12}>
              <Typography variant="h6" className={classes.description}>
                {<FormattedMessage id="socialVerificationForm.loading" />}
              </Typography>
              <CircularProgress disableShrink className={classes.progress} />
            </Grid>
          )}
          {init && (
            <Grid item lg={12} xs={12}>
              <Typography variant="h6" className={classes.description}>
                {screenConfig.contentDescription}
              </Typography>
            </Grid>
          )}
        </Grid>
        {init && (
          <Grid container className={classes.customForm}>
            <Grid item container>

              {formFields.map(field => {

                let component = null;
                let fieldName = field.name;
                switch (field.type) {
                  case 'text':
                  default:
                    component = (
                      <TextFieldInput
                        label={screenConfig[fieldName] || field.label || '' } type={fieldName}
                        dataItem={formValues[fieldName]} dataAction={(value) => handleOnChange(value, fieldName)}
                        validations={validations[fieldName]} isRequired={!!field.required} fieldError={errors[fieldName]} handleErrors={handleErrors}
                      />
                    );
                    break;
                }
                return component;

              })}

            </Grid>
            <Grid item container>
              <Grid item lg={12} xs={12}></Grid>
              <Grid item lg={6} xs={12} style={{ margin: '0 auto', maxWidth: '100%' }}>
                <FormControl className={classes.formControl}>
                  <Button
                    variant="contained"
                    className={classes.button}
                    style={isDisabled ? {} : {
                      color: '#fff',
                      backgroundColor: '#0073d1'
                    }}
                    disabled={isDisabled}
                    onClick={onSubmit}
                    fullWidth
                  >
                    <FormattedMessage id="socialVerificationForm.submit" />
                  </Button>
                  {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        )}
      </div>
    </div>
  );

}

export default GenericLanding;
