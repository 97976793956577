import { makeStyles, useTheme } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
    root: {
        height: 'auto',   
      },
      body: {
        backgroundColor: 'rgb(248, 248, 248)'
      },
      header: {
        backgroundColor: '#f8f8f8'
      },
      appBar: {
        height: '100%',
        width: '100%',
        boxShadow: 'none'
      },
      title: {
        borderBottom: 'none',
      },
      mainContent: {
        height: '100%',
        width: '100%'
      },
      content: {
        maxWidth: '100%',
        width: '100%',
        margin: 'auto',
        [theme.breakpoints.down('sm')]: {
          padding: '32px 30px',
        },
        [theme.breakpoints.up('md')]: {
          padding: '32px 114px 64px 104px',
        },
        [theme.breakpoints.up('lg')]: {
          padding: '32px 114px 64px 104px',
        },
        backgroundColor: '#fff',
      },
      logo: {
        height: "36px",
        position: 'relative',
        left: '10px'
      },
      appBarContent: {
        width: '950px',
        margin: 'auto',
        padding: 0
      },
      menuButton: {
        marginRight: theme.spacing(2),
      },
      footer: {
        background: 'transparent !important'
      }
  }));

  export default useStyles;