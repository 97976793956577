import React, {useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import './custom.css';
import {makeStyles} from '@material-ui/styles';
import {CircularProgress, Grid} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';

import {FIDELITY_GUID} from '../../constants';
import './fonts/style.css';

const ErrorPath = `/${FIDELITY_GUID}/error`;
let queryToken = '';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: '100%',
    flexGrow: 1,
    padding: '0 20',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  requiredLabel: {
    color: 'rgba(0, 0, 0, 0.54)',
    margin: '0 0 0 8px',
  },
  customForm: {
    paddingTop: 20,
    //maxWidth: '500px',
    //border: '1px solid #e2e2e2',
    margin: '0 auto',
  },
  progressWrapper: {
    paddingBottom: '2rem',
  },
  progress: {
    margin: '20px auto',
    color: '#118741'
  }

}));

let Success = (props) => {
  const classes = useStyles();

  const {apiManager, history} = props;
  const intl = useIntl();

  const [init, setInit] = useState(false);
  const [loading, setLoading] = useState(true);

  const [chatData, setChatData] = useState(null);
  const [postMetadata, setPostMetadata] = useState({});


  useEffect(() => {
    const pageTitle = intl.formatMessage({id: 'fidelity.success.pageTitle'});
    if (document.title !== pageTitle) {
      document.title = pageTitle;
    }
  }, [])

  const getModernChatData = async () => {
    const responseData = await apiManager.getChatJson({id: 'fidelity'});

    if (responseData) {
      setPostMetadata(responseData.postMetadata);
      setChatData(responseData.output);

    } else {
      history.push(ErrorPath)
    }
  }

  useEffect(() => {
    if (history.location.search.indexOf('?code=') > -1) {
      queryToken = history.location.search.replace('?code=', '');
      if(!queryToken){
        history.push({pathname: ErrorPath, state:{ error_code: 'SCE-200'}});
      }else{
        onSuccess(queryToken);
      }
    } else {
      history.push({pathname: ErrorPath, state:{ error_code: 'SCE-204'}});
    }
    setInit(true)
  }, [])

  const injectScript = () => {
    window.addEventListener('khorosInit', async (event) => {
      console.log('khorosinit event');

      console.log(window.Brandmessenger);

      setLoading(false);
    });

    const { companyKey, widgetId, jwt, userId, givenName, properties } = chatData;

    window.KHOROS_CONFIG = {
      companyKey: companyKey,
      widgetId: widgetId,
      jwt,
      userId,
      metadata: postMetadata,
      userMetadata: {
        name: givenName,
        metadata: properties
      },
    };

    console.log('injectScript function');

    var scriptTag = document.createElement('script');
    scriptTag.setAttribute('src', 'https://brand-messenger.app.khoros.com/bundle/loader.js?v=' + new Date().getTime());
    document.head.appendChild(scriptTag);
  }

  useEffect(() => {

    if (chatData) {
      injectScript();
    }
  }, [chatData, postMetadata])

  const onSuccess = async (code) => {
    setLoading(true);
    const response = await apiManager.fidelityUpdate(code);
    const errorCode = response && response.response ? response.response.data.sc_error_code : null;

    if (response.status === 200) {
      getModernChatData();
    }
    else {
      setLoading(false);
      history.push({pathname: ErrorPath, state:{ error_code: errorCode}});
    }
  }

  return (
    <div className={classes.root}>

      <div id="overlay" style={{
        height: 0,
        background: '#fff',
        zIndex: 234234,
        position: 'absolute',
        width: '100%',
        left: '0px',
        top: '92px',
      }}>
        <br/>
        <div style={{margin: '0 auto', width: '50%'}}>
        </div>
        <br/>
      </div>
      <div id ="bmv2Container"></div>
      <div className={classes.content}>
        <Grid
          container
        >
          {loading && <CircularProgress className={classes.progress}/>}
        </Grid>

      </div>


    </div>
  );
};

export default Success;