import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { makeStyles } from '@material-ui/styles';
import Wrapper from '../../../components/Wrapper'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    flexGrow: 1
  }
}));

const Error = (props) => {
  const classes = useStyles();

  const error = sessionStorage.getItem('error') ? JSON.parse(sessionStorage.getItem('error')) : false;
  
  const { apiManager, history, screenConfig } = props;
  const [network, setNetwork] = useState(sessionStorage.getItem('network') ? sessionStorage.getItem('network') : false);
  const [user, setUser] = useState(sessionStorage.getItem('user') ? JSON.parse(sessionStorage.getItem('user')) : false);
 
  const { normalizedAuthorName, normalizedUsername, socialNetwork, instagramId, instagramUsername } = error;
  const { authorName, conversationId, authorExternalId, providerTypeName } = user;

  return (
    <div className={classes.root}>
        
            <Wrapper screenConfig={screenConfig}></Wrapper>
            { error && 
            <div>
          <h3>{ error.msg } </h3>
          <table>
            <tr>
              <th style={{width: '200px'}}>Authenticated</th>
              <th>Expected</th>
            </tr>
            <tr>
              <td>{ instagramUsername }</td>
              <td>{ authorName }</td>
            </tr>
            <tr>
              <td>{ instagramId }</td>
              <td>{ authorExternalId }</td>
            </tr>
            <tr>
              <td>{ socialNetwork }</td>
              <td>{ providerTypeName }</td>
            </tr>
          </table>
          </div>
        }
    </div >
  );
};

export default Error;
