import { makeStyles } from '@material-ui/styles';

const customerStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        height: '100%',
        flexGrow: 1,
        padding: 20
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120
    },
    requiredLabel: {
        color: 'rgba(0, 0, 0, 0.54)',
        margin: '0 0 0 8px'
    },
    title: {
        flexGrow: 1,
        borderBottom: '4px solid #0095DA',
        fontSize: '2.33em',
        lineHeight: 1.25,
        color: '#2C2A29',
        fontFamily: 'Proxima Nova Bold, Arial, sans-serif',
        marginBottom: '1rem'
    },
    description: {
        lineHeight: 1.55,
        margin: '0.8em 0',
        color: '#2C2A29',
        fontFamily: 'Proxima Nova Regular, Arial, sans-serif'
    },
    button: {
        marginTop: 18,
        textAlign: 'center',
        fontWeight: 'bold',
        lineHeight: 1,
        borderRadius: '4px',
        letterSpacing: '0.04em',
        padding: '15px 20px',
        fontFamily: 'Proxima Nova Regular, Arial, sans-serif',
        '&:hover': {
            opacity: 0.7
        }
    },
    customForm: {
        paddingTop: 20,
        //maxWidth: '500px',
        //border: '1px solid #e2e2e2',
        margin: '0 auto'
    },
    input: {
        fontFamily: 'Proxima Nova Regular, Arial, sans-serif'
    },
    inputError: {
        color: '#f04848',
        border: '1px solid #f04848',
        borderRadius: '4px'
    },
    progressWrapper: {
        paddingBottom: '2rem'
    },
    progress: {
        margin: 'auto',
        color: '#0095DA'
    },
    buttonProgress: {
        color: '#0095DA'
    }
}));

const svButtonStyles = makeStyles(theme => ({
    bodyCare: {
      backgroundColor: '#f1f4f6'
    },
    root: {
        padding: theme.spacing(1),
        width: '100%',
        flexGrow: 1,
        background: '#f1f4f6'
    },
    content: {
        padding: 5,
        paddingTop: 0,
        textAlign: 'left',
        background: 'none'
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120
    },
    button: {
        marginTop: 18,
        textAlign: 'center',
        borderRadius: '10em',
        letterSpacing: '0.04em',
        padding: '0.6em 0.6em',
        width: '280px',
        fontFamily: "'Montserrat', sans-serif",
        '&:hover': {
            opacity: 0.7
        }
    }
}));

export {
    customerStyles,
    svButtonStyles
}
