import React, { useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import _ from 'lodash';
import moment from 'moment'
import { Grid, Typography, Select, MenuItem, FormControl, InputLabel, TextField, Button, Snackbar, CircularProgress } from '@material-ui/core';
import TextFieldInput from '../../../components/Inputs/TextFieldInput';
import DateFieldInput from '../../../components/Inputs/DateInput';

import { API_LOCAL, API_LAMBDA, IS_LOCAL_DEV, BLUESHIELD_GUID as GUID, PHASE } from '../../../constants';
import {customerStyles as useStyles} from './styles';
//import './fonts/style.css'

const COMPANY_KEY = 'blueshield';
const SuccessPath = `/${GUID}/success`;
const ErrorPath = `/${GUID}/error`;

const Landing = (props) => {
  const classes = useStyles();

  const { apiManager, history, screenConfig } = props;
  const intl = useIntl();

  const [init, setInit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [identityError, setIdentityError] = useState('');
  const [lookupError, setLookupError] = useState('');

  const [memberId, setMemberId] = useState('');
  const [fullName, setFullName] = useState('');
  const [dateOfBirth, setDateOfBirth] = useState(null);
  const [address, setAddress] = useState('');

  const [errors, setErrors] = useState({});
  const [token, setToken] = useState('');

  const validations = {
    memberId: ['atLeastOneIdentity'],
    fullName: ['atLeastOneIdentity'],
    dateOfBirth: ['atLeastOneLookup'],
    address: ['atLeastOneLookup']
  };

  const handleChange = (callback, event) => {
    if (event) {
      callback(event.target.value);
    }
  };

  const handleErrors = (type, errorMsg) => {
    let formErrors = _.cloneDeep(errors);
    if (type) {
      if (errorMsg) {
        formErrors[type] = errorMsg;
      }
      else {
        delete formErrors[type];
      }
      setErrors(formErrors);
    }
  };

  const validateForm = async (inputValues = {}) => {
    let formValidationsResponse = {};
    const requiredLabel = intl.formatMessage({ id: 'validations.required' })
    const atLeastOneLookupError = 'Please provide Date of Birth or Address'
    const atLeastOneIdentityError = 'Please provide your Member Id or Full name'

    let inputFields = Object.keys(validations);

    inputFields.forEach(field => {
      let value = inputValues[field];
      let validationResponse = '';

      validations[field].find(validation => {
        switch (validation) {
          case 'atLeastOneLookup':
            validationResponse = !value || value.length === 0 ? (!inputValues.dateOfBirth && !inputValues.address ? atLeastOneLookupError : null) : null;
            break;
          case 'atLeastOneIdentity':
            validationResponse = !value || value.length == 0 ? (!inputValues.fullName && !inputValues.memberId ? atLeastOneIdentityError : null) : null;
            break;
          default:
            break;
        };

        return !!validationResponse;
      });

      if (validationResponse) {
        formValidationsResponse[field] = validationResponse;
      }
    });

    return {
      errors: formValidationsResponse
    };
  }

  const validateIdentity = (type, value) => {
    
    if(value.length > 0){
      setIdentityError('')
    }
  }

  const validateLookup = (type, value) => {
    
    if(value.length > 0){
      setLookupError('')
    }
  }
  

  const onSubmit = async event => {
    event.preventDefault();
    setLoading(true);

    const formErrors = await validateForm({ memberId, fullName, dateOfBirth, address });

    if(formErrors.errors.dateOfBirth || formErrors.errors.address){
      //setLookupError(intl.formatMessage({ id: 'validations.atLeastOneFormOfContact' }));
      setLookupError('Please provide Date of Birth or Address');
    }

    if(formErrors.errors.memberId || formErrors.errors.fullName){
      //setIdentityError(intl.formatMessage({ id: 'validations.atLeastOneFormOfContact' }));
      setIdentityError('Please provide your Member Id or Full name'); 
    }
    
    if (Object.keys(formErrors.errors).length > 0) {
      setLoading(false);
      setErrors(formErrors.errors);
    }
    else {
      const payload = {
        svMemberId: memberId,
        svFullName: fullName,
        svDateOfBirth: moment(dateOfBirth).format('MM/DD/yyyy'),
        svAddress: address,
      };

      const response = await apiManager.update(payload, token, '', 'blueshield');
      if (response) {
        history.push(SuccessPath)
      }
      else {
        history.push(ErrorPath)
      }

      setLoading(false);
    }
  }


  const getJwt = async (payload) => {
    const API = IS_LOCAL_DEV ? API_LOCAL : API_LAMBDA;
    const token = sessionStorage.getItem('token');
    const queryToken = history.location.search.indexOf("token") > -1 ? history.location.search.replace("?token=", "") : false;
    const queryPayload = history.location.search.indexOf("payload") > -1 ? history.location.search.replace("?payload=", "") : false;

    if (!queryPayload && !queryToken) {
      // error
      history.push(ErrorPath);
    }
    else if (queryPayload && !queryToken) {
      const data = await apiManager.init(payload, `blueshieldca-${PHASE}`);

      if (!data) {
        history.push(ErrorPath)
      }
      else {
        sessionStorage.setItem("token", data.jwt);
        sessionStorage.setItem("network", data.sso.network);
        sessionStorage.setItem("client", 'blueshieldca');
        setToken(token);
        setInit(true);

      }
    }
  };


  let payload = '';
  if (window.location.search.indexOf("?payload=") > -1) {
    payload = window.location.search.replace("?payload=", "");
  }


  if (!init) {
    const pageTitle = intl.formatMessage({ id: 'defaultSecureVerification.pageTitle' });
    if (document.title !== pageTitle) {
      document.title = pageTitle;
    }
    getJwt(payload);
  }


  let disabledSubmit = loading;

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <Grid
          container
        >
          <Grid
            container
            item
            lg={12}
            xs={12}
            justify="space-between"
            alignItems="flex-start"
          >
            <Grid
              item
            >
              <Typography variant="h4" className={classes.title}>
                {<FormattedMessage id='defaultSecureVerification.title' />}
              </Typography>
            </Grid>
          </Grid>
          {!init && (
            <Grid
              className={classes.progressWrapper}
              item
              lg={12}
              xs={12}
            >
              <Typography variant="h6" className={classes.description}>
                {<FormattedMessage id='defaultSecureVerification.loading' />}
              </Typography>
              <CircularProgress disableShrink className={classes.progress} />
            </Grid>
          )}
          {init && <Grid
            item
            lg={12}
            xs={12}
          >
            <Typography variant="h6" className={classes.description}>
              {<FormattedMessage id='defaultSecureVerification.description' />}
            </Typography>
          </Grid>}
        </Grid>
        {init && <Grid
          container
          justify="left"
          className={classes.customForm}
        >
          <Grid
            item
            container
            justify="left"
          >
            <TextFieldInput
              label='Member Id'
              placeholder={'Member Id'}
              type='memberId'
              id='memberId'
              dataItem={memberId} 
              dataAction={setMemberId}
              fieldError={!memberId && !fullName && identityError}
              validations={identityError && validations.memberId} 
            />

            <TextFieldInput
              label='Full Name'
              placeholder={'Full Name'}
              type='fullName'
              id='fullName'
              dataItem={fullName} 
              dataAction={setFullName}
              fieldError={!memberId && !fullName && identityError}
              validations={identityError && validations.fullName} 
            />

            <DateFieldInput
              label='Date of Birth'
              placeholder={'Enter Date of birth (MM/DD/YYYY)'}
              type='dateOfBirth'
              id='dateOfBirth'
              dataItem={dateOfBirth} 
              dataAction={setDateOfBirth}
              format={'MM/dd/yyyy'}
              validations={'isDate'}              
              fieldError={!address && !dateOfBirth && lookupError}
              validations={validations.dateOfBirth} 
              showFormError={!address && !dateOfBirth && lookupError}
            />

            <TextFieldInput
              label='Mailing Address'
              placeholder={'Address'}
              type='address'
              id='address'
              inputProps={{ maxLength: 255 }}
              rows={5}
              multiline
              dataItem={address} 
              dataAction={setAddress}
              fieldError={!address && !dateOfBirth && lookupError}
              validations={validations.address} 
            />
          </Grid>
        </Grid>
        }
        <FormControl className={classes.formControl}>
          <Button variant="contained" className={classes.button}
            style={disabledSubmit ? {} : {
              color: '#FFFEF9',
              backgroundColor: '#0071bc'
            }}
            onClick={onSubmit}
          >
            <FormattedMessage id='defaultSecureVerification.submit' />
          </Button>
          {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
        </FormControl>
      </div>
    </div >
  );
};

export default Landing;
