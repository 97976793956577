import React from 'react';

const SecureChatLogo = (props) => {
  const { color } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 340.3 266.4" width="40px" height="40px">
      <title>Secure Chat</title>
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <path d="M138.7,130.4a4.48,4.48,0,0,0-1.1.1,9.23,9.23,0,0,0-8,7.7,9,9,0,0,0,3.2,8.6l-2.4,11.8a2.06,2.06,0,0,0,2.1,2.5h12.6a2.11,2.11,0,0,0,2.1-2.5l-2.4-11.7a9.21,9.21,0,0,0,3.4-7.2A9.65,9.65,0,0,0,138.7,130.4Z" fill={ color } />
          <path d="M139,70.1c-.7,0-1.5.1-2.3.1-12.3,1.2-21.5,12-21.5,24.4v14h47.7l-.1-14.7A23.86,23.86,0,0,0,139,70.1Z" fill={color} />
          <path d="M138.7,0C62.1,0,0,54.4,0,121.6c0,28.9,11.5,55.5,30.8,76.4-3.6,9.6-10.1,20.5-21.9,28.3a3.84,3.84,0,0,0,1.5,7c12.3,1.7,33.4,2.1,53-9.6a152.51,152.51,0,0,0,75.3,19.5c76.6,0,138.7-54.4,138.7-121.6S215.2,0,138.7,0Zm54.1,171.4a11.07,11.07,0,0,1-11.1,11.1H95.8a11.07,11.07,0,0,1-11.1-11.1V119.7a11.07,11.07,0,0,1,11.1-11.1h1V94.9c0-22.9,17.8-42.4,40.7-43.3.6,0,1.1-.1,1.6-.1a42.38,42.38,0,0,1,42.3,42.3v14.7h.3a11.07,11.07,0,0,1,11.1,11.1v51.8Z" fill={ color } />
          <path d="M314.2,218.1c16.9-20.3,26.1-45.1,26.1-70.4,0-49.9-34.9-92.6-84.3-110.1A130,130,0,0,1,278.3,70c26.3,18.3,43.1,46.4,43.1,77.8,0,22.9-8.7,44.4-25.2,62.2a9.35,9.35,0,0,0-1.9,9.7,61.81,61.81,0,0,0,11.5,19.6,58.89,58.89,0,0,1-27-8,9.25,9.25,0,0,0-9.5-.1,126.9,126.9,0,0,1-63,16.3h0a128.26,128.26,0,0,1-16.5-1,169.21,169.21,0,0,1-43.1,7.6,148.85,148.85,0,0,0,59.6,12.3h0A147.58,147.58,0,0,0,274,250.2c19,9.5,58.5,2.6,59.6-2.7C328.7,243.1,317.6,224.8,314.2,218.1Z" fill={ color } />
        </g>
      </g>
    </svg>)
}

export default SecureChatLogo;
