import React, { useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import axios from 'axios';
import _ from 'lodash';
import $ from 'jquery';
import { Grid, FormControl, Button, Snackbar, CircularProgress } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';

import { BLUESHIELD_GUID, PHASE  } from '../../../constants';
import {svButtonStyles as useStyles} from './styles';

const LandingPath = `${BLUESHIELD_GUID}/landing`;

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const SvButton = (props) => {
  const classes = useStyles();

  const { apiManager, history } = props;
  const intl = useIntl();

  const [loading, setLoading] = useState(false);
  const [verificationLink, setVerificationLink] = useState('');

  const [open, setOpen] = useState(false);
  const [severity, setSeverity] = useState('');
  const [msg, setMsg] = useState('');
  const [init, setInit] = useState(false);

  useEffect(() => {
    document.body.className = classes.bodyCare;
  }, []);

  const copyToClipboard = (details) => {
    let text = document.createElement('textarea')
    text.value = details
    text.setAttribute('readonly', '')
    document.body.appendChild(text)
    text.select()
    text.focus()
    document.execCommand('copy')
    document.body.removeChild(text)
  }

  const populateTextArea = async text => {
    console.log(text);
    window.top.postMessage(JSON.stringify({ appendResponse: text }), "*");
  }

  let payload = "";
  if (window.location.search.indexOf("?payload=") > -1) {
    payload = window.location.search.replace("?payload=", "");
  }
  const populateCRMTransfer = async event => {
    event.preventDefault();
    setLoading(true);
    setVerificationLink('');

    const initData = await apiManager.init(payload, `blueshieldca-${PHASE}`);
    if(initData){
        console.log(initData);
        const { jwt } = initData;
        sessionStorage.setItem("token", jwt);
    }

    let longLink = `${LandingPath}?payload=${payload}`;
    let data = { url: longLink };
    const verificationResponse = await apiManager.sendSecureSocialVerification(data, `sv-blueshieldca-${PHASE}`);

    if (verificationResponse && verificationResponse.shortLink) {
      const shortenedLink = verificationResponse.shortLink;
      console.log("Successfully shortened url - " + shortenedLink);
      const urlText = shortenedLink; // intl.formatMessage({ id: 'socialVerificationButton.verifyText'}, { link: shortenedLink });
      populateTextArea(urlText);
      setVerificationLink(shortenedLink);
    }
    else {
      const urlText = intl.formatMessage({ id: 'socialVerificationButton.verifyText'}, { link: longLink });
      populateTextArea(urlText);
      setMsg(intl.formatMessage({ id: 'socialVerificationButton.error' }));
      setOpen(true);
      setSeverity('error');
      setVerificationLink(longLink);
    }

    setLoading(false);

  }

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <Grid
          container
          justify="left"
          spacing={2}
        >
          <Grid
            item
            lg={12}
            xs={12}
          >
            <FormControl className={classes.formControl}>
              <Button className={classes.button} variant="contained" color="primary" onClick={populateCRMTransfer} disabled={loading} >
                <FormattedMessage id='socialVerificationButton.label' />
              </Button>
              {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
            </FormControl>
          </Grid>
          <Grid
            item
            lg={6}
            xs={12}
          >
            <Snackbar
              open={open}
              autoHideDuration={6000}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
              onClose={e => setOpen(!open)}>
              <Alert onClose={e => setOpen(!open)} severity={severity}>
                {msg}
              </Alert>
            </Snackbar>
          </Grid>
        </Grid>
      </div>
      </div>
  );
};

export default SvButton;
