import React from 'react';
import PropTypes from 'prop-types';
import sprintLogo from './sprintLogo.png'; 
import sprintPrePaidLogo from './sprintPrePaidLogo.jpg'; 

const SprintPrePaidLogo = ({ className }) => (
  <img src={sprintPrePaidLogo} alt={'Sprint Pre Paid Logo'} width={116} height={70}/>
);

SprintPrePaidLogo.propTypes = {
  className: PropTypes.string
};

export default SprintPrePaidLogo;
