import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
// import './style.css';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  header: {
    color: 'white',
    
  },
}));

export default function Header() {
  const classes = useStyles();
  return (
    <Card className="card">
      <CardContent>
        <Typography variant="body2" component="p">
          <small>© TalkTalk - { moment().format('YYYY') } </small>
        </Typography>
      </CardContent>
    </Card>
  );
}
