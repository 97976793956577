import React, { useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';
import MuiAlert from '@material-ui/lab/Alert';
import _ from 'lodash';

import { Grid, FormControl, Button, Snackbar, CircularProgress } from '@material-ui/core';
import { PHASE, CHARTER_GUID as GUID } from '../../../constants';

const LandingPath = `${GUID}/landing`;
let payload = '';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

const useStyles = makeStyles((theme) => ({
  bodyCare: {
    backgroundColor: '#f1f4f6'
  },
  root: {
        padding: theme.spacing(1),
        width: '100%',
        flexGrow: 1,
        background: '#f1f4f6'
    },
    content: {
        padding: 5,
        paddingTop: 0,
        textAlign: 'left',
        background: 'none'
    },
    links: {
        wordBreak: 'break-word'
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120
    },
    button: {
        marginTop: 18,
        textAlign: 'center',
        borderRadius: '10em',
        letterSpacing: '0.04em',
        padding: '0.6em 0.6em',
        width: '280px',
        fontFamily: "'Montserrat', sans-serif",
        '&:hover': {
            opacity: 0.7
        }
    },
    requiredLabel: {
        color: 'rgba(0, 0, 0, 0.54)',
        margin: '0 0 0 8px'
    }
}));

export default function SvButton({ history, apiManager, screenConfig }) {
    const classes = useStyles();

    const intl = useIntl();

    const [loading, setLoading] = useState(false);
    const [verificationLink, setVerificationLink] = useState('');

    const [open, setOpen] = useState(false);
    const [severity, setSeverity] = useState('info');
    const [msg, setMsg] = useState('');
    const [init, setInit] = useState(false);

    let payload = "";
    if (window.location.search.indexOf("?payload=") > -1) {
        payload = window.location.search.replace("?payload=", "");
    }

    useEffect(() => {
       document.body.className = classes.bodyCare;
     }, []);

    const copyToClipboard = (details) => {
        let text = document.createElement('textarea')
        text.value = details
        text.setAttribute('readonly', '')
        document.body.appendChild(text)
        text.select()
        text.focus()
        document.execCommand('copy')
        document.body.removeChild(text)
    }

    const populateTextArea = async text => {
        console.log(text);
        window.top.postMessage(JSON.stringify({ appendResponse: text }), "*");
    }

    const populateCRMTransfer = async event => {
        event.preventDefault();
        setLoading(true);
        setVerificationLink('');

        const initData = await apiManager.init(payload, `charter-${PHASE}`);
        if(initData){
            console.log(initData);
            const { jwt } = initData;
            sessionStorage.setItem("token", jwt);
        }

        const longLink = `${LandingPath}?payload=${payload}`;
        console.log(longLink)
        let data = { url: longLink };
        const verificationResponse = await apiManager.sendSecureSocialVerification(data, `charter-${PHASE}`);

        if (verificationResponse && verificationResponse.shortLink) {
            const shortenedLink = verificationResponse.shortLink;
            console.log("Successfully shortened url - " + shortenedLink);
            const urlText = shortenedLink; // intl.formatMessage({ id: 'socialVerificationButton.verifyText'}, { link: shortenedLink });
            populateTextArea(urlText);
            setVerificationLink(shortenedLink);
        }
        else {
            //const urlText = intl.formatMessage({ id: 'socialVerificationButton.verifyText' }, { link: longLink });
            populateTextArea(longLink);
            setMsg(intl.formatMessage({ id: 'socialVerificationButton.error' }));
            setOpen(true);
            setSeverity('error');
            setVerificationLink(longLink);
        }

        setLoading(false);
    }

    //add purge logic???
    //https://github.com/lithiumtech/services-react-boilerplate/blob/d9803a58e3f51369510a8e4f4525aaf304443c4e/src/views/Videotron/SvButton/SvButton.js

    return (
        <div className={classes.root}>
            <div className={classes.content}>
                <Grid
                container
                spacing={2}
                >
                <Grid
                    item
                    lg={12}
                    xs={12}
                >
                    <FormControl className={classes.formControl}>
                    <Button className={classes.button}  variant="contained" color="primary" onClick={populateCRMTransfer} disabled={loading} >
                        <FormattedMessage id='socialVerificationButton.label' />
                    </Button>
                    {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                    </FormControl>
                </Grid>
                <Grid
                    item
                    lg={6}
                    xs={12}
                >
                    <Snackbar
                    open={open}
                    autoHideDuration={6000}
                    anchorOrigin= {{ vertical: 'bottom', horizontal: 'center' }}
                    onClose={e => setOpen(!open)}>
                    <Alert onClose={e => setOpen(!open)} severity={severity}>
                        {msg}
                    </Alert>
                    </Snackbar>
                </Grid>
                </Grid>
            </div>
        </div >
    );
};
