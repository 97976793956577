import React, { useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import _ from 'lodash';
import { makeStyles } from '@material-ui/styles';
import {
  Grid,
  Typography,
} from '@material-ui/core';

import MuiAlert from '@material-ui/lab/Alert';


const MAIN_COLOR = '#0072CE';
const SECONDARY_COLOR = '#ffffff';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    flexGrow: 1,
    padding: 20,
    backgroundColor: `${MAIN_COLOR}`,
    marginTop: '4%',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  requiredLabel: {
    color: 'rgba(0, 0, 0, 0.54)',
    margin: '0 0 0 8px',
  },
  content: {
    padding: 5,
    paddingTop: 0,
    textAlign: 'left',
    color: `white`,
    fontWeight: 'bold',
  },
  title: {
    flexGrow: 1,
    borderBottom: `1px solid white `,
    fontSize: '2.33em',
    lineHeight: 1.25,
    fontWeight: 'bold',
    textAlign: 'center',
    color: `${SECONDARY_COLOR}`,
  },
  description: {
    lineHeight: 1.55,
    margin: '0.5em 0',
    color: `${SECONDARY_COLOR}`,
    textAlign: 'center',
  },
  button: {
    textAlign: 'center',
    fontWeight: 'bold',
    lineHeight: 1,
    borderRadius: '4px',
    letterSpacing: '0.04em',
    padding: '15px 20px',
    fontFamily: "'Montserrat', sans-serif",
    '&:hover': {
      opacity: 0.7,
    },
  },
  customForm: {
    paddingTop: 20,
    //maxWidth: '500px',
    //border: '1px solid #e2e2e2',
    margin: '0 auto',
  },
  progressWrapper: {
    paddingBottom: '2rem',
  },
  progress: {
    margin: 'auto',
  },
}));

const Offline = (props) => {
  const classes = useStyles();

  const { apiManager, history, screenConfig } = props;
  const intl = useIntl();

  const [init, setInit] = useState(false);
  const [loading, setLoading] = useState(true);

  const [network, setNetwork] = useState('');
  const [networkUrl, setNetworkUrl] = useState('');

  useEffect(() => {
    const pageTitle = intl.formatMessage({ id: 'dominion.landing.pageTitle' });
    if (document.title !== pageTitle) {
      document.title = pageTitle;
    }
  }, []);

  useEffect(() => {
    const networkSession = sessionStorage.getItem('network');
    if (!networkSession || networkSession.length > 0) {
      setNetwork(networkSession);
    }
  }, []);

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <Grid container>
          <Grid container item lg={12} xs={12} justify="space-between" alignItems="flex-start">
            <Grid item lg={12} xs={12}>
              <Typography variant="h4" className={classes.title}>
                {intl.formatMessage({ id: 'error.title' })}
              </Typography>
            </Grid>
          </Grid>
          <Grid item lg={12} xs={12}>
            <Typography variant="h6" className={classes.description}>
              {intl.formatMessage({ id: 'dominion.businessHours.firstText' })}
            </Typography>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default Offline;
