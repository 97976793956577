import React, {useEffect, useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import _ from 'lodash';
import {makeStyles} from '@material-ui/styles';
import {CircularProgress, Grid, Typography} from '@material-ui/core';
import LockImage from './lockImage.png';
import moment from 'moment';

import MuiAlert from '@material-ui/lab/Alert';

import {API_LAMBDA, API_LOCAL, FIDELITY_GUID, IS_LOCAL_DEV, PHASE} from '../../constants';
import './fonts/style.css'

const API = IS_LOCAL_DEV ? API_LOCAL : API_LAMBDA;

const ErrorPath = `/${FIDELITY_GUID}/error`;
const ExpiredPath = `/${FIDELITY_GUID}/expired`;

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    flexGrow: 1,
    padding: '0 20',
    margin: '0 auto',
    marginTop: '4rem',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  requiredLabel: {
    color: 'rgba(0, 0, 0, 0.54)',
    margin: '0 0 0 8px',
  },
  title: {
    flexGrow: 1,
    fontSize: '2.33em',
    fontFamily: 'Fidelity Sans Regular, Helvetica, Sans-serif !important',
    lineHeight: 1.25,
    fontWeight: 'bold',
  },
  description: {
    lineHeight: 1.55,
    fontFamily: 'Fidelity Sans Regular, Helvetica, Sans-serif !important',
    margin: '0.5em 0',
  },
  button: {
    marginTop: 18,
    textAlign: 'center',
    fontWeight: 'bold',
    lineHeight: 1,
    borderRadius: '4px',
    letterSpacing: '0.04em',
    padding: '15px 20px',
    //fontFamily: "'Montserrat', sans-serif",
    '&:hover': {
      opacity: 0.7,
    },
  },
  customForm: {
    paddingTop: 20,
    //maxWidth: '500px',
    //border: '1px solid #e2e2e2',
    margin: '0 auto',
  },
  progressWrapper: {
    paddingBottom: '2rem',
  },
  progress: {
    margin: 'auto',
    color: '#118741'
  },
}));

const Landing = (props) => {
  const classes = useStyles();

  const {apiManager, history} = props;
  const [init, setInit] = useState(false);
  const [loading, setLoading] = useState(true);
  const [showMessage, setShowMessage] = useState(false)
  const intl = useIntl();

  const getJwt = async (data) => {

    const params = history.location.search.slice(1).split('&').reduce((acc, param) => {
      const [key, value] = param.split('=');
      return Object.assign(acc, {[key]: value})
    }, {});
    const {payload, iv} = params;
    const queryToken = params.token ? params.token : false;

    if (!payload && !queryToken) {
      history.push({pathname: ErrorPath, state:{ error_code: 'SCE-100'}});
    } else if (payload && !queryToken) {

      const data = await apiManager.init(payload, `fidelity-${PHASE}`, iv);
      console.log(data)

      if (data) {
        // 20min expiration validation values
        const currentMoment = moment();
        const current = moment().valueOf();
        const payloadTime = data.decryptedPayload.createdTimeMillis;
        console.log('times', payloadTime, current)
        const payloadMoment = moment(payloadTime);
        // Getting time difference between current time and payload time
        const duration = moment.duration(currentMoment.diff(payloadMoment));
        const linkTimeDiff = duration.asMinutes();
        console.log('time difference', linkTimeDiff)

        // conversations check
        const conversation = await apiManager.getConversation(data.decryptedPayload.conversationDisplayId);

        if (conversation.childCaseDisplayIds) {
          const childConversation = await apiManager.getConversation(conversation.childCaseDisplayIds[0]);
          if (childConversation.closeDate) {
            history.push(ExpiredPath)
          } else{
            sessionStorage.setItem('token', data.jwt);
            sessionStorage.setItem('network', data.sso.network);
            sessionStorage.setItem('client', 'fidelity');
            setLoading(false);
            setShowMessage(true);

            window.location = `${API}/oidc/fidelity/login`

          }
        } else if (data.decryptedPayload.providerTypeName === 'rss' && linkTimeDiff >= 60) {
          history.push(ExpiredPath);
        } else if (data.decryptedPayload.providerTypeName !== 'rss' && linkTimeDiff >= 20) {
          history.push(ExpiredPath)
        } else {
          sessionStorage.setItem('token', data.jwt);
          sessionStorage.setItem('network', data.sso.network);
          sessionStorage.setItem('client', 'fidelity');
          setLoading(false);
          setShowMessage(true);

          window.location = `${API}/oidc/fidelity/login`
        }
      } else {
        history.push({pathname: ErrorPath, state:{ error_code: 'SCE-101'}});
      }
    }
  };

  let payload = '';
  if (window.location.search.indexOf('?payload=') > -1) {
    payload = window.location.search.replace('?payload=', '');
  }


  useEffect(() => {

    if (!init) {
      const pageTitle = intl.formatMessage({id: 'fidelity.landing.pageTitle'});
      if (document.title !== pageTitle) {
        document.title = pageTitle;
      }
      getJwt(payload);
    }
  }, [])

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <Grid
          container
        >
          {loading && <CircularProgress className={classes.progress}/>}</Grid>
        <Grid
          item
          lg={12}
          xs={12}
        >

          <img src={LockImage}/>
          <Typography variant="h4" className={classes.title}>
            {<FormattedMessage id="socialVerificationForm.title"/>}
          </Typography>

        </Grid>
        <Grid
          className={classes.progressWrapper}
          item
          lg={12}
          xs={12}
        >
          <Typography variant="h6" className={classes.description}>
            {<FormattedMessage id="fidelity.landing.firstText"/>}
          </Typography>
          <Typography variant="h6" className={classes.description}>
            {<FormattedMessage id="fidelity.landing.secondText"/>}
          </Typography>
          {showMessage &&
            <Typography variant="h6" className={classes.description}>
              <FormattedMessage id="fidelity.landing.thirdText"/>
              <br />
              <a href={`${API}/oidc/fidelity/login`}>Click here</a> if you are not redirected.
            </Typography>}
        </Grid>
      </div>
    </div>
  );
};

export default Landing;
