import React, { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

import { makeStyles } from '@material-ui/styles';
import { Grid, Select, MenuItem, FormControl, FormHelperText, OutlinedInput, Input, FilledInput, InputAdornment, IconButton, InputLabel, TextField, Button } from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';


import zendeskHelper from '../../helpers/zendeskHelper';


const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(4)
    },
    content: {
        padding: 50,
        textAlign: 'left'
    },
    errorTextHelper: {
        color: '#f44336'
    },
    formControl: {
        margin: theme.spacing(1),
        width: '95%',
        maxWidth: '550px'
    }
}));



const PasswordInput = (props) => {
    const classes = useStyles();

    const { dataItem, dataAction, count, label, type, multiline, inputProps, validations, onInputChange, handleErrors, fieldError, isNumericOnly, isRequired, variant } = props;
    const [error, setError] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const hasValidations = validations && validations.length > 0;

    const handleValidations = value => {
        let validationResponse = '';
        let countValue = count;

        validations.find(validation => {
            switch (validation) {
                case 'isEmail':
                    validationResponse = zendeskHelper.validEmail(value);
                    break;
                case 'isPhone':
                    validationResponse = zendeskHelper.validPhone(value);
                    break;
                case 'isRequired':
                    validationResponse = value.length == 0 ? <FormattedMessage id='validations.required' /> : null;
                    break;
                //case 'atLeastTwo':
                //    validationResponse = value.length == 0 ? <FormattedMessage id='validations.required' /> : null;
                //    break;
                default:
                    break;
            };

            return !!validationResponse;
        });

        setError(validationResponse);
        if (handleErrors) {
            handleErrors(type, validationResponse);
        }
    }

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const onChange = (e) => {
        let value = e.target.value;
        if (isNumericOnly) {
            const re = /^[0-9\b]+$/;

            if (value === '' || re.test(value)) {
                dataAction(value);
            }
            else if (value.length == 1) {
                dataAction('');
            }
        }
        else {
            dataAction(value);
        }

        if (hasValidations) {
            handleValidations(value);
        }

        if (onInputChange) {
            onInputChange(type, value);
        }
    }

    const renderInput = (variant = "outlined") => {
        let hasError = hasValidations && (fieldError || error);
        let input = null;
        let renderProps = {
            error: !!hasError,
            id: `${label}-input`,
            type: showPassword ? 'text' : 'password',
            value: dataItem,
            onChange: onChange,
            required: !!isRequired,
            inputProps: inputProps || {},
            labelWidth: label ? label.length * (isRequired ? 10 : 9) : 110,
            'aria-describedby': `${label}-error-text`,
            endAdornment: (
                <InputAdornment position="end">
                    <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                    >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                </InputAdornment>
            )
        };

        switch (variant) {
            case 'outlined':
                input = (
                    <OutlinedInput
                        {...renderProps}
                    />);
                break;
            case 'filled':
                input = (
                    <FilledInput
                        {...renderProps}
                    />);
                break;
            default:
                input = (
                    <Input
                        {...renderProps}
                    />);
                break;
        }

        return input;

    }

    const renderControl = () => {
        let hasError = hasValidations && (fieldError || error);

        return (<Grid
            item
            lg={12}
            xs={12} >
            <FormControl className={classes.formControl} variant={variant || "outlined"}>
                <InputLabel className={hasError && classes.errorTextHelper} htmlFor={`${label}-input`}>{`${label}${isRequired ? ' *' : ''}`}</InputLabel>
                {renderInput(variant)}
                {hasError &&
                    <FormHelperText className={classes.errorTextHelper} id={`${label}-error-text`}>{fieldError || error}</FormHelperText>
                }
            </FormControl>
        </Grid>)
    }

    return (renderControl());
};

export default PasswordInput;