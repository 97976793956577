import "./style.css";

import React, { useState, useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import _ from "lodash";
import { makeStyles } from "@material-ui/styles";
import {
  Grid,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  TextField,
  Button,
  Snackbar,
  CircularProgress,
} from "@material-ui/core";

import MuiAlert from "@material-ui/lab/Alert";
import { isMobile } from "react-device-detect";

import {
  API_LOCAL,
  API_LAMBDA,
  IS_LOCAL_DEV,
  ROCKSNROPES_GUID as GUID,
} from "../../../constants";

import { KhorosLogo as Logo } from "../../../components/logos/index";

const LandingPath = `/${GUID}/landing`;

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
    flexGrow: 1,
    padding: 20,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  requiredLabel: {
    color: "rgba(0, 0, 0, 0.54)",
    margin: "0 0 0 8px",
  },
  title: {
    flexGrow: 1,
    borderBottom: "4px solid #0a0745",
    fontSize: "2.33em",
    lineHeight: 1.25,
    fontWeight: "bold",
    fontFamily: "Avenir, Brandon, Arial",
    color: "#a1a5b3",
  },
  description: {
    lineHeight: 1.55,
    margin: "0.5em 0",
    fontFamily: "Avenir, Brandon, Arial",
    color: "#a1a5b3",
  },
  button: {
    textAlign: "center",
    fontWeight: "bold",
    lineHeight: 1,
    borderRadius: "4px",
    letterSpacing: "0.04em",
    padding: "15px 20px",
    fontFamily: "Avenir, Brandon, Arial",
    "&:hover": {
      opacity: 0.7,
    },
    background: "#0a0745",
    color: "white",
  },
  customForm: {
    paddingTop: 20,
    //maxWidth: '500px',
    //border: '1px solid #e2e2e2',
    margin: "0 auto",
  },
  progressWrapper: {
    paddingBottom: "2rem",
  },
  progress: {
    margin: "auto",
  },
}));

const Success = (props) => {
  const classes = useStyles();

  const { apiManager, history, screenConfig } = props;
  const intl = useIntl();

  const [init, setInit] = useState(false);
  const [loading, setLoading] = useState(true);

  const [network, setNetwork] = useState("");
  const [networkUrl, setNetworkUrl] = useState("");

  useEffect(() => {
    const pageTitle = intl.formatMessage({
      id: "rocksnropes.success.pageTitle",
    });
    if (document.title !== pageTitle) {
      document.title = pageTitle;
    }
  }, []);

  useEffect(() => {
    if (history.location.search.indexOf("?token=") > -1) {
      const queryToken = history.location.search.replace("?token=", "");
      history.push(`${LandingPath}?token=${queryToken}`);
    }
    const networkSession = sessionStorage.getItem("network");
    if (!networkSession || networkSession.length > 0) {
      setNetwork(networkSession);
    }
    setInit(true);
  }, []);

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <Grid container>
          <Grid
            container
            item
            lg={12}
            xs={12}
            justify="space-between"
            alignItems="flex-start"
          >
            <Grid item>
              <Typography variant="h4" className={classes.title}>
                <FormattedMessage id="rocksnropes.success.title" />
              </Typography>
            </Grid>
            <Grid item>
              <Logo />
            </Grid>
          </Grid>
          <Grid item lg={12} xs={12}>
            <Typography variant="h6" className={classes.description}>
              <FormattedMessage id="rocksnropes.success.tellUsMore" />
            </Typography>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default Success;
