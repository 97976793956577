import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';

import { Topbar } from './components';

const useStyles = makeStyles(() => ({
  root: {
    height: '100%',
    background: '#f1f4f6',
    margin: 'auto'
  },
  content: {
    margin: 'auto',
    background: '#f1f4f6'
  }
}));

const Minimal = props => {
  const { children, screenConfig } = props;

  const classes = useStyles();

  useEffect(() => {
    if (screenConfig) {
      if (screenConfig.pageTitle && document.title !== screenConfig.pageTitle) {
        document.title = screenConfig.pageTitle;
      }
      
      if (screenConfig.favicon) {
        const link = document.querySelector("link[rel*='icon']") || document.createElement('link');
        link.type = 'image/x-icon';
        link.rel = 'shortcut icon';
        link.href = screenConfig.favicon;
        document.getElementsByTagName('head')[0].appendChild(link);
      }
    }
  }, [])

  return (
    <div className={classes.root}>

      <main className={classes.content}>{children}</main>
    </div>
  );
};

Minimal.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
};

export default Minimal;
