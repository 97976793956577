
const Hasbro = {
    countries: [
        {
            "id": 1,
            "text": "US"
        },
        {
            "id": 2,
            "text": "CA"
        },
        {
            "id": 3,
            "text": "AU"
        }
    ],
    categories: [
        {
            "id": 60582,
            "text": "Social Media",
        }
    ],
    channelTypes: [
        {
            id: 412,
            text: "Social Media"
        }
    ],
    statuses: [
        {
            id: 1,
            text: "New",
        }
    ],
    createdContact: {
        "result": {
            "id": Math.round(Math.random() * 1000),
            "lookupName": "test contact",
            "name": {
                "first": "test contact",
                "last": ""
            },
            "address": {
                "country": null,
            },
            "channelUsernames": {
                "links": [
                    {
                        "rel": "self",
                        "href": "https://mysite.example.com/services/rest/connect/v1.4/contacts/83/channelUsernames"
                    }
                ]
            },
            "emails": {
                "links": [
                    {
                        "rel": "self",
                        "href": "https://mysite.example.com/services/rest/connect/v1.4/contacts/83/emails"
                    }
                ]
            },
            "notes": {
                "links": [
                    {
                        "rel": "self",
                        "href": "https://mysite.example.com/services/rest/connect/v1.4/contacts/83/notes"
                    }
                ]
            },
            "phones": {
                "links": [
                    {
                        "rel": "self",
                        "href": "https://mysite.example.com/services/rest/connect/v1.4/contacts/83/phones"
                    }
                ]
            }
        }
    },
    createdIncident: {
        "result": {
            "id": Math.round(Math.random() * 1000),
            "lookupName": "160128-000000",
            "assignedTo": {
                "account": null,
                "staffGroup": null
            },
            "primaryContact": {},
            "subject": "FishPhone not working"
        }
    }
};

const Vodafone = {
    idTypes: [
        {
            id: "Driver's License",
            text: "Driver's License"
        },
        {
            id: "Passport",
            text: "Passport"
        }
    ],
    isAccountHolder: [
        {
            id: "Yes",
            text: "Yes"
        },
        {
            id: "No",
            text: "No"
        }
    ]    
}

export {
    Hasbro,
    Vodafone
}