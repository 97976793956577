import React from 'react';
import PropTypes from 'prop-types'; 
import logo from './fitbit_logo.png'; 

const FitbitLogo = () => (
  <img src={logo} alt={'Logo'} height={45} />
);

FitbitLogo.propTypes = {
  className: PropTypes.string
};

export default FitbitLogo;
