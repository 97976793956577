import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { makeStyles } from '@material-ui/styles';
import { Grid, Select, MenuItem, FormControl, InputLabel, TextField, Button, Snackbar, CircularProgress } from '@material-ui/core';

import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4),
    width: '100%',
    flexGrow: 1,
    background: 'none'
  },
  content: {
    padding: 5,
    paddingTop: 0,
    textAlign: 'left',
    background: 'none'
  },
  links: {
    wordBreak: 'break-word'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  button: {
    marginTop: 10,
  },
  requiredLabel: {
    color: 'rgba(0, 0, 0, 0.54)',
    margin: '0 0 0 8px'
  }
}));

const Terms = (props) => {
  const classes = useStyles();

  const { apiManager, history } = props;

  const [loading, setLoading] = useState(false);

  const [initLoading, setInitLoading] = useState({});

  const [open, setOpen] = useState(false);
  const [severity, setSeverity] = useState('');
  const [msg, setMsg] = useState('');

  const handleChange = (callback, event) => {
    if (event) {
      callback(event.target.value);
    }
  };

  const handleInitLoading = (type, isLoading) => {
    let loading = _.cloneDeep(initLoading);
    if (type) {
      if (isLoading) {
        loading[type] = isLoading;
      }
      else if (loading[type] !== undefined) {
        delete loading[type];
      }
      setInitLoading(loading);
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <Grid
          container
          justify="left"
          spacing={2}
        >
          <Grid
            item
            lg={12}
            xs={12}
          >
            <h5>Terms PAGE</h5>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua</p>
          </Grid>
          <Grid
            item
            lg={6}
            xs={12}
          >
            <Snackbar
              open={open}
              autoHideDuration={6000}
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              onClose={e => setOpen(!open)}>
              <Alert onClose={e => setOpen(!open)} severity={severity}>
                {msg}
              </Alert>
            </Snackbar>
          </Grid>
        </Grid>
      </div>
    </div >
  );
};

export default Terms;
