import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/styles';
import { useMediaQuery } from '@material-ui/core';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import { Sidebar, Topbar, Footer, Rightbar } from './components';
import AcUnitIcon from '@material-ui/icons/AcUnit';

import {
  Grid, Select, MenuItem, FormControl, InputLabel, TextField, Button, Snackbar, CircularProgress,
  AppBar, Typography, Toolbar, IconButton
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: '100%',
    flexGrow: 1
  },
  mainContent: {
    height: '100%',
    width: '100%'
  },
  wrapper: {
    textAlign: 'left',
    marginTop: '2em'
  },
  content: {
    width: '950px',
    margin: 'auto',
    padding: 0
  },
  links: {
    wordBreak: 'break-word'
  },
  appBar: {
    height: '113px',
    width: '100%',
    marginBottom: '2em',
    boxShadow: '0 2px 3px'
  },
  preHeader: {
    backgroundColor: '#4b286d',
    height: '40px'
  },
  logo: {
    height: "28px",
    position: 'relative',
    width: '130px'
  },
  appBarContent: {
    width: '950px',
    margin: 'auto',
    padding: 0
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  listItems:{
    color: "#4b286d",
    "height": "33px",
    "borderWidth": "1px 1px 0",
    "borderStyle": "solid",
    "borderColor": "transparent",
    "fontSize": "17px",
  },
  bodyTelus:{
    paddingRight: "0px",
    paddingLeft: "0px"
  }

}));

const Telus = props => {
  const { children, user, screenConfig } = props;
  if(!children){
    debugger;
  }
  const classes = useStyles();
  const theme = useTheme();

  useEffect(() => {
    if (screenConfig) {
      if (screenConfig.pageTitle && document.title !== screenConfig.pageTitle) {
        document.title = screenConfig.pageTitle;
      }

      if (screenConfig.favicon) {
        const link = document.querySelector("link[rel*='icon']") || document.createElement('link');
        link.type = 'image/x-icon';
        link.rel = 'shortcut icon';
        link.href = screenConfig.favicon;
        document.getElementsByTagName('head')[0].appendChild(link);
      }
    }
  }, [])


  useEffect(() => {
    document.body.className = classes.bodyTelus;
  }, []);

  return (
    <div
      className={clsx({
        [classes.root]: true
      })}
    >
      <main className={classes.mainContent}>
        <AppBar className={classes.appBar}
          style={{
            backgroundColor: '#fff',


          }}
          position="relative">
          <div className={classes.preHeader}></div>
          <Grid
            container
            item
            alignContent="space-around"
            alignItems="center"
            alignContent="space-around"
            //lg={8}
            xs={12}
          >
            <Toolbar className={classes.appBarContent}>
              <a className={classes.logo} href={screenConfig.headerLogoUrl} target='_blank'>{screenConfig.headerLogo}</a>

              <div>
                <nav className={classes.nav}>
                  <ul className={classes.list}>
                    <li className={classes.listItems}><a className={classes.links} href="https://www.telus.com/mobility/content/my-telus?linktype=nav" target="_blank">My Telus</a></li>
                    <li className={classes.listItems}><a className={classes.links} href="http://www.telus.com/support?linktype=nav" target="_blank">Support</a></li>
                  </ul>
                </nav>
              </div>
            </Toolbar>
          </Grid>
        </AppBar>
        <div className={classes.wrapper}>
          <Grid
            container
            item
            alignContent="space-around"
            alignItems="center"
            alignContent="space-around"
            //lg={8}
            xs={12}
          >
            <div className={classes.content}>
              {children}
            </div>
          </Grid>
        </div>
      </main>
      <div style={{paddingTop: '159px'}}>

        <Footer />
      </div>
    </div>
  );
};

Telus.propTypes = {
  children: PropTypes.node,
  user: PropTypes.object
};

export default Telus;
