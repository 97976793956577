import React, { useEffect, useState } from 'react';
import { FormattedMessage, injectIntl, useIntl } from 'react-intl';
import { withStyles } from '@material-ui/styles';
import MuiAlert from '@material-ui/lab/Alert';
import _ from 'lodash';
import moment from 'moment'
import { Grid, FormControl, Button, Snackbar, CircularProgress } from '@material-ui/core';
import { PHASE } from '../../constants';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const styles = (theme) => ({
  root: {
    padding: theme.spacing(1),
    width: '100%',
    flexGrow: 1,
    background: '#f1f4f6',
  },
  content: {
    padding: 5,
    paddingTop: 0,
    textAlign: 'left',
    background: '#F1F4F6',
  },
  links: {
    wordBreak: 'break-word',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  button: {
    marginTop: 18,
    textAlign: 'center',
    letterSpacing: '0.04em',
    padding: '0.6em 0.6em',
    width: '160px',
    fontFamily: "'Montserrat', sans-serif",
    '&:hover': {
      opacity: 0.7,
    },
    fontSize: '12px',
    backgroundColor: '#5A5F63',
  },
  requiredLabel: {
    // color: "rgba(0, 0, 0, 0.54)",
    color: '#2e2e2e',
    margin: '0 0 0 8px',
  },
});

const GenericSVButtonVerify = (props) => {
  //const classes = useStyles();
  const { apiManager, history, classes } = props;
  const intl = useIntl();

  const [loading, setLoading] = useState(false);
  const [loadingVerify, setLoadingVerify] = useState(false);
  const [verificationLink, setVerificationLink] = useState('');

  const [open, setOpen] = useState(false);
  const [severity, setSeverity] = useState('');
  const [msg, setMsg] = useState('');
  const [init, setInit] = useState(false);

//  const [classes, setClasses] = useState(null);
  const [token, setToken] = useState(null);
  const [customer, setCustomer] = useState('')
  const [payloadData, setPayloadData] = useState('')
  const [verified, setVerified] = useState(false);
  const [locale, setLocale] = useState('')

  useEffect(() => {
    if (window.location.search.indexOf('?payload=') > -1) {
      setLoading(true);
      setLoadingVerify(true);
      const urlParams = new URLSearchParams(window.location.search);
      const payload = urlParams.get('payload');
      //const payload = "r0oneebgzqrxJXIE0scFZ0-jEN1cPYAXSeAEZJDCPTPrDTzG_G4Gpsa8lACsMzTDjsXBOc_xe6fJ7dbEvWMT75_Urt4HdM5QCtGFZCgallQLM36_vnmD4Yi4MCvo1GGC_Q5DmG5QIHxuHbeB7iVYpLRcYXrmOLdNuhIANmi-NZbtKKlBe7XpPPZGQuQ2NxFv284aRErMDVRvs4dSVZTYBBKs1C6Hw35wwY-bE_PY3kNVf8JkS-lWnu7OYWBY2nS9-tqhZG1ugo0ZkJ9Vn0F0e63S8AI3iKnnXQw7GTTTp_dh6yE6-nvv2ufyZnsVg3hEk1usCUDXJm8pRL1x_NJrZrIwxC2nNEz9ZfCmFNG0xKLylUJAxGBvkeZeRzrBHK3WIiUwaN94MABplkFSj-O7B_LGuFtE1M2V_hv5P4hOc0zy7UCHDh6Tm1yGnbNwiWt8anYsgLOlFzQkqXizeywLtVUXwugM_FWEJ2X7h1fPCXbtEEw_N259bh2OOwiREtapFf4W-XA2T-IfiiC6vQ4F3UjwG6cO8EXqHaymVxm6g6eDRh2liNG5ir6sNc9BBpc5gBR40qR_eH3fFkzG0wOgj4c6IsctIUIoForTyEsUqOuDQZCJ_Pa8asXxnQ8tpKFG_ZtUIMDJ27_dz38PDlnur5fXHggrPICFnjh5-vdOqmX-DSlJYc-OVRCZYZQcyQJOCIOeEqxlVEzaOtwwe_YMNGiFNLJw5Ten24VBv9-FAKkjFxJhk8bYUyaxc-gdt2ikeXSAWEWSsOe43Slru_z9nZIScSqHZtWKr928brKt6A-HCiPCebdE9jamX_fS5XbeLRWij72r50vzAH_X-dG10wL9YKy4ezp3I41SnG8pYRT3Dhy7MQSdyLc1h8dH8FgOeyQksd7rrnuExZ4eckPZzEnRKhCypnisTMhpA7oBBKJGN8dL3liGHLaOjK18PWHOHmjrT7SEUwEpRXfksqM_6EwLV-aDSoVguLrZZ44KQGZy_wIPhcnCkqUYrHiuU4ScpA9ard6BnynQpWhiEhU=";
      setPayloadData(payload);

      if (!document.referrer || document.referrer === '') {
        console.log('No referrer');
        return;
      }

      const customer = document.referrer.split('//')[1].split('.')[0];
      //const customer = "synchronydev";
      setCustomer(customer);
      console.log('Customer state', customer);

      const doPurge = async (payload, customer) => {
        await purgeData(payload, customer);
      };

      initVerifyBtn(payload, customer);
      // add purge logic when rendering iframe
      doPurge(payload, customer);

      setLoading(false);
    }
  }, [])

  const checkPurge = async(payload, customer) => {
    if(payload.length > 0) { 
      await apiManager.purge(payload, `${customer}-${PHASE}`)
    }
  }

  const purgeData = async(payload, customer) => {
    const purgeAttempt = sessionStorage.getItem('purgeAttempt');
    const now = moment().valueOf();

    if(purgeAttempt) {
      const purge = moment(parseInt(purgeAttempt)).add(1, 'minute').valueOf();
      if(purge < now){
        sessionStorage.setItem('purgeAttempt', now);
        await checkPurge(payload, customer);
      }
    }
    else {
      sessionStorage.setItem('purgeAttempt', now);
      await checkPurge(payload, customer);
    }
  }


  const initVerifyBtn = async(payload, customer) => {
    const initData = await getJWT(payload, customer);
      if (initData) {
        console.log(`populateCRMTransfer - initData: ${initData}`);
        const { decryptedPayload } = initData;
  
        const author = await apiManager.getAuthor(
          decryptedPayload.authorUUID,
          payload,
          token,
          '',
          customer,
        );
        console.log('author', author);
        let verified = false;
  
        if (author && author[0].handles.crm) {
          author[0].handles.crm.forEach((author) => {
            if (
              author.networkinstance === 'secureVerification' &&
              author.verified &&
              author.verified[0] === 'true'
            ) {
              verified = true;
            }
          });
        }
  
        setLoadingVerify(false);
        setVerified(verified);
      }
  }

  const getJWT = async(payload, customer) => {
    const initData = await apiManager.init(payload, `${customer}-${PHASE}`);
    if (initData) {
      console.log(`initData: ${initData}`);
      sessionStorage.setItem('token', initData.jwt);
      setToken(initData.jwt);

      console.log(`locale state: ${JSON.stringify(locale)}`);
      setLocale(initData.locale);

      return initData;
    }
    return null;
  }

  const populateTextArea = async (text) => {
    console.log(text);
    window.top.postMessage(JSON.stringify({ appendResponse: text }), '*');
  };

  const populateCRMTransfer = async (event) => {
    event.preventDefault();
    setLoading(true);
    setVerificationLink('')
    
    const longLink = `/default/landing?payload=${payloadData}&customer=${customer}`;
    console.log(longLink);
    const data = { url: longLink };
    const verificationResponse = await apiManager.sendGenericVerificationLink(
      data,
      `${customer}-${PHASE}`,
      'bitly',
    );

    if (verificationResponse && verificationResponse.shortLink) {
      const shortenedLink = verificationResponse.shortLink;
      console.log('Successfully shortened url - ' + shortenedLink);
      const urlText = `I'm happy to help you with that. For your security, I'll need to verify some information first. Please complete the form using the following link: ${shortenedLink}. We'll be back with you shortly after we receive your information.`;
      populateTextArea(urlText);
      setVerificationLink(shortenedLink)
    } else {
      populateTextArea(longLink);

      setMsg(locale.sbVutton.primaryButton.error);
      setOpen(true);
      setSeverity('error');
      setVerificationLink(longLink)
    }

    setLoading(false);
  };

  const verifyUser = async (event) => {
    event.preventDefault();
    setLoadingVerify(true)
    const authorUpdatePayload = { verified: 'true' }
    const initData = await getJWT(payloadData, customer);
    if (initData) {
      //const note = `${locale.svButton.verifyButton.conversationNote}: ${new Date().toDateString()}` //${intl.formatMessage({ id: 'synchrony.svButton.verifyButton.conversationNote'})}
      const response = await apiManager.genericUpdate({ authorUpdatePayload, token, queryToken: '', customer, note: 'Customer authentication completed by associate.', reactivate: false});
      console.log('verify user', response);

      setLoadingVerify(false);

      if (!response) {
        setMsg(intl.formatMessage({ id: 'synchrony.svButton.verifyButton.error'}));
        setOpen(true);
        setSeverity('error');
      }
    } else {
      console.log('there was an error retrieving the token')
    }
    
  };

  const unverifyUser = async (event) => {
    event.preventDefault();
    setLoadingVerify(true);
    const authorUpdatePayload = { verified: 'false' }
    const initData = await getJWT(payloadData, customer);
    if (initData) {
      //const note = `${locale.svButton.unverifyButton.conversationNote}: ${new Date().toDateString()}` //${intl.formatMessage({ id: 'synchrony.svButton.unverifyButton.conversationNote'})}
      const response = await apiManager.genericUpdate({ authorUpdatePayload, token, queryToken: '', customer, note: 'Authentication removed/expired. Customer authentication required.', reactivate: false });
      console.log('unverify user', response);
      
      setLoadingVerify(false);

      if (!response) {
        setMsg(intl.formatMessage({ id: 'synchrony.svButton.unverifyButton.error'}));
        setOpen(true);
        setSeverity('error');
      }
    } else {
      console.log('there was an error retrieving the token')
    }

  };

    return (
      <div className={classes.root}>
        <div className={classes.content}>
          <Grid container spacing={2}>
            <Grid item lg={12} xs={12}>
              <FormControl className={classes.formControl}>
                <Button
                  className={classes.button}
                  variant="contained"
                  color="primary"
                  onClick={populateCRMTransfer}
                  disabled={loading}
                >
                  <FormattedMessage id={`synchrony.svButton.primaryButton.label`} />
                  {/* ${customer}*/}
                </Button>
                {loading && (
                  <CircularProgress size={24} className={classes.buttonProgress} />
                )}
              </FormControl>
              <FormControl className={classes.formControl}>
                <Button
                  className={classes.button}
                  variant="contained"
                  color="primary"
                  onClick={verified ? unverifyUser : verifyUser}
                  disabled={loadingVerify}
                >
                  <FormattedMessage
                    id={
                      verified
                        ? `synchrony.svButton.unverifyButton.label` //${customer}
                        : `synchrony.svButton.verifyButton.label` //${customer}
                    }
                  />
                </Button>
                {loadingVerify && (
                  <CircularProgress size={24} className={classes.buttonProgress} />
                )}
              </FormControl>
            </Grid>
            <Grid item lg={6} xs={12}>
              <Snackbar
                open={open}
                autoHideDuration={6000}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                onClose={(e) => setOpen(!open)}
              >
                <Alert
                  onClose={(e) => setOpen(!open)}
                  severity={severity}
                >
                  {msg}
                </Alert>
              </Snackbar>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }

export default withStyles(styles, { withTheme: true })(injectIntl(GenericSVButtonVerify));
