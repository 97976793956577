import React from 'react';
import khorosLogo from './images/logo';
import {
  BlueShieldCaLogo,
  BoostMobileLogo,
  CharterLogo,
  FitbitLogo,
  KhorosLogo,
  SecureChatLogo,
  SprintPrePaidLogo,
  TelusLogo,
  VodafoneLogo,
  SynchronyLogo,
  FidelityLogo,
  QuickbooksLogo,
  Manualc01Logo,
  KoodoLogo,
  DominionLogo,
} from './components/logos/index';
import {
  blueShieldCaFavicon,
  BoostMobileFavicon,
  CharterFavicon,
  FitbitFavicon,
  KhorosFavicon,
  SprintFavicon,
  VideotronFavicon,
  VodafoneFavicon,
  SynchronyFavicon,
  FidelityFavicon,
  QuickbooksFavicon,
  Manualc01Favicon,
  TelusFavicon,
  koodoFavIcon,
  dominionFavIcon,
} from './components/Favicons/index';

const blueShieldHeader = {
  headerLogo: <BlueShieldCaLogo />,
  favicon: blueShieldCaFavicon,
  headerLogoUrl: 'https://www.blueshieldca.com/',
  headerColor: '#f8f8f8',
  preHeaderColor: '#f8f8f8',
};


const dominionHeader = {
  headerLogo: <DominionLogo/>,
  headerLogoCentered: true,
  favicon: dominionFavIcon,
  secondHeaderLogo: <SecureChatLogo color={'#0072CE'} />,
  headerLogoUrl: 'https://www.dominionenergy.com/',
  headerColor: '#ffffff',
  preHeaderColor: 'red',
  brandName: 'Dominion Energy',
  showFooter: false
};

const fidelityHeader = {
  headerLogo: <FidelityLogo />,
  favicon: FidelityFavicon,
  headerColor: '#fff',
  preHeaderColor: '#fff',
};

const quickbooksHeader = {
  headerLogo: <QuickbooksLogo />,
  favicon: QuickbooksFavicon,
  headerLogoUrl: 'https://quickbooks.intuit.com/',
  headerColor: '#fff',
  preHeaderColor: '#fff',
};

const manualc01Header = {
  headerLogo: <Manualc01Logo />,
  favicon: Manualc01Favicon,
  headerLogoUrl: '',
  headerColor: '#fff',
  preHeaderColor: '#fff',
};

const boostMobileHeader = {
  headerLogo: <KhorosLogo />,
  secondHeaderLogo: <SecureChatLogo />,
  favicon: KhorosFavicon,
  headerLogoUrl: 'https://www.khoros.com',
};

const charterHeader = {
  headerLogo: CharterLogo(),
  favicon: CharterFavicon,
  headerLogoUrl: 'https://www.spectrum.net/',
  headerColor: '#f8f8f8',
  preHeaderColor: '#f8f8f8',
};

const charter = {
  landing: {
    ...charterHeader,
    pageTitle: 'Account Verification',
    contentTitle: 'Account Verification',
    contentDescription:
      'In order to help us better serve your needs, we will need you to provide your name and at least one other field.',
    name: 'Name',
    phone: 'Phone',
    account: 'Account Number',
    address: 'Address',
  },
  success: {
    ...charterHeader,
    pageTitle: 'Account Verification',
    contentTitle: 'Account Verification',
    contentDescription:
      'In order to help us better serve your needs, we will need you to provide your name and at least one other field.',
    success: "Thanks, we'll get back to you soon!",
  },
  error: {
    ...charterHeader,
    pageTitle: 'Application Error',
    contentTitle: 'Account Verification',
    contentDescription:
      'In order to help us better serve your needs, we will need you to provide your name and at least one other field.',
    error: 'An unknown error has occurred. Please contact your agent with error code: 0x73613',
  },
};

const generic = {
  landing: {
    pageTitle: 'Account Verification',
    contentTitle: 'Account Verification',
    contentDescription:
      'In order for us to look up your account, we need some information first. Please provide the following information and submit.',
    firstName: 'First Name',
    lastName: 'Last Name',
    zipCode: 'Zip Code',
    last4ssn: 'Last 4 Digits of SSN',
  },
  success: {
    pageTitle: 'Verification Information Submitted.',
    contentTitle: 'Verification Information Submitted.',
    success:
      'Thank you! We’re looking up and verifying your account information. Please head back and we’ll be with you shortly.',
  },
  error: {
    pageTitle: 'Application Error',
    contentTitle: 'Account Verification',
    contentDescription:
      'In order to help us better serve your needs, we will need you to provide your name and at least one other field.',
    error: 'An unknown error has occurred. Please contact your agent with error code: 0x73613',
  },
};

const defaultValues = {
  headerLogo: KhorosLogo,
  favicon: KhorosFavicon,
  headerLogoUrl: 'https://www.khoros.com',
  headerColor: '#00004b',
};

const fitbitHeader = {
  headerLogo: FitbitLogo(),
  favicon: FitbitFavicon,
  headerLogoUrl: 'https://www.fitbit.com/',
  headerColor: '#ffffff',
};

const landing = {
  ...defaultValues,
  pageTitle: 'Verify social identity',
  contentTitle: 'Verify your social identity',
  contentDescription:
    'In order to help us better serve your needs, we need you to login to the following social network.',
  hasVideo: true,
  videoUrl:
    'https://assets.khoros.com/content/homepage/Homepage-Video-2.mp4?mtime=20200227124008&amp;focal=none',
};

const poweredBy = {
  ...defaultValues,
  pageTitle: 'Khoros - powered by',
  contentTitle: 'Powered by Khoros',
};

const success = {
  ...defaultValues,
  pageTitle: 'Success page',
  contentTitle: 'Success',
  contentDescription: 'Congrats, you have been verified.',
};

const error = {
  ...defaultValues,
  pageTitle: 'Error page',
  contentTitle: 'Error',
  contentDescription:
    'Sorry, we are unable to verify your account. please contact the agent and get a new verification link.',
};

const khorosScreenConfigs = {
  landing,
  success,
  error,
  poweredBy,
};

const rocksnropesHeader = {
  headerLogo: VodafoneLogo(),
  favicon: VodafoneFavicon,
  headerLogoUrl: 'https://www.vodafone.com.au/',
  headerColor: '#ffffff',
};

const sprintPrePaidHeader = {
  headerLogo: <SprintPrePaidLogo />,
  secondHeaderLogo: <SecureChatLogo />,
  favicon: SprintFavicon,
  headerLogoUrl: 'https://www.sprint.com/landings/prepaid/modals/',
  headerColor: '#ffffff',
};

const telusHeader = {
  headerLogo: <TelusLogo/>,
  favicon: TelusFavicon,
  headerLogoUrl: 'https://www.telus.com/',
  headerColor: '#ffffff',
  preHeaderColor: '#4b286d',
};

const koodoHeader = {
  headerLogo: <KoodoLogo/>,
  favicon: koodoFavIcon,
  headerLogoUrl: 'https://www.koodomobile.com/',
  headerColor: '#ffffff',
  preHeaderColor: '#ffffff',
};

const videotronHeader = {
  favicon: VideotronFavicon,
};

const vodafoneHeader = {
  headerLogo: VodafoneLogo(),
  favicon: VodafoneFavicon,
  headerLogoUrl: 'https://www.vodafone.com.au/',
  headerColor: '#ffffff',
};

const genericHeader = {
  headerLogo: <SynchronyLogo />,
  favicon: SynchronyFavicon,
  headerLogoUrl: 'https://www.mysynchrony.com/',
  headerColor: '#3b3c43',
  preHeaderColor: '#3b3c43',
};

/* Screen Configs*/
const blueShieldScreenConfigs = {
  header: blueShieldHeader,
};

const fidelityScreenConfigs = {
  header: fidelityHeader,
};

const quickbooksScreenConfigs = {
  header: quickbooksHeader
};

const manualc01ScreenConfigs = {
  header: manualc01Header,
};

const bryanPScreenConfigs = {
  header: null,
};

const charterScreenConfigs = {
  landing: charter.landing,
  success: charter.success,
  error: charter.error,
};

const dominionScreenConfigs = {
  header: dominionHeader,
}

const fitbitScreenConfigs = {
  header: fitbitHeader,
};

const rocksnropesScreenConfigs = {
  header: rocksnropesHeader,
};

const sprintPrePaidConfigs = {
  //header: sprintPrePaidHeader,
  header: boostMobileHeader,
  landing,
  success,
  error,
  poweredBy,
};

const telusScreenConfigs = {
  header: telusHeader,
};

const koodoScreenConfigs = {
  header: koodoHeader,
}

const videotronScreenConfigs = {
  header: videotronHeader,
};

const vodafoneScreenConfigs = {
  header: vodafoneHeader,
};

const genericScreenConfigs = {
  header: genericHeader,
  landing: generic.landing,
  success: generic.success,
  error: generic.error,
};

export {
  blueShieldScreenConfigs,
  bryanPScreenConfigs,
  charterScreenConfigs,
  fitbitScreenConfigs,
  khorosScreenConfigs,
  rocksnropesScreenConfigs,
  sprintPrePaidConfigs,
  telusScreenConfigs,
  koodoScreenConfigs,
  vodafoneScreenConfigs,
  videotronScreenConfigs,
  fidelityScreenConfigs,
  quickbooksScreenConfigs,
  genericScreenConfigs,
  manualc01ScreenConfigs,
  dominionScreenConfigs,
};
