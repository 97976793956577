import _ from 'lodash';

//locales
import english from "./en.js";
import french from "./fr.js";


const defaultLocale = 'en';
const language = ((navigator.languages && navigator.languages[0])
    || navigator.language
    || navigator.userLanguage
    || defaultLocale).split(/[-_]/)[0];

const data = {
    en: english,
    fr: french
};

const messages = _.defaults(data[language], data[defaultLocale]);

export {
    language,
    messages
};

