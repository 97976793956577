import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
 
import { Card, TextField, Select, MenuItem, Button, CardContent, CardActions } from '@material-ui/core';
import CommonText from './CommonText'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    height: '100%',
    flexGrow: 1,
    padding: 20
  },
  cardContent: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  cardActions: {
    flexGrow: 0,
    maxWidth: '50%',
    flexBasis: '50%',
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  next:{
    color: '#f4f4f4',
    textAlign: 'center',
    fontWeight: 'bold',
    letterSpacing: '0.04em',
    backgroundColor: '#2c4047',
  },
  next_hover:{
    color: '#f4f4f4',
    textAlign: 'center',
    fontWeight: 'bold',
    letterSpacing: '0.04em',
    backgroundColor: 'red',
  },
}));

export default function InitialForm({ updateParentState, handleError, handleLandingNext }) {
  const classes = useStyles();
  const [name, setName] = useState('');
  const [account, setAccount] = useState('');
  const [type, setType] = useState('');
  

  const handleNext = async (event) => {
    if(validate()){
      handleError('You must fill out all items in the form!');
    } else {
      updateParentState('landing', {name, account, type});
      await handleLandingNext(event, type);
    }
  }

  const handleChange = (event) => {
    switch (event.target.name) {
      case 'name':
        setName(event.target.value);
        break;
      case 'account':
        setAccount(event.target.value);
        break;
      case 'type':
        setType(event.target.value);
        break;
    }
  }

  const validate = () => {
    return name.length === 0 || account.length === 0 || type.length === 0;
  }
  

  return (
    <Card className={classes.card}>  
    <CardContent className={classes.cardContent}>
    <CommonText /> 
      <TextField 
          margin="normal"
          required
          fullWidth
          id="name"
          label="Your name"
          name="name" 
          autoFocus
          onChange={handleChange}
        />
        <TextField 
          margin="normal"
          required
          fullWidth
          id="account"
          label="Phone or account number"
          type="number"
          name="account"  
          onChange={handleChange}
        />
        <Select
        value={type}
        onChange={handleChange}
        name="type" 
        className={classes.formControl} 
        displayEmpty
      >  
      <MenuItem value="" disabled>
          Are you the account holder?
        </MenuItem>
        <MenuItem value="0">Account Holder</MenuItem>
        <MenuItem value="1">Non-Account Holder</MenuItem>
        <MenuItem value="2">Power of Attorney</MenuItem>
      </Select> 
    </CardContent>
    <CardActions className={ classes.cardActions } disableSpacing>
     <Button
      onClick={handleNext}
      color="error" 
      >Next</Button>
    </CardActions> 
  </Card>
  );
}