import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { makeStyles } from '@material-ui/styles';
import { Grid, Typography, FormControl, Button } from '@material-ui/core';
import { isMobile } from 'react-device-detect';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: '100%',
    flexGrow: 1,
    padding: '0 20px'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  requiredLabel: {
    color: 'rgba(0, 0, 0, 0.54)',
    margin: '0 0 0 8px'
  },
  title: {
    flexGrow: 1,
    fontSize: '2em',
    lineHeight: 1.25
  },
  description: {
    lineHeight: 1.25,
    margin: '0.6em 0'
  },
  button: {
    textAlign: 'center',
    letterSpacing: '0.04em',
    '&:hover': {
      opacity: 0.7
    }
  },
  customForm: {
    paddingTop: 10,
    margin: '0 auto'
  }
}));

const Success = (props) => {
  const classes = useStyles();

  const { apiManager, history, screenConfig } = props;
  const [network, setNetwork] = useState('');
  const [networkUrl, setNetworkUrl] = useState('');

  useEffect(() => {
    const pageTitle = 'Vodafone Australia - Success';
    if (document.title !== pageTitle) {
      document.title = pageTitle;
    }
    const networkSession = sessionStorage.getItem('network');
    if (!networkSession || networkSession.length > 0) {
      setNetwork(networkSession);
    }
  }, [])

  useEffect(() => {
    let url;

    switch (network) {
      case 'twitter':
        url = 'https://twitter.com/messages/18186920-1235209488824774657';
        break;
      case 'facebook':
        url = isMobile ? 'https://m.me/vodafoneau' : 'https://www.facebook.com/messages/t/vodafoneau';
        break;
      default:
        break;
    }

    setNetworkUrl(url);

  }, [network])

  return (
    <div className={classes.root} id='vodafoneau'>
      <div className={classes.content}>
        <Grid
          container
        >
          <Grid
            item
            lg={12}
            xs={12}
          >
            <Typography variant="h4" className={classes.title}>
              {'Thanks for that.'}
            </Typography>
          </Grid>
          {networkUrl && <Grid
            item
            lg={12}
            xs={12}
          >
            <Typography variant="h6" className={classes.description}>
              {"Please return to " + ((network=='twitter')? 'Twitter direct message' : 'Facebook messenger') + " and continue with your inquiry."}
            </Typography>
          </Grid>}
        </Grid>
      </div>
    </div >
  );
};

export default Success;
