import React, { useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import _ from 'lodash';
import { makeStyles } from '@material-ui/styles';
import { Grid, Typography, Select, MenuItem, FormControl, InputLabel, TextField, Button, Snackbar, CircularProgress } from '@material-ui/core';

import MuiAlert from '@material-ui/lab/Alert';

import { API_LOCAL, API_LAMBDA, IS_LOCAL_DEV, SPRINTPPD_GUID, PHASE } from '../../../constants';

import zendeskHelper from '../../../helpers/zendeskHelper';
import TextFieldInput from '../../../components/Inputs/TextFieldInput';
import PasswordInput from '../../../components/Inputs/PasswordInput'
import DateInput from '../../../components/Inputs/DateInput';

const SuccessPath = `/${SPRINTPPD_GUID}/success`;
const ErrorPath = `/${SPRINTPPD_GUID}/error`;

//const mainColor = '#FEF104'; //sprintPrePaid color
const mainColor = '#00004b'; //boostMobile color

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    flexGrow: 1,
    padding: '0 20px'
  },
  content: {
    marginBottom: '3em'
  },
  formControl: {
    marginTop: theme.spacing(2),
    margin: theme.spacing(1),
    minWidth: 120
  },
  requiredLabel: {
    color: 'rgba(0, 0, 0, 0.54)',
    margin: '0 0 0 8px'
  },
  title: {
    flexGrow: 1,
    fontSize: '2em',
    lineHeight: 1.25,
    color: "#333333"
  },
  description: {
    lineHeight: 1.55,
    margin: '0.8em 0',
    color: "#333333"
  },
  button: {
    textAlign: 'center',
    letterSpacing: '0.04em',
    fontWeight: 'bold',
    color: '#000 !important',
    backgroundColor: mainColor + ' !important',
    '&:hover': {
      opacity: '0.7'
    },
    '&:disabled': {
      opacity: '0.5'
    }
  },
  customForm: {
    marginTop: "20px !important",
    marginBottom: "20px !important",
    paddingTop: 20,
    border: '1px solid #e2e2e2',
    margin: '0 auto'
  },
  extraFieldsLink: {
    marginTop: '1em',
    cursor: 'pointer',
    color: '#333333',
    textDecoration: 'underline',
    '&:hover': {
      color: '#000000'
    }
  },
  progressWrapper: {
    paddingBottom: '2rem',
    color: '#333333'
  },
  progress: {
    margin: 'auto',
    color: '#333333'
  },
  buttonProgress: {
    color: '#333333'
  }
}));

const Landing = (props) => {
  const classes = useStyles();

  const { apiManager, history, screenConfig } = props;
  const intl = useIntl();

  const [init, setInit] = useState(false);
  const [loading, setLoading] = useState(false);

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phone, setPhone] = useState('');
  const [pin, setPin] = useState('');

  const [errors, setErrors] = useState({});
  const [token, setToken] = useState('');
  const [sso, setSso] = useState({});
  const [queryToken, setQueryToken] = useState('');

  const validations = {
    firstName: ['isRequired'],
    lastName: ['isRequired'],
    phone: ['isPhone', 'isRequired'],
    pin: ['isRequired']
  };

  const handleChange = (callback, event) => {
    if (event) {
      callback(event.target.value);
    }
  };

  if(window.Brandmessenger){
    window.Brandmessenger.destroy();
    //delete window.Brandmessenger;
  }

  const isSubmitted = sessionStorage.getItem('form-submitted') === 'true';



  const handleErrors = (type, errorMsg) => {
    let formErrors = _.cloneDeep(errors);
    if (type) {
      if (errorMsg) {
        formErrors[type] = errorMsg;
      }
      else {
        delete formErrors[type];
      }
      setErrors(formErrors);
    }
  };

  const validateForm = async (inputValues = {}) => {
    let formValidationsResponse = {};
    const requiredLabel = intl.formatMessage({ id: 'validations.required' })
    const aditionalInfoLabel = intl.formatMessage({ id: 'validations.aditionalInfo' })

    let inputFields = Object.keys(validations);

    inputFields.forEach(field => {
      let value = inputValues[field];
      let validationResponse = '';

      validations[field].find(validation => {
        switch (validation) {
          case 'isEmail':
            validationResponse = zendeskHelper.validEmail(value);
            validationResponse = !validationResponse ? (value.length == 0 ? requiredLabel : null) : validationResponse;
            break;
          case 'isPhone':
            validationResponse = zendeskHelper.validPhone(value);
            break;
          case 'isRequired':
            validationResponse = value.length === 0 ? requiredLabel : null;
            break;
          default:
            break;
        };

        return !!validationResponse;

      });

      if (validationResponse) {
        formValidationsResponse[field] = validationResponse;
      }
    });

    return {
      errors: formValidationsResponse
    };
  }

  const onSubmit = async event => {
    event.preventDefault();
    setLoading(true);

    const formErrors = await validateForm({ firstName, lastName, phone, pin });

    if (Object.keys(formErrors.errors).length > 0) {
      setLoading(false);
      setErrors(formErrors.errors);
    }
    else {

      const payload = {
        svFirstName: firstName,
        svLastName: lastName,
        svPhoneNumber: phone,
        pin
      };

      const response = await apiManager.sprintPrePaidUpdate(payload, token, queryToken);

      if (response) {
        sessionStorage.setItem('form-submitted', 'true');
        history.push(SuccessPath)
      }
      else {
        history.push(ErrorPath)
      }

      setLoading(false);
    }
  }


  const getJwt = async () => {
    const API = IS_LOCAL_DEV ? API_LOCAL : API_LAMBDA;
    const token = sessionStorage.getItem('token');
    const params = history.location.search.slice(1).split('&').reduce((acc, param) => {
      const [key, value] = param.split('=');
      return Object.assign(acc, {[key]: value})
    }, {});
    const { payload, iv } = params;
    const queryToken = params.token ? params.token : false;

    if (!payload && !queryToken) {
      // error
      history.push(ErrorPath);
    }
    else if (payload && !queryToken) {

      const data = await apiManager.init(payload, `sprintprepaid-${PHASE}`, iv);

      if (!data) {
        history.push(ErrorPath)
      }
      else {
        sessionStorage.setItem("iv", iv);
        sessionStorage.setItem("token", data.jwt);
        sessionStorage.setItem("network", data.sso.network);
        sessionStorage.setItem("client", 'sprintprepaid');

        if (data.sso && data.sso.network) {
          window.location = `${API}/${data.sso.network}/sprintprepaid/login`
        }
      }
    }
    else {
      const validation = await apiManager.sprintPrePaidValidate(queryToken);

      if (validation && validation.token) {
        setToken(token);
        setQueryToken(queryToken);
        setInit(true);
      }
      else {
        sessionStorage.setItem('validation', JSON.stringify(validation));
        history.push(ErrorPath);
      }
    }
  };

  if (!init) {
    const pageTitle = intl.formatMessage({ id: 'defaultSocialSecureTransfer.pageTitle' });
    if (document.title !== pageTitle) {
      document.title = pageTitle;
    }
    getJwt();
  }

  let disabledSubmit = loading || (errors && Object.keys(errors).length > 0);

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <Grid
          container
        >
          {!init && (
            <Grid
              className={classes.progressWrapper}
              item
              lg={12}
              xs={12}
            >
              <Typography variant="h6" className={classes.description}>
                {<FormattedMessage id='socialVerificationForm.loading' />}
              </Typography>
              <CircularProgress disableShrink className={classes.progress} />
            </Grid>
          )}
        </Grid>
        { isSubmitted && <div>You have already verfied. <a href='https://securemessage.boostmobile.com/826f9d30-81dd-40ab-b404-4a6c8c7d23b3/success'>Click here</a> to continue</div>}
        {init && !isSubmitted &&<Grid
          container
          lg={8}
          xs={12}
          spacing={4}
          justify="left"
          className={classes.customForm}
        >
          <Grid
            item
            container
            spacing={4}
            justify="left"
          >
            <TextFieldInput
              label={<FormattedMessage id='socialVerificationForm.firstName' />}
              type='firstName'
              variant={'standard'}
              dataItem={firstName} dataAction={setFirstName}
              validations={validations.firstName} isRequired fieldError={errors.firstName} handleErrors={handleErrors}
            />

            <TextFieldInput
              label={<FormattedMessage id='socialVerificationForm.lastName' />}
              type='lastName'
              variant={'standard'}
              dataItem={lastName} dataAction={setLastName}
              validations={validations.lastName} isRequired fieldError={errors.lastName} handleErrors={handleErrors}
            />

            <TextFieldInput
              label={'Phone Number'}//{<FormattedMessage id='socialVerificationForm.phone' />}
              type='phone'
              variant={'standard'}
              dataItem={phone} dataAction={setPhone}
              validations={validations.phone} isRequired fieldError={errors.phone} handleErrors={handleErrors}
              isNumericOnly={true}
              inputProps={{
                maxLength: 10
              }}
            />

            <PasswordInput
              label={'Pin or security answer'}//{intl.formatMessage({ id: 'socialVerificationForm.accountPassword' })}
              type='pin'
              variant={'standard'}
              dataItem={pin} dataAction={setPin}
              validations={validations.pin} isRequired fieldError={errors.pin} handleErrors={handleErrors}
            />

          </Grid>
          <Grid
            item
            container
            justify="space-around"
            alignItems="center"
          >
            <Grid
              item
              lg={12}
              xs={12}>
              <label className={classes.requiredLabel}><span> * </span> <FormattedMessage id='socialVerificationForm.required' /></label>
            </Grid>
            <FormControl className={classes.formControl}>
              <Button variant="contained" className={classes.button}
                style={disabledSubmit ? {} : {
                  color: '#000',
                  backgroundColor: mainColor
                }}
                onClick={onSubmit} disabled={disabledSubmit}
              >
                <FormattedMessage id='socialVerificationForm.submit' />
              </Button>
              {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
            </FormControl>
          </Grid>
        </Grid>}
      </div>


    </div >
  );
};

export default Landing;
