import React, { useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import _ from 'lodash';
import { makeStyles } from '@material-ui/styles';
import { Grid, Typography, Select, MenuItem, FormControl, InputLabel, TextField, Button, Snackbar, CircularProgress } from '@material-ui/core';

import {customerStyles as useStyles} from './styles';
//import './fonts/style.css'

const Error = (props) => {
  const classes = useStyles();
  const intl = useIntl();
  const [network, setNetwork] = useState('');

  useEffect(() => {
    const pageTitle = intl.formatMessage({ id: 'defaultSecureVerification.pageTitle' });
    if (document.title !== pageTitle) {
      document.title = pageTitle;
    }
  }, [])

  useEffect(() => {
    const networkSession = sessionStorage.getItem('network');
    if (!networkSession || networkSession.length > 0) {
      setNetwork(networkSession);
    }
  }, [])

   
  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <Grid
          container
        >
          <Grid
            container
            item
            lg={12}
            xs={12}
            justify="space-between"
            alignItems="flex-start"
          >
            <Grid
              item
            >
              <Typography variant="h4" className={classes.title}>
                {intl.formatMessage({ id: 'error.title' })}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            item
            lg={12}
            xs={12}
          >
            <Typography variant="h6" className={classes.description}>
              {intl.formatMessage({ id: 'error.description' })}
            </Typography>
          </Grid>
          <Grid
            item
            lg={12}
            xs={12}
          >
            <Typography variant="h6" className={classes.description}>
              {intl.formatMessage({ id: 'error.nextStep' })}
            </Typography>
          </Grid>
          <Grid
            item
            lg={12}
            xs={12}
          >
            <Typography variant="h6" className={classes.description}>
              {intl.formatMessage({ id: 'error.tellUsMore' })}
            </Typography>
          </Grid>
        </Grid>
        
      </div>


    </div >
  );
};

export default Error;
