import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Typography, Link } from '@material-ui/core';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'inline-block',
    color: '#000',
    fontSize: '100%',
    fontWeight: '400',
    fontFamily: 'rutledge-regular',
    lineHeight: 1.5,
    textAlign: 'center',
    margin: 0
  },
  links: {
    
    fontSize: '.875rem',
    borderRight: '1px solid',
    padding: '0 .925rem 0 0',
    margin: '0 .925rem .75rem 0',
    fontWeight: 400,
    textAlign: 'left',
  },
  wrapper: {
    height: '800px'
  }
}));

const Footer = props => {
  const { className, ...rest } = props;

  const classes = useStyles();

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
      style={{width: '100%'}}
    >
        <div class="standalone-legal-link"
          style={{margin: '3rem auto'}}>
            <div>
                <div class="spectrum-footer-legal center">
                    <div class="legal-links-wrapper legal-links">
                        <div class="list-unstyled">
                            <a class="legal-text" href="https://www.spectrum.com/policies/your-privacy-rights"
                              style={{borderRight: '1px solid',padding: '0 .925rem 0 0',margin: '0 .925rem .75rem 0'}}>
                                Your Privacy Rights</a>
                            <a class="legal-text" href="https://www.spectrum.com/policies/california"
                              style={{borderRight: '1px solid',padding: '0 .925rem 0 0',margin: '0 .925rem .75rem 0'}}>
                              California Consumer Privacy Rights</a>
                            <a class="legal-text" href="https://www.spectrum.com/policies/your-privacy-rights-opt-out"
                              style={{borderRight: '1px solid',padding: '0 .925rem 0 0',margin: '0 .925rem .75rem 0'}}>
                              California Consumer Do Not Sell My Personal Information</a>
                            <a class="legal-text" href="https://www.spectrum.com/policies/terms-of-service"
                              style={{borderRight: '1px solid',padding: '0 .925rem 0 0',margin: '0 .925rem .75rem 0'}}>
                              Policies</a>                            
                            <a href="https://www.spectrum.net/contact-us">Contact Us</a>                            
                        </div>                        
                        <p class="copyright-bottom"
                          style={{marginBottom: '40px'}}>
                          {`© ${moment().year()} Charter Communications`}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
};

Footer.propTypes = {
  className: PropTypes.string
};

export default Footer;
