import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/styles';
import Card from '@material-ui/core/Card'; 
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions'; 
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button'; 
import { red } from '@material-ui/core/colors'; 
import PropTypes from 'prop-types';
import CommonText from "../CommonText";

const useStyles = makeStyles((theme) => ({
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: red[500],
  },
  formControl: {
    margin: '20px 0px 0px',
    minWidth: '100%',
    textAlign: 'left'
  },
  next: {
    position: 'relative',
    left: '85%'
  }
}));

export default function Address ({handleSuccess, handleError, updateParentState}) {
  const classes = useStyles();
  const [answer, setAnswer] = useState('')
 
  const validate = () => {
    return answer.length === 0;
  }

  const handleNext = async (event) => {
    if(validate()){
      handleError('You must fill out all items in the form!');
    } else {

      updateParentState('address', {answer});
      handleSuccess(event);
    }
  }

  const handleChange = (event) => {
    setAnswer(event.target.value);
}

  return (
    <Card className={classes.card}>  
      <CardContent>
      <CommonText /> 
        <TextField 
            margin="normal"
            required
            fullWidth
            id="answer"
            label="Address and post code of the account"
            name="answer" 
            autoFocus
            onChange={handleChange}
          /> 
      </CardContent>
      <CardActions disableSpacing>
       <Button
        className={classes.next} 
        onClick={handleNext}
        color="primary" 
        >Next</Button>
      </CardActions> 
    </Card>
  );
}