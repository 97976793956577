import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {  Drawer } from '@material-ui/core';
import DashboardIcon from '@material-ui/icons/Dashboard'; 
import AccountBoxIcon from '@material-ui/icons/AccountBox'; 
import LockOpenIcon from '@material-ui/icons/LockOpen'; 
import {   SidebarNav } from './components'; 
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import IconButton from '@material-ui/core/IconButton';



const useStyles = makeStyles(theme => ({
  drawer: {
    width: 240,
    [theme.breakpoints.up('lg')]: {
      //marginTop: 64,
      //height: 'calc(100% - 64px)'
    }
  },
  root: {
    backgroundColor: theme.palette.white,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: theme.spacing(2)
  },
  divider: {
    margin: theme.spacing(2, 0)
  },
  nav: {
    marginBottom: theme.spacing(2)
  }
}));

const Sidebar = props =>  {
  const { user, open, variant, onClose, className, ...rest } = props;

  const classes = useStyles();

  const pages = [  
    {
      title: 'My Account',
      href: '/account',
      icon: <DashboardIcon onClick={onClose} />
    }, 
    {
      title: 'Logout',
      href: '/login',
      icon: <LockOpenIcon   />
    }
  ];

   
   

  const [expanded, setExpanded] = React.useState(false);

  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  }; 
   

  return (
    <Drawer
      anchor="left"
      classes={{ paper: classes.drawer }}
      onClose={onClose}
      open={open}
      variant={variant}
    >
      <div
        {...rest}
        className={clsx(classes.root, className)}
      >
        
        <IconButton aria-label="close"
        onClick={onClose}
        >
<HighlightOffIcon />
</IconButton>
       
        <SidebarNav
          className={classes.nav}
          pages={pages}
          onClick={onClose}
        /> 
      </div>
    </Drawer>
  );
};

Sidebar.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired
};

export default Sidebar;
