import React, { useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { makeStyles } from '@material-ui/styles';
import { Grid, Typography } from '@material-ui/core';
import LockImage from './lockImage.png';
import moment from 'moment';

import { API_LOCAL, API_LAMBDA, IS_LOCAL_DEV, MANUALC01_GUID, PHASE } from '../../constants';

import './fonts/style.css';

const API = IS_LOCAL_DEV ? API_LOCAL : API_LAMBDA;

const ErrorPath = `/${MANUALC01_GUID}/error`;
const ExpiredPath = `/${MANUALC01_GUID}/expired`;
const SuccessPath = `/${MANUALC01_GUID}/success`;

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    flexGrow: 1,
    padding: '0 20',
    margin: '0 auto',
    marginTop: '4rem',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  requiredLabel: {
    color: 'rgba(0, 0, 0, 0.54)',
    margin: '0 0 0 8px',
  },
  title: {
    flexGrow: 1,
    fontSize: '2.33em',
    fontFamily: 'Fidelity Sans Regular, Helvetica, Sans-serif !important',
    lineHeight: 1.25,
    fontWeight: 'bold',
  },
  description: {
    lineHeight: 1.55,
    fontFamily: 'Fidelity Sans Regular, Helvetica, Sans-serif !important',
    margin: '0.5em 0',
  },
  button: {
    marginTop: 18,
    textAlign: 'center',
    fontWeight: 'bold',
    lineHeight: 1,
    borderRadius: '4px',
    letterSpacing: '0.04em',
    padding: '15px 20px',
    //fontFamily: "'Montserrat', sans-serif",
    '&:hover': {
      opacity: 0.7,
    },
  },
  customForm: {
    paddingTop: 20,
    //maxWidth: '500px',
    //border: '1px solid #e2e2e2',
    margin: '0 auto',
  },
  progressWrapper: {
    paddingBottom: '2rem',
  },
  progress: {
    margin: 'auto',
  },
}));

const Landing = (props) => {
  const classes = useStyles();

  const { apiManager, history, screenConfig } = props;
  const intl = useIntl();

  const [init, setInit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [customer, setCustomer] = useState('');
  const [payload, setPayload] = useState('');

  const [errors, setErrors] = useState({});
  const [token, setToken] = useState('');

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    let payloadValue = urlParams.get('payload');
    //let customerValue = urlParams.get('customer');
    let customerValue = 'manualc01';

    if (payloadValue) {
      setPayload(payloadValue);
      setCustomer(customerValue);

      if (!init) {
        const pageTitle = intl.formatMessage({ id: 'manualc01.landing.pageTitle' });
        if (document.title !== pageTitle) {
          document.title = pageTitle;
        }
        getJwt(payloadValue, customerValue);
      }
    }
    else {
      history.push(ErrorPath)
    }
  }, []);

  const getJwt = async (data, customer) => {
    const params = history.location.search
      .slice(1)
      .split('&')
      .reduce((acc, param) => {
        const [key, value] = param.split('=');
        return Object.assign(acc, { [key]: value });
      }, {});
    const { payload, iv } = params;
    const queryToken = params.token ? params.token : false;

    if (!payload && !queryToken) {
      // error
      history.push(ErrorPath);
    } else if (payload && !queryToken) {
      const data = await apiManager.init(payload, `${customer}-${PHASE}`, iv);

      if (data) {
        const currentMoment = moment();
        const current = moment().valueOf();
        const payloadTime = data.decryptedPayload.timestamp;
        console.log('times', payloadTime, current);
        const payloadMoment = moment(payloadTime);
        // Getting time difference between current time and payload time
        const duration = moment.duration(currentMoment.diff(payloadMoment));
        const linkTimeDiff = duration.asMinutes();
        console.log('time difference', linkTimeDiff);

        if (linkTimeDiff >= 20) {
          history.push(ExpiredPath);
        } else {
          sessionStorage.setItem('payload', payload);
          sessionStorage.setItem('token', data.jwt);
          sessionStorage.setItem('network', data.sso.network);
          sessionStorage.setItem('client', 'manualc01');

          window.location = SuccessPath;
        }
      } else {
        history.push(ErrorPath);
      }
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <Grid
          //className={classes.progressWrapper}
          item
          lg={12}
          xs={12}
        >
          {/* <div style={{ width: '100%', border: '4px solid #568200', margin: '5px 0 20px 0' }}></div> */}

          {/* <LockIcon /> */}
          <img src={LockImage} />
          <Typography variant="h4" className={classes.title}>
            {<FormattedMessage id="socialVerificationForm.title" />}
          </Typography>
        </Grid>
        <Grid className={classes.progressWrapper} item lg={12} xs={12}>
          <Typography variant="h6" className={classes.description}>
            You will be redirected to a Fidelity login page shortlly.
          </Typography>
          <Typography variant="h6" className={classes.description}>
            Once you authenticate you will be redirected to secure chat.
          </Typography>
          <Typography variant="h6" style={{ marginTop: '8rem' }} className={classes.description}>
            Having problems?
          </Typography>
        </Grid>
      </div>
    </div>
  );
};

export default Landing;
