import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/styles';
import { useMediaQuery } from '@material-ui/core';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import { Header, Footer } from './components';
import useStyles from './styles'
import AcUnitIcon from '@material-ui/icons/AcUnit';

import {
  Grid, Select, MenuItem, FormControl, InputLabel, TextField, Button, Snackbar, CircularProgress,
  AppBar, Typography, Toolbar, IconButton
} from '@material-ui/core';

const TalkTalk = props => {
  const { children, user, screenConfig } = props;

  const classes = useStyles();
  const theme = useTheme();

  useEffect(() => {
    if (screenConfig) {
      if (screenConfig.pageTitle && document.title !== screenConfig.pageTitle) {
        document.title = screenConfig.pageTitle;
      }
      
      if (screenConfig.favicon) {
        const link = document.querySelector("link[rel*='icon']") || document.createElement('link');
        link.type = 'image/x-icon';
        link.rel = 'shortcut icon';
        link.href = screenConfig.favicon;
        document.getElementsByTagName('head')[0].appendChild(link);
      }
    }
  }, [])

  return (
    <div
      className={clsx({
        [classes.root]: true
      })}
    >
      <main className={classes.mainContent}>
        <AppBar className={classes.appBar}
          style={{
            backgroundColor: '#f8f8f8'
          }}
          position="relative">
          <Grid
            container
            item
            alignContent="space-around"
            alignItems="center"
            alignContent="space-around"
            //lg={8}
            xs={12}
          >
            {/* <Toolbar className={classes.appBarContent} style={{justifyContent: 'center'}}>
              <a className={classes.logo} href={screenConfig.headerLogoUrl} target='_blank'>{screenConfig.headerLogo}</a>
            </Toolbar> */}
          </Grid>
        </AppBar>
        <div className={classes.wrapper}>
          <Grid
            container
            item
            ali
            alignContent="space-around"
            alignItems="center"
            alignContent="space-around"
            //lg={8}
            xs={12}
          >
            <div className={classes.content}>
              {children}
            </div>
          </Grid>
        </div>
      </main>
    </div>
    
  );
};

TalkTalk.propTypes = {
  children: PropTypes.node,
  user: PropTypes.object
};

export default TalkTalk;
