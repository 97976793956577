import React, { useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import _ from 'lodash';
import { makeStyles } from '@material-ui/styles';
import { Grid, Typography, Select, MenuItem, FormControl, InputLabel, TextField, Button, Snackbar, CircularProgress } from '@material-ui/core';
import $ from 'jquery'
import MuiAlert from '@material-ui/lab/Alert';
import { isMobile, withOrientationChange } from 'react-device-detect';

import { API_LOCAL, API_LAMBDA, IS_LOCAL_DEV, SPRINTPPD_GUID as SprintPpdGUID } from '../../../constants';

const LandingPath = `/${SprintPpdGUID}/landing`;

//const mainColor = '#FEF104'; //sprintPrePaid color
const mainColor = '#00004b'; //boostMobile color

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: '100%',
    flexGrow: 1,
    padding: 20
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  requiredLabel: {
    color: 'rgba(0, 0, 0, 0.54)',
    margin: '0 0 0 8px'
  },
  modernChatDesktop: {
    left: '20% !important',
    width: '60% !important',
    bottom: '10% !important',
    position: 'relative !important',
    maxWidth: '100% !important',
    minHeight: '600px !important',
    maxHeight:  '100% !important'
  },
  modernChatMobile: {
    maxHeight: '95% !important' ,
    position: 'relative !important',
    width: '86% !important',
    left: '7% !important',
    minHeight: '550px !important'
  },
  modernChatMobileLandscape: {
    width: '70% !important',
    minHeight: '500px !important',
    left: '15% !important',
    maxHeight: '100% !important' ,
    position: 'relative !important',
  },
  modernChatTablet: {
    left: '10% !important',
    position: 'relative !important',
    maxHeight: '100% !important' ,
    minHeight: '600px !important',
    width: '80% !important',
  },
  modernChatTabletLandscape: {
    minWidth: '70% !important',
    left: '15% !important',
    maxHeight: '100% !important' ,
    minHeight: '600px !important',
    position: 'relative !important',
  },
  modernChatContent: {
    width: '100% !important',
  },
  title: {
    flexGrow: 1,
    borderBottom: '4px solid ' + mainColor,
    fontSize: '2.33em',
    lineHeight: 1.25,
    fontWeight: 'bold'
  },
  description: {
    lineHeight: 1.55,
    margin: '0.5em 0'
  },
  button: {
    textAlign: 'center',
    fontWeight: 'bold',
    lineHeight: 1,
    borderRadius: '4px',
    letterSpacing: '0.04em',
    padding: '15px 20px',
    fontFamily: "'Montserrat', sans-serif",
    '&:hover': {
      opacity: 0.7
    }
  },
  customForm: {
    paddingTop: 20,
    //maxWidth: '500px',
    //border: '1px solid #e2e2e2',
    margin: '0 auto'
  },
  progressWrapper: {
    paddingBottom: '2rem'
  },
  progress: {
    margin: '20px auto',
    color: mainColor
  }
}));

let Success = (props) => {
  const classes = useStyles();
  const { isLandscape, isPortrait } = props

  const { apiManager, history, screenConfig } = props;
  const intl = useIntl();

  const [init, setInit] = useState(false);
  const [loading, setLoading] = useState(true);

  const [chatData, setChatData] = useState(null);


  useEffect(() => {
    const pageTitle = intl.formatMessage({ id: 'defaultSocialSecureTransfer.success.pageTitle' });
    if (document.title !== pageTitle) {
      document.title = pageTitle;
    }
  }, [])

  useEffect(() => {
    const getModernChatData = async () => {
      const responseData = await apiManager.sprintPrePaidChat();

      if (responseData) {
        setChatData(responseData);
      }
      else {
        setLoading(false);
      }
    }

    if (history.location.search.indexOf("?token=") === -1) {
      getModernChatData();
    }
  }, [])

  const injectScript = () => {
    window.addEventListener('khorosInit', async (event) => {

      const { givenName, properties } = chatData;
      if (event.detail) {
        event.detail.sdk.updateUser({
          givenName,
          properties
        });
      }

      setModernChatStyles();

      if(window.Brandmessenger){

        window.Brandmessenger.open();
      }

      setLoading(false);
    });

    const { companyKey, appId, widgetId, jwt, userId } = chatData;

    window.KHOROS_CONFIG = {
      companyKey,
      appId,
      widgetId,
      jwt,
      userId
    };

    var scriptTag = document.createElement('script');
    scriptTag.setAttribute('src', 'https://brand-messenger.app.khoros.com/bundle/loader.js?v=' + new Date().getTime());
    document.head.appendChild(scriptTag);
  }

  useEffect(() => {
    if(window.Brandmessenger){
      window.Brandmessenger.destroy();
    }
    if (chatData) {
      injectScript();
    }
  }, [chatData])


  useEffect(() => {
    setModernChatStyles();
    $("#khoros-firstMessageChatWrapper").remove()
  }, [isMobile, isPortrait, isLandscape])

  useEffect(() => {
    if (history.location.search.indexOf("?token=") > -1) {
      const queryToken = history.location.search.replace("?token=", "");
      history.push(`${LandingPath}?token=${queryToken}`);
    }

    setInit(true)
  }, [])


  const setModernChatStyles = () => {
    const desktopStyles = classes.modernChatDesktop;
    const mobileStyles = classes.modernChatMobile;
    const mobileLandscapeStyles = classes.modernChatMobileLandscape;
    let webMessengerSelector = $("#web-messenger-container");

    if (webMessengerSelector) {
      if (isMobile) {
        webMessengerSelector.addClass(isLandscape ? mobileLandscapeStyles : mobileStyles);
        webMessengerSelector.removeClass(isLandscape ? mobileStyles : mobileLandscapeStyles);
        webMessengerSelector.removeClass(desktopStyles);
      }
      else {
        webMessengerSelector.addClass(desktopStyles);
        webMessengerSelector.removeClass(mobileStyles);
        webMessengerSelector.removeClass(mobileLandscapeStyles);
      }
      webMessengerSelector.contents().find('#wrapper').css({ width: '100%', height: '80% !important' });
    }
    $("#web-messenger-container").contents().find("#khoros-firstMessageChatWrapper").css({ display: 'none' })

  }


  $("#web-messenger-container").contents().find("#khoros-firstMessageChatWrapper").css({ display: 'none' })


  return (
    <div className={classes.root}>
      <div id="overlay" style={{
        height: 0,
        background: '#fff',
        zIndex: 234234,
        position: 'absolute',
        width: '100%',
        left: '0px',
        top: '92px',
      }}>
        <br/>
        <div style={{margin: '0 auto', width: '50%'}}>
        </div>
        <br/>
      </div>
      <div className={classes.content}>
        <Grid
          container
        >
          {loading && <CircularProgress className={classes.progress}/>}
        </Grid>

      </div>


    </div>
  );
};

Success = withOrientationChange(Success);

export default Success;
