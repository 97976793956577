import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/styles';
import { Grid, Typography } from '@material-ui/core';


const useStyles = makeStyles(theme => ({
  title: {
    flexGrow: 1,
    color: 'rgb(37,37,165)',
    fontSize: '3.33em',
    lineHeight: 1.25,
    fontWeight: 'bold'
  },
  description: {
    lineHeight: 1.55,
    margin: '0.8em 0'
  },
  video: {
    width: '100%',
    display: 'block',
    maxWidth: '1400px',
    margin: 'auto 0'
  },
  menu: {
    position: 'absolute',
    left: '50px',
    top: '10px'
  }
}));

const Wrapper = props => {

  const { screenConfig, children } = props;
  const classes = useStyles();

  return (
    <Grid
      container
      spacing={4}
    >
      <Grid
        container
        item
        lg={screenConfig.hasVideo ? 7 : 12}
        xs={12}
      >
        <Grid
          item
          lg={12}
          xs={12}
        >
          <Typography variant="h4" className={classes.title}>
            {screenConfig.contentTitle}
          </Typography>
        </Grid>
        <Grid
          item
          lg={12}
          xs={12}
        >
          <Typography variant="h6" className={classes.description}>
            {screenConfig.contentDescription}
          </Typography>
        </Grid>
        {children}
      </Grid>
      {screenConfig.hasVideo && <Grid
        container
        item
        lg={5}
        xs={12}
      >
        <video
          src={screenConfig.videoUrl}
          className={classes.video} muted="" autoPlay="" loop playsInline=""></video>
      </Grid>}
    </Grid>
  );
}

Wrapper.propTypes = {
  children: PropTypes.node,
  screenConfig: PropTypes.object
};

export default Wrapper;

