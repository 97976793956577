import React, { useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import _ from 'lodash';
import { makeStyles } from '@material-ui/styles';
import { Grid, Typography, Select, MenuItem, FormControl, InputLabel, TextField, Button, Snackbar, CircularProgress } from '@material-ui/core';

import MuiAlert from '@material-ui/lab/Alert';

import { API_LOCAL, API_LAMBDA, IS_LOCAL_DEV, CHARTER_GUID as GUID, PHASE } from '../../../constants';

import zendeskHelper from '../../../helpers/zendeskHelper';
import TextFieldInput from '../../../components/Inputs/TextFieldInput';
import { CharterLogo } from '../../../components/logos/index';
import './fonts/style.css'

const SuccessPath = `/${GUID}/success`;
const ErrorPath = `/${GUID}/error`;

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles(theme => ({
  root: {
    height: 'auto',
    flexGrow: 1,
    padding: 0,
    color: '#000',
    fontSize: '1.75rem',
    fontWeight: '700',
    fontFamily: 'RUTLEDGE REGULAR',
    fontStyle: 'normal',
    fontStreth: 'normal',
    letterSpacing: '-.9px',
    lineHeight: 1.32,
    margin: 0,
    textAlign: 'center'

  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  requiredLabel: {
    color: 'rgba(0, 0, 0, 0.54)',
    margin: '0 0 0 8px'
  },
  description: {
    lineHeight: 1.55,
    margin: '0.8em 0'
  },
  button: {
    minWidth: '250px',
    marginTop: 18,
    textAlign: 'center',
    fontWeight: 'bold',
    lineHeight: 1,
    borderRadius: '4px',
    letterSpacing: '0.04em',
    padding: '15px 20px',
    color: '#fff',
    backgroundColor: '#00373d1',
    '&:hover': {
      opacity: 0.7
    }
  },
  customForm: {
    paddingTop: 20,
    margin: '0 auto'
  },
  progressWrapper: {
    paddingBottom: '2rem'
  },
  progress: {
    margin: 'auto'
  },
  title: {
    fontFamily: 'RUTLEDGE BOLD',
    fontSize: '1.75rem',
    margin: 0,
    maxWidth: '302px',
    width: '100%',
    height: '45px',
    fontWeight: 700,
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: '1.32',
    letterSpacing: '-.9px',
    textAlign: 'center',
    color: '#000',
  },
  description: {
    fontFamily: 'RUTLEDGE REGULAR',
    fontSize: '16px',
    fontWeight: 300,
    textAlign: 'center',
    lineHeight: '24px',
  },
  error: {
    color:  '#f04848',
    border: '1px solid #f04848',
    padding: '20px',
    margin: '20px 0px'
  },
  inputError: {
    color:  '#f04848',
    border: '1px solid #f04848',
    borderRadius: '4px'
  },
  nameError: {
    margin: 8,
    width: '100%',
    color: 'red',
    fontWeight: 'normal',
    textAlign: 'left'
  }

}));

export default function Landing({ history, apiManager, screenConfig }) {
  const classes = useStyles();
  const intl = useIntl();

  const [init, setInit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [nameError, setNameError] = useState(false);


  const [name, setName] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [account, setAccount] = useState('');
  const [phone, setPhone] = useState('');
  const [address, setAddress] = useState('');

  const [errors, setErrors] = useState({});
  const [token, setToken] = useState('');

  const validations = {
    name: ['isRequired'],
    account: ['atLeastOneFormOfContact'],
    phone: ['atLeastOneFormOfContact'],
    address: ['atLeastOneFormOfContact']

  };

  useEffect(() => {
    if (screenConfig) {
      if (screenConfig.pageTitle && document.title !== screenConfig.pageTitle) {
        document.title = screenConfig.pageTitle;
      }

      if (screenConfig.favicon) {
        const link = document.querySelector("link[rel*='icon']") || document.createElement('link');
        link.type = 'image/x-icon';
        link.rel = 'shortcut icon';
        link.href = screenConfig.favicon;
        document.getElementsByTagName('head')[0].appendChild(link);
      }
    }
  }, [])


  const handleChange = (callback, event) => {
    if (event) {
      callback(event.target.value);
    }
  };

  const handleErrors = (type, errorMsg) => {
    let formErrors = _.cloneDeep(errors);
    if (type) {
      if (errorMsg) {
        formErrors[type] = errorMsg;
      }
      else {
        delete formErrors[type];
      }
      setErrors(formErrors);
    }
  };

  const validateForm = async (inputValues = {}) => {
    let formValidationsResponse = {};
    const requiredLabel = intl.formatMessage({ id: 'validations.required' });
    const atLeastOneFormOfContactRequiredLabel = intl.formatMessage({ id: 'validations.atLeastOneFormOfContact' });

    let inputFields = Object.keys(validations);

    inputFields.forEach(field => {
      let value = inputValues[field];
      let validationResponse = '';

      validations[field].find(validation => {
        switch (validation) {
          case 'isRequired':
            validationResponse = value.length === 0 ? requiredLabel : null;
            break;
          case 'atLeastOneFormOfContact':
            if(field === 'phone') {
              validationResponse = value.length === 0 ? (!inputValues.account && !inputValues.address ? atLeastOneFormOfContactRequiredLabel : null) : zendeskHelper.validPhone(value);
            } else if(field === 'account') {
              validationResponse = value.length === 0 ? (!inputValues.phone && !inputValues.address ? atLeastOneFormOfContactRequiredLabel : null) : null;
            } else if(field === 'address') {
              validationResponse = value.length === 0 ? (!inputValues.phone && !inputValues.account ? atLeastOneFormOfContactRequiredLabel : null) : null;
            }
            break;
          default:
            break;
        };

        return !!validationResponse;
      });

      if (validationResponse) {
        formValidationsResponse[field] = validationResponse;
      }
    });

    return {
      errors: formValidationsResponse    };
  }

  const onSubmit = async event => {
    event.preventDefault();
    setLoading(true);

    const formErrors = await validateForm({ name, account, phone, address });

    if(name.length === 0){
      setNameError(true)
    }
    else {
      setNameError(false)
    }

    if(account.length === 0 && phone.length === 0 && address.length === 0){
      setErrorMsg(intl.formatMessage({ id: 'validations.atLeastOneFormOfContact' }));
    }

    if (Object.keys(formErrors.errors).length > 0) {
      setLoading(false);
      setErrors(formErrors.errors);
    }
    else {

      const payload = {
        svName: name,
        svAccount: account,
        svPhone: phone,
        svAddress: address
      };

      const response = await apiManager.update(payload, token, '', 'charter');

      if (response) {
        history.push(SuccessPath)
      }
      else {
        history.push(ErrorPath)
      }

      setLoading(false);
    }
  }

  const validateName = (e) => {
    const { value } = e.target;
    setName(value)
    if(value.length > 0){
      setNameError(false)
    }
  }

  const validateContact = (e) => {
    const { value, id, validity } = e.target;
    switch(id){
      case 'account':
        setAccount(value);
        break;
      case 'phone':
        if(validity.valid || value === "") {
          setPhone(value);
        }
        break;
      case 'address':
        setAddress(value)
        break;
    }
    if(value.length > 0){
      setErrorMsg('')
    }
  }


  let payload = '';
  if (window.location.search.indexOf("?payload=") > -1) {
    payload = window.location.search.replace("?payload=", "");
  }

  const getJwt = async (payload) => {
    const API = IS_LOCAL_DEV ? API_LOCAL : API_LAMBDA;
    const token = sessionStorage.getItem('token');
    const queryToken = history.location.search.indexOf("token") > -1 ? history.location.search.replace("?token=", "") : false;
    const queryPayload = history.location.search.indexOf("payload") > -1 ? history.location.search.replace("?payload=", "") : false;

    if (!queryPayload && !queryToken) {
      // error
      console.log('no query payload and no query token');
      history.push(ErrorPath)
    }
    else if (queryPayload && !queryToken) {
      const data = await apiManager.init(queryPayload, `charter-${PHASE}`);

        if (!data) {
            console.log('data is not defined');
        }
        else {
            sessionStorage.setItem("token", data.jwt);
            sessionStorage.setItem("queryPayload", queryPayload);
            sessionStorage.setItem("client", 'charter');
            setToken(token);
            setInit(true);
        }
    }
  };

  if (!init) {
    // const pageTitle = intl.formatMessage({ id: 'socialVerificationForm.pageTitle' });
    // if (document.title !== pageTitle) {
    //   document.title = pageTitle;
    // }
    getJwt(payload);
  }


  let disabledSubmit = false;
  //loading || (errors && Object.keys(errors).length > 0) || (atLeastTwoErrors && Object.keys(atLeastTwoErrors).length > 0);

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <Grid
          container
        >
          <Grid
            container
            item
            lg={12}
            xs={12}
            style={{
              justifyContent: 'center'
              }}
          >
            <Grid
              item
            >
              <Typography variant="h4" className={classes.title}>
                {screenConfig.pageTitle}
              </Typography>
            </Grid>
            <Grid
              item
            >

            </Grid>
          </Grid>
          { !init && (
            <Grid
              className={classes.progressWrapper}
              item
              lg={12}
              xs={12}
            >
              <Typography variant="h6" className={classes.description}>
                {<FormattedMessage id='socialVerificationForm.loading' />}
              </Typography>
              <CircularProgress disableShrink className={classes.progress} />
            </Grid>
          )}
          {init && <Grid
            item
            lg={12}
            xs={12}
          >
            <Typography variant="h6" className={classes.description}>
              {screenConfig.contentDescription}
            </Typography>
{ errorMsg.length > 0 &&
<div className={classes.error}>
            <Typography variant="h6" className={classes.description}>
              { errorMsg }
            </Typography>
            </div>
}
          </Grid>}
        </Grid>
        {init && <Grid
          container
          justify="left"
          className={classes.customForm}
        >
          <Grid
            item
            container
            justify="left"
          >
            <label class='charter-input-label' style={{ fontWeight: '400' }}>{screenConfig.name}</label>
            <TextField
              type='name'
              fullWidth
              variant='outlined'
              id='name'
              onChange={validateName}
              className={ nameError ? classes.inputError : '' }
            />
            { nameError &&
              <div className={classes.nameError}>* Name is required</div>

            }
            <div style={{ width: '100%', height: '10px'}}>&nbsp;</div>
            <label class='charter-input-label' style={{ fontWeight: '400' }}>{screenConfig.account}</label>
            <TextField
              fullWidth
              variant='outlined'
              type='account'
              id='account'
              onChange={validateContact}
              className={ errorMsg.length > 0 ? classes.inputError : '' }
            />
            { errors.phone &&
            <div className={classes.nameError}>{errors.phone}</div>
            }
    <div style={{ width: '100%', height: '10px'}}>&nbsp;</div>
            <label class='charter-input-label' style={{ fontWeight: '400' }}>{screenConfig.phone}</label>
            <TextField
              type='tel'
              id='phone'
              value={phone}
              inputProps={{ pattern: "[0-9]*", maxLength: 10 }}
              fullWidth
              variant='outlined'
              onChange={validateContact}
              className={ errorMsg.length > 0 ? classes.inputError : '' }
            />
    <div style={{ width: '100%', height: '10px'}}>&nbsp;</div>
            <label class='charter-input-label' style={{ fontWeight: '400' }}>{screenConfig.address}</label>
            <TextField
              variant='outlined'
              fullWidth
              type='address'
              id='address' onChange={validateContact}
              inputProps={{ maxLength: 255 }}
              rows={5}
              multiline
              className={ errorMsg.length > 0 ? classes.inputError : '' }
            />

          </Grid>
          <Grid
            item
            container
            justify="left"
          >
            <Grid
              item
              lg={12}
              xs={12}>

            </Grid>
            <Grid
              item
              lg={6}
              xs={12}
              style={{ margin: '0 auto', maxWidth: '100%'}}
            >
              <FormControl className={classes.formControl}>
                <Button variant="contained" className={classes.button}
                  style={{
                    color: '#fff',
                    backgroundColor: '#0073d1',
                    textTransform: 'capitalize'
                  }}
                  onClick={onSubmit} disabled={disabledSubmit}
                  fullWidth
                >
                  <FormattedMessage id='socialVerificationForm.submit' />
                </Button>
                {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
              </FormControl>
            </Grid>
          </Grid>
        </Grid>}
      </div>
    </div >
  );
};
