import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { makeStyles } from '@material-ui/styles';
import { Grid, Button, CircularProgress } from '@material-ui/core';

import MuiAlert from '@material-ui/lab/Alert';

import { API_LOCAL, API_LAMBDA, IS_LOCAL_DEV, KHOROS_GUID, PHASE } from '../../../constants';
import Wrapper from '../../../components/Wrapper'

const BASE_URL = IS_LOCAL_DEV ? API_LOCAL : API_LAMBDA;

const SuccessPath = `/${KHOROS_GUID}/success`;
const ErrorPath = `/${KHOROS_GUID}/error`;

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}


const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    flexGrow: 1
  },
  button: {
    marginTop: 18,
    textAlign: 'center',
    borderRadius: '10em',
    letterSpacing: '0.04em',
    padding: '0.6em 0.6em',
    width: '280px',
    fontFamily: "'Montserrat', sans-serif",
    '&:hover': {
      opacity: 0.7
    }
  },
  progressWrapper: {
    paddingBottom: '2rem'
  },
  progress: {
    margin: 'auto'
  }
}));

const socialAuths = [{
  id: 'instagram',
  text: 'Login With Instagram',
  url: `${BASE_URL}/instagram/khoros/login`,
  backgroundColor: '#E1306C'
},]
/*
const socialAuths = [
  {
    id: 'twitter',
    text: 'Login With Twitter',
    url: `${BASE_URL}/twitter/login`,
    backgroundColor: '#1da1f2'
  },
  {
    id: 'facebook',
    text: 'Login With Facebook',
    url: `${BASE_URL}/facebook/login`,
    backgroundColor: '#4267B2'
  },
  {
    id: 'instagram',
    text: 'Login With Instagram',
    url: `${BASE_URL}/instagram/khoros/login`,
    backgroundColor: '#E1306C'
  },
  {
    id: 'linkedin',
    text: 'Login With Linkedin',
    url: `${BASE_URL}/linkedin/login`,
    backgroundColor: '#2867B2'
  },
  {
    id: 'pinterest',
    text: 'Login With Pinterest',
    url: `${BASE_URL}/pinterest/login`,
    backgroundColor: '#BD081C'
  },
  {
    id: 'oauth2',
    text: 'Login With Oauth2',
    url: `${BASE_URL}/oauth2/login`,
    backgroundColor: '#2E1CD4'
  },
  {
    id: 'openidconnect',
    text: 'Login With Openidconnect',
    url: `${BASE_URL}/openidconnect/login`,
    backgroundColor: '#2E1CD4'
  },
  {
    id: 'saml',
    text: 'Login With Saml',
    url: `${BASE_URL}/saml/login`,
    backgroundColor: '#2E1CD4'
  }
]
*/
const Landing = (props) => {
  const classes = useStyles();

  const { apiManager, history, screenConfig } = props;

  const [init, setInit] = useState(false);
  const [user, setUser] = useState(false);

  const [loading, setLoading] = useState(true);

  const renderSocialButtons = (data) => {
    let buttons = []
    data && data.forEach(item => {
      buttons.push(
        <Grid
          item
          lg={6}
          xs={12}
          key={Math.random()}
        >
          <Button key={item.id} className={classes.button} variant="contained" target="_blank"
            style={!loading ? {
              backgroundColor: item.backgroundColor,
              color: 'white'
            } : {}}
            href={item.url}
            disabled={loading} >
            {item.text}
          </Button>
        </Grid>
      )
    })
    return (
      <Grid
        container
        item
        lg={12}
        xs={12}
      >
        {buttons}
      </Grid>);
  }

  const getJwt = async (payload) => {

    const token = await apiManager.init(payload,  `khoros-${PHASE}`);
    
    if (!token) { 
      history.push(ErrorPath)
    }
    else { 
      sessionStorage.setItem('network', token.sso.network);
      sessionStorage.setItem('client', token.decryptedPayload.brandAuthorName);
      sessionStorage.setItem('jwt', token.jwt);
      sessionStorage.setItem('user', JSON.stringify(token.decryptedPayload));
      setUser(token.decryptedPayload)
    }
    setLoading(false);
  };

  //harcoded payload
  // http://localhost:3002/bf2d7dce-4db1-47f2-abd0-bb6e31968622/landing
  //let payload = '8Ub-G2k56qkWCzC4d_FVQqMDaj9h7X2zu1CMKTxDnhuCCfBzDPbPygFwwLLb5nfqnXLDPVhjzR-gwmSerH44B3bUN4wodZKwvFqA7go2HFLkAvWdHv3brrVjDEbPZlWEQmYoXJ_ueXhVMXJf1aJo5ggUg2UQulh4cl0O5TECDWBFoKSu04opGTnJwN33Vwf174VzS4Cm8UJC4zUgAz2hd8XBQ6Q19GZlgZZgaInwo468kQkhbMFuzusTrG44bezycj2f85W-63xz1Be4o6vJxeUeXvh5QDlwlSbb4coGJ9JrbvFWLLFBZGJ6N-yrFEfROJ-yPaKa_tsklMLzk6nmC1jBh57S-ETtiKtz9BZD4Jt-gqUlevY788a5SxLBgAs-00Rt3ir4PRpWmEULzSgf7zc4ggR6F21tQ4LkN_JHkxYvgNZEtCSqbf4mr5LONOegfDMSuLadrtAwH53DQHlcK7mTpjcrQ8EJ7_-iQhR-OWfOHGvvVsqs3Op6V8OOABG7_Txouxt71YEgXC2qvBstb8fmvrYrq--4Uq6_SQHOPlV1BXk9j5rcXr5DyKHVVGEzvqD9zV2Ymg6xxtJhRSAeTqf0CrPl';
  let payload = 'O_tHadVZTmkmc6fjZ_xA0CArnnYYnk9ZJciHULk4bexRtAn3rvM25I3-VF6KK_-Rk3uQLXasTtde7jRTiFpSHqbHd5zWKbKMcEF_9lWiHmvTgWLIBiH7tmbIXfcm4uiwwFA_wqtdJ-HPNvDPVnNi5Ut3iYm6T3eqNWpcICOzwvHRBrsqZLta5q7WOdsAyQzNxOvVfr8TIZhX_CuRUFvHPX5GElQN0Fhqe9fAui6zCoZwiaXGQp4J-QA-oWEIEl2F15hWpzur8EcTaboiMqRAVnNEdFTay-gO1-IidWWzky-jdEJB5Kd4OYtkCwA9VhwnU9Q6KL7vxym07QISYtGc0xtCPagY01wX8mt17CA9mZ6_wpmwPA9am3c30CZ9tvLSRwZ1HIryRrrRb6UqF6Kt7QK30GQhL94-6sF8MLFTo99BbYQ2GTrn4P2C9qY45Y_9mo1OL82kJsYkqLLIVLt2VVcjNGp56ZKbTKGQypV78mwCGlgE6f6v1fOzJw4277x4KyOKNKmLAtPpCNq3kdkkGDHX0_z1BVM8lrdcsA%3D%3D';
  if (window.location.search.indexOf("?payload=") > -1) {
    payload = window.location.search.replace("?payload=", "");
  }
  //payload from queryParam
  //
  console.log('payload', payload);

  if (!init) {
    getJwt(payload);
    setInit(true);
  }

  return (
    <div className={classes.root}>
      {loading && (
        <Grid
          className={classes.progressWrapper}
          container
          item
          lg={12}
          xs={12}
        >
          <CircularProgress className={classes.progress} />
        </Grid>
      )}
      { user && 
        <h3>Welcome { user.authorName }</h3>
      }
      <Wrapper screenConfig={screenConfig}>
        {renderSocialButtons(socialAuths)}
      </Wrapper>
    </div >
  );
};

export default Landing;
