import "./style.css";

import React, {useEffect, useState} from 'react';
import { FormattedMessage, useIntl } from "react-intl";
import _ from "lodash";
import { makeStyles } from "@material-ui/styles";
import {
  Grid,
  FormControl,
  Button,
  Snackbar,
  CircularProgress,
} from "@material-ui/core";

import MuiAlert from "@material-ui/lab/Alert";

import { ROCKSNROPES_GUID, PHASE } from "../../../constants";

const LandingPath = `/${ROCKSNROPES_GUID}/landing`;

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  bodyCare: {
    backgroundColor: '#f7f7f7'
  },
  root: {
    width: "100%",
    flexGrow: 1,
    overflow: "hidden",
    backgroundColor: '#f7f7f7'
  },
  content: {
    textAlign: "left",
    background: "none",
  },
  links: {
    wordBreak: "break-word",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  button: {
    background: "#e0e1e2 none",
    color: "rgba(0,0,0,.6)",
    padding: ".75em 0",
    textAlign: "center",
    fontFamily: "Open Sans",
    "&:hover": {
      color: "rgba(0,0,0,.8)",
      background: "#cacbcd",
      boxShadow: "none",
    },
    fontSize: "12px",
    lineHeight: "1em",
    textTransform: "none",
    boxShadow: "none",
    border: "none",
    fontWeight: "500",
    borderRadius: ".3rem",
    letterSpacing: "0",
  },
  requiredLabel: {
    color: "rgba(0, 0, 0, 0.54)",
    margin: "0 0 0 8px",
  },
}));

const SvButton = (props) => {
  const classes = useStyles();

  const { apiManager } = props;
  const intl = useIntl();

  const [loading, setLoading] = useState(false);
  const [verificationLink, setVerificationLink] = useState("");

  const [open, setOpen] = useState(false);
  const [severity, setSeverity] = useState("");
  const [msg, setMsg] = useState("");

  useEffect(() => {
    document.body.className = classes.bodyCare;
  }, []);

  const populateTextArea = async (text) => {
    console.log(
      `Can you follow this link to authenticate your customer account with us please? ${text}`
    );
    window.top.postMessage(
      JSON.stringify({
        appendResponse: `Can you follow this link to authenticate your customer account with us please? ${text}`,
      }),
      "*"
    );
  };

  let payload = "";
  if (window.location.search.indexOf("?payload=") > -1) {
    payload = window.location.search.replace("?payload=", "");
  }
  const populateCRMTransfer = async (event) => {
    event.preventDefault();
    setLoading(true);
    setVerificationLink("");

    const initData = await apiManager.init(payload, `rocksnropes-${PHASE}`);
    if (initData) {
      console.log(initData);
      const { jwt } = initData;
      sessionStorage.setItem("token", jwt);
    }

    let longLink = `${LandingPath}?payload=${payload}`;
    let data = { url: longLink };
    const verificationResponse = await apiManager.sendSecureSocialVerification(
      data,
      `rocksnropes-${PHASE}`
    );

    if (verificationResponse && verificationResponse.shortLink) {
      const shortenedLink = verificationResponse.shortLink;
      console.log("Successfully shortened url - " + shortenedLink);
      const urlText = shortenedLink; // intl.formatMessage({ id: 'socialVerificationButton.verifyText'}, { link: shortenedLink });
      populateTextArea(urlText);
      setVerificationLink(shortenedLink);
    } else {
      const urlText = intl.formatMessage(
        { id: "rocksnropes.button.verifyText" },
        { link: longLink }
      );
      populateTextArea(urlText);
      setMsg(intl.formatMessage({ id: "rocksnropes.button.error" }));
      setOpen(true);
      setSeverity("error");
      setVerificationLink(longLink);
    }

    setLoading(false);
  };

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <Grid container justify="left" spacing={2}>
          <Grid item lg={12} xs={12}>
            <FormControl className={classes.formControl}>
              <Button
                className={classes.button}
                variant="contained"
                color="primary"
                onClick={populateCRMTransfer}
                disabled={loading}
              >
                <FormattedMessage id="rocksnropes.button.label" />
              </Button>
              {loading && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </FormControl>
          </Grid>
          <Grid item lg={6} xs={12}>
            <Snackbar
              open={open}
              autoHideDuration={6000}
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
              onClose={(e) => setOpen(!open)}
            >
              <Alert onClose={(e) => setOpen(!open)} severity={severity}>
                {msg}
              </Alert>
            </Snackbar>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default SvButton;
