import React, {useState, useEffect} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

import {makeStyles} from '@material-ui/styles';
import {
  Grid,
  Typography,
  CircularProgress,
} from '@material-ui/core';

import MuiAlert from '@material-ui/lab/Alert';

import {TELUS_GUID as GUID} from '../../../constants';

const TENANT_ID = 'sv-telus';
const ERROR_PATH = `/${GUID}/error`;
const MAIN_COLOR = '#4b286d'

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: '100%',
    flexGrow: 1,
    padding: 20,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  requiredLabel: {
    color: 'rgba(0, 0, 0, 0.54)',
    margin: '0 0 0 8px',
  },
  title: {
    flexGrow: 1,
    borderBottom: `4px solid ${MAIN_COLOR} `,
    fontSize: '2.33em',
    lineHeight: 1.25,
    fontWeight: 'bold',
  },
  description: {
    lineHeight: 1.55,
    margin: '0.5em 0',
  },
  button: {
    textAlign: 'center',
    fontWeight: 'bold',
    lineHeight: 1,
    borderRadius: '4px',
    letterSpacing: '0.04em',
    padding: '15px 20px',
    fontFamily: '\'Helvetica Neue\', sans-serif',
    '&:hover': {
      opacity: 0.7,
    },
  },
  customForm: {
    paddingTop: 20,
    //maxWidth: '500px',
    //border: '1px solid #e2e2e2',
    margin: '0 auto',
  },
  progressWrapper: {
    paddingBottom: '2rem',
  },
  progress: {
    margin: 'auto',
    color: `${MAIN_COLOR}`,
  },
}));

const Success = (props) => {
  const classes = useStyles();

  const {apiManager, history, screenConfig} = props;
  const intl = useIntl();

  const [init, setInit] = useState(false);
  const [loading, setLoading] = useState(true);

  const [network, setNetwork] = useState('');

  useEffect(() => {
    const pageTitle = intl.formatMessage({id: 'defaultSecureVerification.pageTitle'});
    if (document.title !== pageTitle) {
      document.title = pageTitle;
    }
  }, [])

  useEffect(() => {
    if (history.location.search.indexOf('?token=') > -1) {
      const queryToken = history.location.search.replace('?token=', '');
      if (!queryToken) {
        history.push(ERROR_PATH);
      } else {
        onSuccess(queryToken);
      }
    }
    const networkSession = sessionStorage.getItem('network');
    if (!networkSession || networkSession.length > 0) {
      setNetwork(networkSession);
    }
    setInit(true)
  }, [])

  const onSuccess = async (code) => {
    setLoading(true);
    const response = await apiManager.authorProfileUpdate('', code, TENANT_ID, true);
    console.log(response)

    if (!response) {
      setLoading(false);
      history.push(ERROR_PATH);
    }
    setLoading(false);
  }


  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <Grid
          container
        >
          {loading && <CircularProgress className={classes.progress}/>}

          <Grid
            container
            item
            lg={12}
            xs={12}
            justify="space-between"
            alignItems="flex-start"
          >
            <Grid item>
              {!loading &&
              <Typography variant="h4" className={classes.title}>
                <FormattedMessage id="success.title"/>
              </Typography>}
            </Grid>
          </Grid>
          <Grid
            item
            lg={12}
            xs={12}
          >
            {!loading &&
            <Typography variant="h6" className={classes.description}>
              <FormattedMessage id="success.description"/>
            </Typography>}
          </Grid>
          <Grid
            item
            lg={12}
            xs={12}
          >
            {!loading &&
            <Typography variant="h6" className={classes.description}>
              <FormattedMessage id="success.nextStep"/>
            </Typography>}
          </Grid>
          <Grid
            item
            lg={12}
            xs={12}
          >
            {!loading &&
            <Typography variant="h6" className={classes.description}>
              <FormattedMessage id="success.tellUsMore"/>
            </Typography>}
          </Grid>
        </Grid>

      </div>


    </div>
  );
};

export default Success;
