import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Grid, Typography, Select, MenuItem, FormControl, InputLabel, TextField, Button, Snackbar, CircularProgress } from '@material-ui/core';
import { isMobile } from 'react-device-detect';

import "./style.css";

const useStyles = makeStyles(() => ({
  root: {
    height: '100%',
    background: '#fff',
    margin: 'auto'
  },
  content: {
    margin: 'auto',
    background: '#fff',
    padding: 20
  }
}));

const Fidelity = props => {
  const { children, screenConfig } = props;

  const classes = useStyles();

  useEffect(() => {
    if (screenConfig) {
      if (screenConfig.pageTitle && document.title !== screenConfig.pageTitle) {
        document.title = screenConfig.pageTitle;
      }

      if (screenConfig.favicon) {
        const link = document.querySelector("link[rel*='icon']") || document.createElement('link');
        link.type = 'image/x-icon';
        link.rel = 'shortcut icon';
        link.href = screenConfig.favicon;
        document.getElementsByTagName('head')[0].appendChild(link);
      }
    }
  }, [])

  return (
    <div className={classes.root}>

      <main className={classes.content}>
        <Grid
          container
          direction='column'
          justify="center"
          alignItems="center"
        >
          <Grid
            container
            item
            // lg={12}
            // xs={12}
            direction='column'
            justify="center"
            alignItems="center"
          >
            <div >
              <a className={classes.logo} href={screenConfig.headerLogoUrl} target='_blank'>{screenConfig.headerLogo}</a>
            </div>

            {children}
          </Grid>
        </Grid>
      </main>
    </div>
  );
};

Fidelity.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
};

export default Fidelity;
