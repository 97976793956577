import React from 'react';
import PropTypes from 'prop-types';
import blueShieldCaLogo from './blueShieldCaLogo.png'

const BlueShieldCaLogo = () => (
    <img src={blueShieldCaLogo} alt={'BlueShield California Logo'} width={125} height={47} />
);

BlueShieldCaLogo.propTypes = {
    className: PropTypes.string
};


export default BlueShieldCaLogo;