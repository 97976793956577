import React from 'react';
import PropTypes from 'prop-types';
import charterLogo from './charter-logo.svg'; 

const CharterLogo = () => (
  <img src={charterLogo} alt={'Charter Logo'} width={100} height={50}/>
);

CharterLogo.propTypes = {
  className: PropTypes.string
};

export default CharterLogo;
