import React, { useState, useEffect } from 'react';
import axios from 'axios';
import _ from 'lodash';
import { makeStyles } from '@material-ui/styles';
import { Grid, Select, MenuItem, FormControl, InputLabel, TextField, Button, Snackbar, CircularProgress } from '@material-ui/core';

import conversationHelper from '../../../helpers/conversationHelper';
import zendeskHelper from '../../../helpers/zendeskHelper';
import { API_LOCAL, API_LAMBDA, IS_LOCAL_DEV, HASBRO_GUID as GUID, PHASE } from '../../../constants';

import SelectInput from '../../../components/Inputs/SelectInput';
import TextFieldInput from '../../../components/Inputs/TextFieldInput';
import MuiAlert from '@material-ui/lab/Alert';

const CRM = 'oracle';
const COMPANY_KEY = 'hasbrodev';
const ErrorPath = `/${GUID}/error`;

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4),
    width: '100%',
    flexGrow: 1
  },
  content: {
    padding: 50,
    textAlign: 'left'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  requiredLabel: {
    color: 'rgba(0, 0, 0, 0.54)',
    margin: '0 0 0 8px'
  }
}));

const CustomForm = (props) => {
  const classes = useStyles();

  const { apiManager, history } = props;

  const [conversationId, setConversationId] = useState(null);
  const [authorName, setAuthorName] = useState(null);
  const [loading, setLoading] = useState(false);
  const [payloadLoading, setPayloadLoading] = useState(true);

  const [countryLoading, setCountryLoading] = useState(true);
  const [categoryLoading, setCategoryLoading] = useState(true);
  const [channelLoading, setChannelLoading] = useState(true);
  const [statusLoading, setStatusLoading] = useState(true);

  //the following inputs are hard-coded and not displayed on the form
  const [country, setCountry] = useState(1); // US
  const [selectedCategory, setSelectedCategory] = useState(60582); // Social Media
  const [selectedChannel, setSelectedChannel] = useState(412); // Social Media (still visible on the form)
  const [selectedStatus, setSelectedStatus] = useState(1); // New

  const [countryText, setCountryText] = useState('');
  const [selectedCategoryText, setSelectedCategoryText] = useState('');
  const [selectedChannelText, setSelectedChannelText] = useState('');
  const [selectedStatusText, setSelectedStatusText] = useState('');

  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [subject, setSubject] = useState('');

  const [errors, setErrors] = useState({});

  const [open, setOpen] = useState(false);
  const [severity, setSeverity] = useState('');
  const [msg, setMsg] = useState('');

  const validations = {
    country: [],
    category: ['isRequired'],
    channel: [],
    status: ['isRequired'],
    email: ['isEmail'],
    phone: ['isPhone'],
    subject: ['isRequired']
  };

  useEffect(() => {
    const initForm = async () => {
      const data = await getPayload();

      if (!data) {
        history.push(ErrorPath)
      }
      else {
        sessionStorage.setItem("token", data.jwt);
        sessionStorage.setItem("network", data.sso.network);
        sessionStorage.setItem("client", COMPANY_KEY);
        await getPhoneNumber(data.decryptedPayload);
        setPayloadLoading(false);
      }
    }

    initForm();

  }, [])


  const getPhoneNumber = async (data) => {
    const conversation = await apiManager.getConversation(data.conversationId);

    const lswUuid = conversationHelper.getlswUuid(conversation);
    const notes = conversationHelper.getNotes(conversation);

    const crmData = await apiManager.getCrmData(lswUuid);

    let phones = [];
    let emails = [];
    const crms = crmData && crmData.handles && crmData.handles.crm ? crmData.handles.crm : [];

    crms.forEach(value => {
      if (value.phone) {
        phones.push(value.phone[0]);
      }
      if (value.email) {
        emails.push(value.email[0]);
      }
    })

    if (phones.length > 0) {
      setPhone(phones[phones.length - 1]);
    }
    if (emails.length > 0) {
      setEmail(emails[emails.length - 1]);
    }
  }


  const handleChange = (callback, event) => {
    if (event) {
      callback(event.target.value);
    }
  };

  const handleErrors = (type, errorMsg) => {
    let formErrors = _.cloneDeep(errors);
    if (type) {
      if (errorMsg) {
        formErrors[type] = errorMsg;
      }
      else {
        delete formErrors[type];
      }
      setErrors(formErrors);
    }
  };

  const validateForm = async (inputValues = {}) => {
    let validationResponse = '';
    let formValidationsResponse = {};

    let inputFields = Object.keys(validations);

    inputFields.forEach(field => {
      let value = inputValues[field];
      validations[field].find(validation => {
        switch (validation) {
          case 'isEmail':
            validationResponse = zendeskHelper.validEmail(value);
            validationResponse = !validationResponse ? (value.length == 0 ? 'Required' : null) : validationResponse;
            break;
          case 'isPhone':
            validationResponse = zendeskHelper.validPhone(value);
            break;
          case 'isRequired':
            validationResponse = value.length == 0 ? 'Required' : null;
            break;
        };

        return !!validationResponse;

      });

      if (validationResponse) {
        formValidationsResponse[field] = validationResponse;
      }
    });

    return formValidationsResponse;
  }

  const getPayload = async () => {

    let payload = window.location.search.replace("?payload=", "");

    // if (!payload) {
    //   payload = "dft1OnJzRXQgLfU07L4R2o9zngSK7WXeMDmjzPmaY3zUtKgaCiPQ1WNbWsTuGNktX3ecAe9q7fm8IlGacYiif7V97EPjmNU2zfaySZjeLtLb-oUZhUZnP2oEgXVThcn9BWyu2EafO1zR0aBpwdMHoMUe8nTihEjsC2N4v5NUeGfDDhdLQH_dZFaBNM1WuHjoviF4_wbIfQs4QBp_piD2Cw3YY4h7KcofzDJ7lgwz81q23-J1cbhlajQHUbFWHqvIGdiBMEBbCc0g2Gl89msEAHZahJegC8_Hd9L207CgdmB4OXbuo7tqGJ1Cu_NDyQQEHT4WlrC2Pyz_Y64TKYehrs16xF5L2TxUAxadoyh8baIBWpatYu62-Ja5OlAQmolmNObAMCvxB5r2J27Ed0o9Tb2rVTapQ7U0nVjf2MLzZEcotxOW9rThl4c8PDTVsCJMC5YyXdHqzIzOO9FrC0sgo7YUEfD_IglWYQVhYB_wa7qrWeNCJtplL-NbZ-PwBos%3D";
    // }

    const careData = await apiManager.init(payload, `hasbro-${PHASE}`);

    if (careData && careData.decryptedPayload) {
      setConversationId(careData.decryptedPayload.conversationId || '');
      setAuthorName(careData.decryptedPayload.authorName || '');
      return careData;
    }

    return null;
  };

  const onSubmit = async event => {
    event.preventDefault();
    setLoading(true);

    const formErrors = await validateForm({ country, category: selectedCategory, channel: selectedChannel, status: selectedStatus, email, phone, subject });

    if (formErrors && Object.keys(formErrors).length > 0) {
      setLoading(false);
      setErrors(formErrors);
    }
    else {
      const conversation = await apiManager.getConversation(conversationId);

      if (!conversation || !conversation.displayNumber) {
        alert('failed to locate conversation.')
      }
      else {
        const lswUuid = conversationHelper.getlswUuid(conversation);
        const notes = conversationHelper.getNotes(conversation);

        const crmData = await apiManager.getCrmData(lswUuid);

        let isFailure = false;
        const oracleResponse = {
          contact: false,
          incident: {}
        };

        const data = { country: country, email, phone, authorName };

        const contactBody = await zendeskHelper.getContactBody(data);
        const getContact = false; //await apiManager.crmSearchContact(CRM, data); //search if contact with entered email already exists

        // force new contact on every case. this elimates the need to update existing contacts per client. 
        if (getContact && getContact.id) {
          //use existing contact
          const contactId = getContact.id;

          const result = await apiManager.crmGetContact(CRM, contactId);
          oracleResponse.contact = result.result;
          let contactCountry = '',
            contactPhone = '';

          if (oracleResponse.contact.address.country && oracleResponse.contact.address.country.links && oracleResponse.contact.address.country.links.length > 0) {
            contactCountry = oracleResponse.contact.address.country.links[0].href.split('/');
            contactCountry = +contactCountry[contactCountry.length - 1];
          }

          const phoneResult = await apiManager.crmGetPhoneContact(CRM, contactId, 1);
          if (phoneResult && phoneResult.result) {
            contactPhone = phoneResult.result.number;
          }

          //update contact if some field is different (country, phone, name)
          if (oracleResponse.contact
            && ((oracleResponse.contact.name && contactBody.name
              && (oracleResponse.contact.name.first != contactBody.name.first || oracleResponse.contact.name.last != contactBody.name.last))
              || (country !== contactCountry)
              || (phone !== contactPhone))
          ) {
            const updateResult = await apiManager.crmUpdateContact(CRM, contactId, contactBody);
            if (updateResult) {
              const updatedContact = await apiManager.crmGetContact(CRM, contactId);
              oracleResponse.contact = updatedContact.result;
            }
          }
        }
        else {
          //create new contact
          const result = await apiManager.crmCreateContact(CRM, contactBody);
          if (result && result.result) {
            oracleResponse.contact = result.result;
          }
        }

        isFailure = !oracleResponse.contact;

        if (isFailure) {
          setMsg('Contact creation failed');
          setOpen(true);
          setSeverity('error')
        }
        else {
          const agentInfo = conversationHelper.getAgentInfo(conversation); //
          const agentLookup = await apiManager.crmGetAgent(CRM, agentInfo);

          const data = { subject, selectedCategory, contact: oracleResponse.contact, selectedChannel, selectedStatus, agentLookup, notes };
          const incident = zendeskHelper.getIncidentBody(data)
          oracleResponse.incident = await apiManager.crmCreateIncident(CRM, incident);
          //console.log(oracleResponse);
          isFailure = !oracleResponse.incident;


          if (isFailure) {
            setMsg('Incident creation failed');
            setOpen(true);
            setSeverity('error');
          }
          else {
            const profileData = {
              id: oracleResponse.incident.result.lookupName,
              contactId: oracleResponse.contact.id,
              incidentNumbers: oracleResponse.incident.result.lookupName,
              country: `${countryText} (id: ${country})`,
              channel: `${selectedChannelText} (id: ${selectedChannel})`,
              category: `${selectedCategoryText} (id: ${selectedCategory})`,
              email,
              phone,
              subject
            };
            const profileUpdates = zendeskHelper.getCareAuthorProfile(profileData)

            const careResponse = await apiManager.updateAuthor(profileUpdates, lswUuid);

            if (careResponse) {
              setMsg('Incident Created');
              setOpen(true);
              setSeverity('success');
            }
            else {
              setMsg('Updating author failed');
              setOpen(true);
              setSeverity('error');
            }
          }
        }
      }

      setLoading(false);

    }
  }

  let initLoading = payloadLoading || countryLoading || categoryLoading || channelLoading || statusLoading;

  let gridProps = {
    lg: 3,
    sm: 6,
    xs: 12
  };

  let inputStyles = {
    width: '85%'
  };

  return (
    <div className={classes.root}>
      {initLoading && <CircularProgress />}
      <div className={classes.content}>

        <Grid
          container
          justify="left"
          spacing={4}
        >

          {/* This select list is not displayed on the form */}
          <SelectInput
            label='Country' type='country'
            selectedValue={country} setSelectedValue={setCountry}
            selectedText={countryText} setSelectedText={setCountryText}
            isLoading={setCountryLoading}
            crm={CRM}
            gridProps={gridProps}
            inputStyles={inputStyles}
          />
          {/* This select list is not displayed on the form */}
          <SelectInput
            label="Category" type="category"
            selectedValue={selectedCategory} setSelectedValue={setSelectedCategory}
            selectedText={selectedCategoryText} setSelectedText={setSelectedCategoryText}
            validations={validations.category} isRequired fieldError={errors.category} handleErrors={handleErrors}
            isLoading={setCategoryLoading}
            crm={CRM}
            gridProps={gridProps}
            inputStyles={inputStyles}
          />
          <SelectInput
            label="Channel" type="channel"
            selectedValue={selectedChannel} setSelectedValue={setSelectedChannel}
            selectedText={selectedChannelText} setSelectedText={setSelectedChannelText}
            isLoading={setChannelLoading}
            crm={CRM}
            gridProps={gridProps}
            inputStyles={inputStyles}
          />
          {/* This select list is not displayed on the form */}
          <SelectInput
            label="Status" type="status"
            selectedValue={selectedStatus} setSelectedValue={setSelectedStatus}
            selectedText={selectedStatusText} setSelectedText={setSelectedStatusText}
            validations={validations.status} isRequired fieldError={errors.status} handleErrors={handleErrors}
            isLoading={setStatusLoading}
            crm={CRM}
            gridProps={gridProps}
            inputStyles={inputStyles}
          />

          <TextFieldInput
            label='User Email' type='email'
            dataItem={email} dataAction={setEmail}
            validations={validations.email} isRequired fieldError={errors.email} handleErrors={handleErrors}
            gridProps={gridProps}
            inputStyles={inputStyles}
            disabled={payloadLoading}
          />
          <TextFieldInput
            label='User Phone' type='phone'
            dataItem={phone} dataAction={setPhone}
            validations={validations.phone} fieldError={errors.phone} handleErrors={handleErrors}
            isNumericOnly={true} inputProps={{ maxLength: 10 }}
            gridProps={gridProps}
            inputStyles={inputStyles}
            disabled={payloadLoading}
          />
          <TextFieldInput
            label='Subject' type='subject'
            dataItem={subject} dataAction={setSubject}
            validations={validations.subject} isRequired fieldError={errors.subject} handleErrors={handleErrors}
            multiline inputProps={{ maxLength: 50 }}
            gridProps={gridProps}
            inputStyles={inputStyles}
            disabled={payloadLoading}
          />

        </Grid>
        <Grid
          container
          justify="left"
          spacing={4}
        >
          <Grid
            item
            lg={12}
            xs={12}>
            <label className={classes.requiredLabel}><span> * </span> Required fields</label>
          </Grid>
          <Grid
            item
            lg={6}
            xs={12}
          >
            <FormControl className={classes.formControl}>
              <Button variant="contained" color="primary" onClick={onSubmit} disabled={payloadLoading || loading || (errors && Object.keys(errors).length > 0)} >
                Create Incident
              </Button>
              {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
            </FormControl>
          </Grid>
          <Grid
            item
            lg={6}
            xs={12}
          >
            <Snackbar open={open} autoHideDuration={6000} onClose={e => setOpen(!open)}>
              <Alert onClose={e => setOpen(!open)} severity={severity}>
                {msg}
              </Alert>
            </Snackbar>
          </Grid>
        </Grid>
      </div>
    </div >
  );
};

export default CustomForm;