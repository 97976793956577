import React, { useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import _ from 'lodash';
import { makeStyles } from '@material-ui/styles';
import { Grid, Typography, Select, MenuItem, FormControl, InputLabel, TextField, Button, Snackbar, CircularProgress } from '@material-ui/core';

import MuiAlert from '@material-ui/lab/Alert';

import { API_LOCAL, API_LAMBDA, API_LOCAL_V2, API_LAMBDA_V2, IS_LOCAL_DEV, KOODO_GUID as GUID, PHASE } from '../../../constants';

import zendeskHelper from '../../../helpers/zendeskHelper';
import TextFieldInput from '../../../components/Inputs/TextFieldInput';
import { KhorosLogo as Logo } from '../../../components/logos/index';
import LockImage from '../../Fidelity/lockImage.png';


const TENANT_ID = 'sv-koodo';
const ERROR_PATH = `${GUID}/error`;
const MAIN_COLOR = '#FCEAC4';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: '100%',
    flexGrow: 1,
    padding: 20,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  requiredLabel: {
    color: 'rgba(0, 0, 0, 0.54)',
    margin: '0 0 0 8px',
  },
  title: {
    flexGrow: 1,
    borderBottom: `4px solid ${MAIN_COLOR} `,
    fontSize: '2.33em',
    lineHeight: 1.25,
    fontWeight: 'bold',
  },
  description: {
    lineHeight: 1.55,
    margin: '0.8em 0',
  },
  button: {
    marginTop: 18,
    textAlign: 'center',
    fontWeight: 'bold',
    lineHeight: 1,
    borderRadius: '4px',
    letterSpacing: '0.04em',
    padding: '15px 20px',
    fontFamily: "Stag Sans Web',Arial,Helvetica,Tahoma,Verdana,Sans-Serif",
    '&:hover': {
      opacity: 0.7,
    },
  },
  customForm: {
    paddingTop: 20,
    //maxWidth: '500px',
    //border: '1px solid #e2e2e2',
    margin: '0 auto',
  },
  progressWrapper: {
    paddingBottom: '2rem',
  },
  progress: {
    margin: 'auto',
    color: `${MAIN_COLOR}`,
  },
}));

const Landing = (props) => {
  const classes = useStyles();

  const { apiManager, history, screenConfig } = props;
  const intl = useIntl();

  const [init, setInit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showMessage, setShowMessage] = useState(false);

  const [errors, setErrors] = useState({});
  const [token, setToken] = useState('');
  const [queryToken, setQueryToken] = useState('');

  const getJwt = async (data) => {

    const API = IS_LOCAL_DEV ? API_LOCAL : API_LAMBDA;
    const API_V2 = IS_LOCAL_DEV ? API_LOCAL_V2 : API_LAMBDA_V2;

    const token = sessionStorage.getItem('token');
    const params = history.location.search.slice(1).split('&').reduce((acc, param) => {
      const [key, value] = param.split('=');
      return Object.assign(acc, {[key]: value})
    }, {});
    const {payload, iv, brand} = params;
    console.log(brand)
    const queryToken = params.token ? params.token : false;
    console.log('queryToken',queryToken);
    if (!payload && !queryToken) {
      history.push(ERROR_PATH);
    } else if (payload && !queryToken) {

      // const data = await apiManager.init(payload, `${COMPANY_KEY}-${PHASE}`);
      const data = await apiManager.authorInit(payload, TENANT_ID)

      if (!data) {
        history.push(ERROR_PATH);
      }
      sessionStorage.setItem('token', data.jwt);
      sessionStorage.setItem('network', data.sso.network);
      sessionStorage.setItem('tenant', TENANT_ID);
      sessionStorage.setItem('brand', brand);

      if (data.sso && data.sso.network && data.sso.network !== 'external') {
        await apiManager.authLogin(data.sso.network, TENANT_ID);
        // window.location = `${API_V2}/auth/login/${TENANT_ID}/${data.sso.network}`;
      } else {
        alert('not a social network')
      }
    } else {
      console.log('queryToken',queryToken);
      // const validation = await apiManager.validate(queryToken, TENANT_ID);
      const validation = await apiManager.authorSocialValidation(queryToken, TENANT_ID);

      if (!validation) {
        history.push(ERROR_PATH);
      }

      setToken(token);
      setQueryToken(queryToken);
      setInit(true);
      // here we redirect to the lambda endpoint which redirects to their idp login portal
      await apiManager.authLogin('idp', TENANT_ID)
    }
  };


  let payload = '';
  if (window.location.search.indexOf('?payload=') > -1) {
    payload = window.location.search.replace('?payload=', '');
  }



  if (!init) {
    const pageTitle = intl.formatMessage({ id: 'koodoSecureVerification.pageTitle' });
    if (document.title !== pageTitle) {
      document.title = pageTitle;
    }
    getJwt(payload);
  }


  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <Grid
          container
        >
          {loading && <CircularProgress className={classes.progress}/>}</Grid>
        <Grid
          item
          lg={12}
          xs={12}
        >
          <Typography variant="h4" className={classes.title}>
            {<FormattedMessage id="koodoSecureVerification.title"/>}
          </Typography>

        </Grid>
        <Grid
          className={classes.progressWrapper}
          item
          lg={12}
          xs={12}
        >
          <Typography variant="h6" className={classes.description}>
            {<FormattedMessage id="koodoSecureVerification.landing.firstText"/>}
          </Typography>
          <Typography variant="h6" className={classes.description}>
            {<FormattedMessage id="koodoSecureVerification.landing.secondText"/>}
          </Typography>
          <br/>
        </Grid>
      </div>
    </div>
  );
};
export default Landing;
