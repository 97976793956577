import React from 'react';
import PropTypes from 'prop-types';
import quickbooksLogo from './quickbooksLogo.png';

const QuickbooksLogo = () => (
  <img src={quickbooksLogo} alt={'Quickbooks Logo'} height={100} style={{ position: 'relative', marginTop: "10px" }} />
);

QuickbooksLogo.propTypes = {
  className: PropTypes.string,
};

export default QuickbooksLogo;
