import React from "react";
//import "./style.css"; 
import Header from "./components/Header";
import Footer from "./components/Footer";
import Validate  from "../Validate"
import Success  from "../Success"
import AHForgotPassword  from "./components/AccountHolder/ForgotPassword"
import AHVerifyPassword  from "./components/AccountHolder/VerifyPassword"
import NAHVerifyPassword  from "./components/NonAccountHolder/VerifyPassword"
import PoAVerifyPassword  from "./components/PowerOfAttorney/VerifyPassword"
import PoAForgotPassword  from "./components/PowerOfAttorney/ForgotPassword"
import PoAAddress  from "./components/PowerOfAttorney/Address"
import Snackbar from "@material-ui/core/Snackbar";
import MySnackbarContentWrapper from "../../../../components/SnackBar";
import InitialForm from './components/InitialForm'
import apiManager from '../../../../ApiManager';
import { API_LOCAL, API_LAMBDA, IS_LOCAL_DEV, PHASE, TALKTALK_GUID as GUID } from '../../../../constants';
import Card from '@material-ui/core/Card';
import moment from 'moment';
import { makeStyles } from '@material-ui/styles';

const defaultState = { 
  snackbarOpen: false,
  message: "Success!",
  isLoginVisible: false,
  isRegisterVisible: false,
  isSuccessVisible: false,
  step: 0,
  type: 0,
  formData: {}
};


const ErrorPath = `/${GUID}/error`;

class Landing extends React.PureComponent {
  //eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    this.state = {
      ...defaultState
    };
    this.handleLandingNext = this.handleLandingNext.bind(this);  
    this.handleSuccess = this.handleSuccess.bind(this);  
    this.handleError = this.handleError.bind(this);  
    this.handleCloseSnackbar = this.handleCloseSnackbar.bind(this);  
    this.onAHForgotPassword = this.onAHForgotPassword.bind(this);

    this.onPoAForgotPassword = this.onPoAForgotPassword.bind(this);
    this.onPoAAddress = this.onPoAAddress.bind(this);
    this.updateParentState = this.updateParentState.bind(this);
    this.getJwt = this.getJwt.bind(this);  
  }

  async componentDidMount() {
    let payload = ''; 
    if (window.location.search.indexOf("?payload=") > -1) {
      payload = window.location.search.replace("?payload=", "");
      console.log(payload);
    }

    const { init } = this.state;
    if (!init) { 
      console.log('not initialized');
      this.getJwt(payload);
    }
    this.getJwt(payload);
  }

  RENDER_STEPS = {
    Landing: 0,
    AHVerifyPassword: 1,
    AHForgotPassword: 2,
    Success: 3,
    NAHVerifyPassword: 4,
    PoAVerifyPassword: 5,
    PoAForgotPassword: 6,
    PoAAddress: 7
  }

  handleCloseSnackbar() {
    this.setState({ snackbarOpen: false });
  }

  handleOpenSnackbar() {
    this.setState({ snackbarOpen: true });
  }
 
  toggleLogin() {
    let { isLoginVisible } = this.state;
    this.setState({isLoginVisible: !isLoginVisible });
  }

  handleLandingNext(event, type) { 
    console.log(event);
    switch(type){
      case "0": 
        this.setState({ step: this.RENDER_STEPS.AHVerifyPassword })
        break;
      case "1":
        this.setState({ step: this.RENDER_STEPS.NAHVerifyPassword })
        break;
      case "2":
        this.setState({ step: this.RENDER_STEPS.PoAVerifyPassword })
        break;
    }     
  }

  async getJwt(payload) {
    const API = IS_LOCAL_DEV ? API_LOCAL : API_LAMBDA;
    const queryToken = window.location.search.indexOf("token") > -1 ? window.location.search.replace("?token=", "") : false;
    const queryPayload = window.location.search.indexOf("payload") > -1 ? window.location.search.replace("?payload=", "") : false;
    
    if (!queryPayload && !queryToken) {
      // error
      console.log('no query payload and no query token');
      // this.props.history.push(ErrorPath);
    }
    else if (queryPayload && !queryToken) {
      const data = await apiManager.init(payload, `talktalk-${PHASE}`);
      console.log(data);
      
      if (!data) {
        console.log('data is not defined');
        this.props.history.push(ErrorPath)
      }
      else {
        const currentMoment = moment();
        const current = moment().valueOf();
        const payloadTime = data.decryptedPayload.timestamp;
        console.log('times', payloadTime, current)
        const payloadMoment = moment(payloadTime);
        // Getting time difference between current time and payload time
        const duration = moment.duration(currentMoment.diff(payloadMoment));
        const linkTimeDiff = duration.asMinutes();
        console.log('time difference', linkTimeDiff)
        
        // 8hs
        if (linkTimeDiff >= 480) {

          this.props.history.push(ErrorPath)
        }

        sessionStorage.setItem("token", data.jwt);
        sessionStorage.setItem("queryPayload", queryPayload);

      }
    }
  }

  async handleSuccess(event) { 
    this.setState({ step: this.RENDER_STEPS.Success })
  }

  async handleAddress(event) { 
    this.setState({ step: this.RENDER_STEPS.PoAAddress }) 
  }

  async handleError(msg) { 
    this.setState({ snackbarOpen: true, message: msg }); 
  }

  onAHForgotPassword(event) {
    this.setState({ step: this.RENDER_STEPS.AHForgotPassword })
  }

  onPoAForgotPassword(event) {
    this.setState({ step: this.RENDER_STEPS.PoAForgotPassword })
  }

  onPoAAddress(event) {
    this.setState({ step: this.RENDER_STEPS.PoAAddress })
  }

  updateParentState(key, value){
    let { formData } = this.state;
    formData[key] = value;
    this.setState({ formData });
  }

  handleNext() {
    alert('next')
  }

  renderStep() {
    let { step, type } = this.state; 

    switch(step){
      case this.RENDER_STEPS.Landing:
        return <InitialForm handleLandingNext={this.handleLandingNext} handleError={this.handleError} updateParentState={this.updateParentState}/>  
      //return  <Landing handleNext={this.handleLandingNext} handleError={this.handleError} updateParentState={this.updateParentState} />;
      case this.RENDER_STEPS.AHVerifyPassword:
        return  <AHVerifyPassword handleSuccess={this.handleSuccess} handleError={this.handleError} onAHForgotPassword={this.onAHForgotPassword} updateParentState={this.updateParentState} />;
      case this.RENDER_STEPS.AHForgotPassword:
        return  <AHForgotPassword handleSuccess={this.handleSuccess} handleError={this.handleError} updateParentState={this.updateParentState} />;
      case this.RENDER_STEPS.Success:
        return  <Success formData={this.state} apiManager={apiManager} />;
      case this.RENDER_STEPS.NAHVerifyPassword:
        return  <NAHVerifyPassword handleSuccess={this.handleSuccess} handleError={this.handleError} updateParentState={this.updateParentState} />;
      case this.RENDER_STEPS.PoAVerifyPassword:
        return  <PoAVerifyPassword handleSuccess={this.onPoAAddress} handleError={this.handleError} onPoAForgotPassword={this.onPoAForgotPassword} updateParentState={this.updateParentState} />;
      case this.RENDER_STEPS.PoAForgotPassword:
        return  <PoAForgotPassword handleSuccess={this.handleSuccess} handleError={this.handleError} onPoAAddress={this.onPoAAddress} updateParentState={this.updateParentState}/>;
      case this.RENDER_STEPS.PoAAddress:
        return  <PoAAddress handleSuccess={this.handleSuccess} handleError={this.handleError} updateParentState={this.updateParentState} />;
      default: 
        return <div></div>
    }
  }

  render() {
    const { snackbarOpen, message } = this.state;
    
    return (
      <span>
         <Card style={{  color: 'white',
    marginRight: 'auto',
    backgroundColor: '#2C4047',
    marginRight: 'auto !important',
    marginLeft: 'auto',
    textAlign: 'center',
    alignItems: 'center',
    margin: '0 auto',
    padding:' 0 20px'}}>
      <header>
        <h1><a href="https://www.talktalk.co.uk/" target="_blank">Talk Talk For Everyone</a></h1>
    </header>
    </Card>
        <br/>
        { this.renderStep() }
       
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "center"
          }}
          open={snackbarOpen}
          autoHideDuration={3000}
          onClose={this.handleCloseSnackbar}
          className="getlocal-snackbar"
        >
          <MySnackbarContentWrapper
            className="error"
            onClose={this.handleCloseSnackbar}
            variant="error"
            message={message}
          />
        </Snackbar>
        <br/>
        <Footer />
     
      </span>
    );
  }
}

export default Landing;