import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { makeStyles } from '@material-ui/styles';
import Wrapper from '../../../components/Wrapper';
import {VANITY_URLS} from '../../../constants';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    flexGrow: 1
  }
}));

const PoweredBy = (props) => {
  const classes = useStyles();

  const {screenConfig } = props;

  const isRedirect = VANITY_URLS.find(value => window.location.href.indexOf(value.domain) > -1);
  if (isRedirect) {
    window.location = `https://${isRedirect.domain}/${isRedirect.redirect_to}`;
  }

  return (
    <div className={classes.root}>
            <Wrapper screenConfig={screenConfig}></Wrapper>
    </div >
  );
};

export default PoweredBy;
