import React from 'react';
import PropTypes from 'prop-types';
import synchronyLogo from './synchronyLogo.png'

const SynchronyLogo = () => (
    <img src={synchronyLogo} alt={'Synchrony Logo'} width={145} height={50} />
);

SynchronyLogo.propTypes = {
    className: PropTypes.string
};


export default SynchronyLogo;