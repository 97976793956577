import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';

import './custom.css';

import { makeStyles } from '@material-ui/styles';
import { Grid, CircularProgress } from '@material-ui/core';
import { DOMINION_GUID as GUID } from '../../../constants';

const ErrorPath = `/${GUID}/error`;

const COMPANY_KEY = 'sv-dominion';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    flexGrow: 1,
    padding: '0 20',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  requiredLabel: {
    color: 'rgba(0, 0, 0, 0.54)',
    margin: '0 0 0 8px',
  },
  customForm: {
    paddingTop: 20,
    margin: '0 auto',
  },
  progressWrapper: {
    paddingBottom: '2rem',
  },
  progress: {
    margin: 'auto',
  },
  bodyDominion: {
    backgroundColor: '#E2E2E2',
    paddingRight: '0px',
    paddingLeft: '0px',
  },
}));

const Success = (props) => {
  const classes = useStyles();

  const { apiManager, history } = props;
  const intl = useIntl();

  const [init, setInit] = useState(false);
  const [loading, setLoading] = useState(true);

  const [chatData, setChatData] = useState(null);
  const [postMetadata, setPostMetadata] = useState({});

  useEffect(() => {
    const pageTitle = intl.formatMessage({ id: 'dominion.success.pageTitle' });
    if (document.title !== pageTitle) {
      document.title = pageTitle;
    }
  }, [intl]);

  const getModernChatData = async () => {
    const responseData = await apiManager.modernChatInfoJson(COMPANY_KEY);

    if (responseData) {
      setChatData(responseData);
    } else {
      history.push(ErrorPath);
    }
  };

  useEffect(() => {
    setLoading(true);
    getModernChatData();

    setInit(true);
  }, []);

  const injectScript = () => {
    window.addEventListener('khorosInit', async (event) => {
      console.log('khorosinit event');

      console.log(window.Brandmessenger);

      setLoading(false);
    });


    const { companyKey, widgetId, jwt, userId, givenName, properties } = chatData;

    window.KHOROS_CONFIG = {
      companyKey: companyKey,
      widgetId: widgetId,
      jwt,
      userId,
      userMetadata: {
        name: givenName,
        metadata: properties,
      }
    };
    
    console.log('injectScript function');

    var scriptTag = document.createElement('script');
    scriptTag.setAttribute(
      'src',
      'https://brand-messenger.app.khoros.com/bundle/loader.js?v=' + new Date().getTime(),
    );
    document.head.appendChild(scriptTag);
  };

  useEffect(() => {
    if (chatData) {
      injectScript();
    }
  }, [chatData]);

  return (
    <div className={classes.root}>
      <div
        id="overlay"
        style={{
          height: 0,
          background: '#fff',
          zIndex: 234234,
          position: 'absolute',
          width: '100%',
          left: '0px',
          top: '92px',
        }}
      >
        <br />
        <div style={{ margin: '0 auto', width: '50%' }}></div>
        <br />
      </div>
      <div id ="bmv2Container"></div>
      <div className={classes.content}>
        <Grid container>{loading && <CircularProgress className={classes.progress} />}</Grid>
      </div>
    </div>
  );
};

export default Success;
