import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/styles";
import { Button, Grid, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "auto",
    flexGrow: 1,
    padding: 20,
    color: "#000",
    fontSize: "1.75rem",
    fontWeight: "700",
    fontStyle: "normal",
    fontStreth: "normal",
    letterSpacing: "-.9px",
    lineHeight: 1.32,
    width: "100%",
    margin: 0,
  },
  wrapper: {
    height: "800px",
  },
  title: {
    fontFamily: "RUTLEDGE BOLD",
    fontSize: "1.75rem",
    margin: 0,
    maxWidth: "302px",
    width: "100%",
    height: "45px",
    fontWeight: 700,
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "1.32",
    letterSpacing: "-.9px",
    textAlign: "center",
    color: "#000",
  },
  description: {
    fontSize: "16px",
    fontWeight: 300,
    textAlign: "center",
    lineHeight: "24px",
  },
  button: {
    marginTop: 18,
    textAlign: 'center',
    letterSpacing: '0.04em',
    padding: '0.6em 0.6em',
    width: '180px',
    fontFamily: "'Montserrat', sans-serif",
    '&:hover': {
      opacity: 0.7,
    },
    fontSize: '12px',
    backgroundColor: '#5A5F63',
  }
}));

const GenericSuccess = (props) => {
  const classes = useStyles();
  const { screenConfig } = props;
  const returnToChannel = JSON.parse(sessionStorage.getItem('returnTo'));
  const network = sessionStorage.getItem('network');
  const instance = sessionStorage.getItem('instance');

  let channel = network;
  if(network == 'external') {
    channel = instance.replace('www.', '').replace('.com', '');
  }

  //redirect back to channel after successfully filling out sv form
  const handleRedirect = async (link, event) => {
    event.preventDefault(); //remove to automatically redirect back to iMessage
    window.location.href = link
  }

  return (
    <div className={classes.root}>
      <style>{"body { background: rgb(248, 248, 248); }"}</style>
      <div className={classes.content}>
        <Grid container>
          <Grid
            container
            item
            lg={12}
            xs={12}
            style={{
              justifyContent: "center",
            }}
          >
            <Grid item>
              <Typography variant="h4" className={classes.title}>
                {screenConfig.success.pageTitle}
              </Typography>
            </Grid>
          </Grid>

          <Grid item lg={12} xs={12}>
            <Typography variant="h6" className={classes.description}>
              {screenConfig.success.success}
            </Typography>
          </Grid>
          <Grid container style={{
              justifyContent: "center",
            }}>
          {returnToChannel[channel].map(field => {
            let component = null;
            switch (field.type) {
              case 'button':
              default:
                component = (
                  <Button
                    className={classes.button}
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={(event) => handleRedirect(field.href, event)}
                  >
                    {field.label}
                  </Button>
                )
                break;
            }
            return component;

          })}
          </Grid>

        </Grid>
      </div>
    </div>
  );
}

export default GenericSuccess;
