import React from 'react';
import PropTypes from 'prop-types';
import logo from './logo.svg';

const TelusLogo = ({  }) => (
  <img src={logo} alt={'Logo'} width={130} height={28}/>
);

TelusLogo.propTypes = {
  className: PropTypes.string
};

export default TelusLogo;
