
const conversationHelper = {
    getlswUuid: (conversation) => {
        let lswUuid = '';

        if (conversation && conversation.messages && conversation.messages.length > 0 && conversation.messages[0].author) {
            const authorData = conversation.messages[0].author;
            lswUuid = authorData.lswUuid;
        }

        return lswUuid;
    },
    getNotes: (conversation) => {
        let notes = '';

        if (conversation && conversation.notes) {
            conversation.notes.forEach(element => {
                if (element.note) {
                    notes += element.note + '\r\n';
                }
            });
        }

        return notes;
    },
    getAgentInfo: (conversation) => {

        if (conversation && conversation.assignedToAgent) {
            return conversation.assignedToAgent;
        }
        return { name: '', id: 0, email: '' };
    }
}


module.exports = conversationHelper;
