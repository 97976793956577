import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, Select, MenuItem, FormControl, InputLabel, TextField, Button } from '@material-ui/core';
import apiManager from '../../ApiManager';


const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(4)
    },
    content: {
        padding: 50,
        textAlign: 'left'
    },
    formControl: {
        margin: theme.spacing(1),
        width: '95%',
        maxWidth: '550px'
    }
}));



const SelectInput = (props) => {
    const classes = useStyles();

    const { label, type, selectedValue, setSelectedValue, selectedText, setSelectedText, isLoading, crm, validations, fieldError, handleErrors, isRequired, gridProps, inputStyles, handleChange } = props;

    const [collection, setCollection] = useState(false);
    const [error, setError] = useState('');
    const hasValidations = validations && validations.length > 0;

    const onChange = (event) => {
        if (hasValidations) {
            handleValidations(event.target.value);
        }
        setSelectedValue(event.target.value);
        if (setSelectedText) {
            setSelectedText(event.currentTarget && event.currentTarget.innerText ? event.currentTarget.innerText : '');
        }

        if (handleChange) {
            handleChange(event);
        }        
    }

    const handleValidations = (value) => {
        let validationResponse = '';

        validations.find(validation => {

            switch (validation) {
                case 'isRequired':
                    validationResponse = value.length == 0 ? 'Required' : null;
                    break;
            };

            return !!validationResponse;
        });

        setError(validationResponse);

        if (handleErrors) {
            handleErrors(type, validationResponse);
        }
    }

    const getCollection = async () => {

        if (isLoading) {
            isLoading(true);
        }

        let collection = [];
        switch (type) {
            case 'country':
                collection = []; //await apiManager.getCountries(crm);
                break;
            case 'category':
                collection = []; //await apiManager.getCategories(crm);
                break;
            case 'channel':
                collection = await apiManager.getChannels(crm);
                break;
            case 'status':
                collection = []; //await apiManager.getStatuses(crm);
                break;
            case 'vodafoneIdType':
                collection = await apiManager.getVodafoneIdTypes();
                break;
            case 'vodafoneAccountHolder':
                collection = await apiManager.getVodafoneAccountHolder();
            break;     
        };

        setCollection(collection);

        if (selectedValue && !selectedText && setSelectedText && collection.length > 0) {
            const selectedItem = collection.find(item => item.id === selectedValue);
            if (selectedItem && selectedItem.text) {
                setSelectedText(selectedItem.text);
            }
        }

        if (isLoading) {
            isLoading(false);
        }
    }

    if (collection === false) {
        setCollection([]);
        getCollection();
    }

    const renderControl = () => {
        return (
            <Grid
                item
                lg={gridProps && gridProps.lg ? gridProps.lg : 12}
                sm={gridProps && gridProps.sm ? gridProps.sm : 12}
                xs={gridProps && gridProps.xs ? gridProps.xs : 12} >
                <FormControl required={!!isRequired} error={hasValidations && (fieldError || error)} style={inputStyles || {}} className={classes.formControl}>
                    <InputLabel id={`${label}-select-label`}>
                        {label}
                    </InputLabel>
                    <Select
                        labelId={`${label}-select-label`}
                        value={selectedValue}
                        MenuProps={{
                            style: {
                                maxHeight: '60%'
                            }
                        }}
                        onChange={onChange}>
                        {collection.map(item => {
                            return (
                                <MenuItem key={`${item.id}-${item.text}-${Math.random()}`} value={item.id}>{item.text}</MenuItem>
                            )
                        })}
                    </Select>
                    {(fieldError || error) && <span style={{ color: "red" }}>{fieldError || error} </span>}
                </FormControl>
            </Grid>)
    }

    return (collection && collection.length > 0 ? renderControl() : null);
};

export default SelectInput;