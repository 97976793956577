import axios from 'axios';
import {
  API_LOCAL,
  API_LAMBDA,
  IS_LOCAL_DEV,
  PHASE,
  API_LOCAL_V2,
  API_LAMBDA_V2,
} from './constants';
import { Vodafone } from './mockups';

const API = IS_LOCAL_DEV ? API_LOCAL : API_LAMBDA;
const API_V2 = IS_LOCAL_DEV ? API_LOCAL_V2 : API_LAMBDA_V2;

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error && error.response && error.response.status === 401) {
      //window.location = "/login";
    }
    return error;
  },
);

const apiManager = {
  baseUrl: API,
  baseUrlV2: API_V2,
  getHeaders: function () {
    return {
      'content-type': 'application/json' 
    };
  },
  sendGenericVerificationLink: async function (data, brandAuthorName, vendor) {
    try {
      let headers = this.getHeaders();
      headers.authorization = 'Bearer ' + sessionStorage.getItem('token');
      data.key = brandAuthorName;
      data.vendor = vendor;
      const response = await axios.post(this.baseUrl + '/url/generic/shortener', data, {
        headers,
      });

      return response.data;
    } catch (ex) {
      console.log(ex);
      return false;
    }
  },
  sendSecureSocialVerification: async function (data, brandAuthorName) {
    try {
      let headers = {};
      headers.authorization = 'Bearer ' + sessionStorage.getItem('token');
      data.key = brandAuthorName;
      const response = await axios.post(this.baseUrl + '/url/urlshortner/shrink', data, {
        headers,
      });
      return response.data;
    } catch (ex) {
      console.log(ex);
      return false;
    }
  },
  //test
  sendSecureSocialVerificationWithLinkExpiration: async function (data, brandAuthorName) {
    try {
      let headers = this.getHeaders();
      headers.authorization = 'Bearer ' + sessionStorage.getItem('token');
      data.key = brandAuthorName;
      const response = await axios.post(this.baseUrl + '/url/urlshortner/shrink/token', data, {
        headers,
      });
      //const response = await axios.post("http://localhost:8021/api/v1/url/urlshortner/shrink/token", data, { headers });
      return response.data;
    } catch (ex) {
      console.log(ex);
      return false;
    }
  },
  //test
  getShortLink: async function (data) {
    try {
      let headers = this.getHeaders();
      headers.token = 'ea6b194b-46c2-414a-beb8-e38ace97ba10';

      const response = await axios.post(this.baseUrl + '/url/urlshortner/shrink/token', data, {
        headers,
      });
      return response.data;
    } catch (ex) {
      console.log(ex);
      return false;
    }
  },
  init: async function (payload, brandAuthorName, iv) {
    try {
      const headers = this.getHeaders();
      const response = await axios.post(
        this.baseUrl + '/info/init',
        { payload, brandAuthorName, iv },
        { headers },
      );

      if (response && response.data) {
        sessionStorage.setItem('token', response.data.jwt);
        if (response.data.sso && response.data.sso.network) {
          sessionStorage.setItem('network', response.data.sso.network);
        }
        if (response.data.sso && response.data.sso.instance) {
          sessionStorage.setItem('instance', response.data.sso.instance);
        }
      }
      return response.data;
    } catch (ex) {
      return false;
    }
  },
  decryptPayload: async function (payload, authorName = 'prod-live-bryanpsb') {
    try {
      const headers = this.getHeaders();
      const response = await axios.post(
        this.baseUrl + '/info/decrypt',
        { payload, brandAuthorName: authorName },
        { headers },
      );
      return response.data;
    } catch (ex) {
      console.log(ex);
      return false;
    }
  },
  getPayload: async function (payload, brandAuthorName) {
    try {
      const headers = this.getHeaders();
      const response = await axios.post(
        this.baseUrl + '/care/decrypt/payload',
        { payload, brandAuthorName },
        { headers },
      );

      return response.data;
    } catch (ex) {
      console.log(ex);
    }
    return {};
  },
  getChatJson: async function ({ id, token = '' }) {
    try {
      let headers = this.getHeaders();
      headers.authorization = 'Bearer ' + sessionStorage.getItem('token');

      let queryParams = token ? `?token=${token}` : '';

      const response = await axios.get(this.baseUrl + `/${id}/chat${queryParams}`, {
        headers,
      });

      return response.data;
    } catch (ex) {
      console.log(ex);
      return false;
    }
  },
  sprintPrePaidChat: async function () {
    try {
      let headers = this.getHeaders();
      headers.authorization = 'Bearer ' + sessionStorage.getItem('token');

      const response = await axios.get(this.baseUrl + '/sprintprepaid/chat', {
        headers,
      });

      return response.data;
    } catch (ex) {
      console.log(ex);
      return false;
    }
  },
  sprintPrePaidUpdate: async function (payload, jwt, queryToken) {
    try {
      let headers = this.getHeaders();
      headers.authorization = 'Bearer ' + sessionStorage.getItem('token');

      const response = await axios.post(
        this.baseUrl + '/sprintprepaid/update',
        { ...payload, jwt, queryToken },
        { headers },
      );

      return response.data;
    } catch (ex) {
      console.log(ex);
      return false;
    }
  },
  sprintPrePaidValidate: async function (queryToken) {
    try {
      let headers = this.getHeaders();
      headers.authorization = 'Bearer ' + sessionStorage.getItem('token');
      const iv = sessionStorage.getItem('iv');

      const response = await axios.post(
        this.baseUrl + '/sprintprepaid/validate',
        { queryToken, iv },
        { headers },
      );

      return response.data;
    } catch (ex) {
      console.log(ex);
      return false;
    }
  },
  talktalkUpdate: async function (formData) {
    try {
      let headers = this.getHeaders();
      headers.authorization = 'Bearer ' + sessionStorage.getItem('token');
      const p = sessionStorage.getItem('queryPayload');
      const payload =
        window.location.search.indexOf('payload') > -1
          ? window.location.search.replace('?payload=', '')
          : false;
      const brandAuthorName = `sv-talktalk-${PHASE}`;

      formData = formData.formData;
      const response = await axios.post(
        this.baseUrl + '/talktalk/update',
        { formData, payload, brandAuthorName },
        { headers },
      );
      return response.data;
    } catch (ex) {
      console.log(ex);
      return false;
    }
  },
  talktalkCheckIfLinkExpired: async function (formData) {
    try {
      let headers = this.getHeaders();
      headers.authorization = 'Bearer ' + sessionStorage.getItem('token');
      const p = sessionStorage.getItem('queryPayload');
      const payload =
        window.location.search.indexOf('payload') > -1
          ? window.location.search.replace('?payload=', '')
          : false;
      const brandAuthorName = `sv-talktalk-${PHASE}`;

      const response = await axios.post(
        this.baseUrl + '/talktalk/hasLinkExpired',
        { formData, payload, brandAuthorName },
        { headers },
      );
      return response.data;
    } catch (ex) {
      console.log(ex);
      return false;
    }
  },
  fidelityUpdate: async function (queryToken) {
    try {
      let headers = this.getHeaders();
      headers.authorization = 'Bearer ' + sessionStorage.getItem('token');

      const response = await axios.post(
        this.baseUrl + '/fidelity/update',
        { queryToken },
        { headers },
      );

      return response;
    } catch (ex) {
      console.log(ex);
      return false;
    }
  },
  getVodafoneIdTypes: async function () {
    try {
      // const headers = this.getHeaders();

      // const response = await axios.get(this.baseUrl + `vodafone/types`, { headers });

      // return response.data.result.map(item => {
      //     return {
      //         id: item.id,
      //         text: item.lookupName
      //     };
      // });

      return Vodafone.idTypes;
    } catch (ex) {
      console.log(ex);
    }
    return [];
  },
  vodafoneUpdate: async function (payload) {
    try {
      let headers = this.getHeaders();
      headers.authorization = 'Bearer ' + sessionStorage.getItem('token');

      const response = await axios.post(
        this.baseUrl + '/vodafone/update',
        { ...payload },
        { headers },
      );

      return response.data;
    } catch (ex) {
      console.log(ex);
      return false;
    }
  },
  videotronUpdate: async function (payload, jwt, queryToken) {
    try {
      const { first, last, mobile, pin, facebook } = payload;
      let headers = this.getHeaders();
      headers.authorization = 'Bearer ' + sessionStorage.getItem('token');

      const response = await axios.post(
        this.baseUrl + '/videotron/update',
        { ...payload, jwt, queryToken },
        { headers },
      );

      return response.data;
    } catch (ex) {
      console.log(ex);
      return false;
    }
  },
  videotronValidate: async function (queryToken) {
    try {
      let headers = this.getHeaders();
      headers.authorization = 'Bearer ' + sessionStorage.getItem('token');

      const response = await axios.post(
        this.baseUrl + '/videotron/validate',
        { queryToken },
        { headers },
      );

      return response.data;
    } catch (ex) {
      console.log(ex);
      return false;
    }
  },
  update: async function (payload, jwt, queryToken, clientPath) {
    try {
      let headers = this.getHeaders();
      headers.authorization = 'Bearer ' + sessionStorage.getItem('token');

      let data = { ...payload, jwt, queryToken };
      const response = await axios.post(this.baseUrl + `/${clientPath}/update`, data, { headers });

      return response.data;
    } catch (ex) {
      console.log(ex);
      return false;
    }
  },
  genericUpdate: async function (payload, jwt, queryToken, client, note) {
    try {
      let headers = this.getHeaders();
      headers.authorization = 'Bearer ' + sessionStorage.getItem('token');

      const response = await axios.post(this.baseUrl + '/generic/update', payload, { headers });

      return response && response.status === 200 ? response.data : null;
    } catch (ex) {
      console.log(ex);
      return false;
    }
  },
  getAuthor: async function (authorId, payload, jwt, queryToken, client) {
    try {
      let headers = this.getHeaders();
      headers.authorization = 'Bearer ' + sessionStorage.getItem('token');

      console.log('headers', headers);
      const response = await axios({
        method: 'get',
        url: `${this.baseUrl}/care/authors/${authorId}`,
        headers,
      });

      return response.data;
    } catch (ex) {
      console.log(ex);
      return false;
    }
  },
  validate: async function (queryToken, clientPath) {
    try {
      let headers = this.getHeaders();
      headers.authorization = 'Bearer ' + sessionStorage.getItem('token');

      const response = await axios.post(
        this.baseUrl + `/${clientPath}/validate`,
        { queryToken },
        { headers },
      );

      return response.data;
    } catch (ex) {
      console.log(ex);
      return false;
    }
  },
  purge: async function (payload, brandAuthorName, iv) {
    try {
      const headers = this.getHeaders();
      headers.authorization = 'Bearer ' + sessionStorage.getItem('token');
      const response = await axios.post(
        this.baseUrl + '/info/purge',
        { payload, brandAuthorName, iv },
        { headers },
      );
      if (response && response.data) {
        return response.data;
      }
    } catch (ex) {
      console.log(ex);
      return false;
    }
  },
  getConversation: async function (conversationId) {
    try {
      const headers = this.getHeaders();
      headers.authorization = 'Bearer ' + sessionStorage.getItem('token');
      const result = await axios.get(
        this.baseUrl + `/care/conversations/displayIds/${conversationId}`,
        { headers },
      );
      if (result.data) {
        return result.data.length > 0 ? result.data[0] : result.data;
      }
      return {};
    } catch (ex) {
      console.log(ex);
    }
    return {};
  },
  getVodafoneAccountHolder: async function () {
    try {
      return Vodafone.isAccountHolder;
    } catch (ex) {
      console.log(ex);
    }
    return [];
  },

  // -------------- API V2 CALLS ---------

  authorInit: async function (payload, tenantId, iv) {
    try {
      const data = JSON.stringify({
        payload: payload,
        tenantId: tenantId,
        iv: iv,
      });

      const config = {
        method: 'post',
        url: `${this.baseUrlV2}/author/init`,
        headers: {
          'Content-Type': 'application/json',
        },
        data: data,
      };
      const response = await axios(config);
      const responseData = response?.response?.data ? response?.response?.data:response.data;


      console.log(response)
      console.log(responseData);
      if(responseData.status === 200){
        sessionStorage.setItem('token', responseData.jwt);
      }
      return responseData;
    } catch (ex) {
      console.log(`Error: ${ex}`);
    }
  },
  authorProfileUpdate: async function (fields = '', queryToken, tenantId, isEnhanced = false) {
    try {
      let headers = this.getHeaders();
      headers.authorization = 'Bearer ' + sessionStorage.getItem('token');

      const response = await axios.post(
        this.baseUrlV2 + '/author/update',
        { ...fields, tenantId, queryToken, isEnhanced },
        { headers },
      );

      return response.data;
    } catch (ex) {
      console.log(ex);
      return false;
    }
  },
  firebaseShortUrl: async function (longUrl, tenantId) {
    try {
      let headers = {};
      headers.authorization = 'Bearer ' + sessionStorage.getItem('token');
      const response = await axios.post(
        this.baseUrlV2 + '/urlshortener/firebase',
        { longUrl, tenantId },
        {
          headers,
        },
      );
      return response.data;
    } catch (ex) {
      console.log(ex);
      return false;
    }
  },
  modernChatInfoJson: async function (tenantId) {
    try {
      let headers = this.getHeaders();
      headers.authorization = 'Bearer ' + sessionStorage.getItem('token');

      const response = await axios.get(this.baseUrlV2 + `/chat/info/${tenantId}`, {
        headers,
      });

      return response.data;
    } catch (ex) {
      console.log(ex);
      return false;
    }
  },
  conversationInfo: async function (tenantId, conversationId) {
    try {
      let headers = this.getHeaders();
      headers.authorization = 'Bearer ' + sessionStorage.getItem('token');

      const response = await axios.get(
        this.baseUrlV2 + `/care/${tenantId}/conversations/displayIds/${conversationId}`,
        {
          headers,
        },
      );

      return response;
    } catch (ex) {
      console.log(ex);
      return false;
    }
  },

};

export default apiManager;
