import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Typography, Link } from '@material-ui/core';
import moment from 'moment';
import './style.css'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'inline-block',
    backgroundColor: '#000',
    fontSize: '100%',
    fontWeight: '400',
    fontFamily: '"Stag Sans Web",Arial,Helvetica,Tahoma,Verdana,Sans-Serif',
    lineHeight: 1.5,
    margin: 0
  },
  links: {
    color: 'white',
    fontSize: '.875rem',
    borderRight: '1px solid',
    padding: '0 .925rem 0 0',
    margin: '0 .925rem .75rem 0',
    fontWeight: 400,
    textAlign: 'left',
  },
  wrapper: {
    height: '800px'
  }
}));

const Footer = props => {
  const { className, ...rest } = props;

  const classes = useStyles();

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
      style={{width: '100%' }}
    >
      <div className="standalone-legal-link"
           style={{margin: '3rem auto'}}>
        <div>
          <div className="spectrum-footer-legal center">
            <div className="legal-links-wrapper legal-links">
              <div className="list-unstyled">
                <div className="hy-grid style-scope hy-footer">

                {/*  <div className="footer-link-container style-scope hy-footer">*/}
                {/*    <div className="footer-section style-scope hy-footer">*/}
                {/*      <ul className="footer-list style-scope hy-footer">*/}

                {/*        <li className="footer-list__item style-scope hy-footer">*/}
                {/*          <a className="footer-list__link style-scope hy-footer"*/}
                {/*             href="https://www.telus.com/digital?linktype=footer">Help</a>*/}
                {/*        </li>*/}

                {/*        <li className="footer-list__item style-scope hy-footer">*/}
                {/*          <a className="footer-list__link style-scope hy-footer"*/}
                {/*             href="https://community.telus.com/?linktype=footer">Stores</a>*/}
                {/*        </li>*/}

                {/*        <li className="footer-list__item style-scope hy-footer">*/}
                {/*          <a className="footer-list__link style-scope hy-footer"*/}
                {/*             href="http://partner.telus.com/?linktype=footer">Careers</a>*/}
                {/*        </li>*/}

                {/*        <li className="footer-list__item style-scope hy-footer">*/}
                {/*          <a className="footer-list__link style-scope hy-footer"*/}
                {/*             href="https://www.telus.com/health">Track your order</a>*/}
                {/*        </li>*/}

                {/*        <li className="footer-list__item style-scope hy-footer">*/}
                {/*          <a className="footer-list__link style-scope hy-footer"*/}
                {/*             href="https://www.telusinternational.com/?linktype=footer">Privacy & Legal</a>*/}
                {/*        </li>*/}

                {/*        <li className="footer-list__item style-scope hy-footer">*/}
                {/*          <a className="footer-list__link style-scope hy-footer"*/}
                {/*             href="http://mytelus.telus.com/?linktype=footer">CRTC Wireless Code</a>*/}
                {/*        </li>*/}
                {/*      </ul>*/}
                {/*    </div>*/}
                {/*  </div>*/}
                  <div className="footer-copyright style-scope hy-footer">
                    <p className="copyright-bottom"
                       style={{marginBottom: '40px'}}>
                      {`© ${moment().year()} Koodo Mobile.`}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
      );
      };

      Footer.propTypes = {
      className: PropTypes.string
    };

      export default Footer;
