import React from 'react';
import PropTypes from 'prop-types';
import dominionLogo from './DominionLogo.svg'

const DominionLogo = () => (
    <img src={dominionLogo} alt={'Dominion Logo'} height={60} style={{ position: 'relative' }} />
);

DominionLogo.propTypes = {
    className: PropTypes.string
};


export default DominionLogo;