import { makeStyles, useTheme } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
    root: {
      width: '100%',
      height: '100%',
      flexGrow: 1
    },
    mainContent: {
      height: '100%',
      width: '100%'
    },
    wrapper: {
      textAlign: 'left',
      marginTop: '2em',
      minHeight: 'calc(100% - 113px - 2em)'
    },
    content: {
      // width: '950px',
      // maxWidth: '85%',
      // margin: 'auto',
      padding: 0
    },
    links: {
      wordBreak: 'break-word'
    },
    appBar: {
      height: '113px',
      width: '100%',
      marginBottom: '2em',
      paddingBottom: '0.5rem',
      boxShadow: '0 2px 3px 0 rgba(0,0,0,.1)'
    },
    preHeader: {
      backgroundColor: '#4a4d4e',
      height: '40px'
    },
    logo: {
      height: "36px",
      position: 'relative',
      left: '10px'
    },
    appBarContent: {
      // width: '950px',
      // maxWidth: '85%',
      // margin: 'auto',
      padding: 0
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    footer: {
      // width: '950px',
      // maxWidth: '85%',
      // margin: 'auto',
      position: 'relative',
      marginTop: '1rem',
      marginBottom: '1.5rem'
    }
  }));

  export default useStyles;