import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Typography, Link } from '@material-ui/core';
import moment from 'moment';
import './style.css'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'inline-block',
    backgroundColor: '#2a2c2e',
    fontSize: '100%',
    fontWeight: '400',
    fontFamily: 'rutledge-regular',
    lineHeight: 1.5,
    margin: 0
  },
  links: {
    color: 'white',
    fontSize: '.875rem',
    borderRight: '1px solid',
    padding: '0 .925rem 0 0',
    margin: '0 .925rem .75rem 0',
    fontWeight: 400,
    textAlign: 'left',
  },
  wrapper: {
    height: '800px'
  }
}));

const Footer = props => {
  const { className, ...rest } = props;

  const classes = useStyles();

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
      style={{width: '100%' }}
    >
        <div class="standalone-legal-link"
          style={{margin: '3rem auto'}}>
            <div>
                <div class="spectrum-footer-legal center">
                    <div class="legal-links-wrapper legal-links">
                        <div class="list-unstyled">
                          <div className="hy-grid style-scope hy-footer">

                            <img src="https://static.telus.com/common/images/footer/TELUS-logo-white.svg"
                                 className="hy_c-footer__logo style-scope hy-footer" width="130" height="25"
                                 alt="TELUS"/>
                              <div className="c-region-select u-hide@xs style-scope hy-footer">
                                <a id="ge_js_region_select_footer_trigger_desktop" href="javascript:void(0)"
                                   className="c-region-select__link style-scope hy-footer"><span
                                  className="th_c-icon th_c-icon--location style-scope hy-footer"></span> British
                                  Columbia EN</a>
                              </div>
                              <div className="footer-link-container style-scope hy-footer">
                                <div className="footer-section style-scope hy-footer">
                                  <ul className="footer-list style-scope hy-footer">

                                    <li className="footer-list__item style-scope hy-footer">
                                      <a className="footer-list__link style-scope hy-footer"
                                         href="https://www.telus.com/digital?linktype=footer">TELUS Digital</a>
                                    </li>

                                    <li className="footer-list__item style-scope hy-footer">
                                      <a className="footer-list__link style-scope hy-footer"
                                         href="https://community.telus.com/?linktype=footer">We Give Where We Live</a>
                                    </li>

                                    <li className="footer-list__item style-scope hy-footer">
                                      <a className="footer-list__link style-scope hy-footer"
                                         href="http://partner.telus.com/?linktype=footer">TELUS Partners</a>
                                    </li>

                                    <li className="footer-list__item style-scope hy-footer">
                                      <a className="footer-list__link style-scope hy-footer"
                                         href="https://www.telus.com/health">TELUS Health</a>
                                    </li>

                                    <li className="footer-list__item style-scope hy-footer">
                                      <a className="footer-list__link style-scope hy-footer"
                                         href="https://www.telusinternational.com/?linktype=footer">TELUS
                                        International</a>
                                    </li>

                                    <li className="footer-list__item style-scope hy-footer">
                                      <a className="footer-list__link style-scope hy-footer"
                                         href="http://mytelus.telus.com/?linktype=footer">MyTELUS</a>
                                    </li>
                                  </ul>
                                </div>
                                <div className="footer-section style-scope hy-footer">
                                  <ul className="footer-list footer-list--other style-scope hy-footer">


                                    <li className="footer-list__item footer-list__item--divider style-scope hy-footer">
                                      <hr className="footer-list-divider style-scope hy-footer"/>
                                    </li>
                                    <li className="footer-list__item style-scope hy-footer">
                                      <a className="footer-list__link style-scope hy-footer"
                                         href="http://about.telus.com/index.jspa?linktype=footer&amp;INTCMP=TcomFooter_EN_aboutus">About
                                        us</a>
                                    </li>

                                    <li className="footer-list__item style-scope hy-footer">
                                      <a className="footer-list__link style-scope hy-footer"
                                         href="http://www.telus.com/en/bc/get-help/accessibility/topic.do?INTCMP=TcomFooter_accessibility">Accessibility</a>
                                    </li>

                                    <li className="footer-list__item style-scope hy-footer">
                                      <a className="footer-list__link style-scope hy-footer"
                                         href="https://www.telus.com/en/bc/careers/?linktype=footer&amp;INTCMP=TcomFooter_EN_careers">Careers</a>
                                    </li>

                                    <li className="footer-list__item style-scope hy-footer">
                                      <a className="footer-list__link style-scope hy-footer"
                                         href="https://www.telus.com/en/bc/support/contact-us?INTCMP=TcomFooter_contact_us">Contact
                                        us</a>
                                    </li>

                                    <li className="footer-list__item style-scope hy-footer">
                                      <a className="footer-list__link style-scope hy-footer"
                                         href="https://www.telus.com/support/article/the-wireless-code-of-conduct">CRTC
                                        Wireless code</a>
                                    </li>

                                    <li className="footer-list__item style-scope hy-footer">
                                      <a className="footer-list__link style-scope hy-footer"
                                         href="https://www.telus.com/en/bc/resources/stores/?linktype=footer&amp;INTCMP=TcomFooter_EN_storefinder">Find
                                        a store</a>
                                    </li>

                                    <li className="footer-list__item style-scope hy-footer">
                                      <a className="footer-list__link style-scope hy-footer"
                                         href="https://www.telus.com/en/bc/futurefriendly/?linktype=footer&amp;INTCMP=TcomFooter_EN_futurefriendly">Future
                                        friendly story</a>
                                    </li>

                                    <li className="footer-list__item style-scope hy-footer">
                                      <a className="footer-list__link style-scope hy-footer"
                                         href="http://about.telus.com/community/english/investor_relations?linktype=footer&amp;INTCMP=TcomFooter_investor_rel">Investor
                                        relations</a>
                                    </li>

                                    <li className="footer-list__item style-scope hy-footer">
                                      <a className="footer-list__link style-scope hy-footer"
                                         href="https://forum.telus.com/?linktype=footer&amp;INTCMP=TcomFooter_neighbourhood_forum">Neighbourhood</a>
                                    </li>

                                    <li className="footer-list__item style-scope hy-footer">
                                      <a className="footer-list__link style-scope hy-footer"
                                         href="https://www.telus.com/en/bc/support/privacy-policy?linktype=footer&amp;INTCMP=TcomFooter_privacy">Privacy/Cookies</a>
                                    </li>


                                    <li className="footer-list__item footer-list__item--divider style-scope hy-footer">
                                      <hr className="footer-list-divider style-scope hy-footer"/>
                                    </li>
                                    <li className="footer-list__item style-scope hy-footer">
                                      <a className="footer-list__link style-scope hy-footer"
                                         href="http://about.telus.com/community/english/security?linktype=footer&amp;INTCMP=TcomFooter_security">Security</a>
                                    </li>

                                    <li className="footer-list__item style-scope hy-footer">
                                      <a className="footer-list__link style-scope hy-footer"
                                         href="http://www.telus.com/en/bc/get-help/service-terms/support.do?linktype=footer&amp;INTCMP=TcomFooter_EN_serviceterms">Service
                                        terms</a>
                                    </li>

                                    <li className="footer-list__item style-scope hy-footer">
                                      <a className="footer-list__link style-scope hy-footer"
                                         href="https://sustainability.telus.com/en/?linktype=footer&amp;INTCMP=TcomFooter_csr">Sustainability</a>
                                    </li>

                                    <li className="footer-list__item style-scope hy-footer">
                                      <a className="footer-list__link style-scope hy-footer"
                                         href="https://blog.telus.com/?linktype=footer&amp;INTCMP=TcomFooter_telus_blog">TELUS
                                        Blog</a>
                                    </li>

                                    <li className="footer-list__item style-scope hy-footer">
                                      <a className="footer-list__link style-scope hy-footer"
                                         href="https://wise.telus.com/en/?linktype=footer&amp;INTCMP=TcomFooter_telus_wise">TELUS
                                        Wise</a>
                                    </li>

                                    <li className="footer-list__item style-scope hy-footer">
                                      <a className="footer-list__link style-scope hy-footer"
                                         href="http://about.telus.com/community/english/legal?linktype=footer&amp;INTCMP=TcomFooter_legal">User
                                        terms</a>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                              <div className="footer-copyright style-scope hy-footer">
                                <p className="copyright-bottom"
                                   style={{marginBottom: '40px'}}>
                                  {`© ${moment().year()} TELUS`}</p>
                              </div>
                          </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>
  );
};

Footer.propTypes = {
  className: PropTypes.string
};

export default Footer;
