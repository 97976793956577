import { flatten } from 'flat';

let french = {
    provide: {
        heading: 'Veuillez inscrire vos informations personnelles.',
        headingTwitter: '{name}, Veuillez inscrire vos informations personnelles.',
        aditionalInfo: 'Informations supplémentaires. Veuillez remplir au moins 2 champs.'
    },
    socialVerificationButton: {
        label: 'Envoyer à la vérification sociale',
        verifyText: 'Nous devons vérifier que vous êtes un client ici: {link} La vérification sera soumise via votre navigateur Web.',
        error: 'Impossible de générer une URL courte, URL longue utilisée à la place'
    },
    socialVerificationForm: {
        pageTitle: 'Videotron - Vérification sécurisée',
        title: 'Vérification de compte',
        description: 'Merci de nous donner les informations liées à votre compte.',
        loading: "Validation de l’identité sociale, un instant s’il vous plaît",
        firstName: 'Nom',
        lastName: 'Prénom',
        address: 'Adresse',
        zipCode: 'Code postal',
        phone: 'Numéro de téléphone associé au compte Vidéotron',
        birthday: 'Date de naissance',
        birthdayPlaceholder: 'Entrer Date de naissance',
        mothermname: "Nom de jeune fille de la mère",
        accountPassword: 'Code secret (si applicable)',
        required: 'Champs obligatoires',
        submit: 'Soumettre',
        alertError: 'Quelque chose a mal tourné. Veuillez contacter votre agent.'
    },
    validations: {
        required: 'Ce champ est requis.',
        email: "Ce n'est pas un e-mail valide.",
        phone: 'Veuillez inclure un numéro de téléphone valide.',
        aditionalInfo: 'Veuillez remplir au moins 2 champs.'
    },
    success: {
        pageTitle: 'Videotron - Succès',
        title: 'Succès!',
        description: 'Merci de nous avoir donné les informations liées à votre compte.',
        nextStep: 'Prochaine étape : Envoyez-nous un message privé pour nous aviser que le formulaire est rempli',
        tellUsMore: 'Nous pourrons alors poursuivre la discussion  avec vous.',
        directMessage: 'Message direct'
    },
    error: {
      pageTitle: 'Videotron - Erreur',
      title: 'Erreur!',
      description: 'Quelque chose a mal tourné.',
      nextStep: 'Prochaine étape : Envoyez-nous un message privé',
      tellUsMore: "Dites-nous en plus sur ce qui se passe afin que nous puissions résoudre les problèmes.",
      directMessage: 'Message direct'
    }
};

export default flatten(french);
