import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/styles';
import { useMediaQuery } from '@material-ui/core';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import { Sidebar, Topbar, Footer, Rightbar } from './components';
import AcUnitIcon from '@material-ui/icons/AcUnit';

import {
  Grid, Select, MenuItem, FormControl, InputLabel, TextField, Button, Snackbar, CircularProgress,
  AppBar, Typography, Toolbar, IconButton
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  nav: {
    margin: '59px 0 0 97px'
  },
  root: {
    width: '100%',
    height: '100%',
    flexGrow: 1,
  },
  mainContent: {
    height: '100%',
    width: '100%'
  },
  wrapper: {
    textAlign: 'left',
    marginTop: '2em'
  },
  content: {
    width: '950px',
    margin: 'auto',
    // paddingBottom: '10px',
    minHeight: '75vh'
  },
  links: {
    wordBreak: 'break-word',
    display: 'block',
    margin: '1px 30px 0 0',
    textDecoration: 'none',
    fontFamily: '"Stag Sans Web",Arial,Helvetica,Tahoma,Verdana,Sans-Serif',
    fontSize: '18px',
    lineHeight: '22px',
    fontWeight: '700',
    color: '#000000',
    borderBottom: '2px solid transparent'
  },
  appBar: {
    height: '113px',
    width: '100%',
    marginBottom: '2em',
    boxShadow: '0 2px 3px'
  },
  preHeader: {
    backgroundColor: '#ffffff',
    height: '40px'
  },
  logo: {
    height: "28px",
    position: 'relative',
    width: '130px'
  },
  appBarContent: {
    width: '950px',
    margin: 'auto',
    padding: 0
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  listItems:{
    position: 'relative',
    float: 'left',
    margin: '0 10px 0 0',
    padding: '0',
    background: 'none'
  },
  bodyTelus:{
    paddingRight: "0px",
    paddingLeft: "0px",
  },
  footerTelus:{
    bottom: 0,
  }

}));

const Telus = props => {
  const { children, user, screenConfig } = props;
  if(!children){
    debugger;
  }
  const classes = useStyles();
  const theme = useTheme();

  useEffect(() => {
    if (screenConfig) {
      if (screenConfig.pageTitle && document.title !== screenConfig.pageTitle) {
        document.title = screenConfig.pageTitle;
      }

      if (screenConfig.favicon) {
        const link = document.querySelector("link[rel*='icon']") || document.createElement('link');
        link.type = 'image/x-icon';
        link.rel = 'shortcut icon';
        link.href = screenConfig.favicon;
        document.getElementsByTagName('head')[0].appendChild(link);
      }
    }
  }, [])


  useEffect(() => {
    document.body.className = classes.bodyTelus;
  }, []);

  return (
    <div
      className={clsx({
        [classes.root]: true
      })}
    >
      <main className={classes.mainContent}>
        <AppBar className={classes.appBar}
          style={{
            backgroundColor: '#fff',


          }}
          position="relative">
          <div className={classes.preHeader}></div>
          <Grid
            container
            item
            alignContent="space-around"
            alignItems="center"
            alignContent="space-around"
            //lg={8}
            xs={12}
          >
            <Toolbar className={classes.appBarContent}>
              <a className={classes.logo} href={screenConfig.headerLogoUrl} target='_blank'>{screenConfig.headerLogo}</a>

              <div>
                <nav className={classes.nav}>
                  <ul className={classes.list}>
                    <li className={classes.listItems}><a className={classes.links} href="https://www.koodomobile.com/phones?INTCMP=KMNew_NavMenu_Shop" target="_blank">Shop</a></li>
                    <li className={classes.listItems}><a className={classes.links} href="https://www.koodomobile.com/my-account/overview?INTCMP=KMNew_NavMenu_SelfServe" target="_blank">Self Serve</a></li>
                    <li className={classes.listItems}><a className={classes.links} href="https://www.koodomobile.com/en/help?INTCMP=KMNew_NavMenu_support&INTCMP=KMNew_NavMenu_support" target="_blank">Help</a></li>
                  </ul>
                </nav>
              </div>
            </Toolbar>
          </Grid>
        </AppBar>
        <div className={classes.wrapper}>
          <Grid
            container
            item
            alignContent="space-around"
            alignItems="center"
            //lg={8}
            xs={12}
          >
            <div className={classes.content}>
              {children}
            </div>
          </Grid>
        </div>
      </main>
      <div className={classes.footerTelus}>

        <Footer />
      </div>
    </div>
  );
};

Telus.propTypes = {
  children: PropTypes.node,
  user: PropTypes.object
};

export default Telus;
