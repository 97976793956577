import React from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import PropTypes from 'prop-types';

import { RouteWithLayout } from './components';
import {
  Main as MainLayout,
  Minimal as MinimalLayout,
  Empty as EmptyLayout,
  Generic as GenericLayout,
  BlueShieldCa as BlueShieldCaLayout,
  Charter as CharterLayout,
  FitbitLayout,
  SprintPrePaid as SprintPpdLayout,
  TalkTalk as TalkTalkLayout,
  Vodafone as VodafoneLayout,
  Fidelity as FidelityLayout,
  Manualc01 as Manualc01Layout,
  Telus as TelusLayout,
  Koodo as KoodoLayout,
} from './layouts';

import apiManager from './ApiManager';
import {
  blueShieldScreenConfigs,
  bryanPScreenConfigs,
  charterScreenConfigs,
  fitbitScreenConfigs,
  khorosScreenConfigs,
  telusScreenConfigs,
  koodoScreenConfigs,
  videotronScreenConfigs,
  vodafoneScreenConfigs,
  rocksnropesScreenConfigs,
  sprintPrePaidConfigs,
  fidelityScreenConfigs,
  quickbooksScreenConfigs,
  genericScreenConfigs,
  manualc01ScreenConfigs,
  dominionScreenConfigs,
} from './configs';

import {
  BLUESHIELD_GUID,
  BRYANP_GUID,
  CHARTER_GUID,
  DELTA_GUID,
  FITBIT_GUID,
  FLOWAI_GUID as FlowAiGUID,
  HASBRO_GUID as HasbroGUID,
  KHOROS_GUID as KhorosGUID,
  ROCKSNROPES_GUID as RocksnropesGUID,
  SPRINTPPD_GUID,
  TALKTALK_GUID,
  VIDEOTRON_GUID as VideotronGUID,
  VODAFONE_GUID as VodafoneGUID,
  FIDELITY_GUID,
  QUICKBOOKS_GUID,
  MANUALC01_GUID,
  MANUALC02_GUID,
  RESPONSEC01_GUID,
  TELUS_GUID as telusGUID,
  KOODO_GUID as KoodoGUID,
  DOMINION_GUID as DominionGUID,
} from './constants';

import {
  //Default Views
  GenericView,

  //Blue Shield Screens
  BlueShieldSvButton as BlueShieldSvButtonView,
  BlueShieldLanding as BlueShieldLandingView,
  BlueShieldSuccess as BlueShieldSuccessView,
  BlueShieldError as BlueShieldErrorView,

  //BryanP Screens
  BryanPSvButton as BryanPSvButtonView,
  BryanPLanding as BryanPLandingView,
  BryanPSuccess as BryanPSuccessView,
  BryanPError as BryanPErrorView,

  //Charter Screens
  CharterSvButton as CharterSvButtonView,
  CharterLanding as CharterLandingView,
  CharterSuccess as CharterSuccessView,
  CharterError as CharterErrorView,

  //Delta Loyalty Lookup Button
  DeltaMemberLookupButton as DeltaMemberLookupButtonView,

  //Fitbit Screens
  FitbitSvButton as FitbitSvButtonView,
  FitbitLanding as FitbitLandingView,
  FitbitSuccess as FitbitSuccessView,
  FitbitError as FitbitErrorView,
  FlowAiError as FlowAiErrorView,
  FlowAiSuccess as FlowAiSuccessView,
  FlowAiLanding as FlowAiLandingView,
  FlowAiSvButton as FlowAiSvButtonView,
  HasbroError as HasbroErrorView,
  HasbroCustomForm as HasbroCustomFormView,

  //Khoros Screens
  KhorosDashboard as KhorosDashboardView,
  KhorosSvButton as KhorosSvButtonView,
  KhorosLanding as KhorosLandingView,
  KhorosPoweredBy as KhorosPoweredByView,
  KhorosSuccess as KhorosSuccessView,
  KhorosError as KhorosErrorView,
  KhorosPrivacy as KhorosPrivacyView,
  KhorosTerms as KhorosTermsView,
  KhorosNotFound as KhorosNotFoundView,

  //Dynamic
  DominionError as DominionErrorView,
  DominionSuccess as DominionSuccessView,
  DominionLanding as DominionLandingView,
  DominionExpired as DominionExpiredView,
  DominionDataForm as DominionDataFormView,
  DominionOffline as DominionOfflineView,
  KoodoError as KoodoErrorView,
  KoodoSuccess as KoodoSuccessView,
  KoodoLanding as KoodoLandingView,
  telusError as telusErrorView,
  telusSuccess as telusSuccessView,
  telusLanding as telusLandingView,
  telusSvButton as telusSvButtonView,
  RocksnropesError as RocksnropesErrorView,
  RocksnropesSuccess as RocksnropesSuccessView,
  RocksnropesLanding as RocksnropesLandingView,
  RocksnropesSvButton as RocksnropesSvButtonView,

  //Sprint PPD Screens
  SprintPrePaidComplianceButton as SprintPrePaidComplianceButtonView,
  SprintPrePaidLanding as SprintPrePaidLandingView,
  SprintPrePaidSuccess as SprintPrePaidSuccessView,
  SprintPrePaidError as SprintPrePaidErrorView,

  //TalkTalk screens
  TalkTalkSvButton as TalkTalkSvButtonView,
  TalkTalkValidate as TalkTalkValidateView,
  TalkTalkLanding as TalkTalkLandingView,
  TalkTalkError as TalkTalkErrorView,

  //VIDEOTRON Screens
  VideotronSvButton as VideotronSvButtonView,
  VideotronLanding as VideotronLandingView,
  VideotronSuccess as VideotronSuccessView,
  VideotronError as VideotronErrorView,

  //VODAFONE Screens
  VodafoneSvButton as VodafoneSvButtonView,
  VodafoneLanding as VodafoneLandingView,
  VodafoneSuccess as VodafoneSuccessView,
  VodafoneError as VodafoneErrorView,

  //fidelity
  fidelityLanding,
  fidelitySuccess,
  fidelityError,
  fidelityExpired,
  fidelityNotFound,

  //quickbooks
  quickbooksLanding,
  quickbooksSuccess,
  quickbooksError,
  quickbooksExpired,
  quickbooksNotFound,

  //FOR ENGINEERING
  //manual-c01
  manualc01Landing,
  manualc01Success,
  manualc01Error,
  manualc01Expired,
  manualc01NotFound,

  //manual-c02
  manualc02Landing,
  manualc02Success,
  manualc02Error,
  manualc02Expired,
  manualc02NotFound,

  //response-c01
  responsec01Landing,
  responsec01Success,
  responsec01Error,
  responsec01Expired,
  responsec01NotFound,
} from './views';

class Routes extends React.PureComponent {
  constructor(props) {
    super(props);
    const { user } = this.props;
    this.state = {
      user: {},
      apiManager,
    };
    this.updateUser = this.updateUser.bind(this);
  }

  componentDidUpdate() {
    const { user } = this.state;
    const { history } = this.props;
    if (!user || (!user.userName && history)) {
      history.push('/login');
    }
  }

  updateUser(user) {
    this.setState({ user });
    //localStorage.setItem('user', JSON.stringify(user));
  }

  render() {
    const { user, apiManager } = this.state;

    return (
      <Switch>
        {/*ROOT screen*/}
        <RouteWithLayout
          component={KhorosPoweredByView}
          exact
          layout={EmptyLayout}
          path={'/'}
          apiManager={apiManager}
          screenConfig={khorosScreenConfigs.poweredBy}
        />
        {/*Default SV*/}
        <RouteWithLayout
          component={GenericView}
          layout={GenericLayout}
          path={'/default'}
          apiManager={apiManager}
          screenConfig={genericScreenConfigs}
        />
        {/*Blue Shield screens*/}
        <RouteWithLayout
          component={BlueShieldSvButtonView}
          exact
          layout={MinimalLayout}
          path={`/${BLUESHIELD_GUID}/sv-button`}
          apiManager={apiManager}
        />
        ,
        <RouteWithLayout
          component={BlueShieldLandingView}
          exact
          layout={BlueShieldCaLayout}
          path={`/${BLUESHIELD_GUID}/landing`}
          apiManager={apiManager}
          screenConfig={blueShieldScreenConfigs.header}
        />
        ,
        <RouteWithLayout
          component={BlueShieldSuccessView}
          exact
          layout={BlueShieldCaLayout}
          path={`/${BLUESHIELD_GUID}/success`}
          apiManager={apiManager}
          screenConfig={blueShieldScreenConfigs.header}
        />
        ,
        <RouteWithLayout
          component={BlueShieldErrorView}
          exact
          layout={BlueShieldCaLayout}
          path={`/${BLUESHIELD_GUID}/error`}
          apiManager={apiManager}
          screenConfig={blueShieldScreenConfigs.header}
        />
        ,{/*BRYANP screens*/}
        <RouteWithLayout
          component={BryanPSvButtonView}
          exact
          layout={MinimalLayout}
          path={`/${BRYANP_GUID}/sv-button`}
          apiManager={apiManager}
        />
        ,
        <RouteWithLayout
          component={BryanPLandingView}
          exact
          layout={MinimalLayout}
          path={`/${BRYANP_GUID}/landing`}
          apiManager={apiManager}
          screenConfig={bryanPScreenConfigs.header}
        />
        ,
        <RouteWithLayout
          component={BryanPSuccessView}
          exact
          layout={MinimalLayout}
          path={`/${BRYANP_GUID}/success`}
          apiManager={apiManager}
          screenConfig={bryanPScreenConfigs.header}
        />
        ,
        <RouteWithLayout
          component={BryanPErrorView}
          exact
          layout={MinimalLayout}
          path={`/${BRYANP_GUID}/error`}
          apiManager={apiManager}
          screenConfig={bryanPScreenConfigs.header}
        />
        ,{/*CHARTER screens*/}
        <RouteWithLayout
          component={CharterSvButtonView}
          exact
          layout={MinimalLayout}
          path={`/${CHARTER_GUID}/sv-button`}
          apiManager={apiManager}
        />
        ,
        <RouteWithLayout
          component={CharterLandingView}
          exact
          layout={CharterLayout}
          path={`/${CHARTER_GUID}/landing`}
          apiManager={apiManager}
          screenConfig={charterScreenConfigs.landing}
        />
        ,
        <RouteWithLayout
          component={CharterSuccessView}
          exact
          layout={CharterLayout}
          path={`/${CHARTER_GUID}/success`}
          apiManager={apiManager}
          screenConfig={charterScreenConfigs.success}
        />
        ,
        <RouteWithLayout
          component={CharterErrorView}
          exact
          layout={CharterLayout}
          path={`/${CHARTER_GUID}/error`}
          apiManager={apiManager}
          screenConfig={charterScreenConfigs.error}
        />
        ,{/*Delta screens*/}
        <RouteWithLayout
          component={DeltaMemberLookupButtonView}
          exact
          layout={MinimalLayout}
          path={`/${DELTA_GUID}/loyalty-member-button`}
          apiManager={apiManager}
        />
        ,{/*Fitbit screens*/}
        <RouteWithLayout
          component={FitbitSvButtonView}
          exact
          layout={MinimalLayout}
          path={`/${FITBIT_GUID}/sv-button`}
          apiManager={apiManager}
        />
        ,
        <RouteWithLayout
          component={FitbitLandingView}
          exact
          layout={FitbitLayout}
          path={`/${FITBIT_GUID}/landing`}
          apiManager={apiManager}
          screenConfig={fitbitScreenConfigs.header}
        />
        ,
        <RouteWithLayout
          component={FitbitSuccessView}
          exact
          layout={FitbitLayout}
          path={`/${FITBIT_GUID}/success`}
          apiManager={apiManager}
          screenConfig={fitbitScreenConfigs.header}
        />
        ,
        <RouteWithLayout
          component={FitbitErrorView}
          exact
          layout={FitbitLayout}
          path={`/${FITBIT_GUID}/error`}
          apiManager={apiManager}
          screenConfig={fitbitScreenConfigs.header}
        />
        ,{/*Flow screens*/}
        <RouteWithLayout
          component={FlowAiSvButtonView}
          exact
          layout={MinimalLayout}
          path={`/${FlowAiGUID}/sv-button`}
          apiManager={apiManager}
        />
        ,
        <RouteWithLayout
          component={FlowAiLandingView}
          exact
          layout={MinimalLayout}
          path={`/${FlowAiGUID}/landing`}
          apiManager={apiManager}
        />
        ,
        <RouteWithLayout
          component={FlowAiSuccessView}
          exact
          layout={MinimalLayout}
          path={`/${FlowAiGUID}/success`}
          apiManager={apiManager}
        />
        ,
        <RouteWithLayout
          component={FlowAiErrorView}
          exact
          layout={MinimalLayout}
          path={`/${FlowAiGUID}/error`}
          apiManager={apiManager}
        />
        ,{/*Hasbro screens*/}
        <RouteWithLayout
          component={HasbroCustomFormView}
          exact
          layout={MinimalLayout}
          path={`/${HasbroGUID}/custom-form`}
          apiManager={apiManager}
        />
        ,
        <RouteWithLayout
          component={HasbroErrorView}
          exact
          layout={MinimalLayout}
          path={`/${HasbroGUID}/error`}
          apiManager={apiManager}
        />
        ,{/*KHOROS screens*/}
        <RouteWithLayout
          component={KhorosSvButtonView}
          exact
          layout={MinimalLayout}
          path={`/${KhorosGUID}/sv-button`}
          apiManager={apiManager}
        />
        ,
        <RouteWithLayout
          component={KhorosLandingView}
          exact
          layout={MainLayout}
          path={`/${KhorosGUID}/landing`}
          apiManager={apiManager}
          screenConfig={khorosScreenConfigs.landing}
        />
        ,
        <RouteWithLayout
          component={KhorosSuccessView}
          exact
          layout={MainLayout}
          path={`/${KhorosGUID}/success`}
          apiManager={apiManager}
          screenConfig={khorosScreenConfigs.success}
        />
        ,
        <RouteWithLayout
          component={KhorosErrorView}
          exact
          layout={MainLayout}
          path={`/${KhorosGUID}/error`}
          apiManager={apiManager}
          screenConfig={khorosScreenConfigs.error}
        />
        ,
        <RouteWithLayout
          component={KhorosPrivacyView}
          exact
          layout={MinimalLayout}
          path={`/${KhorosGUID}/privacy`}
          apiManager={apiManager}
        />
        ,
        <RouteWithLayout
          component={KhorosTermsView}
          exact
          layout={MinimalLayout}
          path={`/${KhorosGUID}/terms`}
          apiManager={apiManager}
        />
        ,
        <RouteWithLayout
          component={KhorosNotFoundView}
          exact
          layout={MinimalLayout}
          path={`/${KhorosGUID}/not-found`}
          apiManager={apiManager}
        />
        ,{/*Rocks n' Ropes screens*/}
        <RouteWithLayout
          component={RocksnropesSvButtonView}
          exact
          layout={MinimalLayout}
          path={`/${RocksnropesGUID}/sv-button`}
          apiManager={apiManager}
        />
        ,
        <RouteWithLayout
          component={RocksnropesLandingView}
          exact
          layout={MinimalLayout}
          path={`/${RocksnropesGUID}/landing`}
          apiManager={apiManager}
          screenConfig={rocksnropesScreenConfigs.landing}
        />
        ,
        <RouteWithLayout
          component={RocksnropesSuccessView}
          exact
          layout={MinimalLayout}
          path={`/${RocksnropesGUID}/success`}
          apiManager={apiManager}
        />
        ,
        <RouteWithLayout
          component={RocksnropesErrorView}
          exact
          layout={MinimalLayout}
          path={`/${RocksnropesGUID}/error`}
          apiManager={apiManager}
        />
        ,{/* Sprint Pre Paid */}
        <RouteWithLayout
          component={SprintPrePaidComplianceButtonView}
          exact
          layout={MinimalLayout}
          path={`/${SPRINTPPD_GUID}/compliance-button`}
          apiManager={apiManager}
        />
        ,
        <RouteWithLayout
          component={SprintPrePaidLandingView}
          exact
          layout={SprintPpdLayout}
          path={`/${SPRINTPPD_GUID}/landing`}
          apiManager={apiManager}
          screenConfig={sprintPrePaidConfigs.header}
        />
        ,
        <RouteWithLayout
          component={SprintPrePaidSuccessView}
          exact
          layout={SprintPpdLayout}
          path={`/${SPRINTPPD_GUID}/success`}
          apiManager={apiManager}
          screenConfig={sprintPrePaidConfigs.header}
        />
        ,
        <RouteWithLayout
          component={SprintPrePaidErrorView}
          exact
          layout={SprintPpdLayout}
          path={`/${SPRINTPPD_GUID}/error`}
          apiManager={apiManager}
          screenConfig={sprintPrePaidConfigs.header}
        />
        ,{/* TalkTalk screens */}
        <RouteWithLayout
          component={TalkTalkSvButtonView}
          exact
          layout={MinimalLayout}
          path={`/${TALKTALK_GUID}/sv-button`}
          apiManager={apiManager}
        />
        ,
        <RouteWithLayout
          component={TalkTalkValidateView}
          exact
          layout={TalkTalkLayout}
          path={`/${TALKTALK_GUID}/validate`}
          apiManager={apiManager}
        />
        ,
        <RouteWithLayout
          component={TalkTalkLandingView}
          exact
          layout={TalkTalkLayout}
          path={`/${TALKTALK_GUID}/landing`}
          apiManager={apiManager}
        />
        ,
        <RouteWithLayout
          component={TalkTalkErrorView}
          exact
          layout={TalkTalkLayout}
          path={`/${TALKTALK_GUID}/error`}
          apiManager={apiManager}
        />
        ,{/*VIDEOTRON screens*/}
        <RouteWithLayout
          component={VideotronSvButtonView}
          exact
          layout={MinimalLayout}
          path={`/${VideotronGUID}/sv-button`}
          apiManager={apiManager}
        />
        ,
        <RouteWithLayout
          component={VideotronLandingView}
          exact
          layout={MinimalLayout}
          path={`/${VideotronGUID}/landing`}
          apiManager={apiManager}
          screenConfig={videotronScreenConfigs.header}
        />
        ,
        <RouteWithLayout
          component={VideotronSuccessView}
          exact
          layout={MinimalLayout}
          path={`/${VideotronGUID}/success`}
          apiManager={apiManager}
          screenConfig={videotronScreenConfigs.header}
        />
        ,
        <RouteWithLayout
          component={VideotronErrorView}
          exact
          layout={MinimalLayout}
          path={`/${VideotronGUID}/error`}
          apiManager={apiManager}
          screenConfig={videotronScreenConfigs.header}
        />
        ,{/*VODAFONE screens*/}
        <RouteWithLayout
          component={VodafoneSvButtonView}
          exact
          layout={MinimalLayout}
          path={`/${VodafoneGUID}/sv-button`}
          apiManager={apiManager}
        />
        ,
        <RouteWithLayout
          component={VodafoneLandingView}
          exact
          layout={VodafoneLayout}
          path={`/${VodafoneGUID}/landing`}
          apiManager={apiManager}
          screenConfig={vodafoneScreenConfigs.header}
        />
        ,
        <RouteWithLayout
          component={VodafoneSuccessView}
          exact
          layout={VodafoneLayout}
          path={`/${VodafoneGUID}/success`}
          apiManager={apiManager}
          screenConfig={vodafoneScreenConfigs.header}
        />
        ,
        <RouteWithLayout
          component={VodafoneErrorView}
          exact
          layout={VodafoneLayout}
          path={`/${VodafoneGUID}/error`}
          apiManager={apiManager}
          screenConfig={vodafoneScreenConfigs.header}
        />
        ,
        <RouteWithLayout
          component={fidelityLanding}
          exact
          layout={FidelityLayout}
          path={`/${FIDELITY_GUID}/landing`}
          apiManager={apiManager}
          screenConfig={fidelityScreenConfigs.header}
        />
        ,
        <RouteWithLayout
          component={fidelitySuccess}
          exact
          layout={FidelityLayout}
          path={`/${FIDELITY_GUID}/success`}
          apiManager={apiManager}
          screenConfig={fidelityScreenConfigs.header}
        />
        ,
        <RouteWithLayout
          component={fidelityError}
          exact
          layout={FidelityLayout}
          path={`/${FIDELITY_GUID}/error`}
          apiManager={apiManager}
          screenConfig={fidelityScreenConfigs.header}
        />
        ,
        <RouteWithLayout
          component={fidelityExpired}
          exact
          layout={FidelityLayout}
          path={`/${FIDELITY_GUID}/expired`}
          apiManager={apiManager}
          screenConfig={fidelityScreenConfigs.header}
        />
        ,
        <RouteWithLayout
          component={fidelityNotFound}
          exact
          layout={FidelityLayout}
          path={`/${FIDELITY_GUID}/not-found`}
          apiManager={apiManager}
          screenConfig={fidelityScreenConfigs.header}
        />
        ,{/* Quickbooks Screens */}
        <RouteWithLayout
          component={quickbooksLanding}
          exact
          layout={FidelityLayout}
          path={`/${QUICKBOOKS_GUID}/landing`}
          apiManager={apiManager}
          screenConfig={quickbooksScreenConfigs.header}
        />
        ,
        <RouteWithLayout
          component={quickbooksSuccess}
          exact
          layout={FidelityLayout}
          path={`/${QUICKBOOKS_GUID}/success`}
          apiManager={apiManager}
          screenConfig={quickbooksScreenConfigs.header}
        />
        ,
        <RouteWithLayout
          component={quickbooksError}
          exact
          layout={FidelityLayout}
          path={`/${QUICKBOOKS_GUID}/error`}
          apiManager={apiManager}
          screenConfig={quickbooksScreenConfigs.header}
        />
        ,
        <RouteWithLayout
          component={quickbooksExpired}
          exact
          layout={FidelityLayout}
          path={`/${QUICKBOOKS_GUID}/expired`}
          apiManager={apiManager}
          screenConfig={quickbooksScreenConfigs.header}
        />
        ,
        <RouteWithLayout
          component={quickbooksNotFound}
          exact
          layout={FidelityLayout}
          path={`/${QUICKBOOKS_GUID}/not-found`}
          apiManager={apiManager}
          screenConfig={quickbooksScreenConfigs.header}
        />
        ,{/* ManualC01 Screens */}
        <RouteWithLayout
          component={manualc01Landing}
          exact
          layout={Manualc01Layout}
          path={`/${MANUALC01_GUID}/landing`}
          apiManager={apiManager}
          screenConfig={manualc01ScreenConfigs.header}
        />
        ,
        <RouteWithLayout
          component={manualc01Success}
          exact
          layout={Manualc01Layout}
          path={`/${MANUALC01_GUID}/success`}
          apiManager={apiManager}
          screenConfig={manualc01ScreenConfigs.header}
        />
        ,
        <RouteWithLayout
          component={manualc01Error}
          exact
          layout={Manualc01Layout}
          path={`/${MANUALC01_GUID}/error`}
          apiManager={apiManager}
          screenConfig={manualc01ScreenConfigs.header}
        />
        ,
        <RouteWithLayout
          component={manualc01Expired}
          exact
          layout={Manualc01Layout}
          path={`/${MANUALC01_GUID}/expired`}
          apiManager={apiManager}
          screenConfig={manualc01ScreenConfigs.header}
        />
        ,
        <RouteWithLayout
          component={manualc01NotFound}
          exact
          layout={Manualc01Layout}
          path={`/${MANUALC01_GUID}/not-found`}
          apiManager={apiManager}
          screenConfig={manualc01ScreenConfigs.header}
        />
        ,
        <RouteWithLayout
          component={manualc02Landing}
          exact
          layout={Manualc01Layout}
          path={`/${MANUALC02_GUID}/landing`}
          apiManager={apiManager}
          screenConfig={manualc01ScreenConfigs.header}
        />
        ,
        <RouteWithLayout
          component={manualc02Success}
          exact
          layout={Manualc01Layout}
          path={`/${MANUALC02_GUID}/success`}
          apiManager={apiManager}
          screenConfig={manualc01ScreenConfigs.header}
        />
        ,
        <RouteWithLayout
          component={manualc02Error}
          exact
          layout={Manualc01Layout}
          path={`/${MANUALC02_GUID}/error`}
          apiManager={apiManager}
          screenConfig={manualc01ScreenConfigs.header}
        />
        ,
        <RouteWithLayout
          component={manualc02Expired}
          exact
          layout={Manualc01Layout}
          path={`/${MANUALC02_GUID}/expired`}
          apiManager={apiManager}
          screenConfig={manualc01ScreenConfigs.header}
        />
        ,
        <RouteWithLayout
          component={manualc02NotFound}
          exact
          layout={Manualc01Layout}
          path={`/${MANUALC02_GUID}/not-found`}
          apiManager={apiManager}
          screenConfig={manualc01ScreenConfigs.header}
        />
        ,
        <RouteWithLayout
          component={responsec01Landing}
          exact
          layout={Manualc01Layout}
          path={`/${RESPONSEC01_GUID}/landing`}
          apiManager={apiManager}
          screenConfig={manualc01ScreenConfigs.header}
        />
        ,
        <RouteWithLayout
          component={responsec01Success}
          exact
          layout={Manualc01Layout}
          path={`/${RESPONSEC01_GUID}/success`}
          apiManager={apiManager}
          screenConfig={manualc01ScreenConfigs.header}
        />
        ,
        <RouteWithLayout
          component={responsec01Error}
          exact
          layout={Manualc01Layout}
          path={`/${RESPONSEC01_GUID}/error`}
          apiManager={apiManager}
          screenConfig={manualc01ScreenConfigs.header}
        />
        ,
        <RouteWithLayout
          component={responsec01Expired}
          exact
          layout={Manualc01Layout}
          path={`/${RESPONSEC01_GUID}/expired`}
          apiManager={apiManager}
          screenConfig={manualc01ScreenConfigs.header}
        />
        ,
        <RouteWithLayout
          component={responsec01NotFound}
          exact
          layout={Manualc01Layout}
          path={`/${RESPONSEC01_GUID}/not-found`}
          apiManager={apiManager}
          screenConfig={manualc01ScreenConfigs.header}
        />
        ,
        <RouteWithLayout
          component={telusSuccessView}
          exact
          layout={TelusLayout}
          path={`/${telusGUID}/success`}
          apiManager={apiManager}
          screenConfig={telusScreenConfigs.header}
        />
        ,
        <RouteWithLayout
          component={telusLandingView}
          exact
          layout={TelusLayout}
          path={`/${telusGUID}/landing`}
          apiManager={apiManager}
          screenConfig={telusScreenConfigs.header}
        />
        ,
        <RouteWithLayout
          component={telusErrorView}
          exact
          layout={TelusLayout}
          path={`/${telusGUID}/error`}
          apiManager={apiManager}
          screenConfig={telusScreenConfigs.header}
        />
        ,
        <RouteWithLayout
          component={telusSvButtonView}
          exact
          layout={MinimalLayout}
          path={`/${telusGUID}/sv-button`}
          apiManager={apiManager}
          screenConfig={telusScreenConfigs.header}
        />
        ,{' '}
        <RouteWithLayout
          component={KoodoSuccessView}
          exact
          layout={KoodoLayout}
          path={`/${KoodoGUID}/success`}
          apiManager={apiManager}
          screenConfig={koodoScreenConfigs.header}
        />
        ,
        <RouteWithLayout
          component={KoodoLandingView}
          exact
          layout={KoodoLayout}
          path={`/${KoodoGUID}/landing`}
          apiManager={apiManager}
          screenConfig={koodoScreenConfigs.header}
        />
        ,
        <RouteWithLayout
          component={KoodoErrorView}
          exact
          layout={KoodoLayout}
          path={`/${KoodoGUID}/error`}
          apiManager={apiManager}
          screenConfig={koodoScreenConfigs.header}
        />
        , ,{' '}
        <RouteWithLayout
          component={DominionSuccessView}
          exact
          layout={MainLayout}
          path={`/${DominionGUID}/success`}
          apiManager={apiManager}
          screenConfig={dominionScreenConfigs.header}
        />
        ,
        <RouteWithLayout
          component={DominionLandingView}
          exact
          layout={MainLayout}
          path={`/${DominionGUID}/landing`}
          apiManager={apiManager}
          screenConfig={dominionScreenConfigs.header}
        />
        ,
        <RouteWithLayout
          component={DominionErrorView}
          exact
          layout={MainLayout}
          path={`/${DominionGUID}/error`}
          apiManager={apiManager}
          screenConfig={dominionScreenConfigs.header}
        />
        ,
        <RouteWithLayout
          component={DominionExpiredView}
          exact
          layout={MainLayout}
          path={`/${DominionGUID}/expired`}
          apiManager={apiManager}
          screenConfig={dominionScreenConfigs.header}
        />
        ,
        <RouteWithLayout
          component={DominionDataFormView}
          exact
          layout={MainLayout}
          path={`/${DominionGUID}/form`}
          apiManager={apiManager}
          screenConfig={dominionScreenConfigs.header}
        />
        ,
        <RouteWithLayout
          component={DominionOfflineView}
          exact
          layout={MainLayout}
          path={`/${DominionGUID}/offline`}
          apiManager={apiManager}
          screenConfig={dominionScreenConfigs.header}
        />
        ,
      </Switch>
    );
  }
}

Routes.propTypes = {
  user: PropTypes.object,
};

export default Routes;
