import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/styles';
import Card from '@material-ui/core/Card'; 
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions'; 
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';  
import PropTypes from 'prop-types';
import CommonText from "../CommonText";
import { red } from '@material-ui/core/colors';
import Link from '@material-ui/core/Link';

const defaultState = { 
  first: '',
  fourth: ''
};

const useStyles = makeStyles((theme) => ({
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: red[500],
  },
  formControl: {
    margin: '20px 0px 0px',
    minWidth: '100%',
    textAlign: 'left'
  },
  next: {
    position: 'relative',
    left: '85%'
  }
}));

export default function VerifyPassword({ handleSuccess, handleError, onPoAForgotPassword, updateParentState }) {
  const classes = useStyles();
  const [first, setFirst] = useState('')
  const [fourth, setFourth] = useState('')

  const validate = () => {
    return first.length === 0 || fourth.length === 0;
  }

  const onForgotPassword = (event) => {
    onPoAForgotPassword(event);
  }

  const handleNext = async (event) => {
    if(validate()){
      handleError('You must fill out all items in the form!');
    } else {
      updateParentState('verifyPassword', {first, fourth});
      handleSuccess(event);
    }
  }

  const handleChange = (event) => {
    switch (event.target.name) {
      case 'first':
        setFirst(event.target.value);
        break;
      case 'fourth':
        setFourth(event.target.value);
        break;
    }
  }

  return (
    <Card className={classes.card}>  
      <CardContent> 
      <CommonText />
        <TextField 
            margin="normal"
            required
            fullWidth
            id="first"
            label="First character"
            name="first" 
            autoFocus
            inputProps={{ maxLength: 1 }}
            onChange={handleChange}
          />
          <TextField 
            margin="normal"
            required
            fullWidth
            id="fourth"
            label="Fourth character"
            name="fourth"
            inputProps={{ maxLength: 1 }}
            onChange={handleChange}
          /> 
          <Link
            className={classes.links}
            onClick={onForgotPassword}
          >
             I don't know my password
            </Link>
      </CardContent>
      <CardActions disableSpacing>
       <Button
        className={classes.next} 
        onClick={handleNext}
        color="primary" 
        >Next</Button>
      </CardActions> 
    </Card>
  );
} 