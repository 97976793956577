import React from 'react';
import PropTypes from 'prop-types';
import boostMobileLogo from './boostMobileLogo.png'

const BoostMobileLogo = ({ className }) => (
    <img src={boostMobileLogo} alt={'Boost Mobile Logo'} width={60} height={60} />
);

BoostMobileLogo.propTypes = {
    className: PropTypes.string
};


export default BoostMobileLogo;