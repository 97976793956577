import React, { useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import _ from 'lodash';
import { makeStyles } from '@material-ui/styles';
import { Grid, Typography, FormControl, Button, CircularProgress } from '@material-ui/core';
import LockImage from './lockImage.png';
import moment from 'moment';

import { API_LOCAL, API_LAMBDA, IS_LOCAL_DEV, QUICKBOOKS_GUID, PHASE } from '../../../../constants';

import './fonts/style.css';

import zendeskHelper from '../../../../helpers/zendeskHelper';
import TextFieldInput from '../../../../components/Inputs/TextFieldInput';

const API = IS_LOCAL_DEV ? API_LOCAL : API_LAMBDA;

const ErrorPath = `/${QUICKBOOKS_GUID}/error`;
const ExpiredPath = `/${QUICKBOOKS_GUID}/expired`;
const SuccessPath = `/${QUICKBOOKS_GUID}/success`;
const mainColor = '#2CA01C'; //quickbooks color
const delimiter = ',';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    flexGrow: 1,
    padding: '0 20',
    // margin: '0 auto',
    marginTop: '4rem',
  },
  content: {
    marginBottom: '3em'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  requiredLabel: {
    color: 'rgba(0, 0, 0, 0.54)',
    margin: '0 0 0 8px',
  },
  title: {
    flexGrow: 1,
    fontSize: '2.33em',
    fontFamily: 'Helvetica !important',
    lineHeight: 1.25,
    fontWeight: 'bold',
  },
  description: {
    lineHeight: 1.55,
    fontFamily: 'Helvetica !important',
    margin: '0.5em 0',
  },
  textSubmitted: {
    lineHeight: 1.55,
    fontFamily: 'Helvetica !important',
    margin: '0.5em 0',
    '&:hover': {
      cursor: "pointer"
    }
  },
  linkSubmitted: {
    color: '#337ab7 !important'
  },
  button: {
    textAlign: 'center',
    letterSpacing: '0.04em',
    fontWeight: 'bold',
    color: '#FFF !important',
    backgroundColor: mainColor + ' !important',
    '&:hover': {
      opacity: '0.7'
    },
    '&:disabled': {
      opacity: '0.5'
    }
  },
  customForm: {
    marginTop: "20px !important",
    marginBottom: "20px !important",
    paddingTop: 20,
    border: '1px solid #e2e2e2',
    margin: '0 auto'
  },
  extraFieldsLink: {
    marginTop: '1em',
    cursor: 'pointer',
    color: '#333333',
    textDecoration: 'underline',
    '&:hover': {
      color: '#000000'
    }
  },
  progressWrapper: {
    paddingBottom: '1rem',
    color: '#333333',
    textAlign: "center"
  },
  progress: {
    margin: 'auto',
    color: '#333333'
  },
  buttonProgress: {
    color: '#333333'
  }
}));

const Landing = (props) => {
  const classes = useStyles();

  const { apiManager, history, screenConfig } = props;
  const intl = useIntl();

  const [init, setInit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [customer, setCustomer] = useState('');
  const [payload, setPayload] = useState('');
  const [verified, setVerified] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const [payloadConversationId, setPayloadConversationId] = useState('');
  const [authorEmail, setAuthorEmail] = useState('');
  const [authorTokenChatId, setAuthorTokenChatId] = useState('');

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [companyId, setCompanyId] = useState('');

  const [errors, setErrors] = useState({});
  const [token, setToken] = useState('');

  const validations = {
    firstName: verified ? [] : ['isRequired'],
    lastName: verified ? [] : ['isRequired'],
    email: ['isEmail', 'isRequired'],
    phone: verified ? [] : ['isPhone', 'isRequired'],
    companyName: verified ? [] : ['isRequired'],
    companyId: verified ? [] : []
  };

  const handleChange = (callback, event) => {
    if (event) {
      callback(event.target.value);
    }
  };

  const handleErrors = (type, errorMsg) => {
    let formErrors = _.cloneDeep(errors);
    if (type) {
      if (errorMsg) {
        formErrors[type] = errorMsg;
      }
      else {
        delete formErrors[type];
      }
      setErrors(formErrors);
    }
  };

  const validateForm = async (inputValues = {}) => {
    let formValidationsResponse = {};
    const requiredLabel = intl.formatMessage({ id: 'validations.required' })
    const aditionalInfoLabel = intl.formatMessage({ id: 'validations.aditionalInfo' })

    let inputFields = Object.keys(validations);

    inputFields.forEach(field => {
      let value = inputValues[field];
      let validationResponse = '';

      validations[field].find(validation => {
        switch (validation) {
          case 'isEmail':
            validationResponse = zendeskHelper.validEmail(value);
            validationResponse = !validationResponse ? (!value || value.length == 0 ? requiredLabel : null) : validationResponse;
            break;
          case 'isPhone':
            validationResponse = zendeskHelper.validPhone(value);
            break;
          case 'isRequired':
            validationResponse = !value || value.length === 0 ? requiredLabel : null;
            break;
          default:
            break;
        };

        return !!validationResponse;

      });

      if (validationResponse) {
        formValidationsResponse[field] = validationResponse;
      }
    });

    return {
      errors: formValidationsResponse
    };
  }

  const onSubmit = async event => {
    event.preventDefault();
    setLoading(true);

    const formErrors = await validateForm(verified ? { email } : { firstName, lastName, email, phone, companyName, companyId });

    if (Object.keys(formErrors.errors).length > 0) {
      setLoading(false);
      setErrors(formErrors.errors);
    }
    else {

      const payload = {
        svFirstName: firstName,
        svLastName: lastName,
        svEmail: email,
        svPhone: phone,
        svCompanyName: companyName,
        svCompanyId: companyId,
        verified
      };

      const response = await apiManager.update(payload, token, null, 'quickbooks');

      if (response && response.token) {
        localStorage.setItem('form-submitted', 'true');
        let conversationsSubmitted = localStorage.getItem('conversations-submitted') && localStorage.getItem('conversations-submitted').split(delimiter);
        if (conversationsSubmitted && conversationsSubmitted.length > 0) {
          if (conversationsSubmitted.indexOf(payloadConversationId) <= -1) {
            localStorage.setItem('conversations-submitted', conversationsSubmitted.concat(payloadConversationId));
          }
        }
        else {
          localStorage.setItem('conversations-submitted', payloadConversationId)
        }
        history.push(`${SuccessPath}?token=${response.token}`)
      }
      else {
        history.push(ErrorPath)
      }

      setLoading(false);
    }
  }

  useEffect(() => {

    if (window.Brandmessenger) {
      window.Brandmessenger.destroy();
    }

    const urlParams = new URLSearchParams(window.location.search);
    let payloadValue = urlParams.get('payload');
    let customerValue = 'quickbooks';

    if (!payloadValue && IS_LOCAL_DEV) {
      setPayload('TPMSrhNYuMxL-Zf6akp8mzFKvIWtUgmXXnZXfeOHKFEcP9rISNNMgLclB_1diY3kX5mRB9yS_OEx5jEpHI2dodrKHQrAYajswM9SLAZ8IPs5o0UhJUb9j159MStIibLflc_eft9COGO-lFI6HHwao5x0FeQv1paWWvCVs3Tdn3s8VBgMnV9Eengmo88RDzVR070gtfYu5Ui-O8_ggSHpgXN1pKZKheG-2a-_wxsuBw_Ib_pE2QIKwkSnB5vZJ-hMXDfMsNBULshYwwIP4x3klt-IQWwrh1JwJm2L__z7CDVoZYF8V5ywjTD7kgYAkKmnIIjkWC2LUtSmk9onsuSQ8fnE8Ob-w6_f4mdCtc4_xv3yEjFVsd1aLMK4_w1jCdIGSdM_zofWbPs6SbuNKgZNSwOXFvsRUGPVOxuE3RU3G0anD9IMb-Jul19q2FBoJPF_1K0eQMOzguxfItqPYvnvOOgKNm1jOHhFjEDRqfbN_JlopegQRFsQ44K1MDrlP8ZJ-4gpxu4npf0Tc_ooAnEpXEp8A7Vk9DYE4Y2PFAGUnY7z-wfXYXte3XlbEsQRwiauMyGLl98STM9x7k8wmytbwy0Zu3v0hDAFose1XZS4WC_NnllpbQ-q5Q2ClIu11RD5xaJP-bcvO6NihIXvfAvCDKHvbyULL0GQPqCU_I5Tj4X0hnefj0teSZYVdnhxAH91vD7lVXyazYmtSzXrmBnz8PytvAhMiSdhUtjaCdbB1M1KHbVgWSs5hFj4M4gP87GHX5aDWAWuFbNUmXxP78tFqUujmDY-MOhjisYjrZ4wwLe8fJqeHeW0l14u8MDp9th7VEJE0G4RhzKCZDcTIDeCChqiFCrrBDO2KVcr--PDNcAbElCWjXMYsWHODYqNmAXiXd6ViVc7yAJBaacV_2AcW0KHl3XeoK2wASX8KT1Ubdv9abTZIepaXItiXXcgVJ2b');
      payloadValue = 'TPMSrhNYuMxL-Zf6akp8mzFKvIWtUgmXXnZXfeOHKFEcP9rISNNMgLclB_1diY3kX5mRB9yS_OEx5jEpHI2dodrKHQrAYajswM9SLAZ8IPs5o0UhJUb9j159MStIibLflc_eft9COGO-lFI6HHwao5x0FeQv1paWWvCVs3Tdn3s8VBgMnV9Eengmo88RDzVR070gtfYu5Ui-O8_ggSHpgXN1pKZKheG-2a-_wxsuBw_Ib_pE2QIKwkSnB5vZJ-hMXDfMsNBULshYwwIP4x3klt-IQWwrh1JwJm2L__z7CDVoZYF8V5ywjTD7kgYAkKmnIIjkWC2LUtSmk9onsuSQ8fnE8Ob-w6_f4mdCtc4_xv3yEjFVsd1aLMK4_w1jCdIGSdM_zofWbPs6SbuNKgZNSwOXFvsRUGPVOxuE3RU3G0anD9IMb-Jul19q2FBoJPF_1K0eQMOzguxfItqPYvnvOOgKNm1jOHhFjEDRqfbN_JlopegQRFsQ44K1MDrlP8ZJ-4gpxu4npf0Tc_ooAnEpXEp8A7Vk9DYE4Y2PFAGUnY7z-wfXYXte3XlbEsQRwiauMyGLl98STM9x7k8wmytbwy0Zu3v0hDAFose1XZS4WC_NnllpbQ-q5Q2ClIu11RD5xaJP-bcvO6NihIXvfAvCDKHvbyULL0GQPqCU_I5Tj4X0hnefj0teSZYVdnhxAH91vD7lVXyazYmtSzXrmBnz8PytvAhMiSdhUtjaCdbB1M1KHbVgWSs5hFj4M4gP87GHX5aDWAWuFbNUmXxP78tFqUujmDY-MOhjisYjrZ4wwLe8fJqeHeW0l14u8MDp9th7VEJE0G4RhzKCZDcTIDeCChqiFCrrBDO2KVcr--PDNcAbElCWjXMYsWHODYqNmAXiXd6ViVc7yAJBaacV_2AcW0KHl3XeoK2wASX8KT1Ubdv9abTZIepaXItiXXcgVJ2b';

    }

    if (payloadValue) {
      setPayload(payloadValue);
      setCustomer(customerValue);

      if (!init) {
        const pageTitle = intl.formatMessage({ id: 'quickbooks.landing.pageTitle' });
        if (document.title !== pageTitle) {
          document.title = pageTitle;
        }
        getJwt(payloadValue, customerValue);
      }
    }
    else {
      history.push(ErrorPath)
    }
  }, []);

  const getJwt = async (data, customer) => {
    const params = history.location.search
      .slice(1)
      .split('&')
      .reduce((acc, param) => {
        const [key, value] = param.split('=');
        return Object.assign(acc, { [key]: value });
      }, {});
    const { payload, iv } = params;
    const queryToken = params.token ? params.token : false;

    if (IS_LOCAL_DEV & (!payload || !iv)) {
      // twitter example
      payload = 'TPMSrhNYuMxL-Zf6akp8mzFKvIWtUgmXXnZXfeOHKFEcP9rISNNMgLclB_1diY3kX5mRB9yS_OEx5jEpHI2dodrKHQrAYajswM9SLAZ8IPs5o0UhJUb9j159MStIibLflc_eft9COGO-lFI6HHwao5x0FeQv1paWWvCVs3Tdn3s8VBgMnV9Eengmo88RDzVR070gtfYu5Ui-O8_ggSHpgXN1pKZKheG-2a-_wxsuBw_Ib_pE2QIKwkSnB5vZJ-hMXDfMsNBULshYwwIP4x3klt-IQWwrh1JwJm2L__z7CDVoZYF8V5ywjTD7kgYAkKmnIIjkWC2LUtSmk9onsuSQ8fnE8Ob-w6_f4mdCtc4_xv3yEjFVsd1aLMK4_w1jCdIGSdM_zofWbPs6SbuNKgZNSwOXFvsRUGPVOxuE3RU3G0anD9IMb-Jul19q2FBoJPF_1K0eQMOzguxfItqPYvnvOOgKNm1jOHhFjEDRqfbN_JlopegQRFsQ44K1MDrlP8ZJ-4gpxu4npf0Tc_ooAnEpXEp8A7Vk9DYE4Y2PFAGUnY7z-wfXYXte3XlbEsQRwiauMyGLl98STM9x7k8wmytbwy0Zu3v0hDAFose1XZS4WC_NnllpbQ-q5Q2ClIu11RD5xaJP-bcvO6NihIXvfAvCDKHvbyULL0GQPqCU_I5Tj4X0hnefj0teSZYVdnhxAH91vD7lVXyazYmtSzXrmBnz8PytvAhMiSdhUtjaCdbB1M1KHbVgWSs5hFj4M4gP87GHX5aDWAWuFbNUmXxP78tFqUujmDY-MOhjisYjrZ4wwLe8fJqeHeW0l14u8MDp9th7VEJE0G4RhzKCZDcTIDeCChqiFCrrBDO2KVcr--PDNcAbElCWjXMYsWHODYqNmAXiXd6ViVc7yAJBaacV_2AcW0KHl3XeoK2wASX8KT1Ubdv9abTZIepaXItiXXcgVJ2b';
      iv = 'rQim1wH7e9HHkCIpIeVNqQ%3D%3D'
    }

    if (!payload && !queryToken) {
      // error
      history.push(ErrorPath);
    } else if (payload && !queryToken) {
      const data = await apiManager.init(payload, `${customer}-${PHASE}`, iv);

      if (data) {
        const linkExpiration = data.linkExpiration;
        const { expiresIn = 48, scale = "hours" } = linkExpiration;
        const currentMoment = moment();
        const current = moment().valueOf();
        const payloadTime = data.decryptedPayload.createdTimeMillis;
        console.log('times', payloadTime, current);
        const payloadMoment = moment(payloadTime);
        // Getting time difference between current time and payload time
        const duration = moment.duration(currentMoment.diff(payloadMoment));
        let linkTimeDiff = 0;

        switch (scale.toLowerCase()) {
          case 'days':
          case 'day':
            linkTimeDiff = duration.asDays();
            break;
          case 'hours':
          case 'hour':
            linkTimeDiff = duration.asHours();
            break;
          case 'minutes':
          case 'minute':
            linkTimeDiff = duration.asMinutes();
            break;
        }

        console.log('time difference', linkTimeDiff);

        if (linkTimeDiff >= +expiresIn) {
          history.push(ExpiredPath);
        } else {
          sessionStorage.setItem('payload', payload);
          sessionStorage.setItem('token', data.jwt);
          sessionStorage.setItem('network', data.sso.network);
          sessionStorage.setItem('client', 'quickbooks');

          setToken(data.jwt);

          const { authorUUID, conversationId, conversationDisplayId } = data.decryptedPayload
          let displayId = conversationId || conversationDisplayId;
          displayId = displayId.toString();

          const author = await apiManager.getAuthor(
            authorUUID
          );
          console.log('author', author);
          let verified = false;

          if (author && author[0].handles.crm) {
            author[0].handles.crm.forEach((author) => {
              const submittedTime = author.msDate && author.msDate[0];
              const submittedMoment = moment(+submittedTime);
              // Getting time difference between current time and author submitted time
              const duration = moment.duration(currentMoment.diff(submittedMoment));
              let linkTimeDiff = 0;

              switch (scale.toLowerCase()) {
                case 'days':
                case 'day':
                  linkTimeDiff = duration.asDays();
                  break;
                case 'hours':
                case 'hour':
                  linkTimeDiff = duration.asHours();
                  break;
                case 'minutes':
                case 'minute':
                  linkTimeDiff = duration.asMinutes();
                  break;
              }

              if (
                author.networkinstance === 'socialVerification' &&
                author.svEmail &&
                author.svEmail[0] &&
                author.tokenChatId && author.tokenChatId[0] &&
                (!author.smWipe || !author.smWipe[0]) &&
                author.conversationId && author.conversationId[0] === displayId
              ) {
                verified = true;
                setAuthorEmail(author.svEmail[0]);
                setAuthorTokenChatId(author.tokenChatId[0]);
              }
            });
          }

          let conversationsSubmitted = localStorage.getItem('conversations-submitted') && localStorage.getItem('conversations-submitted').split(delimiter);
          if (!verified && conversationsSubmitted && conversationsSubmitted.length > 0 && conversationsSubmitted.indexOf(displayId) > -1) {
            localStorage.setItem('conversations-submitted', conversationsSubmitted.filter(c => c !== displayId));
          }
          setVerified(verified);
          setPayloadConversationId(displayId);
          setIsSubmitted(localStorage.getItem('form-submitted') === 'true' && conversationsSubmitted && conversationsSubmitted.length > 0 && conversationsSubmitted.indexOf(displayId) > -1 && verified);

          setInit(true);

        }
      } else {
        history.push(ErrorPath);
      }
    }
  };

  let disabledSubmit = loading || (errors && Object.keys(errors).length > 0);

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <Grid
          container
        >
          {!init && (
            <Grid
              className={classes.progressWrapper}
              item
              lg={12}
              xs={12}
            >
              <Typography variant="h6" className={classes.description}>
                {<FormattedMessage id='quickbooks.socialVerificationForm.loading' />}
              </Typography>
              <CircularProgress disableShrink className={classes.progress} />
            </Grid>
          )}
        </Grid>
        {init && isSubmitted &&
          <Grid
            container
          >
            <Grid
              className={classes.progressWrapper}
              item
              lg={12}
              xs={12}
            >
              <Typography variant="h6" className={classes.description}>
                <FormattedMessage id='quickbooks.socialVerificationForm.submittedAndVerified' />
              </Typography>
              <Typography variant="h6" className={classes.textSubmitted}>
                <a className={classes.linkSubmitted} onClick={() => history.push(`${SuccessPath}?token=${authorTokenChatId}`)} >
                  <FormattedMessage id='quickbooks.socialVerificationForm.linkSubmittedAndVerified' />
                </a>
              </Typography>
            </Grid>
          </Grid>
        }
        {init && !isSubmitted && verified &&
          <Grid
            container
            item
            lg={12}
            xs={12}
            justify="center"
            alignItems="center"
          >
            <Grid
              className={classes.progressWrapper}
              item
              lg={6}
              xs={11}
            >
              <Typography variant="h6" className={classes.description}>
                <FormattedMessage id='quickbooks.socialVerificationForm.verified' />
              </Typography>
            </Grid>
          </Grid>
        }
        {init && !verified &&
          <Grid
            container
            item
            lg={12}
            xs={12}
            justify="center"
            alignItems="center"
          >
            <Grid
              className={classes.progressWrapper}
              item
              lg={6}
              xs={11}
            >
              <Typography variant="h6" className={classes.description}>
                <FormattedMessage id='quickbooks.socialVerificationForm.description' />
              </Typography>
            </Grid>
          </Grid>
        }
        {init && !isSubmitted && <Grid
          container
          item
          lg={4}
          xs={11}
          spacing={4}
          justify="left"
          className={classes.customForm}
        >
          <Grid
            item
            container
            spacing={4}
            justify="left"
          >
            {!verified && [
              <TextFieldInput
                label={intl.formatMessage({ id: 'socialVerificationForm.firstName' })}
                type='firstName'
                variant={'standard'}
                dataItem={firstName} dataAction={setFirstName}
                validations={validations.firstName} isRequired fieldError={errors.firstName} handleErrors={handleErrors}
              />
              ,
              <TextFieldInput
                label={intl.formatMessage({ id: 'socialVerificationForm.lastName' })}
                type='lastName'
                variant={'standard'}
                dataItem={lastName} dataAction={setLastName}
                validations={validations.lastName} isRequired fieldError={errors.lastName} handleErrors={handleErrors}
              />
            ]}

            <TextFieldInput
              label={intl.formatMessage({ id: 'socialVerificationForm.email' })}
              type='email'
              variant={'standard'}
              dataItem={email} dataAction={setEmail}
              validations={validations.email} isRequired fieldError={errors.email} handleErrors={handleErrors}
            />

            {!verified && [
              <TextFieldInput
                label={intl.formatMessage({ id: 'quickbooks.socialVerificationForm.phone' })}
                type='phone'
                variant={'standard'}
                dataItem={phone} dataAction={setPhone}
                validations={validations.phone} isRequired fieldError={errors.phone} handleErrors={handleErrors}
                isNumericOnly={true}
                inputProps={{
                  maxLength: 10
                }}
              />
              ,
              <TextFieldInput
                label={intl.formatMessage({ id: 'quickbooks.socialVerificationForm.companyName' })}
                type='companyName'
                variant={'standard'}
                dataItem={companyName} dataAction={setCompanyName}
                validations={validations.companyName} isRequired fieldError={errors.companyName} handleErrors={handleErrors}
              />
              ,
              <TextFieldInput
                label={intl.formatMessage({ id: 'quickbooks.socialVerificationForm.companyId' })}
                type='companyId'
                variant={'standard'}
                dataItem={companyId} dataAction={setCompanyId}
                validations={validations.companyId} fieldError={errors.companyId} handleErrors={handleErrors}
              />
            ]}

          </Grid>
          <Grid
            item
            container
            justify="space-around"
            alignItems="center"
          >
            <Grid
              item
              lg={12}
              xs={12}>
              <label className={classes.requiredLabel}><span> * </span> <FormattedMessage id='socialVerificationForm.required' /></label>
            </Grid>
            <FormControl className={classes.formControl}>
              <Button variant="contained" className={classes.button}
                style={disabledSubmit ? {} : {
                  color: '#000',
                  backgroundColor: mainColor
                }}
                onClick={onSubmit} disabled={disabledSubmit}
              >
                <FormattedMessage id='socialVerificationForm.submit' />
              </Button>
              {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
            </FormControl>
          </Grid>
        </Grid>}
      </div>
    </div >
  );
};

export default Landing;
