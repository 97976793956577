import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Typography, Link } from '@material-ui/core';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'inline-block',
    color: '#000',
    fontSize: '100%',
    fontWeight: '400',
    fontFamily: 'rutledge-regular',
    lineHeight: 1.5,
    textAlign: 'center',
    margin: 0
  },
  customHeader: {
    fontSize: '14px',
    lineHeight: '1.42857143',
    fontFamily: 'Cereal,Helvetica Neue,Helvetica,Arial,sans-serif',
    boxSizing: 'border-box',
    color: '#394348',
    background: '#fff'
  },
  h1: {
    fontFamily: 'inherit',
    fontWeight: 500,
    color: 'inherit',
    fontSize: '36px',
    lineHeight: '1.1',
    margin: 0
  },
  nav: {
    display: 'block'
  },
  listItems: {
    display: 'inline-block',
    listStyle: 'none',
    paddingLeft: '40px'
  },
  links: {
    lineHeight: '1.42857143',
    color: '#394348',
    fontFamily: 'Omnes,sans-serif',
    fontSize: '20px',
    fontWeight: 200,
    boxSizing: 'border-box',
    margin: 0,
    padding: 0
  },
  wrapper: {
    height: '800px'
  }
}));

const Header = props => {
  const { className, ...rest } = props;

  const classes = useStyles();

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
      style={{width: '100%'}}
    >
        <div  className={classes.customHeader}
          style={{margin: '3rem auto'}}>
            <div>
                <h1 className={classes.h1}><a className={classes.links} href="https://www.talktalk.co.uk/" target="_blank">Talk Talk For Everyone</a></h1>
                <nav className={classes.nav}>
                    <ul className={classes.list}>
                        <li className={classes.listItems}><a className={classes.links} href="https://sales.talktalk.co.uk/" target="_blank">TV, Broadband &amp; Calls</a></li>
                        <li className={classes.listItems}><a className={classes.links} href="https://www.talktalk.co.uk/?do=logout" target="_blank">News &amp; TV Guide</a></li>
                        <li className={classes.listItems}><a className={classes.links} href="https://myaccount.talktalk.co.uk/" target="_blank">My Account</a></li>
                        <li className={classes.listItems}><a className={classes.links} href="https://community.talktalk.co.uk/" target="_blank">Help &amp; Support</a></li>
                        <li className={classes.listItems}><a className={classes.links} href="https://www.talktalk.co.uk/mail/" target="_blank">Mail</a></li>
                    </ul>
                </nav>
            </div>
        </div>
    </div>
  );
};

Header.propTypes = {
  className: PropTypes.string
};

export default Header;

