
const zendeskHelper = {
    getContactBody: async (data) => {
        const { country, email, phone, authorName } = data;

        let contact = {};
        if (authorName) {
            let firstName = authorName;
            let lastName = "";
            const firstIndex = authorName.indexOf(" "); 
                
            if(firstIndex > -1){
                firstName = authorName.substring(0,firstIndex)
                lastName = authorName.substring(firstIndex).trim()
            }
            else {
                firstName = authorName;
                lastName = "";
            }  

            contact.name = {
                first: firstName
            };
            if(lastName && lastName.length > 0){
                contact.name.last = lastName;
            }
        }
        if (country) {
            contact.address = {
                country: {
                    id: country
                }
            };
        }
        if (email) {
            contact.emails = [
                {
                    address: email,
                    addressType: {
                        id: 0
                    }
                }
            ];
        }
        if (phone) {
            contact.phones = [
                {
                    number: phone,
                    phoneType: {
                        id: 4
                    }
                }
            ];
        }
        console.log(contact)
        return contact;
    },
    getIncidentBody: function (data) {

        const { subject, selectedCategory, contact, selectedChannel, selectedStatus, agentLookup, notes } = data;

        let incident = {};

        if (subject != null) {
            incident.subject = subject;
        }

        if (selectedCategory != null) {
            incident.category = {
                id: selectedCategory
            };
        }

        if (contact && contact.id) {
            incident.primaryContact = {
                id: contact.id
            };
        }

        /*
        if (selectedChannel) {
            incident.channel = {
                id: selectedChannel
            };
        }
        */

        //if (selectedStatus) {
            incident.statusWithType = {
                "status": {
                    id: 1
                }
            };
        //}

        if (agentLookup && agentLookup.id) {
            incident.assignedTo = {
                "account": {
                    "lookupName": agentLookup.id
                }
            };
        }

        incident.customFields = {
            c: {
                channel_handling: {
                    id: 412
                }
            }
        }

        if (notes) {
            incident.threads = {
                text: notes,
                entryType: {
                    id: 1
                }
            }
        }

        return incident;
    },
    getCareAuthorProfile: function (data) {
        const { id, contactId, incidentNumbers, country, channel, category, email, phone, subject } = data;
        let optionalInputs = {};

        if (channel) {
            optionalInputs.chan_id = channel + '';
        }
        if (category) {
            optionalInputs.cat_id = category + '';
        }
        if (country) {
            optionalInputs.country_id = country + '';
        }
        if (email) {
            optionalInputs.email = email;
        }
        if (phone) {
            optionalInputs.phone = phone;
        }
        if (subject) {
            optionalInputs.subject = subject;
        }

        return {
            "handles": {
                "crm": [
                    {
                        "networkinstance": "OracleCRM",
                        "id": "Ticket: " + id,
                        "contact_id": contactId + '',
                        "incident_numbers": incidentNumbers,
                        ...optionalInputs
                    }
                ]
            }
        };

    },
    validPhone: function (phone) {
        let isValid = phone.length === 0 || phone.length === 10;

        return isValid ? '' : 'Please include 10 digit phone number';
    },
    validEmail: function (email) {
        let regexEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        let isValid = email.length === 0 || regexEmail.test(String(email).toLowerCase());

        return isValid ? '' : 'It is not a valid email';
    }

}


export default zendeskHelper;
