import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import { Card, TextField, Select, MenuItem, Button, CardContent, CardActions } from '@material-ui/core';
import { TALKTALK_GUID, PHASE } from '../../../../constants';


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
   
  },
  formControl: {
    margin: '20px 0px 0px',
    minWidth: '100%',
    textAlign: 'left'
  },
  next: {
    position: 'relative',
    left: '85%'
  }
}));

export default function Validate({ history, apiManager }) {
  const classes = useStyles();
 
  const [payload, setPayload] = useState(false)
  const [init, setInit] = useState(false)
  const [validate, setValidate] = useState('/validate')
  const [errorpage, setErrorPage] = useState('/error')

  const isExpired = async (payload) => {
      const isExpired = await apiManager.talktalkCheckIfLinkExpired(payload);
      return isExpired == true; 
    }
  
  const getJwt = async (payload) => { 
    const queryToken = window.location.search.indexOf("token") > -1 ? window.location.search.replace("?token=", "") : false;
    const queryPayload = window.location.search.indexOf("payload") > -1 ? window.location.search.replace("?payload=", "") : false;
    
    if (!queryPayload && !queryToken) {
      // error
      console.log('no query payload and no query token');
      history.push(`/${TALKTALK_GUID}${errorpage}`);
    }
    else if (queryPayload && !queryToken) {
      const data = await apiManager.init(payload, `sv-talktalk-${PHASE}`);
      console.log(data);
      
      if (!data || !data.jwt) {
        console.log('data is not defined');
        history.push(`/${TALKTALK_GUID}${errorpage}`)
      }
      else {
        sessionStorage.setItem("token", data.jwt);
        sessionStorage.setItem("queryPayload", queryPayload);
        history.push(`/${TALKTALK_GUID}${validate}`);
      }
    } 
    else {
        history.push(`/${TALKTALK_GUID}${errorpage}`)
    }
  }

  const processPayload = async() => {
    let payload = '';
      if (window.location.search.indexOf("?payload=") > -1) {
          payload = window.location.search.replace("?payload=", "");
          setPayload(payload);
          console.log(payload);
        }
      
        await getJwt(payload);
        const expired = await isExpired(payload);
        if(expired){
            history.push(`/${TALKTALK_GUID}${errorpage}`)
        }

  }

  if(!init){
      setInit(true);
      processPayload();
  }

  return (
    <div>Validating Link...</div>
  );
}

// http://localhost:3002/48f11280-4e94-4605-b286-dc7e2202fa27/landing?payload=UP7kRrJZUmdwyHJwvtOxKA7IBm-lKBhMEjdDUymCcPQHFXNRdISvC1gyZz-jC3ClPTtgcft9SMqYdHjX8I9lJSxIRKfzaxQlmqgMHUn9e4lUTJHHfkhp7ycSngzEwzUXdDzDLjPHoZBgTXVD7fJLV_74x5I3Xqguzf4D1xyJCGh3LEcTSn18zuwtfMsWBvIlnVEXxUYHOEQNPRCGQULipopEmBA_A21n7pX2OS1mWT8n6z79A8vlI4fwqwtowkkDKPZmyf0KXyLsj07HgSPG2bNvU6W2wE8VdiEmmzLqR89lIgEuLR_uJwJDvCSSbYSjx-LzseKMSkmIY3UomScZIknF1MwgreKmN8VPEvqbE05wRaxkBXIdwAfpq5iBpudQuRppOWyebX1G5TDdIjYd01xTL6dRdybyf9C5zBYpApbeDtpNEoJdZpRXNuyni6os_joCXO5GkXl617taGJv6pM1Amkj5awQsz-QuiblDuyVP4VPCtk_ccajM0HGoMbwMEL5L
