import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/styles';
import MuiAlert from '@material-ui/lab/Alert';
import _ from 'lodash';

import { Grid, FormControl, Button, Snackbar, CircularProgress } from '@material-ui/core';
import { PHASE } from '../../constants';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const styles = (theme) => ({
  root: {
    padding: theme.spacing(1),
    width: '100%',
    flexGrow: 1,
    background: '#f1f4f6',
  },
  content: {
    padding: 5,
    paddingTop: 0,
    textAlign: 'left',
    background: '#F1F4F6',
  },
  links: {
    wordBreak: 'break-word',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  button: {
    marginTop: 18,
    textAlign: 'center',
    letterSpacing: '0.04em',
    padding: '0.6em 0.6em',
    width: '160px',
    fontFamily: "'Montserrat', sans-serif",
    '&:hover': {
      opacity: 0.7,
    },
    fontSize: '12px',
    backgroundColor: '#5A5F63',
  },
  requiredLabel: {
    // color: "rgba(0, 0, 0, 0.54)",
    color: '#2e2e2e',
    margin: '0 0 0 8px',
  },
});

class GenericSVButton extends React.Component {
  state = {
    loading: false,
    verificationLink: '',
    open: false,
    severity: 'info',
    msg: '',
    init: false,
    classes: null,
  };

  populateTextArea = async (text) => {
    console.log(text);
    window.top.postMessage(JSON.stringify({ appendResponse: text }), '*');
  };

  populateCRMTransfer = async (event) => {
    event.preventDefault();

    if (!document.referrer || document.referrer === '') {
      console.log('No referrer');
      return;
    }

    const customer = document.referrer.split('//')[1].split('.')[0];
    console.log('Customer', customer);

    this.setState({ loading: true, verificationLink: '' });
    const { apiManager, intl } = this.props;
    const { payload } = this.state;

    const initData = await apiManager.init(payload, `${customer}-${PHASE}`);
    if (initData) {
      console.log(initData);
      const { jwt } = initData;
      sessionStorage.setItem('token', jwt);
    }

    const longLink = `/default/landing?payload=${payload}&customer=${customer}`;
    console.log(longLink);
    const data = { url: longLink };
    const verificationResponse = await apiManager.sendSecureSocialVerification(
      data,
      `${customer}-${PHASE}`,
    );

    if (verificationResponse && verificationResponse.shortLink) {
      const shortenedLink = verificationResponse.shortLink;
      console.log('Successfully shortened url - ' + shortenedLink);
      const urlText = shortenedLink; // intl.formatMessage({ id: 'socialVerificationButton.verifyText'}, { link: shortenedLink });
      this.populateTextArea(urlText);
      this.setState({ verificationLink: shortenedLink });
    } else {
      this.populateTextArea(longLink);
      this.setState({
        msg: intl.formatMessage({
          id: 'generic.socialVerificationButton.error',
        }),
        open: true,
        severity: 'error',
        verificationLink: longLink,
      });
    }

    this.setState({ loading: false });
  };

  componentDidMount() {
    if (window.location.search.indexOf('?payload=') > -1) {
      const urlParams = new URLSearchParams(window.location.search);
      const payload = urlParams.get('payload');
      this.setState({ payload });
    }
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <div className={classes.content}>
          <Grid container spacing={2}>
            <Grid item lg={12} xs={12}>
              <FormControl className={classes.formControl}>
                <Button
                  className={classes.button}
                  variant="contained"
                  color="primary"
                  onClick={this.populateCRMTransfer}
                  disabled={this.state.loading}
                >
                  <FormattedMessage id="generic.socialVerificationButton.label" />
                </Button>
                {this.state.loading && (
                  <CircularProgress size={24} className={classes.buttonProgress} />
                )}
              </FormControl>
            </Grid>
            <Grid item lg={6} xs={12}>
              <Snackbar
                open={this.state.open}
                autoHideDuration={6000}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                onClose={(e) => this.setState({ open: !this.state.open })}
              >
                <Alert
                  onClose={(e) => this.setState({ open: !this.state.open })}
                  severity={this.state.severity}
                >
                  {this.state.msg}
                </Alert>
              </Snackbar>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(injectIntl(GenericSVButton));
