import React from 'react';
import PropTypes from 'prop-types'; 
import logo from './logo.png'; 

const KhorosLogo = ({  }) => (
  <img src={logo} alt={'Logo'} width={116} height={70}/>
);

KhorosLogo.propTypes = {
  className: PropTypes.string
};

export default KhorosLogo;
