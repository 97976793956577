import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { FormattedMessage, useIntl } from 'react-intl';

import moment from 'moment';
import Header from "../Landing/components/Header";
import Footer from "../Landing/components/Footer";
import '../Landing/style.css'
import _ from 'lodash';
import { Grid, Typography, Select, MenuItem, FormControl, InputLabel, TextField, Button, Snackbar, CircularProgress } from '@material-ui/core';

import { KhorosLogo as Logo } from '../../../../components/logos/index';

//https://secure.stage.talktalk.co.uk/t5/custom/page/page-id/error

const useStyles = makeStyles((theme) => ({ 
  header: {
    // "color": "#fff !important",
    // "backgroundColor": "#2c4047 !important",
    // "display": "flex",
    // "fontSize": "20px",
    // "fontWeight": "200",
    // "margin": "0 auto",
    // "maxWidth": "100%",
    color: 'white',
    marginRight: 'auto',
    backgroundColor: '#2C4047',
    marginRight: 'auto !important',
    marginLeft: 'auto',
    textAlign: 'center',
    alignItems: 'center',
    margin: '0 auto',
    padding:' 0 20px'
    },
  error404: {
    boxSizing: 'border-box',
    display: 'block'
  },
  figure: {
    fontSize: '14px',
    lineHeight: '1.71428571',
    color: '#3e3e3e',
    textAlign: 'center',
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
    margin: '0 auto',
    maxWidth: '750px',
    padding:' 0 20px'
  },
  img: {
    fontSize: '14px',
    lineHeight: '1.71428571',
    color: '#3e3e3e',
    textAlign: 'center',
    marginRight: 'auto',
    marginLeft: 'auto',
    border: 0,
    verticalAlign: 'middle',
    paddingRight: '40px',
    width: '50%'
  },
  figCaption: {
    lineHeight: '1.71428571',
    color: '#3e3e3e',
    textAlign: 'left',
    boxSizing: 'border-box',
    display: 'block',
    fontSize: '20px',
    marginRight: 'auto',
    marginLeft: 'auto',
  },
  h2: {
    textAlign: 'center',
    fontWeight: 'normal',
    lineHeight: 1.2,
    color: 'inherit',
    margin: 0,
    marginBottom: '10px',
    marginRight: 'auto',
    marginLeft: 'auto',
  },
  p: {
    lineHeight: 1.71428571,
    color: '#3e3e3e',
    textAlign: 'center',
    fontSize: '20px',
    margin: '0 0 12px',
    marginBottom: '20px',
  },
  a: {
    lineHeight: 1.71428571,
    fontSize: '20px',
    textDecoration: 'none',
    background: '#23519d',
    borderRadius: '2px',
    color: '#fff',
    display: 'block',
    padding: '5px 20px',
    textAlign: 'center',
    transition: 'background-color .3s ease',
    width: '75%',
  }
}));

export default function Error ({}) {
  const classes = useStyles();

  return (
    <Card className={classes.card}>  
    <Card style={{  color: 'white',
    marginRight: 'auto',
    backgroundColor: '#2C4047',
    marginRight: 'auto !important',
    marginLeft: 'auto',
    textAlign: 'center',
    alignItems: 'center',
    margin: '0 auto',
    padding:' 0 20px'}} className={classes.header}>
      <header>
        <h1><a href="https://www.talktalk.co.uk/" target="_blank">Talk Talk For Everyone</a></h1>
    </header>
  </Card>
      <CardContent>
        <div className={classes.error404}>
          <figure className={classes.figure}>
            <img className={classes.img} src="https://community.talktalk.co.uk/html/@1879A4F0F29B09E91F48A7BE23E98A61/assets/404-bath.svg"/>
          </figure>
          <figcaption className={classes.figCaption}>
            <Typography variant="h4" component="h2" className={classes.h2}>
              <span>Something went wrong.</span>
            </Typography>
          
            <Typography variant="body2" color="textSecondary" component="p" className={classes.p}>
            Oops! You shouldn’t be seeing this. 
Please return to the social channel and contact your agent to try again.
            </Typography>
          </figcaption>
        </div>
      
      
    </CardContent> 
    <Card className={classes.header}>
      <CardContent>
        <Typography variant="body2" component="p">
          <small>© TalkTalk - { moment().format('YYYY') } </small>
        </Typography>
      </CardContent>
    </Card>
  </Card>
  );
}