import React from 'react';
import PropTypes from 'prop-types';
import fidelityLogo from './fidelityLogo.svg'

const FidelityLogo = () => (
    <img src={fidelityLogo} alt={'Fidelity Logo'} height={60} style={{ position: 'relative' }} />
);

FidelityLogo.propTypes = {
    className: PropTypes.string
};


export default FidelityLogo;