import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import { red } from '@material-ui/core/colors';
import PropTypes from 'prop-types';
import CommonText from "../CommonText";

const defaultState = { 
  email: '',
  phone: '',
  emailValid: false
};

const useStyles = makeStyles((theme) => ({
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: red[500],
  },
  formControl: {
    margin: '20px 0px 0px',
    minWidth: '100%',
    textAlign: 'left'
  },
  next: {
    position: 'relative',
    left: '85%'
  }
}));

export default function ForgotPassword({ handleSuccess, handleError, updateParentState }) {
  const classes = useStyles();
 
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [emailValid, setEmailValid] = useState(false)
  
  const validateField = (fieldName, value) => {
    let isValid = emailValid;

    switch(fieldName) {
      case 'email':
        isValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
        if(!emailValid) { 
          isValid = false; 
          handleError('Please enter a valid email address.');
        } else {
          isValid = true;
        }
        break;
      default:
        break;
    }

    setEmailValid(isValid);
  }

  const validate = () => {
    return email.length === 0 || phone.length === 0;
  }

  const handleNext = async (event) => {
    if(validate()){
      if(!emailValid) {
        handleError('Please enter a valid email address.');
      } else {
        handleError('You must fill out all items in the form!');  
      }
    } else {
      updateParentState('forgotPassword', {email, phone});
      handleSuccess(event);
    }
  }

  const handleChange = (event) => {
    switch (event.target.name) {
      case 'email':
        setEmail(event.target.value);
        break;
      case 'phone':
        setPhone(event.target.value);
        break;
    }
  }

  return (
    <Card className={classes.card}>  
      <CardContent>
      <CommonText /> 
        <TextField 
            margin="normal"
            required
            fullWidth
            id="email"
            label="Your registered email address"
            name="email" 
            autoFocus
            type="email"
            onChange={handleChange}
          />
          <TextField 
            margin="normal"
            required
            fullWidth
            id="phone"
            label="Your mobile number"
            type="number"
            name="phone"  
            onChange={handleChange}
          /> 
      </CardContent>
      <CardActions disableSpacing>
       <Button
        className={classes.next} 
        onClick={handleNext}
        color="primary" 
        >Next</Button>
      </CardActions> 
    </Card>
  );
} 

//export default withStyles(useStyles)(ForgotPassword);