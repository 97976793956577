import React from 'react';
import PropTypes from 'prop-types';
import logo from './logo.svg';

const KoodoLogo = ({  }) => (
  <img src={logo} alt={'Logo'} width={130} height={28}/>
);

KoodoLogo.propTypes = {
  className: PropTypes.string
};

export default KoodoLogo;
