import React, { useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import axios from 'axios';
import _ from 'lodash';
import $ from 'jquery';
import { makeStyles } from '@material-ui/styles';
import { Grid, FormControl, Button, Snackbar, CircularProgress } from '@material-ui/core';

import MuiAlert from '@material-ui/lab/Alert';

import { TELUS_GUID, KOODO_GUID, PHASE } from '../../../constants';

const LANDING_PATH = `${TELUS_GUID}/landing`;
const LANDING_PATH_KOODO = `${KOODO_GUID}/landing`;
let TENANT_ID = 'sv-telus';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  bodyCare: {
    backgroundColor: '#f1f4f6',
  },
  root: {
    padding: theme.spacing(1),
    width: '100%',
    flexGrow: 1,
    background: '#f1f4f6',
  },
  content: {
    padding: 5,
    paddingTop: 0,
    textAlign: 'left',
    background: 'none',
  },
  links: {
    wordBreak: 'break-word',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  button: {
    marginTop: 18,
    textAlign: 'center',
    borderRadius: '10em',
    letterSpacing: '0.04em',
    padding: '0.6em 0.6em',
    width: '280px',
    fontFamily: '\'Montserrat\', sans-serif',
    '&:hover': {
      opacity: 0.7,
    },
  },
  requiredLabel: {
    color: 'rgba(0, 0, 0, 0.54)',
    margin: '0 0 0 8px',
  },
}));

const SvButton = (props) => {
  const classes = useStyles();

  const { apiManager, history } = props;
  const intl = useIntl();

  const [loading, setLoading] = useState(false);
  const [verificationLink, setVerificationLink] = useState('');

  const [open, setOpen] = useState(false);
  const [severity, setSeverity] = useState('');
  const [msg, setMsg] = useState('');
  const [init, setInit] = useState(false);

  useEffect(() => {
    document.body.className = classes.bodyCare;
  }, [classes.bodyCare]);

  const populateTextArea = async (text) => {
    console.log(text);
    window.top.postMessage(JSON.stringify({ appendResponse: text }), '*');
  };

  const generateSecureVerificationLink = async (brand) => {
    setVerificationLink('');
    setLoading(true);
    if (brand !== 'telus'){
      TENANT_ID = 'sv-koodo';
    }
    // const initData = await apiManager.init(payload, `telus-${PHASE}`);
    const initData = await apiManager.authorInit(payload, TENANT_ID);
    // if(initData){
    //   const { jwt } = initData;
    //   sessionStorage.setItem("token", jwt);
    // }

    let linkPath =
      brand === 'telus'
        ? `${LANDING_PATH}?payload=${payload}`
        : `${LANDING_PATH_KOODO}?payload=${payload}`;
    
    // const verificationResponse = await apiManager.sendSecureSocialVerification(data, `telus-${PHASE}`);
    const shortenerServiceData = await apiManager.firebaseShortUrl(linkPath, TENANT_ID);

    if (!shortenerServiceData && !shortenerServiceData.shortLink) {
      const urlText = intl.formatMessage(
        { id: 'socialVerificationButton.verifyText' },
        { link: linkPath },
      );
      setLoading(false);
      populateTextArea(urlText);
      setMsg(intl.formatMessage({ id: 'socialVerificationButton.error' }));
      setOpen(true);
      setSeverity('error');
      setVerificationLink(linkPath);
    }
    const shortenedLink = shortenerServiceData.shortLink;
    console.log('Successfully shortened url - ' + shortenedLink);
    const urlText = intl.formatMessage(
      { id: 'socialVerificationButton.verifyText' },
      { link: shortenedLink },
    );
    populateTextArea(urlText);
    setVerificationLink(shortenedLink);
  };

  let payload = '';
  if (window.location.search.indexOf('?payload=') > -1) {
    payload = window.location.search.replace('?payload=', '');
  }
  const populateTelusTransfer = async (event) => {
    event.preventDefault();
    setLoading(true);
    generateSecureVerificationLink('telus');
    setLoading(false);
  };

  const populateKoodoTransfer = async (event) => {
    event.preventDefault();
    setLoading(true);
    generateSecureVerificationLink('koodo');
    setLoading(false);
  };

  const getParameterByName = (name, url) => {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  };

  const executeSalesforceApi = async () => {
    // Case id should be obtained from the output of  window.location.href
    // "https://telus.response.lithium.com/console/agent/39154499?"
    try {
      let caseId = getParameterByName('caseId', window.location.search);
      let caseCreateBody = { displayId: caseId };
      let headers = this.getHeaders();
      let data = JSON.stringify(caseCreateBody);
      const response = await axios.post(
        'https://api.cloud-elements.com/elements/api-v2/formulas/7243/instances/726279/executions',
        data,
        { headers },
      );
      return response.data;
    } catch (err) {
      console.log(err);
      return false;
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <Grid container justify="left" spacing={2}>
          <Grid item lg={12} xs={12}>
            <FormControl className={classes.formControl}>
              <Button
                className={classes.button}
                variant="contained"
                color="primary"
                onClick={populateTelusTransfer}
                disabled={loading}
              >
                <FormattedMessage id="telusSecureVerification.button.label" />
              </Button>
              <Button
                className={classes.button}
                variant="contained"
                color="primary"
                onClick={populateKoodoTransfer}
                disabled={loading}
              >
                <FormattedMessage id="koodoButton.label" />
              </Button>
              {/* <Button
                className={classes.button}
                variant="contained"
                color="primary"
                onClick={executeSalesforceApi}
                disabled={loading}
              >
                <FormattedMessage id="salesforceButton.label" />
              </Button> */}
              {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
            </FormControl>
          </Grid>
          <Grid item lg={6} xs={12}>
            <Snackbar
              open={open}
              autoHideDuration={6000}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
              onClose={(e) => setOpen(!open)}
            >
              <Alert onClose={(e) => setOpen(!open)} severity={severity}>
                {msg}
              </Alert>
            </Snackbar>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default SvButton;
