import React from 'react';
import PropTypes from 'prop-types';
import fidelityLogo from './fidelityLogo.svg';

const Manualc01Logo = () => (
  <img src={fidelityLogo} alt={'Fidelity Logo'} height={60} style={{ position: 'relative' }} />
);

Manualc01Logo.propTypes = {
  className: PropTypes.string,
};

export default Manualc01Logo;
